import 'react-app-polyfill/ie11';
import 'react-app-polyfill/stable';
import React, { useMemo } from 'react';
import { createRoot } from 'react-dom/client';
import styled, { ThemeProvider } from 'styled-components';
import { BrowserRouter } from 'react-router-dom';
import * as serviceWorker from './serviceWorker';
import GlobalStyle from './styles/main';
import { ThemeProps } from './interfaces/globals/theme';
import App from './App';
import { getTheme } from './styles/variables';
import defaultTheme from './styles/themes/default.json';
import rootReducer, { initialState } from './store/store';
import ApplicationState from './interfaces/globals/appState';
import { AuthProvider, AuthProviderProps } from './hooks/session';

const Flex = styled.div<ThemeProps>`
    display: flex;
    height: 100%;
    flex-direction: column;
`;

interface IProviderProps {
    children: JSX.Element[];
}

const oidcConfig: AuthProviderProps = {
    authority: process.env.REACT_APP_OPENID_AUTHORITY!,
    client_id: process.env.REACT_APP_OPENID_CLIENTID!,
    redirect_uri: window.location.href,
};

export interface IGlobalStore {
    state: ApplicationState;
    dispatch: Function;
}

export const GlobalStore = React.createContext<IGlobalStore>({
    state: initialState,
    dispatch: () => null,
});

const asyncer =
    (dispatch: Function, state: ApplicationState) =>
    (action: (arg0: Function, arg1: ApplicationState) => unknown) =>
        typeof action === 'function' ? action(dispatch, state) : dispatch(action);

const Provider = ({ children }: IProviderProps) => {
    const [state, dispatchBase] = React.useReducer(rootReducer, initialState);

    // eslint-disable-next-line react-hooks/exhaustive-deps
    const dispatch = React.useCallback(asyncer(dispatchBase, state), []);

    const gState = useMemo(() => ({ state, dispatch }), [dispatch, state]);

    return <GlobalStore.Provider value={gState}>{children}</GlobalStore.Provider>;
};

const container = document.getElementById('root');
const root = createRoot(container!);

root.render(
    <AuthProvider {...oidcConfig}>
        <Provider>
            <GlobalStyle theme={getTheme({ ...defaultTheme })} />
            <ThemeProvider theme={getTheme({ ...defaultTheme })}>
                <Flex>
                    <BrowserRouter basename={process.env.PUBLIC_URL}>
                        <App />
                    </BrowserRouter>
                </Flex>
            </ThemeProvider>
        </Provider>
    </AuthProvider>
);

// If you want your app to work offline and load faster, you can change
// unregister() to register() below. Note this comes with some pitfalls.
// Learn more about service workers: https://bit.ly/CRA-PWA
serviceWorker.unregister();
