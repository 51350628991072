import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';

const Spacer: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
    <SpacerWrapper>{children}</SpacerWrapper>
);

export default Spacer;

const SpacerWrapper = styled.div`
    display: flex;
    flex-direction: row;
`;
