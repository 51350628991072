import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';

export const ButtonPanel = styled.div<ThemeProps>`
    border-top: ${props => props.theme.$border.secondary.thin};
    display: flex;
    flex-direction: row;
    justify-content: space-around;
    padding-top: 0.8rem;
    margin-top: 0.3rem;
`;

interface StyledButtonProps {
    disabled?: boolean;
}

// prettier-ignore
export const OKButton = styled.button<ThemeProps & StyledButtonProps>`
  border: 1px solid ${props => props.disabled ? props.theme.$colorGrey20 : 'transparent'};
  border-radius: 0.1rem;
  max-width: 14rem;
  max-height: 1.7rem;
  text-align: center;
  padding: 0.11rem 0.3rem;
  margin: 0.4rem 0.1rem;
  background-color: ${props => props.disabled ? props.theme.$colorGrey20 : 'transparent'};
  font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;
  color: ${props => props.theme.$colorFontSelected};
`;

// prettier-ignore
export const CancelButton = styled.button<ThemeProps>`
  border: 1px solid ${props => props.theme.$colorPrimary};
  border-radius: 0.2rem;
  max-width: 14rem;
  max-height: 1.7rem;
  text-align: center;
  padding: 0.1rem 0.3rem;
  margin: 0.4rem 0.1rem;
  background-color: transparent;
  font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;
  color: ${props => props.theme.$colorFontSelected};
`;
