/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import { t } from '../../../../lib/language';
import { LanguageInformation } from '../../../../typings/mgmt';

interface EditLanguageProps {
    language: [LanguageInformation | undefined, Function];
}

const EditLanguageInformation: FunctionComponent<EditLanguageProps> = ({ language }) => {
    const localLanguage: LanguageInformation | undefined = language[0];

    return (
        <EditLanguageInformationWrapper>
            <EditLanguageInformationInner>
                {localLanguage && language[0] ? (
                    <>
                        <p>Language Informationen</p>
                        <form>
                            <label>
                                Name:
                                <input
                                    type="text"
                                    name="name"
                                    value={localLanguage.name}
                                    disabled
                                />
                            </label>
                            <label>
                                Code:
                                <input
                                    type="text"
                                    name="code"
                                    value={localLanguage.code}
                                    disabled
                                />
                            </label>
                        </form>
                    </>
                ) : (
                    <p className="center-p">{t('EditLanguageInformation')}</p>
                )}
            </EditLanguageInformationInner>
        </EditLanguageInformationWrapper>
    );
};

export default EditLanguageInformation;

const EditLanguageInformationWrapper = styled.div<ThemeProps>`
    height: 75%;
    margin: 5px 10px;
    border: solid;
    border-width: 0.5px;
`;

const EditLanguageInformationInner = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    border: solid;
    border-width: 0.5px;
    margin: 5px;

    .center-p {
        text-align: center;
    }

    p {
        margin: 5px;
        font-weight: bold;
    }

    form {
        display: flex;
    }

    label {
        margin: 5px 50px 0 0;
        font-size: small;
    }

    input {
        margin-left: 10px;
    }

    select {
        margin-left: 10px;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;
