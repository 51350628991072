import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { FormFieldProps } from '../interfaces/FormField';

const LabelField: FunctionComponent<FormFieldProps> = ({ id, value }): ReactElement => (
    <Label
        id={id}
        data-testid={id}
    >
        {value}
    </Label>
);

const Label = styled.p<ThemeProps>`
    background-color: ${props => props.theme.$colorGrey10};
    margin: 0.4rem 1rem;
    color: 'transparent';
`;

export default LabelField;
