import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import DropZone from '../../../../components/DropZone/DropZone';
import { useGlobalStore } from '../../../../hooks/hooks';
import { ThumbnailPageProps, ThumbnailProps } from '../../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { handleImageUpload } from '../../../../lib/util';
import { emptyThumbnailPage } from '../../../../store/document/reducers';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { ResponseError } from '../../../../typings/error';
import { BoolSwapState } from '../../../../typings/global';
import ImageHeader from './imageHeader/ImageHeader';
import PageNavigation from './pageNavigation/PageNavigation';
import ThumbnailPage from './thumbnail/ThumbnailPage';

interface ImageSilderNewProps {
    imageSliderVisible: BoolSwapState;
    reloadData: BoolSwapState;
    showAdditionalControls: boolean;
    openState: BoolSwapState;
}

const ImageSilderNew: FunctionComponent<ImageSilderNewProps> = ({
    imageSliderVisible,
    reloadData,
    showAdditionalControls,
    openState,
}) => {
    const { state, dispatch } = useGlobalStore();
    const { currentArticle } = state.documentManager;
    const [previousArticleID, setPreviousArticleID] = useState<number>(-1);
    const [thumbnailPage, setThumbnailPage] = useState<ThumbnailPageProps>(emptyThumbnailPage);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const [previousPageNumber, setPreviousPageNumber] = useState<number>(-1);
    const currentTenant = state.config.tenant.current;

    useEffect(() => {
        if (
            currentPageNumber !== previousPageNumber ||
            currentArticle.articleID !== previousArticleID ||
            reloadData.value
        ) {
            if (openState.value || currentArticle.articleID > 0) {
                dispatch(startOperation());
                AxiosClient.get(
                    `${requestURLs.images}/thumbs/page/${currentPageNumber}?articleID=${currentArticle.articleID}`
                )
                    .then(response => setThumbnailPage(response.data))
                    .catch(error =>
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        )
                    )
                    .finally(() => dispatch(finishOperation()));
            }
            reloadData.set(false);
            setPreviousArticleID(currentArticle.articleID);
            setPreviousPageNumber(currentPageNumber);
        }
    }, [
        currentArticle.articleID,
        currentPageNumber,
        dispatch,
        openState.value,
        previousArticleID,
        previousPageNumber,
        reloadData,
    ]);

    function newImageReferenceToArticle(response: ThumbnailProps) {
        const newImageReference = {
            articleID: currentArticle.articleID,
            imageID: response.imageID,
        };
        dispatch(startOperation());
        AxiosClient.post(
            `${requestURLs.editorArticles}${currentArticle.articleID}/images`,
            newImageReference
        )
            .then(() => {
                if (openState) reloadData.set(true);
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => dispatch(finishOperation()));
    }

    function handleOnPaste(e: React.ClipboardEvent<HTMLDivElement>) {
        if (currentTenant?.autoNameImages) {
            const autoImageName = `Bild_${
                currentTenant.tenantID === 'f787a377-3d69-4cae-8eef-5975ea67b640'
                    ? '4'
                    : currentTenant.tenantID
            }_autoImageNameTmp`;
            handleImageUpload(
                autoImageName,
                reloadData,
                e.clipboardData.files,
                dispatch,
                newImageReferenceToArticle
            );
        }
    }

    return imageSliderVisible.value ? (
        <DropZone
            id="image-slider-dropzone"
            handleOnPaste={handleOnPaste}
        >
            <ImageSliderWrapper>
                <ImageHeader
                    showAdditionalControls={showAdditionalControls}
                    imageSliderVisible={imageSliderVisible}
                />
                <ThumbnailPage
                    openImageLibrary={openState.set}
                    thumbnailPage={thumbnailPage}
                    openState={openState.value}
                    reloadData={reloadData}
                    showAdditionalControls={showAdditionalControls}
                />
                <PageNavigation
                    thumbnailPages={thumbnailPage}
                    currentPageNumberState={{
                        value: currentPageNumber,
                        set: setCurrentPageNumber,
                    }}
                />
            </ImageSliderWrapper>
        </DropZone>
    ) : (
        <div />
    );
};

export default ImageSilderNew;

const ImageSliderWrapper = styled.div<ThemeProps>`
    height: 100%;
    display: flex;
    flex-direction: column;
`;
