import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';

const PanelWrapper: FunctionComponent<{ children?: ReactNode }> = ({ children }): ReactElement => (
    <PanelStyle>{children}</PanelStyle>
);

// prettier-ignore
const PanelStyle = styled.div<ThemeProps>`
    position: fixed;
    top: 60px;
    width: 100%;
    padding: .1rem .25rem;
    left: 0;
    z-index: 1000;
`;

export default PanelWrapper;
