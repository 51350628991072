import React, { FunctionComponent, ReactElement, useLayoutEffect } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import { IList } from '../../../hooks/hooks';
import { ThemeProps } from '../../../interfaces/globals/theme';
import Icon from '../../../components/Icon/Icon';
import LoadingScreen from '../../../views/LoadingScreen';
import { CurrentDocumentPropsType } from '../../../typings/global';

export interface DocumentListProps {
    searchID: string;
    documents: IList | undefined;
    currentDocumentProps: CurrentDocumentPropsType;
    selectedLanguages: { [documentID: string]: string };
    handleSelection: Function;
    handleDoubleClick: Function;
}

const DocumentList: FunctionComponent<DocumentListProps> = ({
    searchID,
    documents,
    selectedLanguages,
    handleSelection,
    handleDoubleClick,
    currentDocumentProps,
}): ReactElement => {
    useLayoutEffect(() => {
        let element = document.getElementsByClassName('highlighteddocument')[0] as HTMLElement;
        if (element === undefined)
            element = document.getElementsByClassName('selectedDocument')[0] as HTMLElement;

        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });

    function renderElements(): ReactElement[] | ReactElement {
        if (documents)
            return Object.values(documents).map(element => {
                let className = '';
                if (element.documentID === currentDocumentProps.value.documentID)
                    className += ' selectedDocument';

                let name = '';
                if (element.publishedCheck.published && element.publishedCheck.upToDatePublication)
                    name = 'ok';
                else if (
                    element.publishedCheck.published &&
                    !element.publishedCheck.upToDatePublication
                )
                    name = 'warn';
                return (
                    <span key={weakKey(element)}>
                        <button
                            type="button"
                            data-id={element.documentID}
                            className={className}
                            onClick={event => handleSelection(event)}
                            onDoubleClick={event =>
                                handleDoubleClick ? handleDoubleClick(event) : null
                            }
                        >
                            {element.descriptions[selectedLanguages[element.internalDocumentID]] !==
                            undefined
                                ? element.descriptions[
                                      selectedLanguages[element.internalDocumentID]
                                  ].title
                                : 'pending'}
                        </button>
                        <Icon
                            name={name}
                            className={name}
                        />
                    </span>
                );
            });
        return <LoadingScreen />;
    }

    return (
        <ElementListWrapper
            id={searchID}
            className="styledScrollBar"
        >
            {renderElements()}
        </ElementListWrapper>
    );
};

export const ElementListWrapper = styled.div<ThemeProps>`
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
    padding: 0.2rem 0 0.2rem 0;
    flex: 1;
    overflow: auto;
    span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: ${props => props.theme.$border.grey.dotted[20]};

        svg {
            width: 1.5rem;
            height: 1.5rem;
            margin: auto 0.2rem;
        }

        svg.ok {
            stroke: green;
            fill: green;
        }

        svg.warn {
            stroke: ${props => props.theme.$colorPrimary};
            fill: ${props => props.theme.$colorPrimary};
        }

        button {
            width: 100%;
            background-color: ${props => props.theme.$colorWhite};
            text-align: left;
            border: none;
            outline: none;
            box-shadow: none;
            padding: 0.3rem 0;
        }

        button.highlightedarticle,
        button.highlighteddocument {
            background-color: ${props => props.theme.$colorBackgroundSecondary} !important;
            color: ${props => props.theme.$colorFontSelected};
        }

        button:hover {
            background-color: ${props => props.theme.$colorGrey10};
        }

        button.selectedDocument {
            background-color: ${props => props.theme.$colorBackgroundSelected};
            color: ${props => props.theme.$colorFontSelected};
        }
    }
`;

export default DocumentList;
