/* eslint-disable max-len */
import React, { FunctionComponent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import { useGlobalStore } from '../../../hooks/hooks';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { ThemeProps } from '../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { t } from '../../../lib/language';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import { LanguageInformation } from '../../../typings/mgmt';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import TextInputDialog from '../../../components/dialogs/InputDialog';
import Entry from './Entry';
import { DocumentCategoryProps } from '../../../interfaces/DocumentEntry';
import { emptyCategory } from '../../../store/document/reducers';
import { ResponseError } from '../../../typings/error';
import { TenantInformation } from '../../../store/config/types';

export interface UserAndTenantsProps {
    tenants: [{ [tenantID: number]: TenantInformation }, Function];
    newLengthOfTenantsList: [number, Function];
    categories: { [categoryID: number]: DocumentCategoryProps };
    newLengthOfCategoryList: [number, Function];
    languages: { [languageID: number]: LanguageInformation };
    newLengthOfLanguageList: [number, Function];
    toggleNavigationState: [string, Function];
    currentTenantState: [TenantInformation, Function];
    currentCategoryState: [DocumentCategoryProps | undefined, Function];
    currentLanguageState: [LanguageInformation | undefined, Function];
}

const SideView: FunctionComponent<UserAndTenantsProps> = ({
    tenants,
    newLengthOfTenantsList,
    categories,
    newLengthOfCategoryList,
    languages,
    newLengthOfLanguageList,
    toggleNavigationState,
    currentTenantState,
    currentCategoryState,
    currentLanguageState,
}): ReactElement => {
    const { dispatch } = useGlobalStore();
    const [showInputDialog, setShowInputDialog] = useState<boolean>(false);
    const [showLangCodeInput, setShowLangInput] = useState<boolean>(false);
    const [showConfirmationDialog, setshowConfirmationDialog] = useState<boolean>(false);
    const [newLangName, setNewLangName] = useState<string>('');

    function composeEntries(
        tenantData: { [tenantID: number]: TenantInformation },
        categoryData: { [categoryID: number]: DocumentCategoryProps },
        languageData: { [languageID: number]: LanguageInformation },
        expression: string
    ) {
        const children: ReactElement[] = [];

        switch (expression) {
            case 'tenants':
                Object.entries(tenantData).forEach(entry => {
                    const [key, value] = entry;
                    children.push(
                        <Entry
                            key={weakKey(value) + key}
                            tenant={value}
                            underline={`Tenant ID: ${value.tenantID}`}
                            currentTenantState={currentTenantState}
                        />
                    );
                });
                break;

            case 'categories':
                Object.entries(categoryData).forEach(entry => {
                    const [key, value] = entry;
                    children.push(
                        <Entry
                            key={weakKey(value) + key}
                            category={value}
                            underline={`Category ID: ${value.categoryID}`}
                            currentCategoryState={currentCategoryState}
                        />
                    );
                });
                break;

            case 'languages':
                Object.entries(languageData).forEach(entry => {
                    const [key, value] = entry;
                    children.push(
                        <Entry
                            key={weakKey(value) + key}
                            language={value}
                            underline={`Language ID: ${value.languageID}`}
                            currentLanguageState={currentLanguageState}
                        />
                    );
                });
                break;

            default:
                break;
        }

        if (children.length === 0)
            children.push(
                <Entry
                    key="noCategory"
                    underline={t('SideView.Underline.Entry')}
                />
            );

        return children;
    }

    function saveTenant(tenantName: string) {
        if (tenantName.length > 0) {
            dispatch(startOperation());
            const tenantObj = {
                name: tenantName,
                secured: true,
            };

            AxiosClient.post(`${requestURLs.tenants}`, tenantObj)
                .then(() => {
                    newLengthOfTenantsList[1](Object.entries(tenants).length + 1);
                    dispatch(addError(EErrorTypes.info, t('StatusCode.SpecialCase.SaveTenant')));
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }

    function saveCategory(category: DocumentCategoryProps) {
        dispatch(startOperation());
        if (category.categoryID === -1)
            AxiosClient.post(`${requestURLs.categories}`, category)
                .then(() => {
                    newLengthOfCategoryList[1](
                        newLengthOfCategoryList[0] > 0 ? newLengthOfCategoryList[0] + 1 : 1
                    );
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(dispatch(finishOperation()));
        else
            AxiosClient.put(`${requestURLs.categories}/${category.categoryID}`, category)
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(dispatch(finishOperation()));
    }

    function deleteCategory(category: DocumentCategoryProps) {
        if (category.categoryID === -1) return;
        dispatch(startOperation());
        AxiosClient.delete(`${requestURLs.categories}/${category.categoryID}`)
            .then(() => {
                newLengthOfCategoryList[1](Object.entries(categories).length + 1);
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(dispatch(finishOperation()));
    }

    function saveLanguage(name: string, code: string) {
        dispatch(startOperation());
        if (name.length > 0 && code.length > 0) {
            const lang = {
                code,
                name,
            };
            AxiosClient.post(`${requestURLs.languages}`, lang)
                .then(() => {
                    newLengthOfLanguageList[1](Object.entries(languages).length + 1);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(dispatch(finishOperation()));
        }
    }

    function deleteLanguage(language: LanguageInformation) {
        if (language.languageID === -1) return;
        dispatch(startOperation());
        AxiosClient.delete(`${requestURLs.languages}/${language.languageID}`)
            .then(() => {
                newLengthOfLanguageList[1](Object.entries(languages).length + 1);
            })
            .catch(error =>
                dispatch(addError(EErrorTypes.error, (error.response.data as ResponseError).status))
            )
            .finally(dispatch(finishOperation()));
    }

    return (
        <UserAndTenantsWrapper>
            <Navigation>
                <NavigationTap
                    active={toggleNavigationState[0] === 'tenants'}
                    onClick={() => toggleNavigationState[1]('tenants')}
                >
                    Tenants
                </NavigationTap>
                <NavigationTap
                    active={toggleNavigationState[0] === 'categories'}
                    onClick={() => toggleNavigationState[1]('categories')}
                >
                    Kategorien
                </NavigationTap>
                <NavigationTap
                    active={toggleNavigationState[0] === 'languages'}
                    onClick={() => toggleNavigationState[1]('languages')}
                    className="last-tap"
                >
                    Sprachen
                </NavigationTap>
                <NavigationButtonWrapper>
                    {toggleNavigationState[0] === 'tenants' && (
                        <>
                            <ActionControlButton
                                prefix="image"
                                name="new-user"
                                callback={() => setShowInputDialog(true)}
                                size={24}
                            />
                            <TextInputDialog
                                id="add-new-tenant"
                                title="Neuen Tenant erstellen"
                                message="Geben Sie den Namen des Tenants ein und bestätigen Sie mit OK"
                                onCancel={() => {
                                    setShowInputDialog(false);
                                }}
                                onOK={() => {
                                    const inputValueFromDialog = (
                                        document.getElementById(
                                            'add-new-tenant-input'
                                        ) as HTMLInputElement
                                    ).value;
                                    saveTenant(inputValueFromDialog);
                                }}
                                isOpenState={[showInputDialog, setShowInputDialog]}
                            />
                        </>
                    )}
                    {toggleNavigationState[0] === 'categories' && (
                        <>
                            <ActionControlButton
                                prefix="image"
                                name="delete-user"
                                callback={() => setshowConfirmationDialog(true)}
                                size={24}
                            />
                            <ActionControlButton
                                prefix="image"
                                name="new-user"
                                callback={() => setShowInputDialog(true)}
                                size={24}
                            />
                            <TextInputDialog
                                id="add-new-category"
                                title="Neue Kategorie erstellen"
                                message="Geben Sie den Titel der Kategorie ein und bestätigen Sie mit OK"
                                onCancel={() => {
                                    setShowInputDialog(false);
                                }}
                                onOK={() => {
                                    const c = JSON.parse(JSON.stringify(emptyCategory));
                                    c.title = (
                                        document.getElementById(
                                            'add-new-category-input'
                                        ) as HTMLInputElement
                                    ).value;
                                    saveCategory(c);
                                }}
                                isOpenState={[showInputDialog, setShowInputDialog]}
                            />
                            <ConfirmationDialog
                                id="delete-category"
                                title="Kategorie löschen"
                                message={`Möchten Sie wirklich die Kategorie "${currentCategoryState[0]?.title}" löschen?`}
                                onCancel={() => setshowConfirmationDialog(false)}
                                onOK={() =>
                                    currentCategoryState[0]
                                        ? deleteCategory(currentCategoryState[0])
                                        : undefined
                                }
                                isOpenState={{
                                    value: showConfirmationDialog,
                                    set: setshowConfirmationDialog,
                                }}
                            />
                        </>
                    )}

                    {toggleNavigationState[0] === 'languages' && (
                        <>
                            <ActionControlButton
                                prefix="image"
                                name="delete-user"
                                callback={() => setshowConfirmationDialog(true)}
                                size={24}
                            />
                            <ActionControlButton
                                prefix="image"
                                name="new-user"
                                callback={() => setShowInputDialog(true)}
                                size={24}
                            />
                            <TextInputDialog
                                id="add-new-language-name"
                                title="Neue Sprache erstellen"
                                message="Geben Sie den Namen der Sprache (z.B Deutsch) ein und bestätigen Sie mit OK"
                                onCancel={() => {
                                    setShowInputDialog(false);
                                }}
                                onOK={() => {
                                    const name: string = (
                                        document.getElementById(
                                            'add-new-language-name-input'
                                        ) as HTMLInputElement
                                    ).value;
                                    setNewLangName(name);
                                    if (name && name.length > 0) {
                                        setShowLangInput(true);
                                    } else
                                        dispatch(
                                            addError(
                                                EErrorTypes.error,
                                                t('StatusCode.SpecialCase.EmptyNameForLang')
                                            )
                                        );
                                }}
                                isOpenState={[showInputDialog, setShowInputDialog]}
                            />
                            <TextInputDialog
                                id="add-new-language-code"
                                title="Neue Sprache erstellen"
                                message={`Geben Sie den Code für die Sprache ${newLangName} (z.B de_DE) ein und bestätigen Sie mit OK`}
                                disabled={newLangName.length <= 0}
                                onCancel={() => {
                                    setNewLangName('');
                                    setShowLangInput(false);
                                }}
                                onOK={() => {
                                    const langCode = (
                                        document.getElementById(
                                            'add-new-language-code-input'
                                        ) as HTMLInputElement
                                    ).value;
                                    saveLanguage(newLangName, langCode);
                                }}
                                isOpenState={[showLangCodeInput, setShowLangInput]}
                            />
                            <ConfirmationDialog
                                id="delete-language"
                                title="Sprache löschen"
                                message={`Möchten Sie wirklich die Sprache "${currentLanguageState[0]?.name}: ${currentLanguageState[0]?.code}" löschen?`}
                                onCancel={() => setshowConfirmationDialog(false)}
                                onOK={() =>
                                    currentLanguageState[0]
                                        ? deleteLanguage(currentLanguageState[0])
                                        : undefined
                                }
                                isOpenState={{
                                    value: showConfirmationDialog,
                                    set: setshowConfirmationDialog,
                                }}
                            />
                        </>
                    )}
                </NavigationButtonWrapper>
            </Navigation>
            <ProfileContainer className="styledScrollBar">
                {composeEntries(tenants[0], categories, languages, toggleNavigationState[0])}
            </ProfileContainer>
        </UserAndTenantsWrapper>
    );
};

const UserAndTenantsWrapper = styled.div<ThemeProps>`
    width: 20%;
    height: 100%;
`;

const Navigation = styled.div<ThemeProps>`
    display: flex;

    .last-tap {
        border-right: solid;
        border-width: 0.5px;
    }
`;

const NavigationTap = styled.div<ThemeProps & { active: boolean }>`
    padding: 5px;
    cursor: pointer;
    border-top: solid;
    border-left: solid;
    border-width: 0.5px;
    font-size: small;
    background-color: ${props => (props.active ? '#ffe6cc' : '')};
`;

const NavigationButtonWrapper = styled.div<ThemeProps>`
    width: 100%;
    display: flex;
    flex-direction: row-reverse;

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
        margin-left: 10px;
    }

    button:disabled {
        opacity: 0.5;
    }
`;

const ProfileContainer = styled.div<ThemeProps>`
    height: 764px;
    overflow-y: scroll;
    scrollbar-color: grey light-grey;
    scrollbar-width: thin;
`;

export default SideView;
