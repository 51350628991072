import ActionTypes from '../actionTypes';
import { IUserState, TUserReducerActions } from './types';

export const userReducerInitialState: IUserState = {
    userName: '',
    fullName: '',
    initials: '',
    email: '',
    permissions: {},
    token: '',
};

export const userReducer = (
    action: TUserReducerActions,
    state: IUserState = userReducerInitialState
): IUserState => {
    switch (action.type) {
        case ActionTypes.UPDATE_USER:
            return action.payload;
        case ActionTypes.STORE_USER_PERMISSIONS:
            return action.payload;
        case ActionTypes.LOGIN_USER:
            return action.payload;
        case ActionTypes.LOGOUT_USER:
            return JSON.parse(JSON.stringify(userReducerInitialState));
        default:
            return state;
    }
};
