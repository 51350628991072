import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router-dom';
import { ThemeProps } from '../../../interfaces/globals/theme';
import Icon from '../../Icon/Icon';

interface NavButtonProps {
    id: string;
    target: string;
    renderas?: string;
    className?: string;
    disabled?: boolean;
    active?: boolean;
    icon?: string;
    onClick?: Function;
    children?: ReactNode;
}

const NavButton: FunctionComponent<NavButtonProps> = ({
    id,
    target,
    children,
    renderas,
    className,
    disabled,
    active,
    icon,
    onClick,
}): ReactElement => {
    const history = useNavigate();
    return (
        <StyledNavButton
            id={id}
            renderas={renderas === undefined ? 'button' : renderas}
            className={className}
            disabled={disabled}
            active={active}
            onClick={onClick ? () => onClick() : () => history(target)}
        >
            {icon !== undefined ? <Icon name={icon} /> : children}
        </StyledNavButton>
    );
};

interface StyledNavProps {
    renderas: string;
    active?: boolean;
    disabled?: boolean;
    icon?: string;
}

// prettier-ignore
export const StyledNavButton = styled.button<ThemeProps & StyledNavProps>`
    text-decoration: none;
    line-height: 1.4;
    pointer-events: ${props => props.disabled ? 'none' : 'auto'};
    ${props =>
        props.renderas &&
        props.renderas === 'button' && `
        align-items: center;
        border: 1px solid ${!props.disabled ? props.theme.$colorSecondary : 'transparent'};
        border-radius: 0.2rem;
        margin-bottom: 1rem;
        max-width: 14rem;
        max-height: 2rem;
        text-align: center;
        padding: 0.2rem 0.4rem;
        margin: 0.5rem 0.1rem;
        background-color: transparent;
        font-size: ${props.theme.$headerStyles.small.h6.fontSize}rem;
        color: ${props.theme.$colorSecondary};
        transition: 0.3s;
        font-weight: 400;

        :hover {
            border: 1px solid ${!props.disabled ? props.theme.$colorPrimary : props.theme.$colorSecondary};
            color: ${!props.disabled ? props.theme.$colorPrimary : props.theme.$colorSecondary};
        }
    `}
    ${props =>
        props.renderas &&
        props.renderas === 'menuEntry' && `
        align-items: center;
        border: none;
        text-align: center;
        vertical-align: center;
        padding: 0.2rem 0.4rem;
        margin: 0 0.1rem;
        background-color: transparent;
        font-size: ${props.theme.$headerStyles.medium.h6.fontSize}rem;
        color: ${props.theme.$colorSecondary};
        transition: 0.3s;
        font-weight: 400;
        font-size: small;

        :hover {
            color: ${!props.disabled ? props.theme.$colorPrimary : props.theme.$colorSecondary};
        }
    `}
`;

export default NavButton;
