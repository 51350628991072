import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import LabeledFormField, { FormFieldTypes } from '../../../components/forms/LabeledFormField';
import { useGlobalStore } from '../../../hooks/hooks';
import { DocumentCategoryProps } from '../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { finishOperation, startOperation } from '../../../store/global/actions';
import LoadingScreen from '../../../views/LoadingScreen';

interface CategorySelectorProps {
    initialSelectedCategories: DocumentCategoryProps[];
    visibilityState: [boolean, Function];
    updateValues: Function;
}

const CategorySelector: FunctionComponent<CategorySelectorProps> = ({
    initialSelectedCategories,
    visibilityState,
    updateValues,
}): ReactElement => {
    const [allCategories, setAllCategories] = useState<DocumentCategoryProps[] | null>(null);
    const { dispatch } = useGlobalStore();
    const [selectedCategories, setSelectedCategories] =
        useState<DocumentCategoryProps[]>(initialSelectedCategories);

    useEffect(() => {
        if (!allCategories) {
            dispatch(startOperation());
            AxiosClient.get(requestURLs.categories)
                .then(response => {
                    setAllCategories(response.data as DocumentCategoryProps[]);
                })
                .finally(dispatch(finishOperation()));
        }
    }, [allCategories, dispatch]);

    function handleCategoryClick(event: React.MouseEvent<Element, MouseEvent>) {
        const targetCategoryName = event.currentTarget.getAttribute('data-id');
        const targetCategory = allCategories?.find(c => c.title === targetCategoryName);
        if (targetCategory) {
            if (selectedCategories.find(c => c.title === targetCategoryName)) {
                setSelectedCategories(prev => prev.filter(c => c.title !== targetCategory.title));
            } else setSelectedCategories(prev => [...prev, targetCategory]);
        }
    }

    function buildCategoryEntries(options: string[]): ReactElement[] {
        let kv = 1;
        const selectedFilter = selectedCategories.map(c => c.title);
        return options.map((option, index) => {
            const optionID = `category-selector-list_draggable_${index}`;
            kv = 13 + kv * 31;
            return (
                <button
                    key={`category-selector-list_${option}_${kv}`}
                    className={
                        selectedFilter && selectedFilter.indexOf(option) >= 0 ? 'selectedItem' : ''
                    }
                    id={optionID}
                    data-id={option}
                    type="button"
                    value={index}
                    onClick={event => handleCategoryClick(event)}
                >
                    {option}
                </button>
            );
        });
    }

    return (
        <ConfirmationDialog
            id="category-selector-dialog"
            title="Kategorieauswahl"
            message="Bitte wählen Sie eine oder mehrer Kategorien für das Dokument aus und bestätigen Sie mit ok."
            isOpenState={{
                value: visibilityState[0],
                set: visibilityState[1],
            }}
            onOK={() => {
                updateValues(selectedCategories);
            }}
            onCancel={() => {
                visibilityState[1](false);
            }}
        >
            {allCategories === null ? (
                <LoadingScreen />
            ) : (
                <ListWrapper>
                    <LabeledFormField
                        formFieldType={FormFieldTypes.List}
                        id="category-selector-list"
                        label="Kategorien"
                        size={25}
                    >
                        {buildCategoryEntries(
                            allCategories ? allCategories.map(c => `${c.title}`) : []
                        )}
                    </LabeledFormField>
                </ListWrapper>
            )}
        </ConfirmationDialog>
    );
};

const ListWrapper = styled.div<ThemeProps>`
    margin: 0;
    padding: 0;

    #category-selector-list {
        height: unset;
        max-height: 35rem;
    }
`;

export default CategorySelector;
