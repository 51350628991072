import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { FormFieldProps } from '../interfaces/FormField';

const ButtonField: FunctionComponent<FormFieldProps> = ({
    id,
    disabled,
    label,
    onClick,
    tab,
    children,
}): ReactElement => (
    <Button
        id={id}
        name={id}
        type="button"
        disabled={disabled}
        title={label}
        onClick={onClick}
        data-tabid={tab}
    >
        {children}
    </Button>
);

const Button = styled.button<ThemeProps>`
    margin: 0.4rem 1rem !important;
    background-color: ${props => props.theme.$colorGrey10};
    border: ${props => props.theme.$border.grey.thin[10]};
    padding: 0.2rem;
    text-align: left;

    .report-button-div {
        display: flex;
    }

    .article-p {
        margin: 0;
        padding: 0;
    }

    .article-flags {
        margin: 4px;
    }

    .grey {
        filter: grayscale(100%);
    }

    .img-flag-div {
        display: flex;
        position: relative;
    }

    .icon-flag-strikethrough:before {
        content: ' ';
        height: 2px;
        width: 25px;
        background-color: gray;
        position: absolute;
        transform: rotate(-45deg);
        margin: 8px 2px;
    }
`;

export default ButtonField;
