import React, { FunctionComponent, useEffect, useMemo, useState } from 'react';
import styled from 'styled-components';
import { ReviewDTO, ReviewsService } from '../../lib/backend';
import { useGlobalStore } from '../../hooks/hooks';
import { addError, finishOperation, startOperation } from '../../store/global/actions';
import { EErrorTypes } from '../../interfaces/globals/errors';
import { ResponseError } from '../../typings/error';
import ReviewEntries from './ReviewEntries/ReviewEntries';
import { validatePermissions } from '../../lib/util';
import ErrorPage401 from '../../views/ErrorPage401';
import { t } from '../../lib/language';

const ReviewOverview: FunctionComponent = () => {
    const { state, dispatch } = useGlobalStore();
    const { user } = state;
    const [reviews, setReviews] = useState<ReviewDTO[]>([]);
    const reviewsToCheck = useMemo(
        () => reviews.filter(r => r.reviewUserList?.find(ru => ru.username === user.userName)),
        [reviews, user.userName]
    );
    const ownReviews = useMemo(
        () => reviews.filter(r => r.createdBy === user.fullName),
        [reviews, user.fullName]
    );

    useEffect(() => {
        if (reviews.length <= 0) {
            dispatch(startOperation());
            ReviewsService.getAll3()
                .then(response => setReviews(response))
                .catch(e =>
                    dispatch(addError(EErrorTypes.error, (e.response.data as ResponseError).status))
                )
                .finally(() => dispatch(finishOperation()));
        }
    }, [dispatch, reviews.length]);

    if (
        state.config.tenant.current === null ||
        state.user.userName === '' ||
        !validatePermissions(state.config.tenant.current, state.user.permissions, [
            'REVIEWER',
            'EDITOR',
            'ADMIN',
        ])
    )
        return <ErrorPage401 />;

    return (
        <ReviewModuleWrapper>
            <ReviewList>
                <ReviewEntries
                    headline={t('Review.Overview.Header.Invited')}
                    reviews={reviewsToCheck}
                />
            </ReviewList>
            {validatePermissions(state.config.tenant.current, state.user.permissions, [
                'EDITOR',
                'ADMIN',
            ]) && (
                <>
                    <ReviewList>
                        <ReviewEntries
                            headline={t('Review.Overview.Header.Own')}
                            reviews={ownReviews}
                        />
                    </ReviewList>
                    <ReviewList>
                        <ReviewEntries
                            headline={t('Review.Overview.Header.All')}
                            reviews={reviews}
                        />
                    </ReviewList>
                </>
            )}
        </ReviewModuleWrapper>
    );
};

export default ReviewOverview;

const ReviewModuleWrapper = styled.div`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
`;

const ReviewList = styled.div``;
