import { EErrorTypes } from '../../interfaces/globals/errors';
import { publicationsLoaded } from '../../store/document/actions';
import { addError, finishOperation, startOperation } from '../../store/global/actions';
import { ResponseError } from '../../typings/error';
import AxiosClient, { requestURLs } from '../axios';

/**
 * <p>Uses an asynchronous store mechanism to load publications for the given version.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher.</p>
 *
 * @param {Function} dispatch The dispatcher used to start state operations.
 * @param {number} versionID The versionID.
 * @param {string} lang The language code formatted like "de_DE".
 */
export function loadPublications(dispatch: Function, versionID: number, lang: string) {
    dispatch(startOperation());

    AxiosClient.get(`${requestURLs.publications}versions/${versionID}?l=${lang}`)
        .then(response => {
            dispatch(publicationsLoaded(response.data));
        })
        .catch(error =>
            dispatch(addError(EErrorTypes.error, (error.response.data as ResponseError).status))
        )
        .finally(() => dispatch(finishOperation()));
}

export default loadPublications;
