/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../components/ActionControlButton/ActionControlButton';
import { ArticleEntry } from '../../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import { BoolSwapState } from '../../../../typings/global';

interface MinimizedEditorOutlineProps {
    currentArticle: ArticleEntry;
    articleOutlineState: BoolSwapState;
}

const MinimizedEditorOutline: FunctionComponent<MinimizedEditorOutlineProps> = ({
    currentArticle,
    articleOutlineState,
}) => {
    const outlineID = 'toggle-wrapper-outline-min';
    const toggleModalStyles = () =>
        articleOutlineState.value
            ? document.getElementById(outlineID)?.classList.add('show')
            : document.getElementById(outlineID)?.classList.remove('show');

    useEffect(() => {
        toggleModalStyles();
    }, [articleOutlineState.value]);

    return (
        <Toggle>
            <MinimizedEditorOutlineWrapper id={outlineID}>
                <OutlineButtonWrapper>
                    <ActionControlButton
                        prefix="maximize-header"
                        name="maximize"
                        size={20}
                        callback={() => articleOutlineState.set(false)}
                    />
                </OutlineButtonWrapper>
                <OutlineDataWrapper>
                    <OutlineEntry>
                        <span className="header-entry-spn">Artikel-ID: </span>
                        {currentArticle.articleID}
                    </OutlineEntry>
                    <OutlineEntry>
                        <span className="header-entry-spn">Artikeltitle: </span>
                        {currentArticle.title}
                    </OutlineEntry>
                </OutlineDataWrapper>
            </MinimizedEditorOutlineWrapper>
        </Toggle>
    );
};

export default MinimizedEditorOutline;

const MinimizedEditorOutlineWrapper = styled.div`
    display: flex;
    height: 0;
    width: 0;
    visibility: hidden;
    opacity: 0;
    writing-mode: vertical-rl;
    transition: 0.3s;
`;

const OutlineButtonWrapper = styled.div<ThemeProps>`
    margin: 0 5px;

    button {
        all: unset;
        cursor: pointer;
    }
`;

const OutlineDataWrapper = styled.div<ThemeProps>`
    display: flex;
`;

const OutlineEntry = styled.div`
    margin: 5px;
    width: 100%;

    .header-entry-spn {
        font-weight: 600;
    }
`;

const Toggle = styled.div`
    .show {
        width: 30px;
        height: 100%;
        visibility: visible;
        opacity: 1;
    }
`;
