import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { FormFieldProps } from '../interfaces/FormField';

export interface SelectFieldProps {
    options: string[];
}

const SelectField: FunctionComponent<FormFieldProps & SelectFieldProps> = ({
    id,
    value,
    autoFocus,
    readOnly,
    disabled,
    label,
    onBlur,
    onFocus,
    onChange,
    onClick,
    options,
    tab,
}): ReactElement => (
    <Select
        value={value}
        id={id}
        name={id}
        autoFocus={autoFocus}
        readOnly={readOnly}
        disabled={disabled}
        title={label}
        data-testid={id}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onClick={onClick}
        data-tabid={tab}
    >
        {options.map((option, index) => (
            <option
                key={`${id}_${option}`}
                id={`${index}`}
                value={index}
            >
                {option}
            </option>
        ))}
    </Select>
);

interface SelectStyleProps {
    readOnly: boolean | undefined;
}

const Select = styled.select<ThemeProps & SelectStyleProps>`
    background-color: ${props => props.theme.$colorGrey10};
    margin: 0.4rem 1rem;
    color: ${props => (props.readOnly ? 'transparent' : 'inherit')};
`;

export default SelectField;
