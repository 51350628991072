import ActionTypes from '../actionTypes';
import { TUserReducerActions, IUserState } from './types';

export function updateUser(userData: IUserState): TUserReducerActions {
    return {
        type: ActionTypes.UPDATE_USER,
        payload: userData,
    };
}

export function storeUserPermissions(userData: IUserState): TUserReducerActions {
    return {
        type: ActionTypes.STORE_USER_PERMISSIONS,
        payload: userData,
    };
}

export function loginUser(userData: IUserState): TUserReducerActions {
    return {
        type: ActionTypes.LOGIN_USER,
        payload: userData,
    };
}

export function logoutUser(): TUserReducerActions {
    return {
        type: ActionTypes.LOGOUT_USER,
        payload: {},
    };
}
