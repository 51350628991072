import React, { FunctionComponent, useEffect, ReactNode, useState } from 'react';
import { useLocation } from 'react-router';
import { useGlobalStore } from '../../hooks/hooks';
import { finishOperation, startOperation } from '../../store/global/actions';
import useSession from '../../hooks/session';
import { t } from '../../lib/language';
import ErrorPage401 from '../../views/ErrorPage401';
import ErrorPage from '../../views/ErrorPage';
import LoadingScreen from '../../views/LoadingScreen';
import { ApiError } from '../../lib/backend';

interface PdfDownloadProps {
    children?: ReactNode;
}

function renderPdf(pdf: Blob) {
    const blob = new Blob([pdf], {
        type: 'application/pdf',
    });
    const blobURL = URL.createObjectURL(blob);
    document.open();
    document.write(
        `<html>
                        <body style='margin: 0'>
                        <iframe src='${blobURL}' width='100%' height='100%' style='border: none;'></iframe>
                        </body>
                        </html>`
    );
    document.close();
}

const PdfDownload: FunctionComponent<PdfDownloadProps> = () => {
    const { dispatch } = useGlobalStore();
    const { user } = useSession();
    const [error, setError] = useState<'UNAUTHORIZED' | 'UNKNOWN' | 'NO-ERROR'>('NO-ERROR');
    const useQuery = () => new URLSearchParams(useLocation().search);
    const query = useQuery();
    const id = query.get('id');
    const version = query.get('v');
    const audience = query.get('a');
    const language = query.get('l');
    const report = query.get('report');
    const isIntern = process.env.REACT_APP_SERVER === 'intern';

    useEffect(() => {
        if (id && audience) {
            dispatch(startOperation());
            let reportString = '';
            if (report !== null && report !== undefined) {
                reportString = '&report';
            }

            const urlPart = isIntern ? 'internal' : 'external';
            fetch(
                `${process.env.REACT_APP_BACKEND_SERVER}/api/publications/show/${urlPart}/pdf?` +
                    `id=${id}&v=${version}&a=${audience}&l=${language}${reportString}`,
                { headers: { Authorization: `Bearer ${user?.access_token}` } }
            )
                .then(response => {
                    if (response.status !== 200) {
                        throw new ApiError({ method: 'GET', url: response.url }, response, '');
                    }
                    return response.blob();
                })
                .then(response => {
                    renderPdf(response);
                })
                .catch(err => {
                    if (err instanceof ApiError && (err.status === 401 || err.status === 403)) {
                        setError('UNAUTHORIZED');
                    } else {
                        setError('UNKNOWN');
                    }
                })
                .finally(() => dispatch(finishOperation()));
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    switch (error) {
        case 'UNAUTHORIZED':
            return <ErrorPage401 />;
        case 'UNKNOWN':
            return <ErrorPage>{t('StatusCode.General.Failed')}</ErrorPage>;
        case 'NO-ERROR':
            return <LoadingScreen />;
        default:
            // This is only to check if the switch statement is exhaustive and throws a compiler error if not.
            return ((x: never) => <p>${x}</p>)(error);
    }
};

export default PdfDownload;
