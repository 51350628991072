import React, { FunctionComponent, ReactNode, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';
import InPlaceEditorForm from './InPlaceEditorForm/InPlaceEditorForm';
import InPlaceEditorPreview from './InPlaceEditorPreview/InPlaceEditorPreview';

interface InPlaceEditorProps {
    id: string;
    submit: Function;
    disabled?: boolean;
    showButtons?: boolean;
    minStyles?: boolean;
    actionOnBlur?: 'Cancel' | 'Save';
    value?: string;
    children?: ReactNode;
}

const InPlaceEditor: FunctionComponent<InPlaceEditorProps> = ({
    id,
    submit,
    disabled = false,
    showButtons = true,
    minStyles = false,
    actionOnBlur = 'Cancel',
    value = '',
    children,
}) => {
    const [inPlaceInputText, setInPlaceInputText] = useState<string>(value);
    const [isInputActive, setInputActive] = useState<boolean>(false);

    useEffect(() => {
        if (value !== inPlaceInputText) setInPlaceInputText(value);
    }, [inPlaceInputText, value]);

    return (
        <InPlaceWrapper id={id}>
            {children ||
                (isInputActive ? (
                    <InPlaceEditorForm
                        inPlaceEditorID={id}
                        value={value}
                        showButtons={showButtons}
                        minStyles={minStyles}
                        actionOnBlur={actionOnBlur}
                        submit={submit}
                        inPutActiveState={{
                            value: isInputActive,
                            set: setInputActive,
                        }}
                        inPlaceInputTextState={{
                            value: inPlaceInputText,
                            set: setInPlaceInputText,
                        }}
                    />
                ) : (
                    <InPlaceEditorPreview
                        disabled={disabled}
                        inputText={inPlaceInputText}
                        setInputActive={setInputActive}
                        minStyles={minStyles}
                    />
                ))}
        </InPlaceWrapper>
    );
};

const InPlaceWrapper = styled.div<ThemeProps>`
    width: 100%;
`;

export default InPlaceEditor;
