import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import { useGlobalStore } from '../../../hooks/hooks';
import { ArticleEntry, DocumentEntry } from '../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { ThemeProps } from '../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { addError } from '../../../store/global/actions';
import { ResponseError } from '../../../typings/error';
import { loadDocument } from '../../../lib/app/document';
import LoadingScreen from '../../../views/LoadingScreen';
import { BoolSwapState, CurrentDocumentPropsType } from '../../../typings/global';
import { getObjectWithMatchingKeyFromArray } from '../../../lib/util';

export interface ArticleModalProps {
    openState: BoolSwapState;
    currentArticle: ArticleEntry;
    currentDocumentProps: CurrentDocumentPropsType;
}

const DocumentModal: FunctionComponent<ArticleModalProps> = ({
    openState,
    currentArticle,
    currentDocumentProps,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [documents, setDocuments] = useState<DocumentEntry[] | null>(null);

    useEffect(() => {
        if (openState.value === true && documents === null) {
            AxiosClient.get(
                `${requestURLs.editorSingleDocument}/articles/${currentArticle.articleID}`
            )
                .then(response => {
                    setDocuments(response.data as DocumentEntry[]);
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                );
        }
    }, [currentArticle.articleID, dispatch, documents, openState]);

    function handleSelection(event: React.MouseEvent<HTMLButtonElement>) {
        const element = event.currentTarget;
        const dataID = element.getAttribute('data-id');
        const selectedElements = document.getElementsByClassName('selected-ver');

        if (selectedElements.length > 0) {
            element.classList.remove('selected-ver');
            Array.from(selectedElements).forEach(node => {
                node.classList.remove('selected-ver');
            });
        }
        if (dataID) {
            event.currentTarget.classList.add('selected-ver');
        }
    }

    function handleDoubleClick(event: React.MouseEvent<HTMLButtonElement>) {
        const dataID = event.currentTarget.getAttribute('data-id');

        if (dataID) {
            const docID = dataID.split('_')[0];

            loadDocument(
                dispatch,
                parseInt(docID, 10),
                state.documentManager,
                currentDocumentProps.set
            );
            openState.set(false);
            setDocuments(null);
        }
    }

    function renderDocumentEntries() {
        if (documents) {
            const documentList: ReactElement[] = [];
            let counter = 0;
            documents.forEach(doc =>
                doc.versions.forEach(version => {
                    if (
                        getObjectWithMatchingKeyFromArray(
                            version.articles,
                            'articleID',
                            state.documentManager.currentArticle.articleID
                        )
                    ) {
                        counter += 1;
                        return documentList.push(
                            <span key={weakKey(version)}>
                                <button
                                    type="button"
                                    data-id={`${doc.documentID}_${version.versionID}`}
                                    className={`${version.versionID}`}
                                    onClick={e => handleSelection(e)}
                                    onDoubleClick={e => handleDoubleClick(e)}
                                >
                                    <span className="bold">{counter} </span>
                                    {state.config.language.current
                                        ? doc.descriptions[state.config.language.current.code].title
                                        : doc.descriptions.de_DE.title}{' '}
                                    <span className="bold">{`${version.productVersion} [${version.versionID}]`}</span>
                                </button>
                            </span>
                        );
                    }
                    return null;
                })
            );
            return documentList;
        }
        return <LoadingScreen />;
    }

    return (
        <ConfirmationDialog
            id="filtered-documents-confirmation"
            title="Artikelverwendung"
            // eslint-disable-next-line max-len
            message={`Der Artikel [${currentArticle.articleID} : ${currentArticle.title}] wird in folgenden Dokumenten verwendet`}
            onCancelMessage="OK"
            onCancel={() => {
                openState.set(false);
                setDocuments(null);
            }}
            onOK={undefined}
            isOpenState={{
                value: openState.value,
                set: openState.set(),
            }}
        >
            <DocumentWrapper>{renderDocumentEntries()}</DocumentWrapper>
        </ConfirmationDialog>
    );
};

const DocumentWrapper = styled.div<ThemeProps>`
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
    padding: 0.2rem 0 0.2rem 0;
    flex: 1;
    overflow: auto;

    span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: ${props => props.theme.$border.grey.dotted[20]};

        button {
            width: 100%;
            background-color: ${props => props.theme.$colorWhite};
            text-align: left;
            border: none;
            outline: none;
            box-shadow: none;
            padding: 0.3rem 0;
        }

        button:hover {
            background-color: ${props => props.theme.$colorGrey10};
        }
    }

    .selected-ver {
        background: antiquewhite;

        :hover {
            background: antiquewhite;
        }
    }

    .bold {
        display: initial;
        font-weight: bold;
    }
`;

export default DocumentModal;
