/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';

interface DropDownProps {
    id: string;
    lable: string;
    prefix: string;
    options: { [key: string]: number };
    onSelect: (event: React.MouseEvent<HTMLParagraphElement, MouseEvent>) => void;
}

const DropDown: FunctionComponent<DropDownProps> = ({ id, lable, prefix, options, onSelect }) => {
    const toggle = () => document.getElementById(id)?.classList.toggle('show');
    const handleShow = (event: MouseEvent) => {
        const pointerEvent = event as PointerEvent;
        const element = pointerEvent.target as HTMLElement;

        if (
            document.activeElement !== element &&
            Object.keys(options).filter(
                op => document.getElementById(`${prefix}btn_${op}`) === document.activeElement
            ).length === 0
        ) {
            const dropdowns = document.getElementsByClassName(id);

            Object.values(dropdowns).forEach(dd => {
                if (dd.classList.contains('show')) dd.classList.remove('show');
            });
        }
    };

    useEffect(() => {
        window.addEventListener('click', handleShow, false);
        return () => window.removeEventListener('click', handleShow, false);
    }, []);

    return (
        <DropDownWrapper>
            <DropDownButton
                id={`btn-${id}`}
                type="button"
                onClick={toggle}
            >
                {lable}
            </DropDownButton>
            <DropDownContentWrapper
                id={id}
                className={id}
            >
                {Object.keys(options).map((op, idx) => (
                    <DropDownContent
                        id={`${prefix}btn_${op}`}
                        key={`${idx * 42}-${op}`}
                        onClick={onSelect}
                        size={options[op]}
                    >
                        {op}
                    </DropDownContent>
                ))}
            </DropDownContentWrapper>
        </DropDownWrapper>
    );
};

const DropDownWrapper = styled.div`
    position: relative;
    display: inline-block;

    .show {
        display: block;
    }
`;

const DropDownContentWrapper = styled.div`
    display: none;
    position: absolute;
    background-color: white;
    min-width: 6rem;
    overflow: auto;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    z-index: 1;
`;

const DropDownContent = styled.p<{ size: number }>`
    margin: 0;
    padding: 0.5rem;
    color: black;
    cursor: pointer;
    text-decoration: none;
    display: block;
    font-size: ${props => props.size}px;

    :hover {
        background-color: #e6e6e6;
    }
`;

const DropDownButton = styled.button`
    padding: 0.3rem !important;
`;

export default DropDown;
