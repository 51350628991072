import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router';
import { useGlobalStore } from '../../../hooks/hooks';
import { DocumentEntry, VersionProps } from '../../../interfaces/DocumentEntry';
import AxiosClient from '../../../lib/axios';
import createHtmlDiffView from '../../../lib/htmlDiff';
import { getSecondHighestVersion, validatePermissions } from '../../../lib/util';
import { finishOperation, startOperation } from '../../../store/global/actions';
import ErrorPage401 from '../../../views/ErrorPage401';
import IFrame from '../../htmlcompare/IFrame';
import ReviewMenu from './ReviewMenu/ReviewMenu';
import { ReviewDTO } from '../../../lib/backend';

const ReviewModule: FunctionComponent = () => {
    const { state, dispatch } = useGlobalStore();
    const { tenant } = state.config;
    const { user } = state;
    const [document] = useState<DocumentEntry>(
        JSON.parse(
            (useLocation().state as { currentDocument: string }).currentDocument
        ) as DocumentEntry
    );
    const [review] = useState<ReviewDTO>(
        JSON.parse((useLocation().state as { currentReview: string }).currentReview) as ReviewDTO
    );
    const [isReloading, setReload] = useState<boolean>(true);
    const [htmlContentCurrent, setHtmlContentCurrent] = useState<string>('');
    const [htmlContentOld, setHtmlContentOld] = useState<string>('');
    const [comparativeVersion, setComparativeVersion] = useState<VersionProps | null>(
        getSecondHighestVersion(document.versions)
    );

    useEffect(() => {
        if (htmlContentCurrent === '') {
            dispatch(startOperation());
            AxiosClient.get(
                `/api/publications/show/internal/html?id=${document.internalDocumentID}`
            )
                .then(response => {
                    const value = response.data as string;
                    setHtmlContentCurrent(value);
                })
                .finally(() => dispatch(finishOperation()));
        }
    }, [dispatch, document.internalDocumentID, htmlContentCurrent]);

    useEffect(() => {
        if (isReloading) {
            if (comparativeVersion) {
                dispatch(startOperation());
                AxiosClient.get(
                    `/show/html?id=${document.internalDocumentID}&v=${comparativeVersion.productVersion}`
                )
                    .then(response => {
                        const value = response.data as string;
                        setHtmlContentOld(value);
                    })
                    .finally(() => {
                        setReload(false);
                        dispatch(finishOperation());
                    });
            }
        }
    }, [comparativeVersion, dispatch, document.internalDocumentID, htmlContentOld, isReloading]);

    if (
        tenant.current === null ||
        user.userName === '' ||
        !validatePermissions(tenant.current, user.permissions, ['EDITOR', 'ADMIN', 'REVIEWER'])
    )
        return <ErrorPage401 />;

    return (
        <ReviewModuleWrapper>
            <ReviewMenu
                document={document}
                review={review}
                setVersion={setComparativeVersion}
                setReload={setReload}
            />

            <IFrame
                html={createHtmlDiffView(htmlContentCurrent, htmlContentOld)}
                review={review}
                loading={isReloading}
            />
        </ReviewModuleWrapper>
    );
};

export default ReviewModule;

const ReviewModuleWrapper = styled.div`
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
`;
