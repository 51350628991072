import React, { FunctionComponent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import key from 'weak-key';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import { useGlobalStore } from '../../../../hooks/hooks';
import { ArticleEntry } from '../../../../interfaces/DocumentEntry';
import EDocumentState from '../../../../interfaces/DocumentState';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { TranslationStatus } from '../../../../interfaces/globals/job';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { t } from '../../../../lib/language';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { ResponseError } from '../../../../typings/error';
import { BoolSwapState, CurrentDocumentPropsType } from '../../../../typings/global';
import ArticleRecord from './articleRecord/ArticleRecord';
import ArticleRecordHeader from './articleRecord/ArticleRecordHeader';

interface ArticleOutlineProps {
    articles: ArticleEntry[];
    saveListener: Function;
    articleSelectionListener: Function;
    headingSelectionListener: Function;
    documentModalOpenState: BoolSwapState;
    currentDocumentProps: CurrentDocumentPropsType;
}

const ArticleOutline: FunctionComponent<ArticleOutlineProps> = ({
    articles,
    saveListener,
    articleSelectionListener,
    headingSelectionListener,
    documentModalOpenState,
    currentDocumentProps,
}) => {
    const { state, dispatch } = useGlobalStore();
    const { currentArticle } = state.documentManager;
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);

    const translationStatus = (article: ArticleEntry) => {
        if (state.documentManager.currentTranslations[article.articleID]) {
            const translation = state.documentManager.currentTranslations[article.articleID].find(
                tr => tr.languageCode === state.documentManager.currentDescription.languageCode
            );
            if (translation) return translation.status;
        }
        return TranslationStatus.DRAFT;
    };

    function selectArticle(
        event: React.MouseEvent<HTMLUListElement, MouseEvent>,
        article: ArticleEntry
    ) {
        event.preventDefault();
        articleSelectionListener(article);
    }

    function deleteArticle(articleID: number) {
        if (articleID === state.documentManager.currentArticle.articleID) {
            dispatch(startOperation());
            AxiosClient.delete(
                `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/articles/${articleID}`
            )
                .then(() => {
                    if (currentDocumentProps.value.documentID) {
                        saveListener();
                        dispatch(
                            addError(
                                EErrorTypes.info,
                                t('StatusCode.SpecialCase.RemoveArticleFromVersion')
                            )
                        );
                    }
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => {
                    dispatch(finishOperation());
                });
        } else {
            dispatch(addError(EErrorTypes.error, `${t('StatusCode.General.Failed')}`));
        }
    }

    function buildArticleoutline() {
        const articleOutline: ReactElement[] = [];

        articles.forEach(article => {
            let locked = false;
            if (article.status >= EDocumentState.Published) locked = true;
            articleOutline.push(
                <Test key={`${key(article)}-wrapper`}>
                    {state.documentManager.currentDescription.languageCode !== 'de_DE' && (
                        <Status status={translationStatus(article)} />
                    )}
                    <ArticleOutlineWrapper
                        key={`${key(article)}-inner-wrapper`}
                        selected={article.articleID === currentArticle.articleID}
                        locked={locked}
                        onClick={event => selectArticle(event, article)}
                    >
                        <ArticleEntryWrapper>
                            <ArticleRecordHeader
                                article={article}
                                locked={locked}
                                saveListener={saveListener}
                                documentModalOpenState={documentModalOpenState}
                                showConfirmationDialog={{
                                    value: showConfirmationDialog,
                                    set: setShowConfirmationDialog,
                                }}
                            />
                            <ArticleRecord
                                article={article}
                                headingSelectionListener={headingSelectionListener}
                            />
                        </ArticleEntryWrapper>
                    </ArticleOutlineWrapper>
                </Test>
            );
        });
        return articleOutline;
    }

    return (
        <OutlineWrapper className="styledScrollBar">
            {buildArticleoutline()}
            <ConfirmationDialog
                id="article-action-confirmation"
                title="Artikel entfernen"
                message={`Wollen sie den Artikel "${state.documentManager.currentArticle.title}" wirklich entfernen?`}
                onCancel={() => {
                    setShowConfirmationDialog(false);
                }}
                onOK={() => deleteArticle(state.documentManager.currentArticle.articleID)}
                isOpenState={{
                    value: showConfirmationDialog,
                    set: setShowConfirmationDialog,
                }}
            />
        </OutlineWrapper>
    );
};

const OutlineWrapper = styled.div<ThemeProps>`
    height: 100%;
    display: flex;
    flex-direction: column;
    flex: 1.4;
    min-width: 400px;
    font-size: ${props => props.theme.$headerStyles.small.h5.fontSize}rem;
    overflow-y: auto;
`;

const ArticleOutlineWrapper = styled.ul<ThemeProps & { selected: boolean; locked: boolean }>`
    display: flex;
    width: 100%;
    border-bottom: ${props => props.theme.$border.secondary.dashed};
    margin: 0.2rem;
    align-items: left;
    text-align: left;
    background-color: ${props => (props.selected ? props.theme.$colorPrimary : 'transparent')};
    background-image: ${props => (props.locked ? 'url(/icn/lock.svg)' : '')};
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 25px;
    list-style: none;
    padding-left: 0;
    padding-bottom: 10px;
    max-width: 450px;
`;

const ArticleEntryWrapper = styled.div<ThemeProps>`
    width: 100%;
`;

const Status = styled.div<ThemeProps & { status: TranslationStatus }>`
    width: 4px;
    background-color: ${props => {
        const { status } = props;
        if (status === TranslationStatus.PUBLISHED) return 'green';
        if (status === TranslationStatus.VALIDATED) return 'green';
        if (status === TranslationStatus.OUTDATED) return props.theme.$colorPrimary;
        if (status === TranslationStatus.DRAFT) return 'lightgrey';
        return 'white';
    }};
`;
const Test = styled.div`
    display: flex;
`;

export default ArticleOutline;
