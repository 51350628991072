import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { FormFieldProps } from '../interfaces/FormField';

export interface InputFieldProps {
    type: string;
    defaultChecked?: boolean;
    placeholder?: string;
}

const InputField: FunctionComponent<FormFieldProps & InputFieldProps> = ({
    id,
    value,
    type,
    defaultChecked,
    autoFocus,
    placeholder,
    readOnly,
    disabled,
    label,
    onBlur,
    onFocus,
    onChange,
    onClick,
    tab,
}): ReactElement => (
    <Input
        value={value}
        id={id}
        name={id}
        type={type}
        defaultChecked={defaultChecked}
        autoFocus={autoFocus}
        hasPlaceholder={false}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        title={label}
        data-testid={id}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onClick={onClick}
        data-tabid={tab}
    />
);

interface InputStyleProps {
    hasPlaceholder: boolean;
}

const Input = styled.input<ThemeProps & InputStyleProps>`
    background-color: ${props => props.theme.$colorGrey10};
    margin: 0.4rem 1rem;
    line-height: 1.7rem;

    &::placeholder {
        color: ${props => props.theme.$colorGrey30};
    }

    &::read-only {
        color: transparent;
    }
`;

export default InputField;
