import React, { FunctionComponent, ReactElement, useState } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { useGlobalStore } from '../../../hooks/hooks';
import { validatePermissions } from '../../../lib/util';
import ErrorPage401 from '../../../views/ErrorPage401';
import TranslationBrowser from './TranslationBrowser';
import DocumentEditor from '../document/DocumentEditor';
import { DocumentEntry } from '../../../interfaces/DocumentEntry';
import { emptyDocument } from '../../../store/document/reducers';

const TranslationManager: FunctionComponent = (): ReactElement => {
    const { state } = useGlobalStore();
    const [reloadDocuments, setReloadDocuments] = useState<boolean>(false);
    const [currentDocument, setCurrentDocument] = useState<DocumentEntry>(emptyDocument);

    if (
        state.config.tenant.current === null ||
        state.user.userName === '' ||
        !validatePermissions(state.config.tenant.current, state.user.permissions, [
            'EDITOR',
            'ADMIN',
        ])
    )
        return <ErrorPage401 />;
    return (
        <Wrapper data-testid="translation_manager">
            <TranslationBrowser
                currentDocumentProps={{ value: currentDocument, set: setCurrentDocument }}
                reloadDocuments={{ value: reloadDocuments, set: setReloadDocuments }}
            />
            <DocumentEditor
                isTranslationEditor
                currentDocumentProps={{ value: currentDocument, set: setCurrentDocument }}
                reloadDocuments={{ value: reloadDocuments, set: setReloadDocuments }}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
`;

export default TranslationManager;
