import React, { FunctionComponent, ReactElement, ReactNode } from 'react';

const ErrorPage: FunctionComponent<{ children: ReactNode }> = ({ children }): ReactElement => (
    <div
        className="errorPage"
        data-testid="errorpage"
    >
        {children}
    </div>
);

export default ErrorPage;
