import React, { FunctionComponent, ReactElement, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import NavButton from '../NavButton/NavButton';
import { IUserState } from '../../../store/user/types';
import { useGlobalStore } from '../../../hooks/hooks';
import LanguageSelector, { OptionsType } from '../LanguageSelector/LanuageSelector';
import { t } from '../../../lib/language';
import { IConfigState, TenantInformation } from '../../../store/config/types';
import { setTenant } from '../../../store/config/actions';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { addError, finishOperation } from '../../../store/global/actions';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { ResponseError } from '../../../typings/error';
import { BoolSwapState } from '../../../typings/global';
import Icon from '../../Icon/Icon';
import Login from '../../../containers/login/Login';
import Profile from '../../../containers/login/Profile';

interface TenantOptions {
    value: string;
    label: string;
}

const leadToVisusAcademy = () => {
    window.open('https://academy.visus.com');
};

function renderMenuBasedOnPermissions(
    user: IUserState,
    config: IConfigState,
    dispatch: Function,
    loginState: BoolSwapState,
    showUserProfileState: BoolSwapState,
    onSelectLocale: ((value: string) => void) | null
) {
    const menuEntries: JSX.Element[] = [];
    const system = process.env.REACT_APP_SERVER;
    if (config.tenant.current) {
        const options: TenantOptions[] = config.tenant.list
            .filter(tn => {
                if (tn.name === 'Global') return false;
                if (
                    tn.secured &&
                    user.permissions[tn.name.replaceAll(' ', '').toUpperCase()] === undefined
                )
                    return false;
                return true;
            })
            .map(tn => ({ value: tn.tenantID, label: tn.name }));
        const defaultValue = options.filter(o => {
            if (o.value === config.tenant.current?.tenantID) return true;
            return false;
        })[0];
        if (system === 'intern')
            menuEntries.push(
                <Select
                    key="menu_tenant_selection"
                    classNamePrefix="tenant-selector"
                    className="tenantselection"
                    isSearchable={false}
                    isClearable={false}
                    isLoading={false}
                    isRtl={false}
                    isDisabled={options.length < 2}
                    isMulti={false}
                    value={defaultValue}
                    options={options}
                    onChange={o => {
                        if (o) {
                            AxiosClient.get(`${requestURLs.tenants}/${Object.values(o)[0]}`)
                                .then(response => {
                                    dispatch(setTenant(response.data as TenantInformation));
                                })
                                .catch(error =>
                                    dispatch(
                                        addError(
                                            EErrorTypes.error,
                                            (error.response.data as ResponseError).status
                                        )
                                    )
                                )
                                .finally(() => dispatch(finishOperation()));
                        }
                    }}
                />
            );
    }

    if (
        config.tenant.current &&
        system === 'intern' &&
        (!config.tenant.current.secured ||
            (user.permissions[config.tenant.current.name.toUpperCase()] &&
                user.permissions[config.tenant.current.name.toUpperCase()].length > 0)) &&
        (user.userName !== '' || process.env.REACT_APP_START_PAGE === 'Compendium')
    ) {
        menuEntries.push(
            <NavButton
                key="menu_target_compendium"
                id="menu_target_compendium"
                renderas="menuEntry"
                target="/"
            >
                {t('Menu.Documents')}
            </NavButton>
        );
    }
    if (
        config.tenant.current &&
        user.permissions[config.tenant.current.name.replace(' ', '').toUpperCase()] &&
        user.permissions[config.tenant.current.name.replace(' ', '').toUpperCase()].indexOf(
            'editor'.toUpperCase()
        ) >= 0 &&
        process.env.REACT_APP_DISABLE_MENU !== 'true'
    ) {
        menuEntries.push(
            <NavButton
                key="menu_target_translation"
                id="menu_target_translation"
                renderas="menuEntry"
                target="/translations"
            >
                {t('Menu.Translation')}
            </NavButton>
        );

        menuEntries.push(
            <NavButton
                key="menu_target_editor"
                id="menu_target_editor"
                renderas="menuEntry"
                target="/documents"
            >
                {t('Menu.Editor')}
            </NavButton>
        );
    }
    menuEntries.push(
        <NavButton
            key="menu_target_academy"
            id="menu_target_academy"
            renderas="menuEntry"
            onClick={leadToVisusAcademy}
            target="/"
        >
            {t('Menu.Academy')}
        </NavButton>
    );

    if (onSelectLocale !== null && config.language.current !== null)
        menuEntries.push(
            <LanguageSelector
                classNamePrefix="menu-lang-selector"
                key="menu_languageselector"
                langOptions={Object.keys(config.language.list).map(
                    l =>
                        ({
                            value: l,
                            label: config.language.list[l].name,
                        } as OptionsType)
                )}
                value={config.language.current.code}
                onChange={o => {
                    if (o) {
                        onSelectLocale(`${Object.values(o)[0]}`);
                    }
                }}
            />
        );

    if (
        config &&
        config.tenant.current &&
        user &&
        Object.values(user.permissions).some(array => array.includes('ADMIN'))
    ) {
        menuEntries.push(
            <NavButton
                key="menu_target_admin"
                id="menu_target_admin"
                renderas="menuEntry"
                target="/settings"
                icon="settings"
            >
                {t('Menu.Settings')}
            </NavButton>
        );
    }
    if (user.userName === '')
        menuEntries.push(
            <button
                aria-label="Login"
                key="login-button"
                className="login-button"
                type="button"
                onClick={() => loginState.set(!loginState.value)}
            >
                <Icon
                    name="login"
                    size="24"
                />
            </button>
        );
    if (user.userName !== '')
        menuEntries.push(
            <button
                id="profile-button-id"
                key="profile-button"
                className="profile-button"
                type="button"
                onClick={event => {
                    event.stopPropagation();
                    event.preventDefault();
                    showUserProfileState.set(!showUserProfileState.value);
                }}
            >
                <ImageWrapper>
                    {user.imageData !== undefined ? (
                        <img
                            src={`data:image/jpeg;base64,${user.imageData}`}
                            alt="profile"
                        />
                    ) : (
                        <Icon
                            name="profile"
                            size="100"
                            stroke="#000"
                            fill=""
                        />
                    )}
                </ImageWrapper>
            </button>
        );
    return menuEntries;
}

interface MenuProps {
    onSelectLocale?(value: string): void | null;
}

const Menu: FunctionComponent<MenuProps> = ({ onSelectLocale = null }): ReactElement | null => {
    const { state, dispatch } = useGlobalStore();
    const { user, config } = state;
    const [showLogin, setShowLogin] = useState<boolean>(false);
    const [showUserProfile, setShowUserProfile] = useState<boolean>(false);

    if (config.tenant.current !== null && config.language.current !== null) {
        return (
            <Wrapper data-testid="menu">
                <MenuBar>
                    {renderMenuBasedOnPermissions(
                        user,
                        config,
                        dispatch,
                        { value: showLogin, set: setShowLogin },
                        { value: showUserProfile, set: setShowUserProfile },
                        onSelectLocale
                    )}
                </MenuBar>
                {state.user.userName === '' ? (
                    <Login
                        showLoginModal={{
                            value: showLogin,
                            set: setShowLogin,
                        }}
                    />
                ) : (
                    <Profile
                        showUserProfileState={{
                            value: showUserProfile,
                            set: setShowUserProfile,
                        }}
                        user={state.user}
                    />
                )}
            </Wrapper>
        );
    }
    return null;
};

const Wrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    width: 100%;
    height: 2rem;
    border: 0;
    margin: auto 0;
`;

const MenuBar = styled.div<ThemeProps>`
    margin: auto 0;
    padding: 0 0 0 10rem;
    display: inline-flex;
    justify-content: flex-end;
    flex-direction: row;
    flex: 1;
    align-items: top;
    box-sizing: border-box;
    border: ${props => props.theme.$border.secondary.thin[40]};

    .selection {
        width: 5.2rem;
    }

    .tenantselection {
        width: 10rem;
        font-size: ${props => props.theme.$headerStyles.medium.h6.fontSize}rem;
    }

    .tenant-selector__menu {
        z-index: 15;
    }

    .flagSelected {
        img {
            margin: auto 0 auto auto;
            height: 1.4rem;
            width: 1.4rem;
        }
    }

    .flagLabel {
        font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;
        display: flex;
        flex-direction: row;

        img {
            margin: auto 0.2rem;
            height: 1rem;
            width: 1rem;
        }
    }

    .menu-lang-selector__control {
        border: none;
    }

    .menu-lang-selector__control--is-focused .menu-lang-selector__control--is-open {
        border: none;

        :hover {
            border: none;
        }
    }

    .menu-lang-selector__menu {
        z-index: 15;
    }

    .menu-lang-selector__option {
        :hover {
            background: ${props => props.theme.$colorPrimary};
        }
    }

    .menu-lang-selector__option--is-selected {
        background: ${props => props.theme.$colorSecondary};
    }

    .menu-lang-selector__control--is-disabled {
        background-color: inherit;
    }

    .menu-lang-selector__value-container {
        justify-content: flex-end;
    }

    .menu-lang-selector__single-value--is-disabled {
        color: inherit;
    }

    .menu-lang-selector__indicator {
        :hover {
            color: ${props => props.theme.$colorPrimary};
        }
    }
    .login-button,
    .profile-button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        outline: inherit;
    }
`;

export const ImageWrapper = styled.div<ThemeProps>`
    display: block;
    width: 2rem;
    height: 2rem;
    overflow: hidden;
    border-radius: 50%;
    margin-left: auto;
    margin-right: auto;
    svg,
    img {
        height: 2rem;
        width: auto;
    }
`;

export default Menu;
