import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useNavigate } from 'react-router';
import {
    DocumentDTO,
    DocumentService,
    ReviewDTO,
    VersionDTO,
    VersionsService,
} from '../../../lib/backend';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { makeTimeStampReadable } from '../../../lib/util';
import { ImageWrapper } from '../../../components/navigation/Menu/Menu';
import Icon from '../../../components/Icon/Icon';
import { useGlobalStore } from '../../../hooks/hooks';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { t } from '../../../lib/language';

interface ReviewEntryProps {
    review: ReviewDTO;
}

const Entry: FunctionComponent<ReviewEntryProps> = ({ review }) => {
    const { dispatch } = useGlobalStore();
    const [version, setVersion] = useState<VersionDTO>();
    const [document, setDocument] = useState<DocumentDTO>();
    const history = useNavigate();

    const generateStatusIconName: (status: ReviewDTO.status) => string = (status: string) => {
        switch (status) {
            case ReviewDTO.status.APPROVED:
                return 'approved';
            case ReviewDTO.status.REWORK:
                return 'needs-work';
            default:
                return '';
        }
    };

    const handleOpenReviewModalEditor: () => void = () => {
        history(`/reviews/${review.reviewID}`, {
            state: {
                currentDocument: JSON.stringify(document),
                currentReview: JSON.stringify(review),
            },
        });
    };

    useEffect(() => {
        if (!version) {
            dispatch(startOperation());
            VersionsService.getVersion(review.versionID)
                .then(response => setVersion(response))
                .catch(error => dispatch(addError(EErrorTypes.error, error.body.status)))
                .finally(() => dispatch(finishOperation()));
        }
        if (version && version.documentID && !document)
            DocumentService.getDocument(version.documentID).then(doc => setDocument(doc));
    }, [dispatch, document, review.versionID, version]);

    return (
        <EntryWrapper>
            <Section>
                <ImageWrapper>
                    <Icon
                        name="profile"
                        size="100"
                        stroke="#000"
                        fill=""
                    />
                </ImageWrapper>
            </Section>
            {review.status === ReviewDTO.status.ABORTED && (
                <Section>
                    <ReviewStatus>{t('Review.ReviewStatus.Aborted')}</ReviewStatus>
                </Section>
            )}
            <Section>
                <Title
                    onClick={() =>
                        review.status !== ReviewDTO.status.ABORTED
                            ? handleOpenReviewModalEditor()
                            : null
                    }
                >
                    {document && document.descriptions && document.descriptions.de_DE.title}
                </Title>
                <SubTitle>
                    {review.createdBy} - #{review.reviewID}
                    {t('Review.ReviewEntry.CreatedOn')}{' '}
                    {makeTimeStampReadable(new Date(review.createdDate))}{' '}
                </SubTitle>
            </Section>
            <ReviewerSection>
                {review.reviewUserList &&
                    review.reviewUserList.map(
                        reviewUser =>
                            reviewUser.username && (
                                <ImageWrapper style={{ padding: '0 5px', position: 'relative' }}>
                                    <Icon
                                        name="profile"
                                        size="100"
                                        stroke="#454444"
                                        fill=""
                                        className="reviewer"
                                    />
                                    {reviewUser.status && (
                                        <Icon
                                            name={generateStatusIconName(reviewUser.status)}
                                            size="128"
                                            className="reviewer-status"
                                        />
                                    )}
                                </ImageWrapper>
                            )
                    )}
            </ReviewerSection>
        </EntryWrapper>
    );
};

export default Entry;

const EntryWrapper = styled.div<ThemeProps>`
    display: flex;
    padding: 0.5rem;
    border-bottom: 0.5px solid #f0f0f0;
    border-left: 2px solid transparent;

    :hover {
        border-left: 2px solid ${props => props.theme.$colorPrimary};
        border-bottom: 0.5px solid #f0f0f0;
        background-color: #f0f0f0;
    }
`;

const Section = styled.div<ThemeProps>`
    margin: auto 0.5rem;
    p {
        margin: 0;
        padding: 0;
        font-size: small;
    }
`;

const ReviewerSection = styled.div`
    display: flex;
    margin: auto 0.5rem;
    position: absolute;
    right: 5rem;
    p {
        margin: 0;
        padding: 0;
        font-size: small;
    }

    .reviewer {
        display: block;
    }

    .reviewer-status {
        position: absolute;
        width: 0.8rem;
        right: 5px;
        top: -7px;
    }
`;

const Title = styled.p<ThemeProps>`
    cursor: pointer;
    color: ${props => props.theme.$colorPrimary};

    :hover {
        text-decoration: underline;
    }
`;

const SubTitle = styled.div`
    font-size: x-small;
`;

const ReviewStatus = styled.span`
    border-radius: 20px;
    border: 0.5px solid grey;
    cursor: default;
    color: grey;
    padding: 2px 5px 5px 5px;
    font-size: small;
`;
