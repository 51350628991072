import React, {
    FunctionComponent,
    ReactElement,
    useState,
    useEffect,
    ChangeEvent,
    ReactText,
} from 'react';
import styled from 'styled-components';
import key from 'weak-key';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { useGlobalStore } from '../../../hooks/hooks';
import Form, { FormFields } from '../../../components/forms/Form';
import Modal from '../../../components/dialogs/Modal/Modal';
import {
    DocumentEntry,
    VersionProps,
    DocumentDescriptionProps,
    DocumentCategoryProps,
    ArticleEntry,
    TranslationsEntries,
    TranslationEntryPreview,
} from '../../../interfaces/DocumentEntry';
import { Tab, TabContentContainer } from '../../../components/forms/Fields/TabComponentField';
import LabeledFormField, { FormFieldTypes } from '../../../components/forms/LabeledFormField';
import {
    downloadFile,
    getBiggerVersion,
    makeTimeStampReadable,
    validatePermissions,
} from '../../../lib/util';
import {
    descriptionSelected,
    documentMarkedAsModified,
    versionSelected,
} from '../../../store/document/actions';
import { SupportedElements } from '../../../components/forms/interfaces/FormField';
import { saveDocument, deleteDocument, loadDocument } from '../../../lib/app/document';
import { FormHeader, InnerFormWrapper } from '../../../styles/sharedStyles';
import ConfirmationDialog, {
    ConfirmationDialogParams,
} from '../../../components/dialogs/ConfirmationDialog';
import { emptyDescription, emptyDocument, emptyVersion } from '../../../store/document/reducers';
import Toolbar from './DocumentEditorToolbar';
import DescriptionForm from './DescriptionForm';
import VersionForm from './VersionForm';
import MetaInformation from '../MetaInformation';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';
import VersionModal from './VersionModal';
import Icon from '../../../components/Icon/Icon';
import TextInputDialog from '../../../components/dialogs/InputDialog';
import ButtonToolBar from '../../../components/ButtonToolBar/ButtonToolBar';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { t } from '../../../lib/language';
import ArticleModal from '../article/ArticleModal';
import CategorySelector from './CategorySelector';
import { ResponseError } from '../../../typings/error';
import InPlaceEditor from '../../../components/InPlaceEditor/InPlaceEditor';
import { BoolSwapState, CurrentDocumentPropsType } from '../../../typings/global';
import PublishModal from './PublishModal';
import { loadPublications } from '../../../lib/app/publications';
import { GermanAudiences } from '../../../interfaces/Audiences';
import { PublicationMeta } from '../../../typings/publication';
import ReviewModal from '../../review/ReviewModal';

interface DocumentEditorProps {
    isTranslationEditor: boolean;
    reloadDocuments: BoolSwapState;
    currentDocumentProps: CurrentDocumentPropsType;
}

const DocumentEditor: FunctionComponent<DocumentEditorProps> = ({
    isTranslationEditor,
    reloadDocuments,
    currentDocumentProps,
}): ReactElement => {
    const {
        state: { documentManager, user, config },
        dispatch,
    } = useGlobalStore();
    const [currentDocument, setCurrentDocument] = useState<DocumentEntry>(
        currentDocumentProps.value
    );
    const [confirmationDialogParams, setConfirmationDialogParams] =
        useState<ConfirmationDialogParams | null>(null);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [showInputTranslationDialog, setShowInputTranslationDialog] = useState<boolean>(false);
    const [showInputVersionDialog, setShowInputVersionDialog] = useState<boolean>(false);
    const [showConfirmationDialogDeleteVersion, setShowConfirmationDialogDeleteVersion] =
        useState<boolean>(false);
    const [showConfirmationDialogDeleteDescription, setShowConfirmationDialogDeleteDescription] =
        useState<boolean>(false);
    const [showArticleReportModal, setShowArticleReportModal] = useState<boolean>(false);
    const [currentVersion, setCurrentVersion] = useState<VersionProps>(
        documentManager.currentVersion
    );
    const [currentDescription, setCurrentDescription] = useState<DocumentDescriptionProps>(
        documentManager.currentDescription
    );
    const [initialized, setInitialized] = useState<boolean>(false);
    const [isVersionModalVisible, setisVersionModalVisible] = useState<boolean>(false);
    const [actualVersion, setActualVersion] = useState<DocumentDescriptionProps | VersionProps>();
    const [isUIBlockedForTranslation, setUIBlockedForTranslation] = useState<boolean>(false);
    const [showCategorySelector, setShowCategorySelector] = useState<boolean>(false);
    const [reports, setReports] = useState<ArticleEntry[]>([]);
    const [showPublishModal, setShowPublishModal] = useState<boolean>(false);
    const [showReviewModal, setShowReviewModal] = useState<boolean>(false);
    const [showInputDialogForDownloadFile, setShowInputDialogForDownloadFile] =
        useState<boolean>(false);
    const [fileExtension, setFileExtension] = useState<string>('txt');
    const prefix = 'document_editor_';
    const defaultLang = isTranslationEditor ? 'en_US' : 'de_DE';
    const descriptionID = (): number => {
        if (isTranslationEditor) {
            if (currentDocument.descriptions.en_US)
                return currentDocument.descriptions.en_US.descriptionID;
        } else if (currentDescription) {
            return currentDescription.descriptionID;
        }
        return -1;
    };

    useEffect(() => {
        function keyDownEventListener(event: KeyboardEvent) {
            if (event.ctrlKey) {
                if (event.key === 's') {
                    event.preventDefault();
                    // eslint-disable-next-line no-unused-expressions
                    document.getElementById(`${prefix}btn_save`)?.click();
                } else if (event.key === 'd') {
                    event.preventDefault();
                    // eslint-disable-next-line no-unused-expressions
                    document.getElementById(`${prefix}btn_duplicate`)?.click();
                }
            }
        }

        if (!initialized) {
            document.addEventListener('keydown', event => keyDownEventListener(event));
            setInitialized(true);
        }

        if (currentVersion && currentVersion.report !== documentManager.currentVersion.report) {
            setCurrentDocument(currentDocumentProps.value);
            const vf = currentDocumentProps.value.versions.filter(
                v => v.versionID === currentVersion.versionID
            );
            if (vf.length > 0) setCurrentVersion(vf[vf.length - 1]);
        }

        if (
            Object.keys(currentDocument.descriptions).length !==
            Object.keys(currentDocumentProps.value.descriptions).length
        ) {
            setCurrentDocument(currentDocumentProps.value);
            if (isTranslationEditor)
                setCurrentDescription(
                    currentDocumentProps.value.descriptions.en_US || emptyDescription
                );
            else
                setCurrentDescription(
                    currentDocumentProps.value.descriptions.de_DE || emptyDescription
                );
        }

        if (currentDocument.versions.length !== currentDocumentProps.value.versions.length) {
            setCurrentDocument(currentDocumentProps.value);
            setCurrentVersion(
                currentDocumentProps.value.versions.length > 0
                    ? currentDocumentProps.value.versions[
                          currentDocumentProps.value.versions.length - 1
                      ]
                    : JSON.parse(JSON.stringify(emptyVersion))
            );
        }

        if (currentDocument.documentID !== currentDocumentProps.value.documentID) {
            const documentClone = JSON.parse(JSON.stringify(currentDocumentProps.value));
            setCurrentDocument(documentClone);

            if (Object.keys(documentClone.descriptions).length > 0)
                if (isTranslationEditor)
                    setCurrentDescription(
                        currentDocumentProps.value.descriptions.en_US || emptyDescription
                    );
                else
                    setCurrentDescription(
                        currentDocumentProps.value.descriptions.de_DE || emptyDescription
                    );
            else
                setCurrentDescription(
                    JSON.parse(JSON.stringify(documentManager.currentDescription))
                );

            if (Object.keys(documentClone.versions).length > 0)
                setCurrentVersion(
                    documentClone.versions[
                        Object.keys(documentClone.versions)[
                            Object.keys(documentClone.versions).length - 1
                        ]
                    ]
                );
            else setCurrentVersion(JSON.parse(JSON.stringify(documentManager.currentVersion)));
        }

        if (currentDocument.documentID === undefined && currentDocument.author === '') {
            currentDocument.product = 'JiveX';
            currentDocument.author = user.fullName;
        }

        return () => {
            document.removeEventListener('keydown', event => keyDownEventListener(event));
        };
    }, [
        documentManager.currentArticle,
        documentManager.currentDescription,
        documentManager.currentVersion,
        user.fullName,
        currentDocument,
        initialized,
        currentVersion,
        currentDocumentProps.value,
        isTranslationEditor,
    ]);

    useEffect(() => {
        if (
            currentVersion.versionID !== documentManager.currentVersion.versionID &&
            documentManager.currentVersion.versionID !== -1
        ) {
            loadPublications(
                dispatch,
                documentManager.currentVersion.versionID,
                documentManager.currentDescription.languageCode
            );
            setCurrentVersion(documentManager.currentVersion);
        }
    }, [
        currentVersion.versionID,
        dispatch,
        documentManager.currentDescription.languageCode,
        documentManager.currentVersion,
        documentManager.currentVersion.versionID,
    ]);

    useEffect(() => {
        if (reports.length === 0 && showArticleReportModal && config.tenant.current?.tenantID) {
            dispatch(startOperation());
            AxiosClient.get(
                `${requestURLs.editorArticles}tenants/${config.tenant.current.tenantID}/reports`
            )
                .then(response => {
                    setReports(response.data as ArticleEntry[]);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }, [config.tenant, dispatch, reports.length, showArticleReportModal]);

    function updateDocumentInState(
        attribute: string,
        value:
            | string[]
            | string
            | number
            | boolean
            | VersionProps[]
            | DocumentCategoryProps[]
            | ReactText
    ) {
        if (!documentManager.documentChanged) dispatch(documentMarkedAsModified(true));

        let newValue = value;
        const pathToValue = attribute.split('#');
        const modifiedDocument = JSON.parse(JSON.stringify(currentDocument));

        if (pathToValue.length === 1) modifiedDocument[attribute] = newValue;
        // deep key for array param => format e.g. description#de_DE#title
        else if (pathToValue.length === 3) {
            const updatedArray = currentDocument[pathToValue[0]];

            if (typeof updatedArray[pathToValue[1]][pathToValue[2]] === 'number')
                newValue = typeof newValue === 'string' ? Number.parseInt(newValue, 10) : newValue;
            updatedArray[pathToValue[1]][pathToValue[2]] = newValue;
            updatedArray[pathToValue[1]].changed = true;
            modifiedDocument[pathToValue[0]] = updatedArray;
        } else if (pathToValue.length === 5) {
            const updatedOuterArray = currentDocument[pathToValue[0]];
            const updatedInnerArray = updatedOuterArray[pathToValue[1]][pathToValue[2]];

            if (typeof updatedInnerArray[pathToValue[3]][pathToValue[4]] === 'number')
                newValue = typeof newValue === 'string' ? Number.parseInt(newValue, 10) : newValue;
            updatedInnerArray[pathToValue[3]][pathToValue[4]] = newValue;
            updatedInnerArray[pathToValue[3]].changed = true;
            updatedOuterArray[pathToValue[1]][pathToValue[2]] = updatedInnerArray;
            updatedOuterArray[pathToValue[1]].changed = true;
            modifiedDocument[pathToValue[0]] = updatedOuterArray;
        }
        setCurrentDocument(modifiedDocument);
    }

    function handleChange(event: ChangeEvent<SupportedElements>) {
        const attribute = event.target.id;
        let receivedValue: string[] | string | number | boolean | undefined;

        if (event.currentTarget.type === 'checkbox') {
            const { checked } = event.currentTarget as HTMLInputElement;
            receivedValue = checked;
        } else if (event.currentTarget.type === 'text' || event.currentTarget.type === 'textarea') {
            const { value } = event.currentTarget as HTMLInputElement;
            receivedValue = value;
        } else if (event.currentTarget.type === 'select-one') {
            const { value } = event.currentTarget as HTMLSelectElement;
            receivedValue = value;
        }
        if (attribute !== undefined && receivedValue !== undefined)
            updateDocumentInState(attribute, receivedValue);
    }

    function handleControlButtonClick(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        const buttonID = event.currentTarget.id;

        switch (buttonID) {
            case `${prefix}btn_save`:
                if (documentManager.documentChanged) {
                    saveDocument(
                        dispatch,
                        currentDocument,
                        config.tenant.current?.tenantID,
                        currentDocumentProps.set
                    );
                    dispatch(documentMarkedAsModified(false));
                    reloadDocuments.set(true);
                }
                break;
            case `${prefix}btn_add-document`: {
                const initializedDocument = JSON.parse(
                    JSON.stringify(emptyDocument)
                ) as DocumentEntry;
                initializedDocument.documentID = -1;
                initializedDocument.product = 'JiveX';
                initializedDocument.author = user.fullName;
                if (documentManager.documentChanged) {
                    setConfirmationDialogParams({
                        title: 'Neues Dokument erstellen ...',
                        message: 'Ungespeicherte Änderungen an diesem Dokument gehen verloren.',
                        okAction: () => {
                            currentDocumentProps.set(initializedDocument);
                        },
                    });
                    setShowConfirmationDialog(true);
                } else currentDocumentProps.set(initializedDocument);
                break;
            }
            case `${prefix}btn_duplicate`:
                if (currentDocument.internalDocumentID !== '') {
                    let message = `Das Dokument ${currentDocument.internalDocumentID} wird dupliziert.`;

                    // extend confirmation message as needed
                    if (documentManager.documentChanged)
                        message = `
                            ${message}\n
                            Ungespeicherte Änderungen an diesem Dokument gehen beim Duplizieren verloren.`;
                    setConfirmationDialogParams({
                        title: 'Dokument duplizieren ...',
                        message,
                        okAction: () => {
                            const duplicatedDocument: DocumentEntry = JSON.parse(
                                JSON.stringify(currentDocument)
                            );
                            // only keep most current version
                            let latestVersion = '0';
                            let latestVersionIndex = 0;
                            currentDocument.versions.forEach((v, i) => {
                                latestVersion = getBiggerVersion(latestVersion, v.productVersion);

                                if (latestVersion === v.productVersion) latestVersionIndex = i;
                            });
                            duplicatedDocument.versions = [];
                            duplicatedDocument.versions[0] = JSON.parse(
                                JSON.stringify(currentDocument.versions[latestVersionIndex])
                            ) as VersionProps;

                            // unset IDs
                            duplicatedDocument.documentID = -1;
                            duplicatedDocument.internalDocumentID = '';
                            duplicatedDocument.versions[0].versionID = -1;
                            Object.keys(duplicatedDocument.descriptions).forEach(k => {
                                duplicatedDocument.descriptions[k].descriptionID = -1;
                                duplicatedDocument.descriptions[k].title += ' (Kopie)';
                            });

                            saveDocument(
                                dispatch,
                                duplicatedDocument,
                                config.tenant.current?.tenantID,
                                currentDocumentProps.set
                            );
                            reloadDocuments.set(true);
                            setCurrentDocument(duplicatedDocument);
                        },
                    });
                    setShowConfirmationDialog(true);
                } else {
                    setConfirmationDialogParams({
                        title: 'Fehler beim Duplizieren des Dokuments',
                        message: 'Ein ungesichertes Dokument kann nicht dupliziert werden.',
                    });
                    setShowConfirmationDialog(true);
                }
                break;
            case `${prefix}btn_delete`: {
                let message = `Soll das Dokument '${currentDocument.internalDocumentID}' wirklich gelöscht werden?\n
                Dieser Aktion kann nicht rückgängig gemacht werden.`;

                if (currentDocument.documentID === undefined)
                    message =
                        'Es ist kein Dokument zum Löschen ausgewählt.\nDie Operation kann nicht ausgeführt werden.';

                setConfirmationDialogParams({
                    title: 'Dokument löschen ...',
                    message,
                    okAction: () => {
                        if (currentDocument.documentID !== undefined) {
                            deleteDocument(dispatch, currentDocument, currentDocumentProps.set);
                            reloadDocuments.set(true);
                            setCurrentDocument(JSON.parse(JSON.stringify(emptyDocument)));
                        }
                    },
                });
                setShowConfirmationDialog(true);
                break;
            }

            case `${prefix}btn_mdx`: {
                if (currentDocument.documentID !== undefined) {
                    setFileExtension('mdx');
                    setShowInputDialogForDownloadFile(true);
                }
                break;
            }

            case `${prefix}btn_pdf`: {
                if (currentDocument.documentID !== undefined) {
                    setFileExtension('pdf');
                    setShowInputDialogForDownloadFile(true);
                }
                break;
            }

            case `${prefix}btn_publish`: {
                if (currentDocument.documentID && currentDocument.documentID > 0) {
                    const lang = currentDescription.languageCode;
                    if (
                        currentDocument.versions.find(
                            version =>
                                !version.publishedCheckMap.de_DE.published ||
                                (version.publishedCheckMap[lang].published &&
                                    !version.publishedCheckMap[lang].upToDatePublication)
                        )
                    )
                        setShowPublishModal(true);
                    else
                        dispatch(
                            addError(
                                EErrorTypes.info,
                                'Alle Versionen sind aktuell und publiziert.'
                            )
                        );
                }
                if (currentDocument.documentID === -1)
                    dispatch(
                        addError(
                            EErrorTypes.warning,
                            'Wählen Sie ein Dokument mit einer Version aus.'
                        )
                    );
                break;
            }

            default:
                break;
        }
    }

    function handleVersionDelete() {
        if (currentDocument.documentID && currentVersion.versionID) {
            dispatch(startOperation());
            AxiosClient.delete(`${requestURLs.versions}${currentVersion.versionID}`)
                .then(() => {
                    if (currentDocumentProps.value.documentID)
                        loadDocument(
                            dispatch,
                            currentDocumentProps.value.documentID,
                            documentManager,
                            currentDocumentProps.set,
                            defaultLang
                        );
                })
                .catch(error => {
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status);
                })
                .finally(() => dispatch(finishOperation()));
        } else {
            addError(EErrorTypes.error, `${t('StatusCode.General.Failed')}`);
        }
    }

    function handleDeleteDescription() {
        if (currentDocument.documentID && currentDescription.languageID) {
            dispatch(startOperation());
            AxiosClient.delete(
                `${requestURLs.editorSingleDocument}/descriptions/${currentDescription.descriptionID}`
            )
                .then(() => {
                    if (currentDocumentProps.value.documentID)
                        loadDocument(
                            dispatch,
                            currentDocumentProps.value.documentID,
                            documentManager,
                            currentDocumentProps.set,
                            defaultLang
                        );
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        }
    }

    function handleDescriptionSelection(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (event && event.currentTarget) {
            const descLang = event.currentTarget.getAttribute('data-id');
            if (descLang !== null) {
                setCurrentDescription(currentDocument.descriptions[descLang]);
                dispatch(descriptionSelected(currentDocument.descriptions[descLang]));
                loadPublications(dispatch, currentVersion.versionID, descLang);
            } else setShowInputTranslationDialog(true);
        }
    }

    function handleChangevisibility(publication: PublicationMeta) {
        if (publication.publicationID > 0) {
            dispatch(startOperation());
            AxiosClient.put(`${requestURLs.publications}${publication.publicationID}`)
                .then(() =>
                    loadPublications(
                        dispatch,
                        currentVersion.versionID,
                        currentDescription.languageCode
                    )
                )
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        }
    }

    function buildPublicationEntry(version: VersionProps): ReactElement[] {
        const publicationElements: ReactElement[] = [];

        if (version.publishedCheckMap[currentDescription.languageCode].published)
            if (documentManager.currentPublications.length > 0) {
                documentManager.currentPublications.forEach(pub => {
                    publicationElements.push(
                        <PublicationEntryWrapper key={key(pub)}>
                            {`Produktversion: ${pub.productVersion}, Zielgruppe: ${
                                GermanAudiences[pub.audience]
                            }, Zuletzt freigegeben: ${makeTimeStampReadable(pub.publicationDate)}`}
                            <ActionControlButton
                                prefix="duplicate-button"
                                name={pub.activated ? 'visible' : 'invisible'}
                                size={18}
                                callback={() => handleChangevisibility(pub)}
                            />
                        </PublicationEntryWrapper>
                    );
                });
            }
        return publicationElements;
    }

    function handleVersionSelection(event: React.MouseEvent<HTMLButtonElement>) {
        event.preventDefault();
        if (event && event.currentTarget) {
            const versionIndex = event.currentTarget.getAttribute('data-id');
            if (versionIndex !== null) {
                loadPublications(
                    dispatch,
                    currentDocument.versions[Number.parseInt(versionIndex, 10)].versionID,
                    currentDescription.languageCode
                );
                dispatch(
                    versionSelected(currentDocument.versions[Number.parseInt(versionIndex, 10)])
                );
                setCurrentVersion(currentDocument.versions[Number.parseInt(versionIndex, 10)]);
            } else setShowInputVersionDialog(true);
        }
    }

    function handleDoubleClickInArticleModal(event: React.MouseEvent<HTMLButtonElement>) {
        const articleID = event.currentTarget.getAttribute('data-id');
        const { versionID } = currentVersion;
        if (documentManager.currentVersion.versionID && articleID) {
            dispatch(startOperation());
            AxiosClient.put(`${requestURLs.versions}${versionID}/report/${articleID}`)
                .then(response => {
                    if (currentDocumentProps.value.documentID) {
                        loadDocument(
                            dispatch,
                            currentDocumentProps.value.documentID,
                            documentManager,
                            currentDocumentProps.set,
                            defaultLang
                        );
                        setActualVersion({ ...currentVersion, report: response.data.report });
                        setShowArticleReportModal(false);
                    }
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        }
    }

    function renderTabHeaders(
        tabPrefix: string,
        titleProperty: string,
        iterable: { [key: string]: DocumentDescriptionProps } | VersionProps[],
        currentID: number,
        callback: Function,
        editable = false,
        addDescription = false
    ): ReactElement[] {
        const renderedTabs: ReactElement[] = [];
        Object.keys(iterable).forEach((v, i) => {
            const defaultLanguage = 'de_DE';
            const entry: DocumentDescriptionProps | VersionProps = Object.values(iterable)[i];
            const keys = Object.keys(entry);
            const values = Object.values(entry);
            const pubMap = keys.indexOf('publishedCheckMap');
            let title: string =
                keys.indexOf(titleProperty) >= 0 ? values[keys.indexOf(titleProperty)] : '???';
            if (titleProperty === 'languageCode' && title !== '???')
                title = config.language.list[title].name;

            let published = false;
            let upToDate = true;
            let descriptionOutdated = false;

            if (pubMap > -1 && values[pubMap][currentDescription.languageCode]) {
                published = values[pubMap][currentDescription.languageCode].published;
                upToDate = values[pubMap][currentDescription.languageCode].upToDatePublication;
            }

            if (
                keys.indexOf('languageCode') >= 0 &&
                values[keys.indexOf('languageCode')] === currentDescription.languageCode &&
                currentDescription.lastModifiedDate &&
                currentDocument.descriptions[defaultLanguage] &&
                currentDocument.descriptions[defaultLanguage].lastModifiedDate
            ) {
                descriptionOutdated =
                    currentDescription.lastModifiedDate <
                    // eslint-disable-next-line @typescript-eslint/no-non-null-assertion
                    currentDocument.descriptions[defaultLanguage].lastModifiedDate!;
            }

            const idx = Math.max(keys.indexOf('descriptionID'), keys.indexOf('versionID'));
            const active = values[idx] === currentID;

            const value = title === '' ? '(ohne)' : title;
            let status = '';
            if (published && upToDate) status = 'ok';
            else if (published && !upToDate) status = 'warn';

            const descriptionStatus = descriptionOutdated ? 'warn' : '';

            renderedTabs.push(
                <FieldWrapperVersionCopy key={`tabWrapper${key(entry)}`}>
                    {active && config.tenant.current !== null && title !== 'Deutsch' && (
                        <ButtonToolBar className={active ? 'active' : ''}>
                            <ActionControlButton
                                prefix="delete-button"
                                name="delete"
                                size={15}
                                callback={() => {
                                    if (addDescription) {
                                        setShowConfirmationDialogDeleteDescription(true);
                                    } else setShowConfirmationDialogDeleteVersion(true);
                                    setActualVersion(entry);
                                }}
                            />
                            {validatePermissions(config.tenant.current, user.permissions, [
                                'EDITOR',
                                'ADMIN',
                            ]) &&
                                editable && (
                                    <>
                                        <ActionControlButton
                                            prefix="duplicate-button"
                                            name="duplicate"
                                            size={15}
                                            callback={() => {
                                                setisVersionModalVisible(true);
                                                setActualVersion(entry);
                                            }}
                                        />
                                        {/* Comment needs to be removed after fully implemented review process */}
                                        {/* <ActionControlButton
                                            prefix="review-button"
                                            name="review"
                                            size={15}
                                            callback={() => setShowReviewModal(true)}
                                        /> */}
                                    </>
                                )}
                        </ButtonToolBar>
                    )}
                    <VersionTab>
                        <Tab
                            prefix={tabPrefix}
                            key={key(entry)}
                            className={active ? 'active' : ''}
                            active={active}
                            id={v}
                            data-id={values[idx]}
                            changed={entry.changed}
                            onClick={(event: React.MouseEvent<HTMLButtonElement>) =>
                                callback(event)
                            }
                        >
                            <div className={`title ${status} ${descriptionStatus}`}>
                                {active && editable ? (
                                    <InPlaceEditor
                                        id={`${tabPrefix}_active`}
                                        value={value}
                                        showButtons={false}
                                        actionOnBlur="Cancel"
                                        submit={() =>
                                            updateDocumentInState(
                                                `versions#${v}#productVersion`,
                                                (
                                                    document.getElementById(
                                                        `in-place-editor#${tabPrefix}_active`
                                                    ) as HTMLInputElement
                                                ).value
                                            )
                                        }
                                        minStyles
                                    />
                                ) : (
                                    value
                                )}
                            </div>
                        </Tab>
                    </VersionTab>
                </FieldWrapperVersionCopy>
            );
        });
        if (editable || addDescription)
            renderedTabs.push(
                <Tab
                    className="add-version"
                    prefix={tabPrefix}
                    key={`add_${tabPrefix}`}
                    id={null}
                    changed={false}
                    onClick={(event: React.MouseEvent<HTMLButtonElement>) => callback(event)}
                >
                    <div className="tab-header-content">
                        <div className="title">
                            <Icon
                                name="add-thumbnail"
                                size="18"
                            />
                        </div>
                    </div>
                </Tab>
            );
        return renderedTabs;
    }

    function renderConfirmationDialogDeleteVersions() {
        return (
            <ConfirmationDialog
                id="version-action-confirmation"
                title="Version entfernen"
                message="Wollen Sie die Version wirklich entfernen?"
                onCancel={() => {
                    setShowConfirmationDialogDeleteVersion(false);
                }}
                onOK={() => handleVersionDelete()}
                isOpenState={{
                    value: showConfirmationDialogDeleteVersion,
                    set: setShowConfirmationDialogDeleteVersion,
                }}
            />
        );
    }

    function renderConfirmationDialogDeleteDescription() {
        return (
            <ConfirmationDialog
                id="description-action-confirmation"
                title="Beschreibung entfernen"
                message="Wollen Sie die Beschreibung wirklich entfernen?"
                onCancel={() => setShowConfirmationDialogDeleteDescription(false)}
                onOK={() => handleDeleteDescription()}
                isOpenState={{
                    value: showConfirmationDialogDeleteDescription,
                    set: setShowConfirmationDialogDeleteDescription,
                }}
            />
        );
    }

    function renderConfirmationDialog() {
        return (
            <ConfirmationDialog
                id="document-action-confirmation"
                title={confirmationDialogParams?.title || 'NO TITLE GIVEN!'}
                message={confirmationDialogParams?.message || 'NO MESSAGE GIVEN!'}
                onCancel={() => {
                    setShowConfirmationDialog(false);
                }}
                onOK={
                    confirmationDialogParams?.okAction
                        ? () => {
                              setShowConfirmationDialog(false);
                              if (confirmationDialogParams?.okAction)
                                  confirmationDialogParams.okAction();
                          }
                        : undefined
                }
                isOpenState={{
                    value: showConfirmationDialog,
                    set: setShowConfirmationDialog,
                }}
            />
        );
    }

    function renderInputDialogForVersions() {
        return (
            <TextInputDialog
                id="documenteditor-newversion"
                title="Neue Version anlegen"
                message="Bitte Versionsnummer eintragen:"
                defaultValue={
                    currentDocument.versions.length > 0
                        ? currentDocument.versions[
                              currentDocument.versions.length - 1
                          ].productVersion
                              .split('.')
                              .map((digit, index) =>
                                  index ===
                                  currentDocument.versions[
                                      currentDocument.versions.length - 1
                                  ].productVersion.match(/\./g)?.length
                                      ? Number.parseInt(digit, 10) + 1
                                      : Number.parseInt(digit, 10)
                              )
                              .join('.')
                        : '5.1'
                }
                onOK={() => {
                    const inputValueFromDialog = (
                        document.getElementById(
                            'documenteditor-newversion-input'
                        ) as HTMLInputElement
                    ).value;
                    if (inputValueFromDialog !== null && inputValueFromDialog !== '') {
                        dispatch(startOperation());
                        const newVersion = {
                            id: -1,
                            audience:
                                currentDocument.versions.length > 0
                                    ? currentDocument.versions[currentDocument.versions.length - 1]
                                          .audience
                                    : 1,
                            author: user.fullName,
                            coauthors: null,
                            status: 0,
                            productVersion: inputValueFromDialog,
                            createdDate: new Date(Date.now()),
                            createdBy: user.fullName,
                            lastModifiedDate: null,
                            lastModifiedBy: '',
                            articles:
                                currentDocument.versions.length > 0
                                    ? currentDocument.versions[currentDocument.versions.length - 1]
                                          .articles
                                    : [],
                            report:
                                currentDocument.versions.length > 0
                                    ? currentDocument.versions[currentDocument.versions.length - 1]
                                          .report
                                    : null,
                        };
                        AxiosClient.post(
                            `${requestURLs.versions}documents/${currentDocument.documentID}`,
                            newVersion
                        )
                            .then(response => {
                                loadDocument(
                                    dispatch,
                                    response.data.documentID,
                                    documentManager,
                                    currentDocumentProps.set,
                                    defaultLang
                                );
                            })
                            .catch(error => {
                                dispatch(
                                    addError(
                                        EErrorTypes.error,
                                        (error.response.data as ResponseError).status
                                    )
                                );
                            })
                            .finally(() => finishOperation());
                    }
                }}
                isOpenState={[showInputVersionDialog, setShowInputVersionDialog]}
            />
        );
    }

    function renderInputDialogForTranslations() {
        return (
            <TextInputDialog
                id="documenteditor-newtranslation"
                title="Neue Beschreibung für eine Übersetzung anlegen"
                message={`Bitte Sprache für Version ${
                    currentVersion !== null && currentVersion.versionID !== -1
                        ? currentVersion.productVersion
                        : '???'
                } auswählen:`}
                disabled={
                    currentVersion === null ||
                    currentVersion.versionID === -1 ||
                    Object.keys(config.language.list).filter(
                        lang => !!currentDocument.descriptions[lang]
                    ).length === Object.keys(config.language.list).length
                }
                onOK={() => {
                    dispatch(startOperation());
                    const input = (
                        document.getElementById('description-lang-selector') as HTMLInputElement
                    ).value;

                    if (input !== undefined && input !== '' && input !== 'DEFAULT') {
                        const desc = currentDocument.descriptions.de_DE;
                        const payload = {
                            title: desc.title,
                            description: desc.description,
                            languageCode: input,
                            keywords: desc.keywords,
                        };
                        setUIBlockedForTranslation(true);
                        AxiosClient.post(
                            `${requestURLs.editorSingleDocument}/${currentDocument.documentID}/descriptions`,
                            payload
                        )
                            .then(response => {
                                if (currentDocument.documentID)
                                    loadDocument(
                                        dispatch,
                                        response.data.documentID,
                                        documentManager,
                                        currentDocumentProps.set,
                                        defaultLang
                                    );
                            })
                            .catch(error =>
                                dispatch(
                                    addError(
                                        EErrorTypes.error,
                                        (error.response.data as ResponseError).status
                                    )
                                )
                            )
                            .finally(() => {
                                setUIBlockedForTranslation(false);
                                dispatch(finishOperation());
                            });
                    } else {
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                'Die ausgewählte Sprache wurde nicht gefunden!'
                            )
                        );
                    }
                }}
                selectField
                isOpenState={[showInputTranslationDialog, setShowInputTranslationDialog]}
            >
                {config.language.current !== null ? (
                    <select
                        id="description-lang-selector"
                        defaultValue="DEFAULT"
                        disabled={
                            Object.keys(config.language.list).length ===
                            Object.keys(currentDocument.descriptions).length
                        }
                    >
                        {Object.values(config.language.list).map(lang => {
                            const elements: ReactElement[] = [];

                            if (Object.keys(currentDocument.descriptions).indexOf(lang.code) === -1)
                                elements.push(
                                    <option
                                        key={key(lang)}
                                        value={lang.code}
                                    >
                                        {lang.name}
                                    </option>
                                );
                            return elements;
                        })}
                        <option
                            value="DEFAULT"
                            style={{ display: 'none' }}
                            disabled
                        >
                            {t('StatusCode.SpecialCase.SelectLanguage')}
                        </option>
                    </select>
                ) : null}
            </TextInputDialog>
        );
    }

    function buildAudienceEntry() {
        const elements: ReactElement[] = [];
        for (let i = 0; i <= currentVersion.audience; i += 1) {
            const audience = () => {
                if (i === 0) return 'Intern';
                if (i === 1) return 'Partner';
                if (i === 2) return 'Kunden';
                if (i === 3) return 'Anwender';
                return '';
            };
            elements.push(
                <option
                    key={i}
                    value={i}
                >
                    {audience()}
                </option>
            );
        }
        return elements;
    }

    function renderInputDialogForDownload() {
        if (fileExtension !== 'pdf' && fileExtension !== 'mdx') {
            return null;
        }
        return (
            <TextInputDialog
                id={`documenteditor-download-doc-${fileExtension}`}
                title={`Download des Dokuments als ${fileExtension}`}
                message={`Bitte Audience für Version ${
                    currentVersion !== null && currentVersion.versionID !== -1
                        ? currentVersion.productVersion
                        : '???'
                } auswählen:`}
                onOK={() => {
                    dispatch(startOperation());
                    const input = (document.getElementById('audience-selector') as HTMLInputElement)
                        .value;

                    if (input !== undefined && input !== '' && input !== 'DEFAULT') {
                        if (fileExtension === 'pdf') {
                            downloadFile(
                                // eslint-disable-next-line max-len
                                `${process.env.REACT_APP_BACKEND_SERVER}/${requestURLs.mdx}${currentVersion.versionID}/${input}/${currentDescription.languageCode}/pdf`
                            );
                        }
                        if (fileExtension === 'mdx') {
                            downloadFile(
                                // eslint-disable-next-line max-len
                                `${process.env.REACT_APP_BACKEND_SERVER}/${requestURLs.mdx}${currentVersion.versionID}/${input}/${currentDescription.languageCode}/md`
                            );
                        }
                    } else {
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                'Die ausgewählte Sprache wurde nicht gefunden!'
                            )
                        );
                    }
                    dispatch(finishOperation());
                }}
                selectField
                isOpenState={[showInputDialogForDownloadFile, setShowInputDialogForDownloadFile]}
            >
                {config.language.current !== null ? (
                    <select
                        id="audience-selector"
                        defaultValue="DEFAULT"
                    >
                        {buildAudienceEntry()}
                        <option
                            value="DEFAULT"
                            style={{ display: 'none' }}
                            disabled
                        >
                            {t('Bitte wählen Sie eine Audience aus.')}
                        </option>
                    </select>
                ) : null}
            </TextInputDialog>
        );
    }

    function buildFlags(translation: TranslationEntryPreview) {
        return (
            <div
                key={`img_selected_flag_strikethrough_${translation.languageCode}`}
                className={`img-flag-div ${
                    translation.status === 50 ? 'icon-flag-strikethrough' : ''
                }`}
            >
                <img
                    key={`img_selected_flag_${translation.languageCode}`}
                    src={`/icn/flags/${translation.languageCode}.svg`}
                    alt="Selected flag"
                    width="20px"
                    height="10px"
                    className={`article-flags ${translation.status === 15 ? 'grey' : ''}`}
                />
            </div>
        );
    }

    let kv = 1;

    function buildArticleButtonEnrties(
        id: number,
        version: VersionProps,
        translations: TranslationsEntries
    ) {
        let kvb = 1;
        const elements: ReactElement[] = [];
        if (version) {
            version.articles.forEach((article, index) => {
                if (translations && translations[article.articleID]) {
                    const optionID = `${id}_draggable_${index}`;
                    const articleStatus = `(${article.articleID})`;
                    const articleTitle = article.title;
                    kvb = 13 + kvb * 31;

                    const flags: ReactElement[] = [];
                    flags.push(
                        <div
                            key="img_selected_flag_strikethrough_de_DE"
                            className="img-flag-div"
                        >
                            <img
                                key="img_selected_flag_de_DE"
                                src="/icn/flags/de_DE.svg"
                                alt="Selected flag"
                                width="20px"
                                height="10px"
                                className={`article-flags ${article.status === 40 ? '' : 'grey'}`}
                            />
                        </div>
                    );
                    translations[article.articleID].forEach(translation =>
                        flags.push(buildFlags(translation))
                    );
                    elements.push(
                        <button
                            type="button"
                            id={optionID}
                            key={`${id}_${articleStatus + articleTitle}_${kvb}`}
                        >
                            <p
                                key={`${id}_${articleStatus}_${kvb}`}
                                className="article-p"
                            >
                                {articleStatus}&nbsp;
                            </p>
                            {flags}
                            <p
                                key={`${id}_${articleTitle}_${kvb}`}
                                className="article-p"
                            >
                                {articleTitle}
                            </p>
                        </button>
                    );
                }
            });
        }
        return elements;
    }

    function buildReportEntry(version: VersionProps, translations: TranslationsEntries) {
        if (
            version !== null &&
            version.report !== null &&
            version.report !== undefined &&
            translations &&
            translations[version.report.articleID]
        ) {
            const flags: ReactElement[] = [];
            flags.push(
                <div
                    key="img_selected_flag_strikethrough_de_DE"
                    className="img-flag-div"
                >
                    <img
                        key="img_selected_flag_de_DE"
                        src="/icn/flags/de_DE.svg"
                        alt="Selected flag"
                        width="20px"
                        height="10px"
                        className={`article-flags ${version.report.status === 40 ? '' : 'grey'}`}
                    />
                </div>
            );
            translations[version.report.articleID].forEach(translation =>
                flags.push(buildFlags(translation))
            );
            const reportStatus = `(${version.report.articleID})`;
            const reportTitle = version.report.title;

            return (
                <div className="report-button-div">
                    <p className="article-p">{reportStatus}&nbsp;</p>
                    {flags}
                    <p className="article-p">{reportTitle}</p>
                </div>
            );
        }
        return <div className="report-button-div">{t('Version.Form')}</div>;
    }

    return (
        <Wrapper data-testid="documenteditor">
            <FormHeader>
                <h4>
                    Dokumenteigenschaften
                    {currentDocument.documentID === undefined ? ' (neues Dokument)' : ''}
                    {documentManager.documentChanged ? <span>&nbsp;*</span> : ''}
                </h4>
                <Toolbar
                    btnPrefix={prefix}
                    handler={event => handleControlButtonClick(event)}
                />
            </FormHeader>
            <Form>
                <InnerFormWrapper>
                    <ColumnContainer>
                        <RowContainer>
                            <FieldWrapperDocument>
                                <FormFields>
                                    <LabeledFormField
                                        formFieldType={FormFieldTypes.Input}
                                        id="product"
                                        label="Produkt:"
                                        trackChanges
                                        onChange={event => handleChange(event)}
                                        value={currentDocument.product}
                                        type="text"
                                    />
                                    <LabeledFormField
                                        formFieldType={FormFieldTypes.Button}
                                        id="categories"
                                        label="Kategorien:"
                                        value={currentDocument.categories
                                            .map(c => c.title)
                                            .join(', ')}
                                        onClick={() => setShowCategorySelector(true)}
                                    />
                                </FormFields>
                            </FieldWrapperDocument>
                        </RowContainer>
                        <RowContainer>
                            <FieldWrapperDescription>
                                <FormFields>
                                    <LabeledFormField
                                        formFieldType={FormFieldTypes.Tab}
                                        id="descriptions"
                                        label="Beschreibung:"
                                        trackChanges
                                        onChange={event => handleChange(event)}
                                        tab="tabName"
                                        tabHeader={renderTabHeaders(
                                            'descriptions',
                                            'languageCode',
                                            currentDocument.descriptions,
                                            descriptionID(),
                                            handleDescriptionSelection,
                                            false,
                                            true
                                        )}
                                        tabContent={
                                            Object.keys(currentDocument.descriptions).length > 0
                                                ? Object.values(currentDocument.descriptions).map(
                                                      description => (
                                                          <TabContentContainer
                                                              id={`content#description#${description.languageCode}`}
                                                              key={`content#description#${key(
                                                                  description
                                                              )}`}
                                                              visible={
                                                                  description.descriptionID ===
                                                                  currentDescription?.descriptionID
                                                              }
                                                          >
                                                              <DescriptionForm
                                                                  description={description}
                                                                  changeHandler={event =>
                                                                      handleChange(event)
                                                                  }
                                                              />
                                                          </TabContentContainer>
                                                      )
                                                  )
                                                : [
                                                      <TabContentContainer
                                                          id={`content#${-1}`}
                                                          key={`content#description#${-1}`}
                                                          visible
                                                      >
                                                          <DescriptionForm />
                                                      </TabContentContainer>,
                                                  ]
                                        }
                                    />
                                </FormFields>
                            </FieldWrapperDescription>
                        </RowContainer>

                        <RowContainer>
                            <FieldWrapperVersion>
                                <FormFields>
                                    <LabeledFormField
                                        formFieldType={FormFieldTypes.Tab}
                                        id="versions"
                                        label="Versionen:"
                                        trackChanges
                                        onChange={event => handleChange(event)}
                                        tabHeader={renderTabHeaders(
                                            'version',
                                            'productVersion',
                                            currentDocument.versions.sort((a, b) => {
                                                if (a.productVersion < b.productVersion) return -1;
                                                if (a.productVersion > b.productVersion) return 1;
                                                return 0;
                                            }),
                                            currentVersion ? currentVersion.versionID : -1,
                                            handleVersionSelection,
                                            true
                                        )}
                                        tabContent={
                                            Object.keys(currentDocument.versions).length > 0
                                                ? Object.values(currentDocument.versions)
                                                      .sort((a, b) => {
                                                          if (a.productVersion < b.productVersion)
                                                              return -1;
                                                          if (a.productVersion > b.productVersion)
                                                              return 1;
                                                          return 0;
                                                      })
                                                      .map((version, index) => {
                                                          kv = 13 + kv * 31;
                                                          return (
                                                              <TabContentContainer
                                                                  id={`content#version#${index}`}
                                                                  key={`content#verion#${key(
                                                                      version
                                                                  )}${kv}`}
                                                                  visible={
                                                                      version.versionID ===
                                                                      currentVersion?.versionID
                                                                  }
                                                              >
                                                                  <VersionForm
                                                                      key={`${key(version)}${
                                                                          kv + 47
                                                                      }`}
                                                                      version={version}
                                                                      index={index}
                                                                      changeHandler={event =>
                                                                          handleChange(event)
                                                                      }
                                                                      callback={() =>
                                                                          setShowArticleReportModal(
                                                                              true
                                                                          )
                                                                      }
                                                                      report={buildReportEntry(
                                                                          version,
                                                                          documentManager.currentTranslations
                                                                      )}
                                                                      publication={buildPublicationEntry(
                                                                          version
                                                                      )}
                                                                  >
                                                                      {buildArticleButtonEnrties(
                                                                          index,
                                                                          version,
                                                                          documentManager.currentTranslations
                                                                      )}
                                                                  </VersionForm>
                                                              </TabContentContainer>
                                                          );
                                                      })
                                                : [
                                                      <TabContentContainer
                                                          id={`content_${-1}`}
                                                          key={`version_content_${-1}`}
                                                          visible
                                                      >
                                                          <VersionForm />
                                                      </TabContentContainer>,
                                                  ]
                                        }
                                    />
                                </FormFields>
                            </FieldWrapperVersion>
                            <MetaInformation
                                currentDocument={currentDocument}
                                currentDescription={currentDescription}
                                currentVersion={currentVersion}
                            />
                        </RowContainer>
                    </ColumnContainer>
                </InnerFormWrapper>
                {renderInputDialogForVersions()}
                {renderInputDialogForTranslations()}
                {renderConfirmationDialog()}
                {renderConfirmationDialogDeleteVersions()}
                {renderConfirmationDialogDeleteDescription()}
                {renderInputDialogForDownload()}
            </Form>
            <ReviewModal openState={{ value: showReviewModal, set: setShowReviewModal }} />
            <PublishModal
                currentDocument={currentDocumentProps.value}
                openState={{ value: showPublishModal, set: setShowPublishModal }}
            />
            <VersionModal
                currentDocumentProps={currentDocumentProps}
                actualVersion={actualVersion}
                openState={{ value: isVersionModalVisible, set: setisVersionModalVisible }}
            />
            <ArticleModal
                openState={{
                    value: showArticleReportModal,
                    set: setShowArticleReportModal,
                }}
                handleDoubleClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    handleDoubleClickInArticleModal(e)
                }
                articles={reports}
            />
            {showCategorySelector && (
                <CategorySelector
                    visibilityState={[showCategorySelector, setShowCategorySelector]}
                    initialSelectedCategories={currentDocument.categories}
                    updateValues={(categories: DocumentCategoryProps[]) =>
                        updateDocumentInState('categories', categories)
                    }
                />
            )}
            <Modal
                id="translationModal"
                key="translationModal"
                shouldCloseOnOverlayClick={false}
                isOpen={isUIBlockedForTranslation}
            >
                <h3>Übersetzung wird durchgeführt ...</h3>
            </Modal>
        </Wrapper>
    );
};

const Wrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    padding: 5px;
    border-left: ${props => props.theme.$border.secondary.thin};

    button {
        display: flex;
        margin: 0;
    }

    div.tab-header-content {
        display: flex;
        margin: 0;
    }

    .title {
        margin: auto;

        button {
            margin: auto;
        }
    }

    .versionToolbar {
        display: block;
        position: relative;
        top: 0;
        width: 100%;
        height: 20px;
        align-items: middle;
        background-color: rgba(0, 0, 0, 0);
        padding: auto 2px;
    }

    .active .versionToolbar {
        background-color: rgba(197, 197, 197, 0.2);
    }

    .ok {
        color: ${props => props.theme.$colorSuccess} !important;
    }

    .warn {
        color: ${props => props.theme.$colorDanger} !important;
    }

    div.status {
        margin: 0 0 0 0.2rem;
        position: relative;
        top: 0;
        right: 0;
        z-index: 0;
    }
`;

const ColumnContainer = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
`;

const RowContainer = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    flex: 1;

    .add-version {
        display: flex;
        flex-direction: column;
        position: relative;
        min-width: 80px;
        min-height: 45px;

        button {
            position: absolute;
            bottom: 0;
        }
    }
`;

const FieldWrapperDescription = styled.div<ThemeProps>`
    disply: flex;
    flex-direction: column;
    flex: 1;
`;

const FieldWrapperDocument = styled.div<ThemeProps>`
    disply: flex;
    flex-direction: column;
    flex: 1;
    max-height: 147px;
`;

const FieldWrapperVersion = styled.div<ThemeProps>`
    disply: flex;
    flex-direction: column;
    flex: 1;
`;

const VersionTab = styled.div<ThemeProps>`
    position: absolute;
    bottom: 0;
`;

const FieldWrapperVersionCopy = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    position: relative;
    min-width: 80px;
    min-height: 45px;

    :hover {
        .active.btn-tool-bar {
            opacity: 0.9;
            transition: opacity 0.5s;
        }
    }
`;

const PublicationEntryWrapper = styled.div<ThemeProps>`
    display: flex;
    justify-content: space-between;

    p {
        margin: 0;
        padding: 0;
    }

    button {
        width: fit-content;
    }
`;

export default DocumentEditor;
