import {
    ArticleEntry,
    DocumentDescriptionProps,
    DocumentEntry,
    VersionProps,
} from '../../interfaces/DocumentEntry';
import { startOperation, finishOperation, addError } from '../../store/global/actions';
import AxiosClient, { requestURLs } from '../axios';
import { EErrorTypes } from '../../interfaces/globals/errors';
import {
    emptyArticle,
    emptyDescription,
    emptyDocument,
    emptyVersion,
} from '../../store/document/reducers';
import { t } from '../language';
import { ResponseError } from '../../typings/error';
import { currentJobLoaded } from '../../store/job/actions';
import { DocumentManagerState } from '../../store/document/types';
import {
    articleSelected,
    descriptionSelected,
    versionSelected,
} from '../../store/document/actions';
import { loadTranslations } from './translation';
import { JobDTO } from '../backend';

/**
 *<p>Takes a document, the current document state and returns the default version of this document.</p>
 * <p>If the document doesn't have a version an {@code emptyVersion} will be returned.</p>
 *
 * @param {DocumentEntry} document Document object.
 * @param {DocumentManagerState} documentManager Current document state.
 * @returns {VersionProps} a default version.
 */
function selectVersion(
    document: DocumentEntry,
    documentManager: DocumentManagerState
): VersionProps {
    let version: VersionProps;

    if (
        document.versions.filter(v => v.versionID === documentManager.currentVersion.versionID)
            .length === 1
    )
        [version] = document.versions.filter(
            v => v.versionID === documentManager.currentVersion.versionID
        );
    else if (Object.keys(document.versions).length > 0)
        version = Object.values(document.versions)[Object.keys(document.versions).length - 1];
    else version = emptyVersion;

    return version;
}

/**
 * <p>Takes document and returns the default description in language "de_DE".
 * If this document doesn't have a description an {@code emptyDescription} will be returned</p>
 *
 * @param {DocumentEntry} document Document object.
 * @returns {DocumentDescriptionProps} Document description.
 */
function selectDescription(
    document: DocumentEntry,
    languageCode = 'de_DE'
): DocumentDescriptionProps {
    return Object.keys(document.descriptions).length > 0
        ? document.descriptions[languageCode]
        : emptyDescription;
}

/**
 * <p>Takes a version and retuns the fist article in the
 * list or the report article if its selected</p>
 *
 * @param {VersionProps} version Version object.
 * @param {DocumentManagerState} documentManager Current document state.
 * @returns {ArticleEntry} The selected article.
 */
function selectArticle(version: VersionProps, documentManager: DocumentManagerState): ArticleEntry {
    // eslint-disable-next-line no-nested-ternary
    return version.report && documentManager.currentArticle.articleID === version.report.articleID
        ? version.report
        : version.articles.filter(
              article => article.articleID === documentManager.currentArticle.articleID
          ).length === 0
        ? version.articles[0] || emptyArticle
        : version.articles.filter(
              article => article.articleID === documentManager.currentArticle.articleID
          )[0];
}

/**
 * <p>Uses an asynchronous store mechanism to load the given document.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher.</p>
 *
 * @param {Function} dispatch The dispatcher used to start state operations.
 * @param {DocumentEntry} document The document to save.
 */
export function loadDocument(
    dispatch: Function,
    documentID: number,
    documentManager: DocumentManagerState,
    getDocumentCallback: (value: DocumentEntry) => void,
    languageCode = 'de_DE'
) {
    dispatch(startOperation());

    AxiosClient.get(`${requestURLs.editorSingleDocument}/${documentID}`)
        .then(response => {
            const document: DocumentEntry = response.data;
            document.versions = document.versions.sort((a, b) => {
                if (a.productVersion < b.productVersion) return -1;
                if (a.productVersion > b.productVersion) return 1;
                return 0;
            });
            const version = selectVersion(document, documentManager);
            getDocumentCallback(document);
            loadTranslations(dispatch, version.versionID);
            dispatch(versionSelected(version));
            dispatch(descriptionSelected(selectDescription(document, languageCode)));
            dispatch(articleSelected(selectArticle(version, documentManager)));
        })
        .catch(error =>
            dispatch(addError(EErrorTypes.error, (error.response.data as ResponseError).status))
        )
        .finally(() => dispatch(finishOperation()));
}

function handleDocumentSave(
    document: DocumentEntry,
    saveDocumentCallback: (value: DocumentEntry) => void
) {
    saveDocumentCallback(document);
}

/**
 * <p>Uses an asynchronous store mechanism to save the given document.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher.</p>
 *
 * @param {Function} dispatch The dispatcher used to start state operations.
 * @param {DocumentEntry} document The document to save.
 * @param {SearchEntryProps} associatedSearch The optional search box ID associated with this operation
 */
export function saveDocument(
    dispatch: Function,
    document: DocumentEntry,
    tenantID: string | undefined,
    saveDocumentCallback: (value: DocumentEntry) => void
) {
    const d = JSON.parse(JSON.stringify(document)) as DocumentEntry;
    if (d.documentID === undefined) d.documentID = -1;
    const isNew = d.documentID === -1;
    d.tenantID = tenantID;

    const url = `${requestURLs.editorSingleDocument}${
        d.documentID !== -1 ? `/${d.documentID}` : ''
    }`;
    if (isNew) {
        dispatch(startOperation());
        AxiosClient.post(url, d)
            .then(response => {
                const savedDocument: DocumentEntry = response.data;
                handleDocumentSave(savedDocument, saveDocumentCallback);
                dispatch(addError(EErrorTypes.info, t('StatusCode.SpeacialCase.SaveDocument')));
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => dispatch(finishOperation()));
    } else {
        dispatch(startOperation());
        AxiosClient.put(url, d)
            .then(response => {
                const savedDocument: DocumentEntry = response.data;
                handleDocumentSave(savedDocument, saveDocumentCallback);
                dispatch(addError(EErrorTypes.info, t('StatusCode.SpeacialCase.SaveDocument')));
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => dispatch(finishOperation()));
    }
}

/**
 * <p>Uses an asynchronous delete mechanism to remove the given document.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher.</p>
 *
 * @param {DocumentEntry} document The document to delete.
 * @param {Function} dispatch The dispatcher used to start state operations.
 */
export function deleteDocument(
    dispatch: Function,
    document: DocumentEntry,
    getDocumentCallback: (value: DocumentEntry) => void
) {
    if (document.documentID !== undefined) {
        dispatch(startOperation());
        getDocumentCallback(JSON.parse(JSON.stringify(emptyDocument)));
        AxiosClient.delete(`${requestURLs.editorSingleDocument}/${document.documentID}`)
            .then()
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => dispatch(finishOperation()));
    }
}

/**
 * <p>Uses an asynchronous delete mechanism to remove the given document.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher.</p>
 *
 * @param {Function} dispatch The dispatcher used to start state operations.
 * @param {versionID} number The ID of the version that has to be published.
 * @param {language} string The language code for the publication (e.g. de_DE)
 * @param {tenantID} number The ID of the current tenant
 * @param {SearchEntryProps} associatedSearch The optional search box ID associated with this operation
 * @param {Function} setUIBlocked The function that has to be called for blocking the UI.
 */
export function publishVersion(
    dispatch: Function,
    versionID: number,
    language: string,
    setUIBlocked: Function,
    currentRunningJob: JobDTO,
    whatsNew: string | null,
    isPubVisible = false
) {
    if (versionID !== undefined) {
        dispatch(startOperation());
        setUIBlocked(true);
        AxiosClient.put(`${requestURLs.versions}${versionID}/publish/${language}`, {
            whatsNew,
            activated: isPubVisible,
        })
            .then(response => {
                if (currentRunningJob.id.length === 0) dispatch(currentJobLoaded(response.data));
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => {
                setUIBlocked(false);
                dispatch(finishOperation());
            });
    }
}
