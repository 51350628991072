import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import key from 'weak-key';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';
import { useGlobalStore } from '../../../../../hooks/hooks';
import { ThumbnailPageProps, ThumbnailProps } from '../../../../../interfaces/DocumentEntry';
import EDocumentState from '../../../../../interfaces/DocumentState';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import { emptyThumbnail } from '../../../../../store/document/reducers';
import { BoolSwapState } from '../../../../../typings/global';
import LoadingScreen from '../../../../../views/LoadingScreen';
import ImageTranslationModal from './ImageTranslationModal';
import Thumbnail from './Thumbnail';

interface IThumbnailPageProps {
    thumbnailPage: ThumbnailPageProps;
    reloadData: BoolSwapState;
    openState: boolean;
    showAdditionalControls: boolean;
    openImageLibrary?: Function;
}

const ThumbnailPage: FunctionComponent<IThumbnailPageProps> = ({
    thumbnailPage,
    reloadData,
    openState,
    showAdditionalControls,
    openImageLibrary,
}) => {
    const { state } = useGlobalStore();
    const [isImageTranslationModalOpen, setIsImageTranslationModalOpen] = useState<boolean>(false);
    const [currentImage, setCurrentImage] = useState<ThumbnailProps>(emptyThumbnail);

    function onDoubleClick(thumbnail: ThumbnailProps) {
        setCurrentImage(thumbnail);
        setIsImageTranslationModalOpen(true);
    }

    return (
        <div style={{ height: '100%' }}>
            <ThumbnailPageWrapper>
                {showAdditionalControls && openImageLibrary && (
                    <UploadImagesWrapper>
                        <ActionControlButton
                            prefix="upload"
                            className="upload-btn"
                            name="add-thumbnail"
                            disabled={
                                state.documentManager.currentArticle.status > EDocumentState.Review
                            }
                            callback={() => openImageLibrary(true)}
                            size={120}
                        />
                    </UploadImagesWrapper>
                )}
                {thumbnailPage.content.map(thumbnail => {
                    if (thumbnail.imageID !== -1) {
                        return (
                            <Thumbnail
                                key={`thumbnail-${key(thumbnail)}`}
                                thumbnail={thumbnail}
                                openState={openState}
                                showAdditionalControls={showAdditionalControls}
                                reloadData={reloadData}
                                onDoubleClick={onDoubleClick}
                            />
                        );
                    }
                    return (
                        <UploadImagesWrapper key={`${key(thumbnail)}-wrapper`}>
                            <LoadingScreen key={`${key(thumbnail)}-loading`} />
                        </UploadImagesWrapper>
                    );
                })}
                <ImageTranslationModal
                    openState={{
                        value: isImageTranslationModalOpen,
                        set: setIsImageTranslationModalOpen,
                    }}
                    thumbnail={currentImage}
                />
            </ThumbnailPageWrapper>
        </div>
    );
};

const ThumbnailPageWrapper = styled.div<ThemeProps>`
    width: 325px;
    height: 100%;
    display: flex;
    flex-direction: row;
    flex-wrap: wrap;
    align-content: flex-start;

    .thumbnail:hover {
        .btn-tool-bar {
            opacity: 0.9;
        }
    }
`;

const UploadImagesWrapper = styled.div<ThemeProps>`
    display: flex;
    background-color: white;
    height: 150px;
    width: 150px;
    margin: 5px;
    position: relative;
    border: 0.5px solid black;

    .upload-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: white;
    }
`;

export default ThumbnailPage;
