import React, { FunctionComponent } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import ProfileModal from '../../components/dialogs/Modal/ProfileModal';
import Icon from '../../components/Icon/Icon';
import { StyledNavButton } from '../../components/navigation/NavButton/NavButton';
import { ThemeProps } from '../../interfaces/globals/theme';
import { t } from '../../lib/language';
import useSession from '../../hooks/session';
import { IUserState } from '../../store/user/types';
import { BoolSwapState } from '../../typings/global';

interface ProfileButtonProps {
    showUserProfileState: BoolSwapState;
    user: IUserState;
}

const Profile: FunctionComponent<ProfileButtonProps> = ({ showUserProfileState, user }) => {
    const { signOut } = useSession();
    const history = useNavigate();
    const system = process.env.REACT_APP_SERVER;

    function navigateUser(path: string) {
        if (user) {
            history(path);
            showUserProfileState.set(false);
        }
    }

    return (
        <ProfileModal
            id="profile-modal"
            showModalState={showUserProfileState}
        >
            <UserName>{user.userName}</UserName>
            <ProfileInfomation>
                <ImageWrapper>
                    {user.imageData !== undefined ? (
                        <img
                            src={`data:image/jpeg;base64,${user.imageData}`}
                            alt="profile"
                        />
                    ) : (
                        <Icon
                            name="profile"
                            size="100"
                            stroke="#000"
                            fill=""
                        />
                    )}
                </ImageWrapper>
                <ProfileContact>
                    <Text>{user.fullName}</Text>
                    <Text>{user.email}</Text>
                </ProfileContact>
            </ProfileInfomation>
            {system === 'intern' && (
                <ProfileLink onClick={() => navigateUser('/reviews')}>Meine Reviews</ProfileLink>
            )}
            <ButtonWrapper>
                <StyledNavButton
                    id="login-button-accept"
                    renderas="button"
                    onClick={() => signOut()}
                >
                    {t('Logout')}
                </StyledNavButton>
            </ButtonWrapper>
        </ProfileModal>
    );
};

const ProfileInfomation = styled.div`
    display: flex;
    border-bottom: solid 1px;
`;

const ProfileContact = styled.div`
    margin: auto 0;
`;

const ImageWrapper = styled.div<ThemeProps>`
    margin: 0.5rem;
    display: block;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    border-radius: 50%;
    svg,
    img {
        height: 4rem;
        width: auto;
    }
`;

const ButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 1rem;
    justify-content: center;
`;

const UserName = styled.h3`
    width: fit-content;
    margin: 0.5rem;
`;

const Text = styled.p`
    width: fit-content;
    margin: 0.5rem;
    font-size: small;
`;

const ProfileLink = styled.p`
    font-size: small;
    cursor: pointer;
    padding: 0.5rem;
    margin: 0;

    :hover {
        background-color: #f6f6f6;
    }
`;

export default Profile;
