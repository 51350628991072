import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';

interface DropZoneProps {
    id: string;
    handleOnPaste?: Function;
    handleOnDragOver?: Function;
    handleOnDragEnter?: Function;
    handleOnDrop?: Function;
    children?: ReactNode;
}

const DropZone: FunctionComponent<DropZoneProps> = ({
    children,
    id,
    handleOnPaste,
    handleOnDragOver,
    handleOnDragEnter,
    handleOnDrop,
}) => {
    function onDrop(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        if (handleOnDrop) handleOnDrop(e);
    }

    function onDragOver(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        if (handleOnDragOver) handleOnDragOver(e);
    }

    function onDragEnter(e: React.DragEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        if (handleOnDragEnter) handleOnDragEnter(e);
    }

    function onPaste(e: React.ClipboardEvent<HTMLDivElement>) {
        e.stopPropagation();
        e.preventDefault();
        if (handleOnPaste) handleOnPaste(e);
    }

    return (
        <DropZoneWrapper
            id={id}
            onDragOver={e => onDragOver(e)}
            onDragEnter={e => onDragEnter(e)}
            onDrop={e => onDrop(e)}
            onPaste={e => onPaste(e)}
        >
            {children}
        </DropZoneWrapper>
    );
};

const DropZoneWrapper = styled.div<ThemeProps>`
    height: 100%;
`;

export default DropZone;
