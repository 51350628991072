/* eslint-disable import/no-duplicates */
import React, { FunctionComponent, ReactElement } from 'react';
import OptionTypeBase from 'react-select';
import Select, {
    MultiValue,
    SingleValue,
    OptionProps,
    components as SelectComponents,
} from 'react-select';
import { useGlobalStore } from '../../../hooks/hooks';

export interface OptionsType extends OptionTypeBase {
    value: string;
    label: string;
}

interface LanguageSelectorProps {
    classNamePrefix: string;
    value: string;
    langOptions: OptionsType[];
    onChange: (
        value:
            | MultiValue<{ value: string; label: string }>
            | SingleValue<{ value: string; label: string }>
    ) => void;
    isDisabled?: boolean;
}

const LanguageSelector: FunctionComponent<LanguageSelectorProps> = ({
    classNamePrefix,
    value,
    langOptions,
    onChange,
    isDisabled,
}): ReactElement => {
    const { state } = useGlobalStore();

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function singleOption(props: OptionProps<any>) {
        return (
            <SelectComponents.Option {...props}>
                <div className="flagLabel">
                    {props.data.value ? (
                        <img
                            key={`img_selected_flag${props.data.value}`}
                            src={`/icn/flags/${props.data.value}.svg`}
                            alt="Selected flag"
                        />
                    ) : null}
                    {props.label}
                </div>
            </SelectComponents.Option>
        );
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    function singleValue(props: any) {
        return (
            <SelectComponents.SingleValue {...props}>
                <div className="flagSelected">
                    {props.data.value ? (
                        <img
                            key={`img_selected_flag${props.data.value}`}
                            src={`/icn/flags/${props.data.value}.svg`}
                            alt={`Flag option${props.data.value}`}
                        />
                    ) : null}
                </div>
            </SelectComponents.SingleValue>
        );
    }

    return (
        <Select
            className="selection"
            classNamePrefix={classNamePrefix}
            isSearchable={false}
            isDisabled={isDisabled}
            defaultValue={{
                value,
                label: state.config.language.list[value].name,
            }}
            options={langOptions}
            components={{
                Option: singleOption,
                SingleValue: singleValue,
            }}
            onChange={onChange}
        />
    );
};

export default LanguageSelector;
