// eslint-disable-next-line no-shadow
export enum EErrorTypes {
    error = 'ERROR',
    warning = 'WARNING',
    info = 'INFO',
}

export interface IError {
    type: EErrorTypes;
    message: string;
}

export const errorDeclaration: IError[] = [
    {
        type: EErrorTypes.error,
        message: 'unknown error',
    },
];
