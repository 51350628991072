import React, { FunctionComponent, ReactNode, useEffect } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';

interface MDXTableGeneratorProps {
    tableSize: number;
    prefix: string;
    iconSize: number;
    insertTable: (table: string) => void;
}

const MDXTableGenerator: FunctionComponent<MDXTableGeneratorProps> = ({
    tableSize,
    prefix,
    iconSize,
    insertTable,
}) => {
    const selectedClass = 'size-chooser-hover';
    const toggle = () => document.getElementById('mdx-table-chooser')?.classList.toggle('show');
    const getElementIndex = (node: Element | null) => {
        if (!node) return 0;
        let index = 0;
        let checkSibling: Element = node;
        while (checkSibling.previousElementSibling) {
            checkSibling = checkSibling.previousElementSibling;
            index += 1;
        }
        return index;
    };

    useEffect(() => {
        const handleHover = (event: MouseEvent) => {
            const element = event.target as HTMLElement;
            const elementParent = element.parentElement;
            if (element.getAttribute('data-id') === 'mdx-table-td' && elementParent) {
                const table = document.getElementById('mdx-table');
                const x = getElementIndex(element);
                const y = getElementIndex(elementParent);
                Object.values(document.getElementsByTagName('td')).forEach(td =>
                    td.classList.remove(selectedClass)
                );
                if (table) {
                    const searchedTDs = Object.values(table.getElementsByTagName('td')).filter(
                        td => getElementIndex(td.parentElement) <= y
                    );
                    Object.values(table.getElementsByTagName('tr')).forEach(tr =>
                        Object.values(searchedTDs).forEach(td => {
                            if (getElementIndex(td) <= x && getElementIndex(tr) <= y) {
                                td.classList.add(selectedClass);
                            }
                        })
                    );
                }
            }
        };
        window.addEventListener('mouseover', handleHover, false);
        return () => window.removeEventListener('mouseover', handleHover, false);
    }, []);

    useEffect(() => {
        const handleTableSizeSelection = (event: MouseEvent) => {
            const element = event.target as HTMLElement;
            if (element.getAttribute('data-id') === 'mdx-table-td')
                if (element.parentElement) {
                    const rows = getElementIndex(element.parentElement) + 1;
                    const columns = getElementIndex(element) + 1;
                    const headerSeperater = ':---|';
                    const breakLine = '\r\n';
                    const delimiter = '|';
                    const cell = '    |';

                    let table = '';
                    Array.from({ length: columns }).forEach((__, idx) => {
                        if (idx === 0) table += delimiter;
                        table += cell;
                    });
                    table += breakLine;
                    Array.from({ length: columns }).forEach((__, idx) => {
                        if (idx === 0) table += delimiter;
                        table += headerSeperater;
                    });
                    table += breakLine;
                    Array.from({ length: rows }).forEach(() => {
                        Array.from({ length: columns }).forEach((__, idx) => {
                            if (idx === 0) table += delimiter;
                            table += cell;
                        });
                        table += breakLine;
                    });
                    table += `[ Bildunterschrift ] ${breakLine}${breakLine}`;
                    table += `Fußnote {tbl-footnote} ${breakLine}`;
                    const button = document.getElementById(`${prefix}btn_table`);
                    if (button) {
                        Object.values(document.getElementsByTagName('td')).forEach(td =>
                            td.classList.remove(selectedClass)
                        );
                        button.click();
                        insertTable(table);
                    }
                }
        };
        window.addEventListener('click', handleTableSizeSelection, false);
        return () => window.removeEventListener('click', handleTableSizeSelection, false);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    function MDXTable(): ReactNode {
        return Array.from({ length: tableSize }).map((_, id) => (
            <tr key={`${tableSize * id}_tr`}>
                {Array.from({ length: tableSize }).map((__, idx) => (
                    <td
                        data-id="mdx-table-td"
                        key={`${tableSize * idx}_td`}
                    >
                        {' '}
                    </td>
                ))}
            </tr>
        ));
    }

    return (
        <Wrapper>
            <ActionControlButton
                size={iconSize}
                prefix={prefix}
                name="table"
                callback={toggle}
            />
            <MDXTableChooser
                id="mdx-table-chooser"
                className="table_size_chooser"
            >
                <SizeChooser className="size-chooser">
                    <MDXTableWrapper id="mdx-table">
                        <tbody>{MDXTable()}</tbody>
                    </MDXTableWrapper>
                </SizeChooser>
            </MDXTableChooser>
        </Wrapper>
    );
};

const Wrapper = styled.div`
    display: flex;
    .show {
        display: block;
    }
`;

const MDXTableChooser = styled.div`
    display: none;
    position: absolute;
    margin-top: 1.5rem;
    background: #ffffff;
    border: 1px solid #888;
    height: max-content;
    z-index: 99;

    .size-chooser-hover {
        background-color: #f29400;
    }
`;

const SizeChooser = styled.div`
    table {
        border-collapse: separate;
        border-spacing: 3px;
    }

    td {
        cursor: pointer;
        border: 1px #ccc solid;
        height: 16px;
        min-width: 16px;
        line-height: 10px;
    }
`;

const MDXTableWrapper = styled.table`
    z-index: 99;
    position: relative;
    background-color: white;
`;

export default MDXTableGenerator;
