import React, { FunctionComponent, ReactElement, useEffect, useState, useCallback } from 'react';
import { Routes, Route } from 'react-router';

import styled from 'styled-components';

import { User } from 'oidc-client-ts';
import { ThemeProps } from './interfaces/globals/theme';
import Compendium from './containers/compendium/Compendium';
import ErrorPage404 from './views/ErrorPage404';
import { useGlobalStore } from './hooks/hooks';
import { setLocale } from './store/config/actions';
import lang, { loadAllLanguages } from './lib/language';
import Header from './components/navigation/Header/Header';
import Footer from './components/navigation/Footer/Footer';
import LoadingScreen from './views/LoadingScreen';
import DocumentManager from './containers/documentmanager/document/DocumentManager';
import loadAllTenants from './lib/app/tenant';
import ContentManager from './containers/documentmanager/article/ContentManager';
import Management from './containers/management/Management';
import ErrorList from './components/ErrorList/ErrorList';
import JobProcessContainer from './components/JobProcess/JobProcessContainer';
import TranslationManager from './containers/documentmanager/translation/TranslationManager';
import ReviewOverview from './containers/review/ReviewOverview';
import { OpenAPI } from './lib/backend';
import ReviewModule from './containers/review/ReviewModule/ReviewModule';
import useSession from './hooks/session';
import AxiosClient from './lib/axios';
import { IUserState } from './store/user/types';
import { updateUser } from './store/user/actions';
import PublicationView from './containers/compendium/PublicationView';
import PdfDownload from './containers/compendium/PdfDownload';
import ErrorPage401 from './views/ErrorPage401';

OpenAPI.BASE = process.env.REACT_APP_BACKEND_SERVER!;

type Roles = {
    [client: string]: { roles: string[] };
};

const App: FunctionComponent = (): ReactElement => {
    const { initSession, signIn, extend, user } = useSession();
    const { state, dispatch } = useGlobalStore();
    const [localeIsDetermined, setLocaleIsDetermined] = useState<boolean>();
    const [tenantIsRequested, setTenantIsRequested] = useState<boolean>();
    const [languageIsRequested, setLanguageIsRequested] = useState<boolean>();
    const { language, tenant } = state.config;
    const parseUserObj = useCallback(
        (u: User | null | undefined) => {
            if (u) {
                const userPermissions: { [tenant: string]: string[] } = {};
                const client: string = process.env.REACT_APP_OPENID_CLIENTID!;

                if (u.profile?.resource_access !== undefined) {
                    const roles: Roles = u.profile.resource_access as Roles;
                    const clientRoles = roles[client];
                    if (clientRoles !== undefined) {
                        clientRoles.roles.forEach(clientRole => {
                            const tenantName = clientRole
                                .substring(0, clientRole.indexOf('_'))
                                .toUpperCase();
                            const roleName = clientRole
                                .substring(clientRole.indexOf('_') + 1, clientRole.length)
                                .toUpperCase();
                            if (
                                Object.keys(userPermissions).filter(key => key === tenantName)
                                    .length > 0
                            )
                                userPermissions[tenantName].push(roleName);
                            else userPermissions[tenantName] = [roleName];
                        });
                    }
                }
                const userState: IUserState = {
                    userName: u.profile.preferred_username ?? '',
                    fullName: u.profile.name ?? '',
                    initials: '',
                    email: '',
                    permissions: userPermissions,
                    token: u.access_token,
                };
                OpenAPI.HEADERS = { Authorization: `Bearer ${u.access_token}` };
                AxiosClient.setAuthorizationHeader(u.access_token ?? '');
                dispatch(updateUser(userState));
            }
        },
        [dispatch]
    );

    signIn();
    initSession();

    useEffect(() => {
        document.addEventListener('click', extend, { capture: true, passive: true });
        return () => document.removeEventListener('click', extend, { capture: true });
    }, [extend]);

    useEffect(() => {
        if (user) parseUserObj(user);
    }, [dispatch, parseUserObj, user]);

    useEffect(() => {
        if (!localeIsDetermined && user) {
            if (!tenantIsRequested) {
                setTenantIsRequested(true);
                loadAllTenants(dispatch);
            }
            if (!languageIsRequested) {
                setLanguageIsRequested(true);
                loadAllLanguages(dispatch);
            }
            if (language.current !== null && tenant.current !== null)
                lang.init({
                    supportedLocales: Object.keys(language.list).map(l => language.list[l].code),
                    locale: language.list.de_DE.code,
                    fallbackLocale: language.list.de_DE.code,
                }).then(locale => {
                    setLocaleIsDetermined(true);
                    dispatch(setLocale(locale));
                });
        }
    }, [
        dispatch,
        language,
        tenant,
        tenantIsRequested,
        languageIsRequested,
        localeIsDetermined,
        user,
    ]);

    const handleManualLocaleSelection = (newLocale: string): void => {
        lang.setLocale(newLocale).then(() => {
            if (newLocale.indexOf('ar') === 0) {
                window.document.dir = 'rtl';
            } else {
                window.document.dir = 'ltr';
            }
            dispatch(setLocale(newLocale));
        });
    };

    if (localeIsDetermined) {
        const hasAnyPermission = Object.keys(state.user.permissions).length !== 0;
        return (
            <>
                <Header onSelectLocale={handleManualLocaleSelection} />
                <ApplicationFrame>
                    {!hasAnyPermission && <ErrorPage401 />}
                    {hasAnyPermission && (
                        <Routes>
                            <Route
                                path="/"
                                element={<Compendium />}
                            />
                            <Route
                                path="/documents"
                                element={<DocumentManager />}
                            />
                            <Route
                                path="/translations"
                                element={<TranslationManager />}
                            />
                            <Route
                                path="/settings"
                                element={<Management />}
                            />
                            <Route
                                path="/editor"
                                element={<ContentManager />}
                            />
                            <Route
                                path="/reviews"
                                element={<ReviewOverview />}
                            />
                            <Route
                                path="/reviews/:reviewID"
                                element={<ReviewModule />}
                            />
                            <Route
                                path="/show/html"
                                element={<PublicationView />}
                            />
                            <Route
                                path="/show/pdf"
                                element={<PdfDownload />}
                            />
                            <Route
                                path="/*"
                                element={<ErrorPage404 />}
                            />
                        </Routes>
                    )}
                    <ErrorList />
                    <JobProcessContainer />
                </ApplicationFrame>
                <Footer />
            </>
        );
    }
    return <LoadingScreen />;
};

const ApplicationFrame = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 84%;
    justify-content: space-around;
    box-sizing: border-box;
    overflow: hidden;
    height: 100%;
`;

export default App;
