/* eslint-disable no-use-before-define */
/* eslint-disable no-promise-executor-return */
import Translations from '../typings/Translations';
import resolveUserLocale from './user-locale';
import { normalize, containsNormalized } from './langutils';
import AxiosClient, { requestURLs } from './axios';
import { languageListUpdated } from '../store/config/actions';

let pLocale: string;
let pTranslations: Translations;
let pSupportedLocales: ReadonlyArray<string>;

/**
 * <p>Uses an asynchronous fetch mechanism to retrieve the language list.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher. </p>
 *
 * @param {Function} dispatch The dispatcher used to start state operations
 */
export function loadAllLanguages(dispatch: Function) {
    AxiosClient.get(requestURLs.languages).then(response =>
        dispatch(languageListUpdated(response.data))
    );
}

/**
 * The Language element, that is used throughout the application.
 *
 * It provides methods for retrieving and checking locales as well
 * methods for looking up translations in a loaded locale.
 */
const language = {
    init(options: {
        supportedLocales: string[];
        locale?: string;
        fallbackLocale?: string;
    }): Promise<string> {
        return new Promise((resolve, reject) => {
            if (!options.supportedLocales || options.supportedLocales.length === 0) {
                return reject(
                    new Error('No supported locales given. Please provide supported locales.')
                );
            }

            pSupportedLocales = Object.freeze(options.supportedLocales.map(normalize));

            if (options.fallbackLocale && !language.isSupported(options.fallbackLocale)) {
                return reject(
                    new Error(
                        `Fallback locale ${options.fallbackLocale} is not in ` +
                            'supported locales given: ' +
                            `[${pSupportedLocales.join(', ')}].`
                    )
                );
            }

            if (options.locale) {
                pLocale = options.locale;
            } else {
                pLocale =
                    resolveUserLocale(pSupportedLocales) ||
                    options.fallbackLocale ||
                    pSupportedLocales[0];
            }

            return loadAndSet(pLocale).then(() => resolve(pLocale));
        });
    },

    /**
     * Retrieves the array of support locales.
     *
     * @returns {ReadonlyArray<string>} the supported locales
     */
    get supportedLocales(): ReadonlyArray<string> {
        return pSupportedLocales;
    },

    /**
     * Checks if the given locale is in the array of supported locales.
     *
     * @param locale to check for support
     * @returns {boolean} true, if the locale is supported and false otherwise
     */
    isSupported(locale: string): boolean {
        return containsNormalized(language.supportedLocales, locale);
    },

    get locale() {
        return pLocale;
    },

    setLocale(locale: string): Promise<void> {
        return loadAndSet(locale);
    },

    /**
     * Looks up a given key in the translation dictionary for the given locale.
     *
     * @param {string} key the key to search for a translation
     * @returns {string} the identified translation for the given key or the itself,
     * if the translation could not be found.
     */
    t(key: string): string {
        return pTranslations[key] || key;
    },
};

export default language;

/**
 * An abbreviation for translating elements in the gui.
 */
export const { t } = language;

function loadAndSet(locale: string): Promise<void> {
    return new Promise((resolve, reject) => {
        if (!language.isSupported(locale)) {
            return reject(
                new Error(
                    `Locale ${locale} is not in supported ` +
                        `locales given: [${pSupportedLocales.join(', ')}].`
                )
            );
        }

        const normalizedLocale = normalize(locale);

        return load(normalizedLocale).then(json => {
            pLocale = normalizedLocale;
            pTranslations = json;

            return resolve();
        });
    });
}

/**
 * Retrieves the file containing the translations for the given locale.
 *
 * @param {string} locale the locale to retrieve
 * @returns {Promise} a Promise to the requested translation
 * @throws {Error} if the requested translation counld not be retrieved.
 */
function load(locale: string): Promise<Translations> {
    const url = `/lang/${locale}.json`;

    return fetch(url).then(response => {
        if (!response.ok) {
            throw new Error(`${response.status}: Could not retrieve file at ${url}`);
        }

        return response.json();
    });
}
