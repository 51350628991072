import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { t } from '../../../lib/language';

const ReviewFallback: FunctionComponent = () => (
    <FallbackWrapper>
        <BoldP>{t('Review.Fallback.Title')}</BoldP>
        <p>{t('Review.Fallback.SubTitle')}</p>
    </FallbackWrapper>
);

const FallbackWrapper = styled.div<ThemeProps>`
    width: fit-content;
    margin: auto;

    p {
        text-align: center;
        margin: 1rem auto;
        font-size: small;
    }
`;

const BoldP = styled.p<ThemeProps>`
    font-weight: bold;
    text-align: center;
`;

export default ReviewFallback;
