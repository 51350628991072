import { JobDTO } from '../../lib/backend';
import ActionTypes from '../actionTypes';
import { JobState, TJobReducerActions } from './types';

export const emptyJob: JobDTO = {
    id: '',
    jobType: 'emptyJob',
    status: JobDTO.status.CANCELED,
    progress: 0,
    payload: '',
    server: '',
    errorMessage: '',
    jobStartTime: '',
    jobChangeTime: '',
    jobEndTime: '',
    createdBy: '',
    createdDate: '',
};

export const jobInitialState: JobState = {
    currentRunningJob: JSON.parse(JSON.stringify(emptyJob)),
    jobList: [],
};

export const jobReducer = (
    action: TJobReducerActions,
    state: JobState = jobInitialState
): JobState => {
    switch (action.type) {
        case ActionTypes.LOAD_CURRENT_JOB:
            return {
                ...state,
                currentRunningJob: action.payload.currentRunningJob,
            };

        case ActionTypes.LOAD_ALL_JOBS:
            return {
                ...state,
                jobList: action.payload.jobList,
            };

        default:
            return state;
    }
};
