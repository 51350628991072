import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import {
    DocumentDescriptionProps,
    DocumentEntry,
    VersionProps,
} from '../../interfaces/DocumentEntry';
import { ThemeProps } from '../../interfaces/globals/theme';
import { makeTimeStampReadable } from '../../lib/util';

interface MetaInformationProps {
    currentDocument: DocumentEntry;
    currentDescription: DocumentDescriptionProps;
    currentVersion: VersionProps;
}

const MetaInformation: FunctionComponent<MetaInformationProps> = ({
    currentDocument,
    currentDescription,
    currentVersion,
}): ReactElement => (
    <MetaWrapper>
        <h5>
            {currentDocument.documentID ? `Dokument (${currentDocument.documentID})` : 'Dokument'}
        </h5>
        <span>
            <Label>Interne Dokument ID: </Label>
            <Content>{currentDocument.internalDocumentID}</Content>
        </span>
        <span>
            <Label>Erstellt: </Label>
            <Content>
                {currentDocument && currentDocument.createdDate
                    ? `am ${makeTimeStampReadable(currentDocument.createdDate)}`
                    : '-/-'}
                <br />
                {currentDocument && currentDocument.createdBy !== ''
                    ? `von ${currentDocument.createdBy}`
                    : '\xa0'}
            </Content>
        </span>
        <span>
            <Label>Geändert: </Label>
            <Content>
                {currentDocument && currentDocument.lastModifiedDate
                    ? `am ${makeTimeStampReadable(currentDocument.lastModifiedDate)}`
                    : '-/-'}
                <br />
                {currentDocument && currentDocument.lastModifiedBy !== ''
                    ? `von  ${currentDocument.lastModifiedBy}`
                    : '\xa0'}
            </Content>
        </span>
        <h5>
            {currentDescription.descriptionID > 0
                ? `gewählte Beschreibung (${currentDescription?.descriptionID})`
                : 'gewählte Beschreibung'}
        </h5>
        <span>
            <Label>Geändert: </Label>
            <Content>
                {currentDescription && currentDescription.lastModifiedDate
                    ? `am ${makeTimeStampReadable(currentDescription.lastModifiedDate)}`
                    : '-/-'}
                <br />
                {currentDescription && currentDescription.lastModifiedBy !== ''
                    ? `von ${currentDescription.lastModifiedBy}`
                    : '\xa0'}
            </Content>
        </span>
        <h5>
            {currentVersion.versionID > 0
                ? `gewählte Version (${currentVersion.versionID})`
                : 'gewählte Version'}
        </h5>
        <span>
            <Label>Erstellt: </Label>
            <Content>
                {currentVersion && currentVersion.createdDate
                    ? `am ${makeTimeStampReadable(currentVersion.createdDate)}`
                    : '-/-'}
                <br />
                {currentVersion && currentVersion.createdBy !== ''
                    ? `von ${currentVersion.createdBy}`
                    : '\xa0'}
            </Content>
        </span>
        <span>
            <Label>Geändert: </Label>
            <Content>
                {currentVersion && currentVersion.lastModifiedDate
                    ? `am ${makeTimeStampReadable(currentVersion.lastModifiedDate)}`
                    : '-/-'}
                <br />
                {currentVersion && currentVersion.lastModifiedBy !== ''
                    ? `von ${currentVersion.lastModifiedBy}`
                    : '\xa0'}
            </Content>
        </span>
    </MetaWrapper>
);

export default MetaInformation;

const MetaWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 0.2;
    margin-top: 0.7rem;
    margin-right: 1.9rem;

    span {
        display: inline-flex;
    }
    font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;
`;

const Label = styled.div<ThemeProps>`
    width: 8rem;
    font-weight: 600;
`;

const Content = styled.div<ThemeProps>`
    width: 10rem;
`;
