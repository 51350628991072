import { JobDTO } from '../../lib/backend';
import ActionTypes from '../actionTypes';
import { TJobReducerActions } from './types';

export function currentJobLoaded(job: JobDTO): TJobReducerActions {
    return {
        type: ActionTypes.LOAD_CURRENT_JOB,
        payload: {
            currentRunningJob: job,
        },
    };
}

export function currentJobListLoaded(jobs: JobDTO[]): TJobReducerActions {
    return {
        type: ActionTypes.LOAD_ALL_JOBS,
        payload: {
            jobList: jobs,
        },
    };
}
