import React, { FunctionComponent } from 'react';
import key from 'weak-key';
import styled from 'styled-components';
import Entry from './Entry';
import { ReviewDTO } from '../../../lib/backend';
import ReviewFallback from '../ReviewFallback/ReviewFallback';
import { ThemeProps } from '../../../interfaces/globals/theme';

interface ReviewEntriesProps {
    reviews: ReviewDTO[];
    headline: string;
}

const ReviewEntries: FunctionComponent<ReviewEntriesProps> = ({ reviews, headline }) => (
    <>
        <StyledHeadline>{headline}</StyledHeadline>
        {reviews.length > 0 ? (
            reviews.map(review => (
                <Entry
                    key={key(review)}
                    review={review}
                />
            ))
        ) : (
            <ReviewFallback />
        )}
    </>
);

const StyledHeadline = styled.h3<ThemeProps>`
    padding-bottom: 0.7rem;
`;

export default ReviewEntries;
