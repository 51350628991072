import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalStore } from '../../hooks/hooks';
import { ThemeProps } from '../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../lib/axios';
import { currentJobListLoaded, currentJobLoaded } from '../../store/job/actions';
import { emptyJob } from '../../store/job/reducers';
import JobEntry from './JobEntry';
import JobList from './JobList';
import { JobDTO } from '../../lib/backend';

const JobProcessContainer: FunctionComponent = (): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const { currentRunningJob, jobList } = state.jobs;
    const currentTenant = state.config.tenant.current;
    const [showJobList, setShowJobList] = useState<boolean>(false);
    const limitJobListBy = 10;

    useEffect(() => {
        const interval = setInterval(() => {
            if (
                (state.user.userName !== '' &&
                    (currentRunningJob.status === JobDTO.status.PENDING ||
                        currentRunningJob.status === JobDTO.status.ASSIGNED ||
                        currentRunningJob.status === JobDTO.status.RUNNING ||
                        currentRunningJob.status === JobDTO.status.FINISHED)) ||
                showJobList
            )
                AxiosClient.get(`${requestURLs.jobs}page?pageSize=${limitJobListBy}`).then(
                    response => {
                        dispatch(currentJobListLoaded(response.data));
                    }
                );
        }, 3000);
        return () => clearInterval(interval);
    }, [currentRunningJob.status, dispatch, showJobList, state.user.userName]);

    useEffect(() => {
        const interval = setInterval(() => {
            jobList.forEach(job => {
                if (
                    currentRunningJob.status === JobDTO.status.PENDING ||
                    currentRunningJob.status === JobDTO.status.ASSIGNED ||
                    currentRunningJob.status === JobDTO.status.RUNNING
                ) {
                    if (job.id === currentRunningJob.id)
                        AxiosClient.get(`${requestURLs.jobs}${job.id}`)
                            .then(response => {
                                const runningJob = response.data as JobDTO;
                                dispatch(currentJobLoaded(runningJob));
                            })
                            .catch(() => dispatch(currentJobLoaded(emptyJob)));
                }
                if (
                    job.id === currentRunningJob.id &&
                    currentRunningJob.status === JobDTO.status.FINISHED
                ) {
                    dispatch(currentJobLoaded(emptyJob));
                }
            });
        }, 1000);
        return () => clearInterval(interval);
    }, [
        currentRunningJob.id,
        currentRunningJob.status,
        currentTenant?.tenantID,
        dispatch,
        jobList,
    ]);

    return (
        <>
            {currentRunningJob.id.length > 0 &&
                (currentRunningJob.status === JobDTO.status.RUNNING ||
                    currentRunningJob.status === JobDTO.status.PENDING) &&
                !showJobList && (
                    <CurrentJobWrapper onClick={() => setShowJobList(true)}>
                        <JobEntry
                            jobEntry={currentRunningJob}
                            isCurrentJob
                        />
                    </CurrentJobWrapper>
                )}
            {showJobList && (
                <JobList
                    showJobListState={{
                        value: showJobList,
                        set: setShowJobList,
                    }}
                />
            )}
        </>
    );
};

const CurrentJobWrapper = styled.div<ThemeProps>`
    display: flex;
    justify-content: flex-end;
    margin-bottom: 15px;
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
    cursor: pointer;
    p {
        margin: auto 0;
    }

    img {
        margin: auto 0;
        height: 7px;
        width: 100px;
    }
`;

export default JobProcessContainer;
