import { EErrorTypes } from '../../interfaces/globals/errors';
import { tenantListUpdated } from '../../store/config/actions';
import { addError, finishOperation, startOperation } from '../../store/global/actions';
import { ResponseError } from '../../typings/error';
import AxiosClient, { requestURLs } from '../axios';

/**
 * <p>Uses an asynchronous fetch mechanism to retrieve the tenant list.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher. </p>
 *
 * @param {Function} dispatch The dispatcher used to start state operations
 */
function loadAllTenants(dispatch: Function) {
    dispatch(startOperation());
    AxiosClient.get(requestURLs.tenants)
        .then(response => {
            dispatch(tenantListUpdated(response.data));
        })
        .catch(error => {
            dispatch(addError(EErrorTypes.error, (error.response.data as ResponseError).status));
        })
        .finally(() => dispatch(finishOperation()));
}

export default loadAllTenants;
