import { ILanguage, ITenant, TConfigReducerActions } from './types';
import ActionTypes from '../actionTypes';

export function languageListUpdated(languages: {
    [key: string]: ILanguage;
}): TConfigReducerActions {
    return {
        type: ActionTypes.LOAD_LANGUAGES,
        payload: {
            languageList: languages,
        },
    };
}

export function setLanguage(language: ILanguage): TConfigReducerActions {
    return {
        type: ActionTypes.SET_LANGUAGE,
        payload: {
            language,
        },
    };
}

export function setLocale(locale: string): TConfigReducerActions {
    return {
        type: ActionTypes.SET_LOCALE,
        payload: {
            locale,
        },
    };
}

export function tenantListUpdated(tenants: [ITenant]): TConfigReducerActions {
    return {
        type: ActionTypes.LOAD_TENANTS,
        payload: {
            tenantList: tenants,
        },
    };
}

export function setTenant(tenant: ITenant): TConfigReducerActions {
    return {
        type: ActionTypes.SET_TENANT,
        payload: {
            tenant,
        },
    };
}
