import { TDocumentManagerReducerActions } from './types';
import ActionTypes from '../actionTypes';
import {
    ArticleEntry,
    VersionProps,
    DocumentDescriptionProps,
    TranslationsEntries,
} from '../../interfaces/DocumentEntry';
import { PublicationMeta } from '../../typings/publication';

export function artcileLoaded(article: ArticleEntry): TDocumentManagerReducerActions {
    return {
        type: ActionTypes.LOAD_ARTICLE,
        payload: {
            article,
        },
    };
}

export function articleMarkedAsModified(modified: boolean) {
    return {
        type: ActionTypes.MARK_ARTICLE_AS_CHANGED,
        payload: {
            changeState: modified,
        },
    };
}

export function articleSaved(article: ArticleEntry): TDocumentManagerReducerActions {
    return {
        type: ActionTypes.SAVE_ARTICLE,
        payload: {
            article,
        },
    };
}

export function documentMarkedAsModified(modified: boolean) {
    return {
        type: ActionTypes.MARK_DOCUMENT_AS_CHANGED,
        payload: {
            changeState: modified,
        },
    };
}

export function audienceSelected(audience: number): TDocumentManagerReducerActions {
    return {
        type: ActionTypes.SELECT_AUDIENCE,
        payload: {
            audience,
        },
    };
}

export function versionSelected(version: VersionProps): TDocumentManagerReducerActions {
    return {
        type: ActionTypes.SELECT_VERSION,
        payload: {
            version,
        },
    };
}

export function translationsLoaded(translations: TranslationsEntries) {
    return {
        type: ActionTypes.LOAD_TRANSLATIONS,
        payload: {
            translations,
        },
    };
}

export function descriptionSelected(
    description: DocumentDescriptionProps
): TDocumentManagerReducerActions {
    return {
        type: ActionTypes.SELECT_DESCRIPTION,
        payload: {
            description,
        },
    };
}

export function articleSelected(article: ArticleEntry): TDocumentManagerReducerActions {
    return {
        type: ActionTypes.SELECT_ARTICLE,
        payload: {
            article,
        },
    };
}

export function publicationsLoaded(
    publications: PublicationMeta[]
): TDocumentManagerReducerActions {
    return {
        type: ActionTypes.LOAD_PUBLICATIONS,
        payload: {
            publications,
        },
    };
}
