import React, { FunctionComponent, useEffect, useState } from 'react';
import Select, { SingleValue } from 'react-select';
import styled from 'styled-components';
import { useGlobalStore } from '../../../../hooks/hooks';
import { DocumentEntry, VersionProps } from '../../../../interfaces/DocumentEntry';
import { getHighestVersion, getSecondHighestVersion } from '../../../../lib/util';
import LoadingScreen from '../../../../views/LoadingScreen';
import { ReviewDTO, ReviewsService } from '../../../../lib/backend';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { InputReview, ReviewUsers, UserWrapper } from '../../ReviewModal';
import ActionControlButton from '../../../../components/ActionControlButton/ActionControlButton';
import { t } from '../../../../lib/language';

interface ReviewMenuProps {
    review: ReviewDTO;
    document: DocumentEntry;
    setVersion: (value: VersionProps | null) => void;
    setReload: (value: boolean) => void;
}

interface VersionOptions {
    value: number;
    label: string;
}

const ReviewMenu: FunctionComponent<ReviewMenuProps> = ({
    review,
    document,
    setVersion,
    setReload,
}) => {
    const { dispatch, state } = useGlobalStore();
    const { config } = state;
    const { tenant } = config;
    const { versions } = document;
    const [reviewUser, setReviewUser] = useState<string[]>([]);
    const largestVersion = getHighestVersion(document.versions);
    const secondLargest = getSecondHighestVersion(document.versions);
    const options = versions.map(v => ({
        value: v.versionID,
        label: v.productVersion,
    }));
    const [defaultValue, setDefaultValue] = useState<VersionOptions | undefined>(
        options.find(o => o.label === secondLargest?.productVersion)
    );

    const handleVersionChange: (object: SingleValue<VersionOptions>) => void = (
        object: SingleValue<VersionOptions>
    ) => {
        if (object) {
            setDefaultValue({
                value: object.value,
                label: object.label,
            });
            setVersion(versions.find(v => v.versionID === object.value) || null);
            setReload(true);
        }
    };

    const removeUserFromReview: (id: number, name: string) => void = (id: number, name: string) => {
        dispatch(startOperation());
        if (tenant.current)
            ReviewsService.removeUserFromReview(id, name)
                .then(() => setReviewUser(reviewUser.filter(u => u !== name)))
                .catch(error => dispatch(addError(EErrorTypes.error, error.body.status)))
                .finally(dispatch(finishOperation()));
    };

    const addUserToReview: (id: number, name: string) => void = (id: number, name: string) => {
        dispatch(startOperation());
        if (tenant.current)
            ReviewsService.addUserToReview(id, name)
                .then(fetchallReviewerForAReview)
                .catch(error => dispatch(addError(EErrorTypes.error, error.body.status)))
                .finally(() => dispatch(finishOperation()));
    };

    const fetchallReviewerForAReview = () => {
        dispatch(addError(EErrorTypes.info, 'Benutzer wurder erfolgreich hinzugefügt'));
        dispatch(startOperation());
        ReviewsService.getAllReviewerByReviewId(review.reviewID)
            .then(response => setReviewUser(response))
            .catch(error => dispatch(addError(EErrorTypes.error, error.body.status)))
            .finally(() => dispatch(finishOperation()));
    };

    useEffect(() => {
        if (review && review.reviewUserList) {
            const arr: string[] = [];
            review.reviewUserList.forEach(ru => {
                if (ru.username) arr.push(ru.username);
            });
            setReviewUser(arr);
        }
    }, [review]);

    if (!options && !defaultValue) return <LoadingScreen />;

    return (
        <ReviewMenuWrapper>
            <ReviewMenuEntry>
                <h3>
                    {document.descriptions[review.language.code].title}{' '}
                    {largestVersion?.productVersion}
                </h3>
            </ReviewMenuEntry>
            <ReviewMenuEntry>
                <fieldset>
                    <legend>{t('Review.Menu.Version')}</legend>
                    <Select
                        key="review_version_selection"
                        className="versionselection"
                        isSearchable={false}
                        isClearable={false}
                        isLoading={false}
                        isRtl={false}
                        isDisabled={versions.length < 2}
                        isMulti={false}
                        value={defaultValue}
                        options={options.filter(o => o.value !== largestVersion?.versionID)}
                        onChange={o => handleVersionChange(o)}
                        theme={theme => ({
                            ...theme,
                            borderRadius: 0,
                            colors: {
                                ...theme.colors,
                                primary: '#F29400',
                                primary25: '#ffce82',
                            },
                        })}
                    />
                </fieldset>
            </ReviewMenuEntry>
            <ReviewMenuEntry>
                {review && review.createdBy === state.user.fullName && (
                    <>
                        <fieldset>
                            <legend>{t('Review.Menu.Header.Date')}</legend>
                            {t('Review.Start')}{' '}
                            <input
                                onChange={() => null}
                                type="date"
                                id="start"
                                name="review-start"
                                value={new Date(review.reviewStartDate).toISOString().split('T')[0]}
                                min={new Date().toISOString().split('T')[0]}
                            />{' '}
                            {t('Review.End')}{' '}
                            <input
                                onChange={() => null}
                                type="date"
                                id="start"
                                name="review-start"
                                value={new Date(review.reviewEndDate).toISOString().split('T')[0]}
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </fieldset>
                        <fieldset>
                            <legend>{t('Reviewer.Head')}</legend>
                            <InputReview
                                onKeyDown={e => {
                                    if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                        const name = e.currentTarget.value;
                                        const sp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
                                        const n = /\d/;
                                        if (!sp.test(name) && !n.test(name) && name !== '')
                                            addUserToReview(review.reviewID, name.toLowerCase());
                                        else
                                            dispatch(
                                                addError(
                                                    EErrorTypes.warning,
                                                    'Sonderzeichen und/oder Zahlen sind nicht erlaubt!'
                                                )
                                            );
                                        e.currentTarget.value = '';
                                    }
                                }}
                            />
                            <ReviewUsers>
                                {reviewUser.map(ru => (
                                    <UserWrapper key={ru}>
                                        {ru}
                                        <ActionControlButton
                                            prefix="close"
                                            name="close"
                                            className="remove-btn"
                                            callback={() =>
                                                removeUserFromReview(review.reviewID, ru)
                                            }
                                            size={12}
                                        />
                                    </UserWrapper>
                                ))}
                            </ReviewUsers>
                        </fieldset>
                    </>
                )}
            </ReviewMenuEntry>
        </ReviewMenuWrapper>
    );
};

export default ReviewMenu;

const ReviewMenuWrapper = styled.div`
    padding: 0.5rem;
    max-width: 25rem;
    background-color: #fafbfc;
`;

const ReviewMenuEntry = styled.div`
    h3 {
        margin: 0;
        margin-bottom: 1rem;
    }

    .versionselection {
        :focus {
            all: none;
        }
    }
`;
