import { EErrorTypes } from '../../interfaces/globals/errors';
import { translationsLoaded } from '../../store/document/actions';
import { addError, finishOperation, startOperation } from '../../store/global/actions';
import { ResponseError } from '../../typings/error';
import AxiosClient, { requestURLs } from '../axios';

/**
 * <p>Uses an asynchronous store mechanism to load the translations for the given version.</p>
 *
 * <p>Depending on the state of the retrieval different actions are dispatched
 * to the local queue using the given dispatcher.</p>
 *
 * @param {Function} dispatch The dispatcher used to start state operations.
 * @param {number} versionID The versionID.
 */
export function loadTranslations(dispatch: Function, versionID: number) {
    dispatch(startOperation());

    AxiosClient.get(`${requestURLs.translations}versions/${versionID}`)
        .then(response => dispatch(translationsLoaded(response.data)))
        .catch(error =>
            dispatch(addError(EErrorTypes.error, (error.response.data as ResponseError).status))
        )
        .finally(() => dispatch(finishOperation()));
}

export default loadTranslations;
