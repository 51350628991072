/* eslint-disable react/require-default-props */
import React, { forwardRef, ReactElement, MouseEvent, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { FormFieldProps } from '../interfaces/FormField';

export interface TabComponentFieldProps {
    tabHeader: ReactElement[];
    tabContent: ReactElement[];
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Ref = any;

// -----------------------------------------------------------
// Form field
// -----------------------------------------------------------

const TabComponentField = forwardRef<Ref, FormFieldProps & TabComponentFieldProps>(
    (props, ref): ReactElement => (
        <TabComponentWrapper ref={ref}>
            <TabHeaderWrapper className="styledScrollBar">{props.tabHeader}</TabHeaderWrapper>
            <TabContentWrapper>{props.tabContent}</TabContentWrapper>
        </TabComponentWrapper>
    )
);

const TabComponentWrapper = styled.div<ThemeProps>`
    overflow: hidden;
    margin: 0.1rem 1rem;
    box-sizing: border-box;
`;

const TabHeaderWrapper = styled.div<ThemeProps>`
    display: flex;
    flex: 1;
    overflow: auto;

    button.active {
        border-bottom: ${props => props.theme.$border.secondary.thin[40]};
    }
`;

const TabContentWrapper = styled.div<ThemeProps>``;

// -----------------------------------------------------------
// A single tab button
// -----------------------------------------------------------

interface TabProps {
    prefix: string;
    id: string | null;
    onClick: (event: MouseEvent<HTMLButtonElement>) => void;
    className?: string | null;
    changed: boolean;
    active?: boolean;
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    children?: ReactNode;
}

export const Tab = forwardRef<Ref, TabProps>(
    (
        { prefix, id, className = null, onClick, changed, active = false, children },
        ref
    ): ReactElement => (
        <TabWrapper className={className !== null ? className : ''}>
            <TabButton
                id={`${prefix}_${id}`}
                data-id={id}
                onClick={onClick}
                changed={changed}
                active={active}
                ref={ref}
            >
                <div>{children}</div>
            </TabButton>
        </TabWrapper>
    )
);

interface TabHeaderProps {
    changed?: boolean;
    active?: boolean;
}

const TabWrapper = styled.div<ThemeProps>`
    margin: 0;
`;

const TabButton = styled.button<ThemeProps & TabHeaderProps>`
    border: none;
    outline: none;
    cursor: pointer;
    transition: 0.3s;
    margin: 0 auto;
    padding: 0;
    box-sizing: border-box;
    overflow: visible;
    background-color: transparent;
    align-items: center;
    width: 5rem;
    display: inline-flex;

    border-bottom: ${props =>
        props.active ? props.theme.$border.secondary.thin : props.theme.$border.transparent.thin};
    border-left: ${props => props.theme.$border.transparent.thin};
    border-right: ${props => props.theme.$border.transparent.thin};

    :hover {
        border-bottom: ${props => props.theme.$border.primary.thin};
    }

    div {
        line-height: 1.7rem;
        font-weight: 500;
        text-align: center;
        width: 100%;
    }

    div::after {
        content: ${props => (props.changed ? ' (*)' : ' x')};
        color: ${props => props.theme.$colorRed};
    }

    .e-control {
        font-family: inherit;
        font-size: inherit;
    }

    .e-editable-component {
        min-width: 0 !important;
    }

    .e-editable-value-wrapper {
        padding: 0;
        color: inherit !important;
        font-weight: inherit !important;
        font-size: inherit !important;
        margin: 0;

        .e-editable-value {
            color: inherit !important;
            font-family: inherit;
            font-size: inherit;
            font-weight: inherit !important;
            border-bottom: none;
            margin: 0;
        }
    }

    .e-inplaceeditor .e-editable-value-wrapper:hover {
        background-color: transparent;
    }

    .e-control-wrapper {
        font-family: inherit !important;
        font-size: inherit !important;
    }

    .e-clear-icon,
    .e-clear-icon-hide {
        min-height: 0 !important;
        min-width: 0 !important;
        padding: 0 !important;
        margin: 0 !important;
        display: none !important;
    }

    .e-editable-overlay-icon {
        display: none;
    }
    .e-editable-overlay-icon::before {
        content: '';
    }

    .e-input-group.e-control-wrapper {
        margin-bottom: 0;
    }

    .e-input-group {
        width: 4rem;
        text-align: center;

        .e-control-wrapper {
            margin-bottom: 0 !important;
        }

        input.e-input {
            min-height: 0;
            padding: 0;
        }

        input.e-input:focus {
            padding: 0;
        }
    }

    .e-inplace-editor {
        min-width: 0;

        .e-component-group {
            margin-bottom: 0;
        }
    }

    .e-input-group:not(.e-success):not(.e-warning):not(.e-error):not(.e-float-icon-left) {
        border-bottom: none !important;
    }

    .e-input-focus::before,
    .e-input-group.e-control-wrapper:not(.e-float-icon-left):not(.e-float-input)::after {
        display: none;
    }
`;

// -----------------------------------------------------------
// A single tab content container
// -----------------------------------------------------------

interface TabContentProps {
    visible?: boolean;
}

export const TabContentContainer = styled.div<ThemeProps & TabContentProps>`
    display: ${props => (props.visible ? 'grid' : 'none')};
    grid-template-columns: max-content auto;
    grid-gap: 0.1rem;
    padding: 0.2rem 0.4rem;
`;

export default TabComponentField;
