import styled from 'styled-components';
import { ThemeProps } from '../interfaces/globals/theme';

export const FormHeader = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    align-items: center;

    h3,
    h4 {
        flex: 1;

        span {
            color: ${props => props.theme.$colorPrimary};
        }
    }
`;

export const InnerFormWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    overflow-y: auto;
`;

export const GridParent = styled.div<ThemeProps>`
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 0.1rem;
`;
