import React, { FunctionComponent, ReactElement } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import { useGlobalStore } from '../../hooks/hooks';
import { DocumentDescriptionProps } from '../../interfaces/DocumentEntry';
import { ThemeProps } from '../../interfaces/globals/theme';
import {
    audienceSelected,
    descriptionSelected,
    versionSelected,
} from '../../store/document/actions';
import LanguageSelector, {
    OptionsType,
} from '../../components/navigation/LanguageSelector/LanuageSelector';
import InPlaceEditor from '../../components/InPlaceEditor/InPlaceEditor';
import { CurrentDocumentPropsType } from '../../typings/global';
import { ContentArticleAudit } from '../../typings/articleAudit';

interface DocumentInformationProps {
    currentDocumentProps: CurrentDocumentPropsType;
    setArticleDiffData: (value: ContentArticleAudit | null) => void;
}

const DocumentInformation: FunctionComponent<DocumentInformationProps> = ({
    currentDocumentProps,
    setArticleDiffData,
}): ReactElement => {
    const {
        state: {
            documentManager,
            config: { language },
        },
        dispatch,
    } = useGlobalStore();

    return (
        <Wrapper>
            <RowContainer>
                <h4>ID:</h4>
                <InPlaceEditor
                    id="element"
                    value={currentDocumentProps.value.internalDocumentID}
                    actionOnBlur="Cancel"
                    submit={() => null}
                    disabled
                />
            </RowContainer>
            <RowContainer>
                <h4>Sprache:</h4>
                <LanguageSelector
                    classNamePrefix="info"
                    isDisabled={Object.keys(currentDocumentProps.value.descriptions).length <= 1}
                    value={documentManager.currentDescription.languageCode}
                    langOptions={Object.values(currentDocumentProps.value.descriptions).map(
                        v =>
                            ({
                                value: v.languageCode,
                                label: language.list[v.languageCode].name,
                            } as OptionsType)
                    )}
                    onChange={o => {
                        if (o) {
                            const selectedLanguage = Object.values(o)[0];
                            const selectedDescription: DocumentDescriptionProps =
                                currentDocumentProps.value.descriptions[selectedLanguage];
                            dispatch(descriptionSelected(selectedDescription));
                            if (selectedLanguage === 'de_DE') setArticleDiffData(null);
                        }
                    }}
                />
            </RowContainer>
            <RowContainer>
                <h4>Version:</h4>
                <Select
                    className="selection"
                    classNamePrefix="info"
                    isSearchable={false}
                    isDisabled={Object.keys(currentDocumentProps.value.versions).length <= 1}
                    value={{
                        value: `${documentManager.currentVersion.versionID}`,
                        label: documentManager.currentVersion.productVersion,
                    }}
                    options={Object.values(currentDocumentProps.value.versions).map((v, index) => ({
                        value: `${index}`,
                        label: v.productVersion,
                    }))}
                    onChange={o => {
                        if (o) {
                            dispatch(
                                versionSelected(
                                    Object.values(currentDocumentProps.value.versions)[
                                        Number.parseInt(Object.values(o)[0], 10)
                                    ]
                                )
                            );
                        }
                    }}
                />
            </RowContainer>
            <RowContainer>
                <h4>Zielgruppe:</h4>
                <Select
                    className="selection"
                    classNamePrefix="info"
                    isSearchable={false}
                    isDisabled={documentManager.currentVersion.audience === 0}
                    defaultValue={{
                        value: '0',
                        label: documentManager.audiences[0],
                    }}
                    options={documentManager.audiences
                        .filter((val, idx) => idx <= documentManager.currentVersion.audience)
                        .map((val, idx) => ({
                            value: `${idx}`,
                            label: documentManager.audiences[idx],
                        }))}
                    onChange={o => {
                        if (o) {
                            dispatch(audienceSelected(Number.parseInt(Object.values(o)[0], 10)));
                        }
                    }}
                />
            </RowContainer>
        </Wrapper>
    );
};

const RowContainer = styled.div<ThemeProps>`
    display: inline-flex;
    justify-content: space-between;
    align-items: center;
    margin: 0 0.2rem;

    h4 {
        margin: 0.1rem;
    }

    #element {
        display: flex;
        justify-content: end;
    }
`;

const Wrapper = styled.div<ThemeProps>`
    display: flex;
    flex: 0.2;
    flex-direction: column;
    border: 1px solid green;

    font-size: ${props => props.theme.$headerStyles.medium.h6.fontSize}rem;

    .info {
        font-size: ${props => props.theme.$headerStyles.medium.h6.fontSize}rem;
    }

    .info__control {
        width: 8rem;
        height: 0.8rem;
        border: none;
    }

    .info__control--is-focused .info__control--is-open {
        border: none;

        :hover {
            border: none;
        }
    }

    .info__option {
        :hover {
            background: ${props => props.theme.$colorPrimary};
        }
    }

    .info__option--is-selected {
        background: ${props => props.theme.$colorSecondary};
    }

    .info__control--is-disabled {
        background-color: inherit;
    }

    .info__value-container {
        justify-content: flex-end;
    }

    .info__single-value--is-disabled {
        color: inherit;
    }

    .info__indicator {
        :hover {
            color: ${props => props.theme.$colorPrimary};
        }
    }
`;

export default DocumentInformation;
