import React, { FunctionComponent, ReactElement } from 'react';
import ErrorPage from './ErrorPage';
import Icon from '../components/Icon/Icon';

const ErrorPage401: FunctionComponent = (): ReactElement => (
    <div className="grid-container">
        <ErrorPage>
            <div className="errorPageWrapper">
                <div className="errorPage__icon">
                    <Icon
                        name="sad"
                        size="76"
                        fill="#d3665e"
                    />
                </div>
                <div className="ml-1 errorPage__message">
                    <h1>Zugriff verweigert</h1>
                    <p>
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        Sie verfügungen nicht über die erforderlichen Berechtigungen, um die Seite{' '}
                        <b>{window.location.href}</b> aufzurufen.
                        <br />
                        Wenn Sie dies für einen Fehler halten, wenden Sie sich bitte an Ihren
                        Administrator.
                    </p>
                </div>
            </div>
        </ErrorPage>
        <footer />
    </div>
);

export default ErrorPage401;
