import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { TranslationsEntries, VersionProps } from '../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { FormFieldProps } from '../interfaces/FormField';

export interface ListFieldProps {
    options: string[];
    size: number;
    version: VersionProps | null;
    translations: TranslationsEntries;
    selectedFilter?: string[];
    selectedClassName?: string;
}

const ListField: FunctionComponent<FormFieldProps> = ({ id, readOnly, children }): ReactElement => (
    <List
        id={id}
        readOnly={readOnly}
        data-testid={id}
        className="styledScrollBar"
    >
        {children}
    </List>
);

interface ListStyleProps {
    readOnly: boolean | undefined;
}

const List = styled.div<ThemeProps & ListStyleProps>`
    background-color: ${props => props.theme.$colorGrey10};
    margin: 0.4rem 1rem;
    padding: 0;
    color: ${props => (props.readOnly ? 'transparent' : 'inherit')};
    height: 8rem;
    border: ${props => props.theme.$border.secondary.thin};
    overflow-y: auto;
    overflow-x: hidden;

    .selectedItem {
        background-color: ${props => props.theme.$colorPrimary} !important;
    }

    .article-p {
        margin: 0;
        padding: 0;
    }

    .article-flags {
        margin: 4px;
    }

    .grey {
        filter: grayscale(100%);
    }

    .img-flag-div {
        display: flex;
        position: relative;
    }

    .icon-flag-strikethrough:before {
        content: ' ';
        height: 2px;
        width: 25px;
        background-color: gray;
        position: absolute;
        transform: rotate(-45deg);
        margin: 8px 2px;
    }

    button {
        background-color: ${props => props.theme.$colorGrey10};
        border: ${props => props.theme.$border.grey.thin[10]};
        padding: 0.2rem;
        text-align: left;
        width: 95%;
    }

    button.dragable {
        cursor: move;
        opacity: 0.4;
    }

    button.over {
        border: ${props => props.theme.$border.secondary.dashed};
    }
`;

export default ListField;
