import React, { FunctionComponent, useEffect, ReactNode, useState } from 'react';
import { useLocation } from 'react-router';
import { useGlobalStore } from '../../hooks/hooks';
import { finishOperation, startOperation } from '../../store/global/actions';
import {
    ApiError,
    PublicationService,
    InternalPublicationService,
    CancelablePromise,
} from '../../lib/backend';
import LoadingScreen from '../../views/LoadingScreen';
import { t } from '../../lib/language';
import ErrorPage401 from '../../views/ErrorPage401';
import ErrorPage from '../../views/ErrorPage';

interface PublicationViewProps {
    children?: ReactNode;
}

function replaceHtml(str: string) {
    const anchor = window.location.hash;

    document.open();
    document.write(str);
    document.close();

    document.addEventListener('DOMContentLoaded', () => {
        document.location.hash = anchor;
    });
}

const getService = (): ((
    id: string,
    v?: string | undefined,
    a?: number | undefined,
    l?: string | undefined
) => CancelablePromise<string[]>) => {
    const system = process.env.REACT_APP_SERVER;
    if (system === 'intern') {
        return InternalPublicationService.getHtml;
    }
    return PublicationService.getHtmlExternal;
};

const PublicationView: FunctionComponent<PublicationViewProps> = () => {
    const { dispatch } = useGlobalStore();
    const useQuery = () => new URLSearchParams(useLocation().search);
    const [error, setError] = useState<'UNAUTHORIZED' | 'UNKNOWN' | 'NO-ERROR'>('NO-ERROR');
    const query = useQuery();
    const id = query.get('id');
    const version = query.get('v');
    const audience = query.get('a');
    const language = query.get('l');

    useEffect(() => {
        if (id && audience) {
            dispatch(startOperation());
            getService()(
                id,
                version as string | undefined,
                audience as unknown as number,
                language as string | undefined
            )
                .then(response => {
                    const p = response as unknown as string;
                    replaceHtml(p);
                })
                .catch(err => {
                    if (err instanceof ApiError && (err.status === 401 || err.status === 403)) {
                        setError('UNAUTHORIZED');
                    } else {
                        setError('UNKNOWN');
                    }
                })
                .finally(() => {
                    dispatch(finishOperation());
                });
        }
    }, [audience, dispatch, id, language, version]);

    switch (error) {
        case 'UNAUTHORIZED':
            return <ErrorPage401 />;
        case 'UNKNOWN':
            return <ErrorPage>{t('StatusCode.General.Failed')}</ErrorPage>;
        case 'NO-ERROR':
            return <LoadingScreen />;
        default:
            // This is only to check if the switch statement is exhaustive and throws a compiler error if not.
            return ((x: never) => <p>${x}</p>)(error);
    }
};

export default PublicationView;
