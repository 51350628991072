/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

/**
 * The user of this review.
 *
 * The user that need to check this review and set there status.
 */
export type ReviewUserDTO = {
    /**
     * The creator of the entity.
     *
     * The name of the user who created the entity. The name must not be longer than 255 characters.
     */
    createdBy: string;
    /**
     * The date of creation for the entity.
     *
     * The date/time of the entity was created.
     */
    createdDate: string;
    /**
     * The modifier of the entity.
     *
     * The name of the user who last modified the entity. The name must not be longer than 255 characters.
     */
    lastModifiedBy: string;
    /**
     * The date of the last modification of an entity.
     *
     * The date/time of the entity was modified last.
     */
    lastModifiedDate: string;
    /**
     * The ID of the review.
     *
     * The ID has to be a long.
     */
    reviewID: number;
    /**
     * The status of the review.
     *
     * The current status of the review. Type is an ENUM.
     */
    status: ReviewUserDTO.status;
    /**
     * The username of this review user.
     *
     * The username of this review user.
     */
    username: string;
};

export namespace ReviewUserDTO {

    /**
     * The status of the review.
     *
     * The current status of the review. Type is an ENUM.
     */
    export enum status {
        OPEN = 'OPEN',
        FINISHED = 'FINISHED',
        ABORTED = 'ABORTED',
        APPROVED = 'APPROVED',
        REWORK = 'REWORK',
    }


}

