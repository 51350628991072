// eslint-disable-next-line no-shadow
enum ActionTypes {
    START_OPERATION = 'GLOBAL_START_ASYNC_OPERATION',
    FINISH_OPERATION = 'GLOBAL_FINISH_ASYNC_OPERATION',
    ADD_ERROR = 'GLOBAL_ERROR_FOUND',
    REMOVE_ERROR = 'GOLBAL_ERROR_REMOVED',
    LOAD_LANGUAGES = 'CONFIG_LOAD_LANGUAGES',
    SET_LANGUAGE = 'CONFIG_SET_LANGUAGE',
    SET_LOCALE = 'CONFIG_SET_LOCALE',
    LOAD_TENANTS = 'CONFIG_LOAD_TENANTS',
    SET_TENANT = 'CONFIG_SET_TENANT',
    STORE_USER_PERMISSIONS = 'USER_STORE_PERMISSIONS',
    UPDATE_USER = 'USER_UPDATE',
    LOGIN_USER = 'USER_LGGIN',
    LOGOUT_USER = 'USER_LOGOUT',
    MARK_DOCUMENT_AS_CHANGED = 'DOCUMENT_MARK_AS_CHANGED',
    LOAD_DOCUMENT = 'DOCUMENT_LOAD',
    SAVE_DOCUMENT = 'DOCUMET_SAVE',
    UPDATE_DOCUMENT_LIST = 'DOCUMENT_UPDATE_LIST',
    SELECT_ARTICLE = 'DOCUMENT_SELECT_ARTICLE',
    SELECT_DESCRIPTION = 'DOCUMENT_SELECT_DESCRIPTION',
    SELECT_VERSION = 'DOCUMENT_SELECT_VERSION',
    SELECT_AUDIENCE = 'DOCUMENT_SELECT_AUDIENCE',
    LOAD_ARTICLE = 'ARTICLE_LOAD',
    LOAD_TRANSLATIONS = 'LOAD_TRANSLATIONS',
    SAVE_ARTICLE = 'ARTICLE_SAVE',
    UPDATE_ARTICLE_LIST = 'ARTICLE_UPDATE_LIST',
    MARK_ARTICLE_AS_CHANGED = 'ARTICLE_MARK_AS_CHANGED',
    SELECT_ELEMENT_FROM_HAYSTACK = 'SELECT_ELEMENT_FROM_HAYSTACK',
    LOAD_CURRENT_JOB = 'LOAD_CURRENT_JOB',
    LOAD_ALL_JOBS = 'LOAD_ALL_JOBS',
    UPDATE_SEARCH_STRING = 'UPDATE_SEARCH_STRING',
    LOAD_PUBLICATIONS = 'LOAD_PUBLICATIONS',
}

export default ActionTypes;
