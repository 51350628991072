import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import Select from 'react-select';
import styled from 'styled-components';
import Icon from '../../components/Icon/Icon';
import LanguageSelector, {
    OptionsType,
} from '../../components/navigation/LanguageSelector/LanuageSelector';
import Tooltip from '../../components/Tooltip/Tooltip';
import { useGlobalStore } from '../../hooks/hooks';
import Audiences, { GermanAudiences, getHighestAudience } from '../../interfaces/Audiences';
import { ThemeProps } from '../../interfaces/globals/theme';
import { t } from '../../lib/language';
import { SelectionType } from '../../typings/collection';
import pdfIcon from '../../icons/documenttypes/pdf.svg';
import { Publication } from '../../typings/publication';

interface RowProps {
    entry: Publication;
    index: number;
}

const DocumentRow: FunctionComponent<RowProps> = ({ entry, index }): ReactElement => {
    const { state } = useGlobalStore();
    const [selectedLanguage, setSelectedLanguage] = useState<string>(
        entry.selectedLanguage ?? Object.keys(entry.descriptions)[0]
    );
    const system = process.env.REACT_APP_SERVER;
    const uppercaseAudiences: string[] = Audiences.map(a => a.toUpperCase());
    const defaultAudience: number =
        system === 'intern'
            ? 0
            : uppercaseAudiences.indexOf(getHighestAudience(state.user.permissions['']) ?? '');
    const [selectedAudience, setSelectedAudience] = useState<number>(defaultAudience);
    const currentTenant = state.config.tenant.current;

    const versionOptions: SelectionType[] = Object.keys(entry.versions)
        .filter(key => key.substring(key.indexOf('#') + 1, key.length) === selectedLanguage)
        .sort((a, b) => {
            if (a < b) return 1;
            if (a > b) return -1;
            return 0;
        })
        .map(v => ({
            value: v.substring(0, v.indexOf('#')),
            label: v.substring(0, v.indexOf('#')),
        }));

    const [selectedVersion, setSelectedVersion] = useState<string>(versionOptions[0].value);
    const selectedAudienceForRow = selectedAudience - defaultAudience;

    const audienceOptions: SelectionType[] = entry.versions[
        `${selectedVersion}#${selectedLanguage}`
    ].map(a => ({
        value: `${a.audience}`,
        label: GermanAudiences[a.audience],
    }));

    const whatsNewList: SelectionType[] = entry.versions[
        `${selectedVersion}#${selectedLanguage}`
    ].map(w => ({
        value: w.whatsNew ? w.whatsNew : '',
        label: selectedVersion,
    }));

    const [linkParams, setLinkParams] = useState<string>(`id=${entry.internalDocumentID}`);

    useEffect(() => {
        setLinkParams(
            `id=${entry.internalDocumentID}` +
                `&v=${selectedVersion}&a=${selectedAudience}&l=${selectedLanguage}`
        );
    }, [entry.internalDocumentID, selectedAudience, selectedLanguage, selectedVersion]);

    return (
        <tr
            key={index}
            data-id={index}
        >
            <td className="id">{entry.internalDocumentID}</td>
            <td className="title">
                <Tooltip text={entry.descriptions[selectedLanguage].description}>
                    <a
                        href={`./show/html?${linkParams}`}
                        target="_blank"
                        rel="noreferrer"
                    >
                        {entry.descriptions[selectedLanguage].title}
                    </a>
                </Tooltip>
            </td>
            <td>
                {whatsNewList[selectedAudienceForRow] &&
                    whatsNewList[selectedAudienceForRow].value !== null &&
                    whatsNewList[selectedAudienceForRow].value !== '' && (
                        <Tooltip text={whatsNewList[selectedAudienceForRow].value}>
                            <Icon
                                name="info"
                                size="20"
                            />
                        </Tooltip>
                    )}
            </td>
            <td>{entry.product}</td>
            <td>
                {Object.keys(entry.versions).length > 1 ? (
                    <Select
                        className="selection"
                        classNamePrefix="compendium"
                        isSearchable={false}
                        isDisabled={Object.keys(entry.versions).length === 1}
                        defaultValue={{
                            value: selectedVersion,
                            label: selectedVersion,
                        }}
                        options={versionOptions}
                        onChange={o => {
                            if (o) {
                                setSelectedVersion(Object.values(o)[0]);
                                if (
                                    !entry.versions[`${selectedVersion}#${selectedLanguage}`][
                                        selectedAudience
                                    ]
                                )
                                    setSelectedAudience(defaultAudience);
                                if (!entry.descriptions[selectedLanguage])
                                    setSelectedLanguage('de_DE');
                            }
                        }}
                    />
                ) : (
                    <div className="single_value">
                        <span>{selectedVersion}</span>
                    </div>
                )}
            </td>
            <td>
                {audienceOptions.length > 1 ? (
                    <Select
                        className="selection"
                        classNamePrefix="compendium"
                        isSearchable={false}
                        isDisabled={audienceOptions.length <= 1}
                        defaultValue={{
                            value: `${selectedAudience}`,
                            label: Audiences[selectedAudience],
                        }}
                        options={audienceOptions}
                        onChange={o => {
                            if (o) setSelectedAudience(Number.parseInt(Object.values(o)[0], 10));
                        }}
                    />
                ) : (
                    <div className="single_value">
                        <span>{Audiences[selectedAudience]}</span>
                    </div>
                )}
            </td>
            <td>
                {' '}
                {Object.keys(entry.descriptions).length > 1 &&
                Object.keys(entry.versions).includes(`${selectedVersion}#en_US`) ? (
                    <LanguageSelector
                        classNamePrefix="compendium"
                        isDisabled={Object.keys(entry.descriptions).length <= 1}
                        value={selectedLanguage}
                        langOptions={Object.keys(entry.descriptions).map(
                            l =>
                                ({
                                    value: l,
                                    label: state.config.language.list[l].name,
                                } as OptionsType)
                        )}
                        onChange={o => {
                            if (o) {
                                setSelectedLanguage(`${Object.values(o)[0]}`);
                            }
                        }}
                    />
                ) : (
                    <img
                        className="single_flag"
                        key={`img_selected_flag${selectedLanguage}`}
                        src={`/icn/flags/${selectedLanguage}.svg`}
                        alt="Selected flag"
                    />
                )}
            </td>
            {currentTenant && currentTenant.name !== 'Training&Education' && (
                <>
                    <td>
                        <Tooltip
                            position="top-right"
                            width="15rem"
                            text={`PDF Export: ${entry.internalDocumentID} ${t('für')} ${t(
                                Audiences[selectedAudience]
                            )}`}
                        >
                            <a
                                href={`./show/pdf?${linkParams}`}
                                target="_blank"
                                rel="noreferrer"
                            >
                                <InteractiveIcon
                                    src={pdfIcon}
                                    alt="PDF"
                                />
                            </a>
                        </Tooltip>
                    </td>
                    <td>
                        <Tooltip
                            position="top-right"
                            width="15rem"
                            text={`Abschlussbericht: ${entry.internalDocumentID} ${t('für')} ${t(
                                Audiences[selectedAudience]
                            )}`}
                        >
                            {entry.versions[`${selectedVersion}#${selectedLanguage}`][
                                selectedAudienceForRow
                            ]?.hasReport && (
                                <a
                                    href={`./show/pdf?${linkParams}&report`}
                                    target="_blank"
                                    rel="noreferrer"
                                >
                                    <InteractiveIcon
                                        src={pdfIcon}
                                        alt="Report PDF"
                                    />
                                </a>
                            )}
                        </Tooltip>
                    </td>
                </>
            )}
        </tr>
    );
};

const InteractiveIcon = styled.img<ThemeProps>`
    height: 1rem;
`;

export default DocumentRow;
