import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { useGlobalStore } from '../../hooks/hooks';
import { ThemeProps } from '../../interfaces/globals/theme';
import { currentJobLoaded } from '../../store/job/actions';
import { emptyJob } from '../../store/job/reducers';
import Icon from '../Icon/Icon';
import ProgressBar from './ProgressBar/ProgressBar';
import { JobDTO } from '../../lib/backend';

interface JobEntryProps {
    jobEntry: JobDTO;
    isCurrentJob: boolean;
}

const JobEntry: FunctionComponent<JobEntryProps> = ({ jobEntry, isCurrentJob }): ReactElement => {
    const { dispatch } = useGlobalStore();

    const publicationJobPayload = (job: JobDTO) => {
        const jsonObject = JSON.parse(job.payload);

        return {
            documentID: jsonObject.documentID as number,
            versionID: jsonObject.versionID as number,
            internalDocumentID: jsonObject.internalDocumentID as string,
            productVersion: jsonObject.productVersion as string,
            whatsNew: jsonObject.whatsNew as string | null,
            lang: jsonObject.lang as string,
            activated: jsonObject.activated as boolean,
        };
    };

    function generateProgress(): ReactElement {
        let element: ReactElement;
        switch (jobEntry.status) {
            case JobDTO.status.ASSIGNED:
                element = <p>Assigned</p>;
                break;
            case JobDTO.status.RUNNING:
                element = <ProgressBar progress={jobEntry.progress} />;
                break;
            case JobDTO.status.FINISHED:
                element = (
                    <>
                        <p>Finished</p>
                        <Icon
                            name="ok"
                            className="ok"
                        />
                    </>
                );
                if (isCurrentJob) dispatch(currentJobLoaded(emptyJob));
                break;
            case JobDTO.status.CANCELED:
                element = (
                    <>
                        <p>Canceled</p>
                        <Icon
                            name="warn"
                            className="warn"
                        />
                    </>
                );
                break;
            case JobDTO.status.FAILED:
                element = (
                    <>
                        <p>Failed</p>
                        <Icon
                            name="warn"
                            className="warn"
                        />
                    </>
                );
                break;
            default:
                element = <p>Pending...</p>;
                break;
        }
        return element;
    }

    return (
        <JobEntryWrapper>
            <p>
                Publikation:&nbsp;
                <span className="bold-text">
                    {publicationJobPayload(jobEntry).internalDocumentID}&nbsp;
                </span>
                ({publicationJobPayload(jobEntry).productVersion})&nbsp;
            </p>
            {generateProgress()}
        </JobEntryWrapper>
    );
};

const JobEntryWrapper = styled.div<ThemeProps>`
    display: flex;
    font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;
    border-bottom: 0.1rem dashed;
    p {
        margin: 5px 0 5px 5px;
    }

    img {
        margin: auto 0;
        height: 7px;
        width: 140px;
    }

    .bold-text {
        font-weight: bold;
    }

    svg.ok {
        margin: auto 5px;
    }
`;

export default JobEntry;
