import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';
import { hexToRGBA } from '../../styles/variables';

interface TooltipProps {
    text: string;
    position?: string;
    width?: string;
    children?: ReactNode;
}

const Tooltip: FunctionComponent<TooltipProps> = ({
    text,
    position = 'top-left',
    width = '25rem',
    children,
}): ReactElement => {
    function renderTooltipText(t: string) {
        if (t && t.length > 0)
            return (
                <span
                    style={{ width }}
                    className={position}
                >
                    {text.split('\n').map((l, idx, lines) => (
                        <div key={`${l}_${3 * idx}`}>
                            {l}
                            {idx < lines.length - 1 ? <br /> : ''}
                        </div>
                    ))}
                </span>
            );
        return null;
    }

    return (
        <TooltipContainer>
            {children}
            {renderTooltipText(text)}
        </TooltipContainer>
    );
};

const TooltipContainer = styled.div<ThemeProps>`
    position: relative;

    span {
        heigth: max-content;
        whitespace: inherit;
        visibility: hidden;
        background-color: ${props => hexToRGBA(props.theme.$colorGrey50, 0.8)};
        color: ${props => props.theme.$colorFontSelected};
        padding: 0.5rem;
        opacity: 0;
        overflow: visible;
        transition: opacity 0.5s;
        transition-delay: 0.5s;
        border-radius: 0.3rem;
        position: absolute;
        z-index: 10;
    }

    :hover span {
        visibility: visible;
        opacity: 1;
    }

    span::after {
        content: '';
        position: absolute;
        border-with: 0.5rem;
        border-style: solid;
    }

    span.top-left {
        text-align: left;
        bottom: calc(100% + 0.2rem);
        left: 2%;
    }

    span.top-left::after {
        top: 100%;
        left: 0.8rem;
        border-color: ${props => hexToRGBA(props.theme.$colorGrey50, 0.8)} transparent transparent
            transparent;
    }

    span.top-right {
        text-align: right;
        bottom: calc(100% + 0.25rem);
        right: 2%;
    }

    span.top-right::after {
        top: 100%;
        right: 0.8rem;
        border-color: ${props => hexToRGBA(props.theme.$colorGrey50, 0.8)} transparent transparent
            transparent;
    }

    span.bottom {
        top: calc(100% -0.2rem);
        left: 50%;
    }
`;

export default Tooltip;
