import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../../components/ActionControlButton/ActionControlButton';
import Icon from '../../../../../../components/Icon/Icon';
import { ThemeProps } from '../../../../../../interfaces/globals/theme';

interface ImageUploadEntryProps {
    name: string;
    size: number;
    callback: Function;
}

const ImageUploadEntry: FunctionComponent<ImageUploadEntryProps> = ({
    name = 'Kein Name gefunden',
    size = 5.58,
    callback,
}) => (
    <ImageUploadEntryWrapper>
        <Icon
            name="base-image"
            size="40"
        />
        <span>
            <p>{name}</p>
            <p className="sml-p">{(size / 1000).toFixed(2)} KB</p>
        </span>
        <ActionControlButton
            className="close-button"
            prefix="close"
            name="close"
            callback={() => callback()}
            size={15}
        />
    </ImageUploadEntryWrapper>
);

const ImageUploadEntryWrapper = styled.div<ThemeProps>`
    display: flex;
    width: 100%;
    padding: 0.3rem;
    background-color: #fff;
    border-width: 1px;
    border-radius: 2px;
    border-style: solid;

    span {
        width: 100%;
    }

    .sml-p {
        font-size: smaller;
    }

    .close-button {
        margin: auto;
        border: none;
        background-color: inherit;
    }
`;

export default ImageUploadEntry;
