/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArticleAuditDTO } from '../models/ArticleAuditDTO';
import type { ArticleDTO } from '../models/ArticleDTO';
import type { ContentlessArticle } from '../models/ContentlessArticle';
import type { ImageDTO } from '../models/ImageDTO';
import type { SearchInput } from '../models/SearchInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class ArticlesService {

    /**
     * Retrieves an article
     * @param id
     * @returns ArticleDTO OK
     * @throws ApiError
     */
    public static getArticle(
        id: number,
    ): CancelablePromise<ArticleDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/articles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates an article
     * @param id
     * @param requestBody
     * @returns ArticleDTO OK
     * @throws ApiError
     */
    public static updateArticle(
        id: number,
        requestBody: ArticleDTO,
    ): CancelablePromise<ArticleDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/articles/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes an article
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static deleteArticle(
        id: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/articles/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Creates an article
     * @param requestBody
     * @returns ArticleDTO OK
     * @throws ApiError
     */
    public static createArticle(
        requestBody: ArticleDTO,
    ): CancelablePromise<ArticleDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/articles',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates image reference for article
     * @param id
     * @param requestBody
     * @returns ArticleDTO OK
     * @throws ApiError
     */
    public static createImgRefForArticle(
        id: number,
        requestBody: ImageDTO,
    ): CancelablePromise<ArticleDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/articles/{id}/images',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves meta data for all articles by tenant
     * @param id
     * @param requestBody
     * @returns ArticleDTO OK
     * @throws ApiError
     */
    public static getAllArticlesBySearch(
        id: string,
        requestBody: SearchInput,
    ): CancelablePromise<Array<ArticleDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/articles/tenants/{id}/search',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Gets all audits for an article
     * @param id
     * @returns ArticleAuditDTO OK
     * @throws ApiError
     */
    public static getAllAudits(
        id: number,
    ): CancelablePromise<Array<ArticleAuditDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/articles/{id}/audit',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get content article audit for one article
     * @param id
     * @param revId
     * @returns ArticleAuditDTO OK
     * @throws ApiError
     */
    public static getContentOfArticlesAudit(
        id: number,
        revId: number,
    ): CancelablePromise<ArticleAuditDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/articles/{id}/audit/{revID}/content',
            path: {
                'id': id,
                'revID': revId,
            },
        });
    }

    /**
     * @param id
     * @returns ArticleDTO OK
     * @throws ApiError
     */
    public static getAllByVersion(
        id: number,
    ): CancelablePromise<Array<ArticleDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/articles/versions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get all articles that contains 'Abschlussbericht' in it
     * @param id
     * @returns ContentlessArticle OK
     * @throws ApiError
     */
    public static getAllReports(
        id: string,
    ): CancelablePromise<Array<ContentlessArticle>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/articles/tenants/{id}/reports',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Deletes image reference from article
     * @param articleId
     * @param imageId
     * @returns string OK
     * @throws ApiError
     */
    public static deleteImgRefFromArticle(
        articleId: number,
        imageId: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/articles/{articleID}/images/{imageID}',
            path: {
                'articleID': articleId,
                'imageID': imageId,
            },
        });
    }

}
