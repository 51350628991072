import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { useGlobalStore } from '../../../hooks/hooks';
import { validatePermissions } from '../../../lib/util';
import ErrorPage401 from '../../../views/ErrorPage401';
import DocumentEditor from './DocumentEditor';
import DocumentBrowser from './DocumentBrowser';
import { DocumentEntry } from '../../../interfaces/DocumentEntry';
import { emptyDocument } from '../../../store/document/reducers';
import { ITenant } from '../../../store/config/types';

const DocumentManager: FunctionComponent = (): ReactElement => {
    const { state } = useGlobalStore();
    const [localTenant, setLocalTenant] = useState<ITenant | null>(state.config.tenant.current);
    const [reloadDocuments, setReloadDocuments] = useState<boolean>(false);
    const [currentDocument, setCurrentDocument] = useState<DocumentEntry>(emptyDocument);

    useEffect(() => {
        if (localTenant !== state.config.tenant.current) {
            setLocalTenant(state.config.tenant.current);
            setCurrentDocument(emptyDocument);
        }
    }, [localTenant, state.config.tenant]);

    if (
        state.config.tenant.current === null ||
        state.user.userName === '' ||
        !validatePermissions(state.config.tenant.current, state.user.permissions, [
            'EDITOR',
            'ADMIN',
        ])
    )
        return <ErrorPage401 />;
    return (
        <Wrapper data-testid="document_manager">
            <DocumentBrowser
                expansion={0.4}
                currentDocumentProps={{ value: currentDocument, set: setCurrentDocument }}
                reloadDocuments={{ value: reloadDocuments, set: setReloadDocuments }}
            />
            <DocumentEditor
                isTranslationEditor={false}
                currentDocumentProps={{ value: currentDocument, set: setCurrentDocument }}
                reloadDocuments={{ value: reloadDocuments, set: setReloadDocuments }}
            />
        </Wrapper>
    );
};

const Wrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    flex: 1;
    height: 100%;
`;

export default DocumentManager;
