import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import { Buffer } from 'buffer';
import { IList, useGlobalStore } from '../../../hooks/hooks';
import { ArticleEntry } from '../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { ThemeProps } from '../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import LoadingScreen from '../../../views/LoadingScreen';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';
import TextInputDialog from '../../../components/dialogs/InputDialog';
import Modal from '../../../components/dialogs/Modal/Modal';
import Search from '../../search/Search';
import { ResponseError } from '../../../typings/error';
import { BoolSwapState } from '../../../typings/global';
import { t } from '../../../lib/language';
import { SearchRequestBody } from '../../../typings/search';

export interface ArticleModalProps {
    openState: BoolSwapState;
    handleDoubleClick: Function;
    articles?: ArticleEntry[];
    saveListener?: Function;
    isReport?: boolean;
}

const ArticleModal: FunctionComponent<ArticleModalProps> = ({
    openState,
    articles,
    handleDoubleClick,
    saveListener,
    isReport,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [listOfArticle, setListOfArticle] = useState<ArticleEntry[]>();
    const [showInputDialog, setShowInputDialog] = useState<boolean>(false);
    const [showReportInputDialog, setShowReportInputDialog] = useState<boolean>(false);
    const [startLoading, setStartLoading] = useState<boolean>(false);
    const searchID = 'search_article';
    const tenant = state.config.tenant.current;

    useEffect(() => {
        if (!articles && openState.value && tenant && !listOfArticle) {
            const requestBody: SearchRequestBody = {
                keywordSearch: null,
                deepSearch: null,
                languageCode: null,
            };
            AxiosClient.post(
                `${requestURLs.editorArticles}tenants/${tenant.tenantID}/search`,
                requestBody
            )
                .then(response => {
                    const articleList = response.data as ArticleEntry[];
                    const articleIList: IList = {};
                    setListOfArticle(articleList);
                    articleList.forEach(article => {
                        articleIList[article.articleID] = article;
                    });
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                });
        } else if (articles) setListOfArticle(articles);
    }, [articles, dispatch, listOfArticle, openState, tenant]);

    function deepSearch(needle = '') {
        setStartLoading(true);
        if (tenant) {
            const url = `${requestURLs.editorArticles}tenants/${tenant.tenantID}/search`;
            const requestBody: SearchRequestBody = {
                keywordSearch: null,
                deepSearch: null,
                languageCode: null,
            };

            if (needle.startsWith('$'))
                requestBody.deepSearch = Buffer.from(needle).toString('base64');
            else requestBody.keywordSearch = Buffer.from(needle).toString('base64');

            AxiosClient.post(url, requestBody)
                .then(response => {
                    setListOfArticle(response.data);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => {
                    setStartLoading(false);
                    dispatch(finishOperation());
                });
        }
    }

    function handleSelection(event: React.MouseEvent<HTMLButtonElement>) {
        const element = event.currentTarget;
        const articleID = element.getAttribute('data-id');
        const selectedElements = document.getElementsByClassName('selected-art');

        if (selectedElements.length > 0) {
            element.classList.remove('selected-art');
            Array.from(selectedElements).forEach(node => {
                node.classList.remove('selected-art');
            });
        }
        if (articleID) {
            event.currentTarget.classList.add('selected-art');
        }
    }

    function createNewArticle(title: string) {
        if (tenant && state.documentManager.currentVersion && title) {
            dispatch(startOperation());
            const newArticle = {
                title,
                tenantID: tenant.tenantID,
            };
            AxiosClient.post(
                `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/articles`,
                newArticle
            )
                .then(() => {
                    openState.set(false);
                    if (saveListener) saveListener();
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }

    function createNewReport(title: string) {
        if (title.startsWith('Abschlussbericht ')) {
            if (tenant && state.documentManager.currentVersion && title) {
                dispatch(startOperation());
                const newReport = {
                    title: `Abschlussbericht ${title}`,
                    tenantID: tenant.tenantID,
                };
                AxiosClient.post(
                    `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/report`,
                    newReport
                )
                    .then(() => {
                        openState.set(false);
                        if (saveListener) saveListener();
                    })
                    .catch(error => {
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        );
                    })
                    .finally(() => dispatch(finishOperation()));
            }
        } else {
            dispatch(addError(EErrorTypes.error, t('StatusCode.SpecialCase.WrongNameForReport')));
        }
    }

    function buildArticleList(list: ArticleEntry[]): ReactElement[] | ReactElement {
        const articleList: ReactElement[] = [];
        if (!articles && listOfArticle) {
            Object.values(listOfArticle).forEach(element =>
                articleList.push(
                    <span key={weakKey(element)}>
                        <button
                            type="button"
                            data-id={element.articleID}
                            className={`${element.articleID}`}
                            onClick={e => handleSelection(e)}
                            onDoubleClick={e => handleDoubleClick(e)}
                        >
                            {element.title}
                        </button>
                    </span>
                )
            );
        } else {
            list.forEach(element =>
                articleList.push(
                    <span key={weakKey(element)}>
                        <button
                            type="button"
                            data-id={element.articleID}
                            className={`${element.articleID}`}
                            onClick={e => handleSelection(e)}
                            onDoubleClick={e => handleDoubleClick(e)}
                        >
                            {element.title}
                        </button>
                    </span>
                )
            );
        }
        return articleList;
    }

    return (
        <Modal
            id="article-modal"
            isOpen={openState.value}
            shouldCloseOnOverlayClick
        >
            <InnerModal>
                <ModalHeader>
                    <ButtonWrapper>
                        <ActionControlButton
                            prefix="close"
                            name="close"
                            className="close-btn"
                            callback={() => openState.set(false)}
                            size={26}
                        />
                    </ButtonWrapper>
                    <SearchWrapper>
                        <h4>Artikelübersicht</h4>
                        <p>
                            {articles
                                ? 'Klicken Sie doppelt auf einen Artikel, um ihn als Abschlussbericht zu setzen.'
                                : 'Klicken Sie doppelt auf einen Artikel, um ihn dem aktuellen Dokument hinzuzufügen.'}
                        </p>
                        <Search
                            searchID={searchID}
                            deepSearch={deepSearch}
                        />
                        {!articles && (
                            <span
                                className="entry"
                                key="new-article"
                            >
                                <button
                                    type="button"
                                    data-id="newArticle"
                                    onClick={() => setShowInputDialog(true)}
                                >
                                    <p className="bold">Neuen Artikel erstellen</p>
                                </button>
                            </span>
                        )}
                        {isReport && (
                            <span
                                className="entry"
                                key="new-report"
                            >
                                <button
                                    type="button"
                                    data-id="newReport"
                                    onClick={() => setShowReportInputDialog(true)}
                                >
                                    <p className="bold">Neuen Abschlussbericht erstellen</p>
                                </button>
                            </span>
                        )}
                    </SearchWrapper>
                </ModalHeader>
                <ArticleWrapper className="styledScrollBar">
                    {!listOfArticle || startLoading ? (
                        <LoadingScreen />
                    ) : (
                        buildArticleList(listOfArticle)
                    )}
                </ArticleWrapper>
                <TextInputDialog
                    id="add-new-article"
                    title="Neuen Artikel erstellen"
                    message="Geben Sie den Titel des Artikels ein und bestätigen Sie mit OK."
                    onCancel={() => {
                        setShowInputDialog(false);
                    }}
                    onOK={() => {
                        const inputValueFromDialog = (
                            document.getElementById('add-new-article-input') as HTMLInputElement
                        ).value;
                        createNewArticle(inputValueFromDialog);
                    }}
                    isOpenState={[showInputDialog, setShowInputDialog]}
                />
                <TextInputDialog
                    id="add-new-report"
                    title="Neuen Abschlussbericht erstellen"
                    message="Geben Sie den Titel des Abschlussbericht ein und bestätigen Sie mit OK."
                    defaultValue="Abschlussbericht "
                    onCancel={() => {
                        setShowReportInputDialog(false);
                    }}
                    onOK={() => {
                        const inputValueFromDialog = (
                            document.getElementById('add-new-report-input') as HTMLInputElement
                        ).value;
                        createNewReport(inputValueFromDialog);
                    }}
                    isOpenState={[showReportInputDialog, setShowReportInputDialog]}
                />
            </InnerModal>
        </Modal>
    );
};

const InnerModal = styled.div<ThemeProps>`
    margin: 5px;
    display: flex;
    flex-direction: column;
    height: 770px;
    .selected-art {
        background: antiquewhite;

        :hover {
            background: antiquewhite;
        }
    }
`;

const ModalHeader = styled.div<ThemeProps>`
    margin-bottom: 5px;
`;

const SearchWrapper = styled.div<ThemeProps>`
    p {
        font-size: 0.7rem;
        font-style: italic;
        margin-top: 5px;
    }

    h4 {
        margin-bottom: 0;
    }

    .bold {
        margin: 0;
        font-weight: bold;
    }

    .entry {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: ${props => props.theme.$border.grey.dotted[20]};

        button {
            width: 100%;
            background-color: ${props => props.theme.$colorWhite};
            text-align: left;
            border: none;
            outline: none;
            box-shadow: none;
            padding: 0.3rem 0;
        }

        button:hover {
            background-color: ${props => props.theme.$colorGrey10};
        }
    }
`;

const ButtonWrapper = styled.div<ThemeProps>`
    .close-btn {
        margin: 10px;
        padding: 0;
        right: 0%;
        top: 0%;
        justify-content: flex-end;
        position: absolute;
        border: none;
        background-color: inherit;
    }
`;

const ArticleWrapper = styled.div<ThemeProps>`
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
    padding: 0.2rem 0 0.2rem 0;
    flex: 1;
    overflow: auto;

    span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: ${props => props.theme.$border.grey.dotted[20]};

        button {
            width: 100%;
            background-color: ${props => props.theme.$colorWhite};
            text-align: left;
            border: none;
            outline: none;
            box-shadow: none;
            padding: 0.3rem 0;
        }

        button:hover {
            background-color: ${props => props.theme.$colorGrey10};
        }
    }
`;

export default ArticleModal;
