import React, { FunctionComponent, ReactElement, ChangeEvent, useState, useEffect } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';
import InputField, { InputFieldProps } from './Fields/InputField';
import TextAreaField, { TextAreaFieldProps } from './Fields/TextAreaField';
import SelectField, { SelectFieldProps } from './Fields/SelectField';
import LabelField from './Fields/LabelField';
import TabComponentField, { TabComponentFieldProps } from './Fields/TabComponentField';
import { useGlobalStore } from '../../hooks/hooks';
import { FormFieldProps, SupportedElements } from './interfaces/FormField';
import ListField, { ListFieldProps } from './Fields/ListField';
import ButtonField from './Fields/ButtonField';

// eslint-disable-next-line no-shadow
export enum FormFieldTypes {
    Label = 0,
    Input = 10,
    Select = 20,
    List = 25,
    TextArea = 30,
    Button = 60,
    Tab = 90,
    Childs = 100,
}

type TLabeledFormField = FormFieldProps &
    (
        | InputFieldProps
        | TextAreaFieldProps
        | SelectFieldProps
        | ListFieldProps
        | TabComponentFieldProps
        | Record<string, never>
    );

const LabeledFormField: FunctionComponent<TLabeledFormField> = ({
    id,
    label,
    propagateChangeHandler = null,
    ...props
}): ReactElement => {
    const { state } = useGlobalStore();
    const [changed, setChanged] = useState<boolean>(false);

    useEffect(() => {
        if (!state.documentManager.documentChanged) {
            setChanged(false);
        }
    }, [state.documentManager.documentChanged]);

    function handleChange(
        event: ChangeEvent<SupportedElements>,
        callback: ((e: ChangeEvent<SupportedElements>) => void) | undefined
    ) {
        if (props.trackChanges && (props.readOnly === undefined || !props.readOnly)) {
            setChanged(true);
            // const tabParentID = event.currentTarget.getAttribute('data-tabid');
            // if (tabParentID !== null) {
            //     const tabParent = document.getElementById(tabParentID) as HTMLButtonElement;
            //     if (tabParent) tabParent.dispatchEvent(new Event('change', { bubbles: true }));
            // }
            if (propagateChangeHandler) {
                propagateChangeHandler(event);
            }
        }
        if (callback !== undefined) callback(event);
    }

    function renderFormElement(allProps: TLabeledFormField): ReactElement | null {
        const { onChange, ...remainingProps } = allProps;

        const capturedChangeHandler = (event: ChangeEvent<SupportedElements>) =>
            handleChange(event, onChange);
        switch (allProps.formFieldType) {
            case FormFieldTypes.Input:
                return (
                    <InputField
                        onChange={capturedChangeHandler}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(remainingProps as FormFieldProps & InputFieldProps)}
                    />
                );
            case FormFieldTypes.TextArea:
                return (
                    <TextAreaField
                        onChange={capturedChangeHandler}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(remainingProps as FormFieldProps & TextAreaFieldProps)}
                    />
                );
            case FormFieldTypes.Select:
                return (
                    <SelectField
                        onChange={capturedChangeHandler}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(remainingProps as FormFieldProps & SelectFieldProps)}
                    />
                );
            case FormFieldTypes.List:
                return (
                    <ListField
                        onChange={capturedChangeHandler}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(remainingProps as FormFieldProps & ListFieldProps)}
                    />
                );

            case FormFieldTypes.Tab:
                return (
                    <TabComponentField
                        onChange={capturedChangeHandler}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(remainingProps as FormFieldProps & TabComponentFieldProps)}
                    />
                );
            case FormFieldTypes.Button:
                return (
                    <ButtonField
                        onChange={capturedChangeHandler}
                        // eslint-disable-next-line react/jsx-props-no-spreading
                        {...(remainingProps as FormFieldProps)}
                    />
                );
            case FormFieldTypes.Label:
                // eslint-disable-next-line react/jsx-props-no-spreading
                return <LabelField {...(allProps as FormFieldProps)} />;
            default:
                return null;
        }
    }

    return (
        <>
            <Label
                htmlFor={id}
                hasChanged={changed}
            >
                {label}
            </Label>
            {renderFormElement({ id, label, ...props })}
        </>
    );
};

const Label = styled.label<ThemeProps & { hasChanged: boolean }>`
    margin: 0.5rem 0;
    width: 7rem;
    color: ${props => (props.hasChanged ? props.theme.$colorChanged : 'inherit')};
`;

export default LabeledFormField;
