import React, { FunctionComponent, ReactElement, KeyboardEvent, ReactNode } from 'react';
import styled from 'styled-components';
import Modal from './Modal/Modal';
import { ButtonPanel } from './SharedStyles';
import { ThemeProps } from '../../interfaces/globals/theme';
import { StyledNavButton } from '../navigation/NavButton/NavButton';

interface TextInputDialogProps {
    id: string;
    title: string;
    message: string;
    defaultValue?: string;
    disabled?: boolean;
    textField?: boolean;
    selectField?: boolean;
    isOpenState: [boolean, Function];
    onOK: (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
    ) => void | undefined;
    onCancel?: (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
    ) => void | undefined;
    children?: ReactNode;
}

const TextInputDialog: FunctionComponent<TextInputDialogProps> = ({
    id,
    isOpenState,
    title,
    disabled = false,
    message,
    onCancel,
    onOK,
    defaultValue,
    textField = false,
    selectField = false,
    children,
}): ReactElement => {
    function keyDownEventListener(event: KeyboardEvent<HTMLInputElement | HTMLTextAreaElement>) {
        if (event.key === 'Escape') {
            event.preventDefault();
            // eslint-disable-next-line no-unused-expressions
            document.getElementById(`${id}-cancel`)?.click();
        } else if (!textField && event.key === 'Enter') {
            event.preventDefault();
            // eslint-disable-next-line no-unused-expressions
            document.getElementById(`${id}-ok`)?.click();
        }
    }

    function renderFields(): React.ReactNode {
        if (!selectField) {
            if (textField) {
                return (
                    <TextField
                        id={`${id}-input`}
                        typeof="input"
                        autoFocus
                        defaultValue={defaultValue || ''}
                        onKeyDown={event => keyDownEventListener(event)}
                    />
                );
            }
            return (
                <div className="report-div">
                    <TextInput
                        id={`${id}-input`}
                        type="input"
                        autoFocus
                        defaultValue={defaultValue || ''}
                        onKeyDown={event => keyDownEventListener(event)}
                    />
                </div>
            );
        }
        return children;
    }

    return (
        <Modal
            shouldCloseOnOverlayClick
            onRequestClose={onCancel}
            id={id}
            isOpen={isOpenState[0]}
        >
            <h2>{title}</h2>
            <ContentPanel>
                <p>{message}</p>
                {renderFields()}
            </ContentPanel>
            <ButtonPanel>
                <StyledNavButton
                    id={`${id}-ok`}
                    renderas="button"
                    disabled={disabled}
                    onClick={event => {
                        onOK(event);
                        isOpenState[1](false);
                    }}
                >
                    OK
                </StyledNavButton>
                <StyledNavButton
                    id={`${id}-cancel`}
                    renderas="button"
                    onClick={event => {
                        if (onCancel !== undefined) onCancel(event);
                        isOpenState[1](false);
                    }}
                >
                    Abbrechen
                </StyledNavButton>
            </ButtonPanel>
        </Modal>
    );
};

const ContentPanel = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    justify-content: space-between;
    align-items: center;

    .report-div {
        display: flex;

        p {
            padding: 0;
            margin: 0;
        }
    }
`;

const TextInput = styled.input<ThemeProps>`
    flex: 1;
    text-align: left;
    min-width: 8rem;
    margin-left: 1rem;
`;

const TextField = styled.textarea<ThemeProps>`
    width: 97%;
    height: 100px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
    border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1em;
    line-height: 1.4em;
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;

    :focus {
        background: none repeat scroll 0 0 #ffffff;
        outline-width: 0;
    }
`;
export default TextInputDialog;
