import { userReducer, userReducerInitialState } from './user/reducers';
import { configReducer, configReducerInitialState } from './config/reducers';
import ApplicationState from '../interfaces/globals/appState';
import { globalReducerInitialState, globalReducer } from './global/reducers';
import { documentManagerReducer, documentManagerInitialState } from './document/reducers';
import { TDocumentManagerReducerActions } from './document/types';
import { jobInitialState, jobReducer } from './job/reducers';

export const initialState: ApplicationState = {
    global: { ...globalReducerInitialState },
    user: { ...userReducerInitialState },
    documentManager: { ...documentManagerInitialState },
    config: { ...configReducerInitialState },
    jobs: { ...jobInitialState },
};

// eslint-disable-next-line @typescript-eslint/no-explicit-any
function rootReducer(state: ApplicationState, action: any): ApplicationState {
    const { global, user, config, documentManager, jobs } = state;

    if (process.env.NODE_ENV.startsWith('development'))
        // eslint-disable-next-line no-console
        console.log(
            'received action:',
            action.type,
            '\n\tpayload:',
            action.payload,
            action.origin ? `\n\tfrom: ${action.origin}` : ''
        );
    return {
        global: globalReducer(action, global),
        user: userReducer(action, user),
        config: configReducer(action, config),
        documentManager: documentManagerReducer(
            action as TDocumentManagerReducerActions,
            documentManager
        ),
        jobs: jobReducer(action, jobs),
    };
}

export type TAppState = ReturnType<typeof rootReducer>;

export default rootReducer;
