import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../../../../interfaces/globals/theme';

interface EditorButtonGroupProps {
    border: boolean;
    children?: ReactNode;
}

const EditorBarGroup: FunctionComponent<EditorButtonGroupProps> = ({ border, children }) => (
    <ButtonGroupWrapper border={border}>{children}</ButtonGroupWrapper>
);

export default EditorBarGroup;

const ButtonGroupWrapper = styled.div<ThemeProps & { border: boolean }>`
    margin: 0;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
    border-right: ${props => (props.border ? 'solid 1px grey' : 'none')};
    button {
        margin: 0 0.2rem;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        outline: inherit;
        transition: 0.5s;
    }

    .activePref {
        border: ${props => props.theme.$border.secondary.bold};
    }

    .inactivePref {
        border: 0.15rem solid transparent;
    }
    button:hover {
        background-color: #e6e6e6;
    }
`;
