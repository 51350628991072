import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import { BoolSwapState } from '../../../../../typings/global';
import Search from '../../../../search/Search';

interface ImageHeaderProps {
    showAdditionalControls: boolean;
    imageSliderVisible?: BoolSwapState;
    handleSearch?: Function;
}

const ImageHeader: FunctionComponent<ImageHeaderProps> = ({
    showAdditionalControls,
    imageSliderVisible,
    handleSearch,
}) => (
    <div>
        {showAdditionalControls && imageSliderVisible && (
            <SliderHeader>
                <h3>Artikelbildverzeichnis</h3>
                <ActionControlButton
                    prefix="close"
                    name="close"
                    className="close-btn"
                    callback={() => imageSliderVisible.set(false)}
                    size={26}
                />
            </SliderHeader>
        )}
        {handleSearch && (
            <SearchBar>
                <h3>Bildverzeichnis</h3>
                <Search
                    searchID="search_image"
                    callbackSearch={(s: string) => handleSearch(s)}
                />
            </SearchBar>
        )}
    </div>
);

export default ImageHeader;

const SliderHeader = styled.div<ThemeProps>`
    width: 325px;
    display: flex;
    justify-content: center;
    margin: auto;

    .close-btn {
        position: absolute;
        margin: 10px;
        right: 0;
        padding: 0;
        border: none;
        background-color: inherit;
    }
`;

const SearchBar = styled.div<ThemeProps>`
    display: flex;
    width: 315px;
    margin: 0 5px;
    flex-direction: column;

    h3 {
        display: flex;
        justifycontent: center;
    }
`;
