import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import Modal from '../../components/dialogs/Modal/Modal';
import { BoolSwapState } from '../../typings/global';
import { ThemeProps } from '../../interfaces/globals/theme';
import { ButtonWrapper } from '../documentmanager/document/PublishModal';
import ActionControlButton from '../../components/ActionControlButton/ActionControlButton';
import { useGlobalStore } from '../../hooks/hooks';
import { addError, finishOperation, startOperation } from '../../store/global/actions';
import { EErrorTypes } from '../../interfaces/globals/errors';
import { StyledNavButton } from '../../components/navigation/NavButton/NavButton';
import { ButtonPanel } from '../../components/dialogs/SharedStyles';
import { ReviewsService } from '../../lib/backend';

interface ReviewDialogProps {
    openState: BoolSwapState;
}

const ReviewModal: FunctionComponent<ReviewDialogProps> = ({ openState }) => {
    const { dispatch, state } = useGlobalStore();
    const { tenant } = state.config;
    const { currentVersion, currentDescription } = state.documentManager;
    const [startDate, setStartDate] = useState<Date>(new Date());
    const [endDate, setEndDate] = useState<Date>(new Date(startDate.getTime() + 12096e5));
    const [users, setUsers] = useState<string[]>([]);

    function createReview() {
        if (users.length <= 0 || !tenant.current)
            dispatch(addError(EErrorTypes.error, 'Es wurden keine Reviewer hinzugefügt!'));
        else {
            dispatch(startOperation());
            ReviewsService.createReview({
                versionID: currentVersion.versionID,
                langCode: currentDescription.languageCode,
                users,
                reviewStartDate: startDate.toISOString(),
                reviewEndDate: endDate.toISOString(),
            })
                .then(review => {
                    dispatch(
                        addError(
                            EErrorTypes.info,
                            `Review mit der ID ${review.reviewID} wurde erfolgreich erstellt`
                        )
                    );
                    openState.set(false);
                })
                .catch(error => dispatch(addError(EErrorTypes.error, error.body.status)))
                .finally(() => dispatch(finishOperation()));
        }
    }

    return (
        <Modal
            id="ReviewModal"
            isOpen={openState.value}
            shouldCloseOnOverlayClick
        >
            <InnerModal>
                <ModalHeader>
                    <ButtonWrapper>
                        <ActionControlButton
                            prefix="close"
                            name="close"
                            className="close-btn"
                            callback={() => openState.set(false)}
                            size={26}
                        />
                    </ButtonWrapper>
                    <h3>{`Erstellen eines Review's für die Version ${currentVersion.versionID}`}</h3>
                </ModalHeader>
                <ModalBody>
                    <fieldset>
                        <legend>Wählen Sie einen Start- und Endzeitpunkt.</legend>
                        <DatePicker>
                            <p>Start Datum:</p>
                            <input
                                onChange={event => {
                                    setStartDate(new Date(event.target.value));
                                    setEndDate(
                                        new Date(new Date(event.target.value).getTime() + 12096e5)
                                    );
                                }}
                                type="date"
                                id="start"
                                name="review-start"
                                value={startDate.toISOString().split('T')[0]}
                                min={new Date().toISOString().split('T')[0]}
                            />
                        </DatePicker>
                        <DatePicker>
                            <p>End Datum:</p>
                            <input
                                onChange={event => setEndDate(new Date(event.target.value))}
                                type="date"
                                id="start"
                                name="review-start"
                                value={endDate.toISOString().split('T')[0]}
                                min={endDate.toISOString().split('T')[0]}
                            />
                        </DatePicker>
                    </fieldset>
                    <fieldset>
                        <legend>Fügen Sie diesem Review Benutzer hinzu.</legend>
                        <InputReview
                            onKeyDown={e => {
                                if (e.code === 'Enter' || e.code === 'NumpadEnter') {
                                    const name = e.currentTarget.value;
                                    const sp = /[ `!@#$%^&*()_+\-=[\]{};':"\\|,.<>/?~]/;
                                    const n = /\d/;
                                    if (!sp.test(name) && !n.test(name) && name !== '')
                                        setUsers([...users, e.currentTarget.value]);
                                    else
                                        dispatch(
                                            addError(
                                                EErrorTypes.warning,
                                                'Sonderzeichen und/oder Zahlen sind nicht erlaubt!'
                                            )
                                        );
                                    e.currentTarget.value = '';
                                }
                            }}
                        />
                        <ReviewUsers>
                            {users.map(u => (
                                <UserWrapper key={u}>
                                    {u}
                                    <ActionControlButton
                                        prefix="close"
                                        name="close"
                                        className="remove-btn"
                                        callback={() => {
                                            setUsers(users.filter(user => user !== u));
                                        }}
                                        size={12}
                                    />
                                </UserWrapper>
                            ))}
                        </ReviewUsers>
                    </fieldset>
                    <ButtonPanel>
                        <StyledNavButton
                            renderas="button"
                            onClick={() => createReview()}
                        >
                            OK
                        </StyledNavButton>
                        <StyledNavButton
                            renderas="button"
                            onClick={() => {
                                openState.set(false);
                                setUsers([]);
                                setStartDate(new Date());
                                setEndDate(new Date(startDate.getTime() + 12096e5));
                            }}
                        >
                            Abbrechen
                        </StyledNavButton>
                    </ButtonPanel>
                </ModalBody>
            </InnerModal>
        </Modal>
    );
};

export default ReviewModal;

const InnerModal = styled.div<ThemeProps>`
    margin-top: 2rem;
    max-width: 500px;
`;
const ModalHeader = styled.div<ThemeProps>``;
const ModalBody = styled.div<ThemeProps>`
    p {
        font-size: small;
        margin: 0 0.5rem;
        min-width: 5rem;
    }
`;
const DatePicker = styled.div<ThemeProps>`
    display: flex;
    margin: 0.5rem 0;
`;

export const InputReview = styled.input<ThemeProps>`
    padding: 5px;
    width: 97%;
`;

export const ReviewUsers = styled.div<ThemeProps>`
    display: flex;
    flex-wrap: wrap;
`;

export const UserWrapper = styled.div<ThemeProps>`
    margin: 0.5rem 0.2rem;
    padding: 0.3rem;
    border: 0.5px solid grey;
    border-radius: 3px;

    button {
        border: none;
        background-color: inherit;
    }
`;
