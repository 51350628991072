import { IConfigState, ITenant, TConfigReducerActions } from './types';
import packageInfo from '../../../package.json';
import { getObjectWithMatchingKeyFromArray } from '../../lib/util';
import ActionTypes from '../actionTypes';

export const configReducerInitialState: IConfigState = {
    version: packageInfo.version,
    language: {
        current: null,
        fallback: null,
        list: {},
    },
    tenant: {
        current: null,
        list: [],
    },
    footer: {
        leftFooter: 'VISUS Health IT GmbH (C) 2023',
        rightFooter: `Version: ${packageInfo.version}`,
    },
    header: {
        height: 40,
    },
};

const currentTenant = (tenant: ITenant | null) => {
    const system = process.env.REACT_APP_SERVER;
    if (tenant) return tenant.name;
    if (!tenant && system === 'extern') return 'Global';
    return 'Serviceberatung';
};

export const configReducer = (
    action: TConfigReducerActions,
    state: IConfigState = configReducerInitialState
): IConfigState => {
    switch (action.type) {
        case ActionTypes.LOAD_LANGUAGES:
            return {
                ...state,
                language: {
                    ...state.language,
                    list: action.payload.languageList,
                    current: action.payload.languageList.de_DE,
                },
            };
        case ActionTypes.SET_LANGUAGE:
            return {
                ...state,
                language: {
                    ...state.language,
                    current: action.payload.language,
                },
            };
        case ActionTypes.SET_LOCALE:
            return {
                ...state,
                language: {
                    ...state.language,
                    current: state.language.list[action.payload.locale],
                },
            };
        case ActionTypes.LOAD_TENANTS:
            return {
                ...state,
                tenant: {
                    list: action.payload.tenantList,
                    current: getObjectWithMatchingKeyFromArray(
                        action.payload.tenantList,
                        'name',
                        currentTenant(state.tenant.current)
                    ),
                },
            };
        case ActionTypes.SET_TENANT:
            return {
                ...state,
                tenant: {
                    ...state.tenant,
                    current: action.payload.tenant,
                },
            };
        default:
            return state;
    }
};
