import { normalize, languageCode, find } from './langutils';

/**
 * Modification to the window element for cross-browser compatibility.
 *
 * Ported features are:
 * - locale detection
 */
declare global {
    interface Window {
        // IE navigator lanuage settings (non-standard)
        userLanguage: string;
        browserLanguage: string;
    }
}

/**
 * Retrieves the user locale, that is transmitted by the users browser.
 *
 * @returns {string} the users' locale
 */
export function getUserLocale(): string {
    return window.navigator.language || window.browserLanguage || window.userLanguage;
}

/**
 * Retrieves the current user locale and matches it against supported locales.
 * If there is an exact match for a locale, e.g. 'de_DE', this locale is returned.
 * In case only a match for the first two locale characters could be found, e.g. 'de_AT',
 * this locale is returned.
 *
 * If no matching locale can be found, nothing will be returned.
 *
 * @param {ReadOnlyArray<string>} supportedLocales the locales supported by this application
 * @returns {string | undefined} the supported locale matching the user locale or undefined
 */
export default function resolveUserLocale(
    supportedLocales: ReadonlyArray<string>
): string | undefined {
    const userLocale = normalize(getUserLocale());

    const exactMatch = find(supportedLocales, supported => supported === userLocale);

    if (exactMatch) {
        return exactMatch;
    }

    const userLanguageCode = languageCode(userLocale);

    const languageCodeMatch = find(
        supportedLocales,
        supported => languageCode(supported) === userLanguageCode
    );

    if (languageCodeMatch) {
        return languageCodeMatch;
    }

    return undefined;
}
