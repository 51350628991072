import React, { FunctionComponent, ReactElement, ReactNode, useEffect, useState } from 'react';
import styled, { keyframes } from 'styled-components';
import ActionControlButton from '../../ActionControlButton/ActionControlButton';

interface PanelProps {
    colorType: 'ERROR' | 'WARNING' | 'INFO';
    children?: ReactNode;
}

const Panel: FunctionComponent<PanelProps> = ({ colorType, children }): ReactElement => {
    const [show, setShow] = useState<boolean>(true);

    // eslint-disable-next-line consistent-return
    useEffect(() => {
        if (show) {
            const interval = setInterval(() => {
                setShow(false);
            }, 3000);
            return () => clearInterval(interval);
        }
    }, [show]);

    return (
        <div>
            {show && (
                <PanelStyle
                    id={`panel-${colorType}`}
                    colorType={colorType}
                >
                    <ActionControlButton
                        prefix="close"
                        name="close"
                        className="close-btn"
                        callback={() => setShow(false)}
                        size={15}
                    />
                    <FlexWrap colorType={colorType}>{children}</FlexWrap>
                </PanelStyle>
            )}
        </div>
    );
};

const animate = keyframes`
    50% {transform: translate(0, 0);}
    60% {transform: translate(0, +5px);}
    62% {transform: translate(0, +7px);}
    66% {transform: translate(0, +5px);}
    100% {transform: translate(0, 0);}
`;

const PanelStyle = styled.div<PanelProps>`
    border-radius: 2px;
    border: 1px solid transparent;
    border-color: ${props => {
        if (props.colorType === 'ERROR') {
            return '#D8000C';
        }

        if (props.colorType === 'WARNING') {
            return '#9F6000';
        }

        if (props.colorType === 'INFO') {
            return '#31708F';
        }

        return '#D8000C';
    }};

    background-color: ${props => {
        if (props.colorType === 'ERROR') {
            return '#FFBABA';
        }

        if (props.colorType === 'WARNING') {
            return '#FEEFB3';
        }

        if (props.colorType === 'INFO') {
            return '#D9EDF7';
        }

        return '#FFBABA';
    }};

    color: ${props => {
        if (props.colorType === 'ERROR') {
            return '#D8000C';
        }

        if (props.colorType === 'WARNING') {
            return '#9F6000';
        }

        if (props.colorType === 'INFO') {
            return '#31708F';
        }

        return '#D8000C';
    }};

    padding: 0.5rem;
    position: relative;
    font-size: 0.875rem;
    animation: ${animate} 1s;
    animation-iteration-count: 2;
    animation-fill-mode: forwards;

    .close-btn {
        position: absolute;
        right: 0.5rem;
        top: 0.5rem;
        border: none;
        background-color: inherit;
    }

    &:not(:last-child) {
        margin-bottom: 0.5rem;
    }
`;

const FlexWrap = styled.div<PanelProps>`
    display: flex;
`;

export default Panel;
