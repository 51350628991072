import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { makeTimeStampReadable } from '../../../lib/util';

interface ChangeHistoryState {
    createdBy: string | null;
    createdDate: Date | null;
    lastModifiedBy: string | null;
    lastModifiedDate: Date | null;
}

const ChangeInformation: FunctionComponent<ChangeHistoryState> = ({
    createdBy,
    createdDate,
    lastModifiedBy,
    lastModifiedDate,
}): ReactElement => (
    <DetailsWrapper>
        <p>
            <span className="bold-text">Erstellt von: </span>
            {createdBy || 'Keine Daten vorhanden'}
        </p>
        <p>
            <span className="bold-text">Erstellt am: </span>
            {makeTimeStampReadable(createdDate)}
        </p>
        <p>
            <span className="bold-text">Zuletzt bearbeitet von: </span>
            {lastModifiedBy || 'Keine Daten vorhanden'}
        </p>
        <p>
            <span className="bold-text">Zuletzt bearbeitet am: </span>
            {makeTimeStampReadable(lastModifiedDate)}
        </p>
    </DetailsWrapper>
);

const DetailsWrapper = styled.div<ThemeProps>`
    border: solid;
    border-width: 0.5px;
    font-size: small;
    height: max-content;
    line-height: 0.49;
    margin-left: 5px;
    padding: 5px;
    width: 35%;

    .bold-text {
        font-weight: bold;
    }
`;

export default ChangeInformation;
