import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../interfaces/globals/theme';

const LoadingScreen: FunctionComponent = (): ReactElement => (
    <div className="grid-container">
        <LoadingPage>
            <img
                src={`${process.env.REACT_APP_DIR_BASENAME}/icn/loading.gif`}
                alt="Lade..."
            />
        </LoadingPage>
        <footer />
    </div>
);

// prettier-ignore
const LoadingPage = styled.div<ThemeProps>`
    display: flex;
    flex: 1;
    padding: 2em;

    img {
      height: 2rem;
      widht: 2rem;
      margin: auto;
    }
`

export default LoadingScreen;
