/* eslint-disable react-hooks/exhaustive-deps */
import React, { FunctionComponent, useEffect } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../components/ActionControlButton/ActionControlButton';
import { useGlobalStore } from '../../../../hooks/hooks';
import { DocumentEntry } from '../../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import { BoolSwapState } from '../../../../typings/global';

interface MinimizedEditorHeaderProps {
    currentDocument: DocumentEntry;
    isEditorHeaderClosedProps: BoolSwapState;
}

const MinimizedEditorHeader: FunctionComponent<MinimizedEditorHeaderProps> = ({
    currentDocument,
    isEditorHeaderClosedProps,
}) => {
    const { currentDescription } = useGlobalStore().state.documentManager;
    const headerID = 'toggle-wrapper-header-min';
    const toggleModalStyles = () =>
        isEditorHeaderClosedProps.value
            ? document.getElementById(headerID)?.classList.add('show')
            : document.getElementById(headerID)?.classList.remove('show');

    useEffect(() => {
        toggleModalStyles();
    }, [isEditorHeaderClosedProps.value]);

    return (
        <Toggle>
            <MinimizedEditorHeaderWrapper id={headerID}>
                <HeaderDataWrapper>
                    <HeaderEntry>
                        <span className="header-entry-spn">Interne Dokumenten ID: </span>
                        {currentDocument.internalDocumentID}
                    </HeaderEntry>
                    <HeaderEntry>
                        <span className="header-entry-spn">Titel: </span>
                        {currentDescription.title}
                    </HeaderEntry>
                </HeaderDataWrapper>
                <HeaderButtonWrapper>
                    <ActionControlButton
                        prefix="maximize-header"
                        name="maximize"
                        size={20}
                        callback={() => isEditorHeaderClosedProps.set(false)}
                    />
                </HeaderButtonWrapper>
            </MinimizedEditorHeaderWrapper>
        </Toggle>
    );
};

export default MinimizedEditorHeader;

const MinimizedEditorHeaderWrapper = styled.div<ThemeProps>`
    height: 0;
    opacity: 0;
    visibility: hidden;
    display: flex;
    border-bottom: ${props => props.theme.$border.secondary.thin};
    transition: 0.3s;
`;

const HeaderDataWrapper = styled.div<ThemeProps>`
    display: flex;
    width: 100%;
`;

const HeaderButtonWrapper = styled.div<ThemeProps>`
    button {
        all: unset;
        cursor: pointer;
    }
`;

const HeaderEntry = styled.div<ThemeProps>`
    margin: 0 5px;

    .header-entry-spn {
        font-weight: 600;
    }
`;

const Toggle = styled.div`
    .show {
        height: 30px;
        visibility: visible;
        opacity: 1;
    }
`;
