import React, { FunctionComponent, useState } from 'react';
import { useNavigate } from 'react-router-dom';
import styled from 'styled-components';
import ProfileModal from '../../components/dialogs/Modal/ProfileModal';
import Icon from '../../components/Icon/Icon';
import Logo from '../../components/navigation/Logo/Logo';
import { StyledNavButton } from '../../components/navigation/NavButton/NavButton';
import { useGlobalStore } from '../../hooks/hooks';
import { ThemeProps } from '../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../lib/axios';
import { t } from '../../lib/language';
import { finishOperation, startOperation } from '../../store/global/actions';
import { updateUser } from '../../store/user/actions';
import { IUserState } from '../../store/user/types';
import { BoolSwapState } from '../../typings/global';
import { OpenAPI } from '../../lib/backend';

interface LoginButtonProps {
    showLoginModal: BoolSwapState;
}

const Login: FunctionComponent<LoginButtonProps> = ({ showLoginModal }) => {
    const { dispatch } = useGlobalStore();
    const [errorMessage, setErrorMessage] = useState<string>('\u00a0');
    const history = useNavigate();
    const compTitle = 'Compendium';
    const compLogo = './logo/compendium.png';

    function keyDownEventHandler(event: React.KeyboardEvent<HTMLInputElement>) {
        if (event.key === 'Enter') {
            event.preventDefault();
            document.getElementById('login-button-accept')?.click();
        }
    }

    function handleLogin() {
        const username = (document.getElementById('username') as HTMLInputElement).value;
        const password = (document.getElementById('password') as HTMLInputElement).value;
        const loginButton = document.getElementById('login-button-accept');

        if (username && password && loginButton) {
            loginButton.setAttribute('disabled', 'true');
            dispatch(startOperation());

            AxiosClient.login(requestURLs.login, username, password)
                .then(response => {
                    if (Object.keys(response.data).length !== 0) {
                        const userData: IUserState = response.data;
                        AxiosClient.setAuthorizationHeader(userData.token);
                        OpenAPI.HEADERS = { Authorization: `Bearer ${userData.token}` };
                        history('/');
                        setErrorMessage('\u00a0');
                        showLoginModal.set(false);
                        dispatch(updateUser(userData));
                    }
                })
                .catch(error => {
                    if (error.response !== undefined) {
                        if (error.response.status === 401) setErrorMessage(t('Login.Failed'));
                        else setErrorMessage('Unknown error');
                    } else setErrorMessage(`Error ${error}`);
                })
                .finally(() => {
                    dispatch(finishOperation());
                    loginButton.removeAttribute('disabled');
                });
        } else setErrorMessage(t('Login.Failed'));
    }

    return (
        <ProfileModal
            id="login-modal"
            showModalState={showLoginModal}
        >
            <LoginHeader>
                <Logo
                    file={compLogo}
                    height={35}
                    target=""
                />
                <LoginTitle>{compTitle}</LoginTitle>
            </LoginHeader>
            <Message>{errorMessage}</Message>
            <div>
                <InputWrapper>
                    <Icon
                        className="icon"
                        name="profile"
                        size="25"
                    />
                    <input
                        id="username"
                        type="text"
                        autoComplete="UserName"
                        placeholder={t('Login.Input.Placeholder.User')}
                    />
                </InputWrapper>
                <InputWrapper>
                    <Icon
                        className="icon"
                        name="lock"
                        size="25"
                    />
                    <input
                        id="password"
                        type="password"
                        onKeyDown={keyDownEventHandler}
                        autoComplete="Password"
                        placeholder={t('Login.Input.Placeholder.Pass')}
                    />
                </InputWrapper>
                <ButtonWrapper>
                    <StyledNavButton
                        id="login-button-accept"
                        renderas="button"
                        onClick={() => handleLogin()}
                    >
                        {t('Login.Button.Text.In')}
                    </StyledNavButton>
                </ButtonWrapper>
            </div>
        </ProfileModal>
    );
};

const LoginHeader = styled.div<ThemeProps>`
    margin: 25px auto 0 auto;
    display: flex;
    flex-direction: row;
    justify-content: center;
`;

const LoginTitle = styled.div<ThemeProps>`
    margin-left: 0.5rem;
    font-weight: 600;
    font-size: ${props => props.theme.$headerStyles.xlarge.h2.fontSize}rem;
    box-sizing: border-box;
    text-transform: uppercase;
    vertical-align: middle;
    color: ${props => props.theme.$colorSecondary};
    text-decoration: none;
`;

const InputWrapper = styled.div`
    display: flex;
    justify-content: center;
    margin: 5px 0;
    height: 2rem;
    .icon {
        margin-right: 5px;
    }
`;

const ButtonWrapper = styled.div`
    position: absolute;
    display: flex;
    width: 100%;
    bottom: 25px;
    justify-content: center;
`;

const Message = styled.div`
    margin: 10px;
    text-align: center;
    color: red;
    font-size: 0.8rem;
    font-weight: 400;
`;

export default Login;
