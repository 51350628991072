/*
    eslint-disable no-unneeded-ternary, @typescript-eslint/no-empty-function
*/

import React, { ChangeEvent, FunctionComponent, ReactElement } from 'react';
import { DocumentDescriptionProps } from '../../../interfaces/DocumentEntry';
import EDocumentState from '../../../interfaces/DocumentState';
import { t } from '../../../lib/language';
import { emptyDescription } from '../../../store/document/reducers';
import { SupportedElements } from '../../../components/forms/interfaces/FormField';
import LabeledFormField, { FormFieldTypes } from '../../../components/forms/LabeledFormField';

interface DescriptionProps {
    description?: DocumentDescriptionProps | null;
    changeHandler?: ((event: ChangeEvent<SupportedElements>) => void) | null;
    editable?: boolean;
    hiddenFields?: { [key: string]: boolean };
}

const DescriptionForm: FunctionComponent<DescriptionProps> = ({
    description = null,
    changeHandler = null,
    editable = true,
    hiddenFields = {},
}): ReactElement => {
    const capturedDescription: DocumentDescriptionProps =
        description !== null ? description : JSON.parse(JSON.stringify(emptyDescription));
    return (
        <>
            {!hiddenFields.title && (
                <LabeledFormField
                    formFieldType={editable ? FormFieldTypes.Input : FormFieldTypes.Label}
                    id={`descriptions#${description?.languageCode}#title`}
                    label="Titel:"
                    value={`${capturedDescription.title}`}
                    trackChanges
                    onChange={changeHandler ? changeHandler : () => {}}
                />
            )}
            {!hiddenFields.tooltip && (
                <LabeledFormField
                    formFieldType={editable ? FormFieldTypes.TextArea : FormFieldTypes.Label}
                    id={`descriptions#${description?.languageCode}#description`}
                    label="Tooltip:"
                    value={`${capturedDescription.description}`}
                    trackChanges
                    onChange={changeHandler ? changeHandler : () => {}}
                />
            )}
            {!hiddenFields.keywords && (
                <LabeledFormField
                    formFieldType={editable ? FormFieldTypes.Input : FormFieldTypes.Label}
                    id={`descriptions#${description?.languageCode}#keywords`}
                    label="Keywords:"
                    value={`${capturedDescription.keywords}`}
                    trackChanges
                    onChange={changeHandler ? changeHandler : () => {}}
                />
            )}
            {!hiddenFields.status && (
                <LabeledFormField
                    formFieldType={FormFieldTypes.Label}
                    id={`descriptions#${description?.languageCode}#status`}
                    label="Status:"
                    disabled
                    value={`${t(EDocumentState[capturedDescription.status])}`}
                />
            )}
        </>
    );
};

export default DescriptionForm;
