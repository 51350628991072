/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { DescriptionDTO } from '../models/DescriptionDTO';
import type { DocumentDTO } from '../models/DocumentDTO';
import type { SearchInput } from '../models/SearchInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class DocumentService {

    /**
     * Retrieves a single document
     * @param id
     * @returns DocumentDTO OK
     * @throws ApiError
     */
    public static getDocument(
        id: number,
    ): CancelablePromise<DocumentDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates a document an returns it
     * @param id
     * @param requestBody
     * @returns DocumentDTO OK
     * @throws ApiError
     */
    public static update3(
        id: number,
        requestBody: DocumentDTO,
    ): CancelablePromise<DocumentDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/documents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes a Document by the given ID
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static delete3(
        id: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/documents/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates a document description
     * @param id
     * @param requestBody
     * @returns DescriptionDTO OK
     * @throws ApiError
     */
    public static updateDescription(
        id: number,
        requestBody: DescriptionDTO,
    ): CancelablePromise<DescriptionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/documents/descriptions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes a Description by the given ID
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static deleteDescription(
        id: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/documents/descriptions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Retrieves meta data for all Documents
     * @returns DocumentDTO OK
     * @throws ApiError
     */
    public static getAll5(): CancelablePromise<Array<DocumentDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents',
        });
    }

    /**
     * Creates a new Document by the given data
     * @param requestBody
     * @returns DocumentDTO OK
     * @throws ApiError
     */
    public static create(
        requestBody: DocumentDTO,
    ): CancelablePromise<DocumentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/documents',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a new Description for a Document by the given data
     * @param id
     * @param requestBody
     * @returns DocumentDTO OK
     * @throws ApiError
     */
    public static createDescription(
        id: number,
        requestBody: DescriptionDTO,
    ): CancelablePromise<DocumentDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/documents/{id}/descriptions',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * @param id
     * @param requestBody
     * @param qf
     * @returns DocumentDTO OK
     * @throws ApiError
     */
    public static lookupAllByTenantAndSearch(
        id: string,
        requestBody: SearchInput,
        qf?: number,
    ): CancelablePromise<Array<DocumentDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/documents/tenants/{id}/search',
            path: {
                'id': id,
            },
            query: {
                'qf': qf,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Generates internal document id and returns it | debugging ep
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static intDocId(
        id: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/{id}/internalDocumentID',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Get a list of Documents by the given ID
     * @param id
     * @returns DocumentDTO OK
     * @throws ApiError
     */
    public static findDocumentsByArticle(
        id: number,
    ): CancelablePromise<Array<DocumentDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/documents/articles/{id}',
            path: {
                'id': id,
            },
        });
    }

}
