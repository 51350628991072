import React, { FunctionComponent, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../../../interfaces/globals/theme';

const EditorBar: FunctionComponent<{ children: ReactNode }> = ({ children }) => (
    <EditorBarWrapper>{children}</EditorBarWrapper>
);

export default EditorBar;

const EditorBarWrapper = styled.div<ThemeProps>`
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.theme.$colorGray20};
    margin-bottom: 4px;
    border-bottom: ${props => props.theme.$border.secondary.thin};
    padding: 4px 0;
`;
