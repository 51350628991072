import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import Icon from '../../../../components/Icon/Icon';
import { ImageWrapper } from '../../../../components/navigation/Menu/Menu';
import { useGlobalStore } from '../../../../hooks/hooks';
import ActionControlButton from '../../../../components/ActionControlButton/ActionControlButton';

const ReviewFrameHeader: FunctionComponent = () => {
    const { user } = useGlobalStore().state;

    return (
        <ReviewFrameHeaderWrapper>
            <SpacerDiv>
                <ImageWrapper className="review-profil-img">
                    {user.imageData !== undefined ? (
                        <img
                            src={`data:image/jpeg;base64,${user.imageData}`}
                            alt="profile"
                        />
                    ) : (
                        <Icon
                            name="profile"
                            size="100"
                            stroke="#000"
                            fill=""
                        />
                    )}
                </ImageWrapper>
                <p>{user.fullName}</p>
            </SpacerDiv>
            <SpacerDiv style={{ justifyContent: 'flex-end' }}>
                <ActionControlButton
                    prefix="approved"
                    name="approved"
                    className="btn"
                    callback={() => null}
                    size={25}
                />
                <ActionControlButton
                    prefix="needs-work"
                    name="needs-work"
                    className="btn"
                    callback={() => null}
                    size={25}
                />
            </SpacerDiv>
        </ReviewFrameHeaderWrapper>
    );
};

export default ReviewFrameHeader;

const ReviewFrameHeaderWrapper = styled.div`
    display: flex;

    .review-profil-img {
        margin: auto 0;
    }
`;

const SpacerDiv = styled.div`
    display: flex;
    width: 50%;
    .btn {
        margin-right: 0.5rem;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;
