/* eslint-disable import/no-relative-packages */
// globalStyle.js
import { createGlobalStyle } from 'styled-components';
import { transition, hexToRGBA } from './variables';
import { ThemeProps } from '../interfaces/globals/theme';

// prettier-ignore
const GlobalStyle = createGlobalStyle<ThemeProps>`
  html {
    height: 100%;
  }

  body {
    margin: 0;
    height: 100%;
    font-family: "Segoe UI", Arial, sans-serif;
    -webkit-font-smoothing: antialiased;
    -moz-osx-font-smoothing: grayscale;
    font-size: 1rem;

    .styledScrollBar {
      scrollbar-width: thin !important;
      scrollbar-color: grey light-grey !important;

      ::-webkit-scrollbar-track{
        -webkit-box-shadow: inset 0 0 6px rgba(240,240,240);
      }

      ::-webkit-scrollbar {
        width: 5px;
      }

      ::-webkit-scrollbar-thumb {
        background-color: #CDCDCD;
      }
    }
  }

  code {
    font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New', monospace;
  }

  p {
    margin-bottom: 1rem;
  }

  svg.ok {
    stroke: green;
    fill: green;
  }

  svg.warn {
      stroke: ${props => props.theme.$colorPrimary};
      fill: ${props => props.theme.$colorPrimary};
  }

  .error {
    color: ${props => props.theme.$colorDanger};
  }

  *:focus {
    outline: ${props => props.theme.$outline.grey.thin[90]};
  }

  a {
    ${transition('color', 'slow', 'linear')}
    color: ${props => props.theme.$colorPrimary};
    text-decoration: none;

    &:hover {
      color: ${props => props.theme.$colorSecondary};
    }
  }

  h3 {
    font-weight: 600;
  }

  .h1,
  .h2,
  .h3,
  .h4,
  .h5,
  .h6 {
    text-transform: uppercase;
  }

  .h1 {
    font-size: ${props => props.theme.$headerStyles.small.h1.fontSize}rem;
    @media (min-width: ${props => props.theme.$breakpoints.medium}px) {
      font-size: ${props => props.theme.$headerStyles.medium.h1.fontSize}rem;
    }

    @media (min-width: ${props => props.theme.$breakpoints.xlarge}px) {
      font-size: ${props => props.theme.$headerStyles.xlarge.h1.fontSize}rem;
    }
  }

  .h2 {
    font-size: ${props => props.theme.$headerStyles.small.h2.fontSize}rem;

    @media (min-width: ${props => props.theme.$breakpoints.medium}px) {
      font-size: ${props => props.theme.$headerStyles.medium.h2.fontSize}rem;
    }

    @media (min-width: ${props => props.theme.$breakpoints.xlarge}px) {
      font-size: ${props => props.theme.$headerStyles.xlarge.h2.fontSize}rem;
    }
  }

  .h3 {
    font-size: ${props => props.theme.$headerStyles.small.h3.fontSize}rem;

    @media (min-width: ${props => props.theme.$breakpoints.medium}px) {
      font-size: ${props => props.theme.$headerStyles.medium.h3.fontSize}rem;
    }

    @media (min-width: ${props => props.theme.$breakpoints.xlarge}px) {
      font-size: ${props => props.theme.$headerStyles.xlarge.h3.fontSize}rem;
    }
  }

  .h4 {
    font-size: ${props => props.theme.$headerStyles.small.h4.fontSize}rem;

    @media (min-width: ${props => props.theme.$breakpoints.medium}px) {
      font-size: ${props => props.theme.$headerStyles.medium.h4.fontSize}rem;
    }

    @media (min-width: ${props => props.theme.$breakpoints.xlarge}px) {
      font-size: ${props => props.theme.$headerStyles.xlarge.h4.fontSize}rem;
    }
  }

  .h5 {
    font-size: ${props => props.theme.$headerStyles.small.h5.fontSize}rem;

    @media (min-width: ${props => props.theme.$breakpoints.medium}px) {
      font-size: ${props => props.theme.$headerStyles.medium.h5.fontSize}rem;
    }

    @media (min-width: ${props => props.theme.$breakpoints.xlarge}px) {
      font-size: ${props => props.theme.$headerStyles.xlarge.h5.fontSize}rem;
    }
  }

  .h6 {
    font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;

    @media (min-width: ${props => props.theme.$breakpoints.medium}px) {
      font-size: ${props => props.theme.$headerStyles.medium.h6.fontSize}rem;
    }

    @media (min-width: ${props => props.theme.$breakpoints.xlarge}px) {
      font-size: ${props => props.theme.$headerStyles.xlarge.h6.fontSize}rem;
    }
  }

  .p {
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;

    @media (min-width: ${props => props.theme.$breakpoints.medium}px) {
      font-size: ${props => props.theme.$headerStyles.medium.p.fontSize}rem;
    }
  }

  .errorPageWrapper {
    align-self: center;
    display: flex;
    flex-wrap: wrap;
  }

  .errorPage {
    align-content: center;
    display: flex;
    justify-content: center;
    margin: 0 auto;
    max-width: 40rem;
    height: 100%;

    &__message {
      align-self: center;
      flex: 1 1 0;
      min-width: 15rem;
    }

    &__icon {
      min-width: 5rem;
    }
  }

  .mt-3 {
    margin-top: 3rem;
  }

  .mt-5 {
    margin-top: 5rem;
  }

  .mb-0 {
    margin-bottom: 0 !important;
  }

  .mb-1 {
    margin-bottom: ${props => props.theme.$globalMargin};
  }

  .mb-2 {
    margin-bottom: 2rem;
  }

  .mb-3 {
    margin-bottom: 3rem;
  }

  .mb-5 {
    margin-bottom: 5rem;
  }

  .mr-3 {
    margin-right: 3rem;
  }

  .ml-1 {
    margin-left: 1rem;
  }

  .ml-3 {
    margin-left: 3rem;
  }

  .ReactModal__Overlay {
    background-color: ${props => hexToRGBA(props.theme.$colorBlack, .75)} !important;
    z-index: 997;
  }

  .star-ratings {
    margin-bottom: 1rem;
  }

  input[type='date'] {
    &.placeholder {
      color: ${props => props.theme.$colorGrey30};
      content: attr(placeholder);
    }

    &:read-only.placeholder {
      color: transparent;
    }
  }

  input[type='file'] {
    height: 100%;
    left: 0;
    margin-bottom: 0;
    opacity: 0;
    position: absolute;
    top: 0;
    width: 100%;
    z-index: -1;
  }

  label {
    font-size: inherit;
    color: inherit;
    display: block;
    line-height: inherit;
    position: relative;
    margin-bottom: 1rem;
    font-weight: inherit;

    > span {
      display: block;
      margin-bottom: .3rem;
    }

    input {
      margin-top: 6px;
      position: relative;
      background-color: ${props => props.theme.$colorGrey10};
      border: none;
    }
  }

  button {
    &:hover {
      cursor: pointer;
    }
  }

  .min-height {
    min-height: calc(100vh - ${props => props.theme.$footerHeight});
    padding-top: 2rem;
    padding-bottom: .4rem;
  }

  .accordion {
    &__item {
      border: $border-black-1;
      padding: 1rem;
    }

    &__heading {
      display: flex;
      justify-content: space-between;
    }

    &__button {
      position: relative;
      margin-right: .5rem;

      &::after {
        content: 'v';
        position: relative;
        right: -.5rem;
      }
    }
  }

  .scrollcontainer {
    overflow-x: auto;
    overflow-y: scroll;
    flex: 1;
    box-sizing: border-box;
  }

  .flagSelected {
    img {
        margin: auto 0 auto auto;
        height: 1.4rem;
        width: 1.4rem;
    }
  }

.flagLabel {
  font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;
  display: flex;
  flex-direction: row;

  img {
    margin: auto 0.2rem;
    height: 1rem;
    width: 1rem;
  }

  .gridParent {
    display: grid;
    grid-template-columns: max-content auto;
    grid-gap: 0.1rem;
  }

}
`;

export default GlobalStyle;
