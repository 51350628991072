import React, { FunctionComponent, ReactElement } from 'react';

interface InputProps {
    name: string;
    fill?: string;
    stroke?: string;
    size?: string;
    className?: string;
}

const path = '/icn/sprite.svg';

const Icon: FunctionComponent<InputProps> = ({
    name,
    fill,
    stroke,
    size = '16',
    className,
}): ReactElement => (
    <svg
        xmlns="http://www.w3.org/2000/svg"
        xmlnsXlink="http://www.w3.org/1999/xlink"
        viewBox={`0 0 ${size} ${size}`}
        width={`${size}px`}
        height={`${size}px`}
        fill={fill}
        stroke={stroke}
        className={`c-icon ${className || ''}`}
    >
        <use
            xmlns="http://www.w3.org/2000/svg"
            xmlnsXlink="http://www.w3.org/1999/xlink"
            width={`${size}px`}
            height={`${size}px`}
            xlinkHref={`${path}#icon-${name}`}
        />
    </svg>
);

export default Icon;
