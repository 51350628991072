import HtmlDiff from 'htmldiff-js';

/**
 * Returns the body of an html file as a string.
 *
 * @param {string} html Input file (HTML string)
 *
 * @returns Body as a string
 */
function extractBody(html) {
    const doc = new DOMParser().parseFromString(html, 'text/html');
    const body = doc.querySelector('body');

    if (body) return body.outerHTML;
    return '';
}

/**
 * Returns the new HTML file with the replaced body from input.
 *
 * @param {string} htmlFile
 * @param {string} newBody
 *
 * @returns HTML file with the replaced body
 */
function replaceBody(htmlFile, newBody) {
    const parser = new DOMParser();
    const doc = parser.parseFromString(htmlFile, 'text/html');
    const oldBody = doc.querySelector('body');

    if (!oldBody) throw new Error('No <body> tag found in HTML file.');

    oldBody.parentNode.replaceChild(
        parser.parseFromString(newBody, 'text/html').querySelector('body'),
        oldBody
    );

    return doc;
}

/**
 * Returns the HTML file without the summary.
 *
 * @param {string} htmlFile
 * @param {string} className
 *
 * @returns the HTML file without the summary
 */
// eslint-disable-next-line no-unused-vars
function removeElementFromHtml(htmlFile, className) {
    const elementToRemove = htmlFile.querySelector(`.${className}`);

    if (elementToRemove) elementToRemove.remove();

    return htmlFile;
}

/**
 * Creates a diff between two HTML files.
 *
 * @param {string} oldHtml
 * @param {string} newHtml
 *
 * @returns the new HTML file
 */
function createHtmlDiffView(oldHtml, newHtml) {
    const oldBody = extractBody(oldHtml);
    const newBody = extractBody(newHtml);

    const htmlWithReplacedBody = replaceBody(newHtml, HtmlDiff.execute(newBody, oldBody));

    return htmlWithReplacedBody;
}

export default createHtmlDiffView;
