import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';

interface ProgressBarProps {
    progress: number;
}

const ProgressBar: FunctionComponent<ProgressBarProps> = ({ progress }): ReactElement => (
    <ProgressBarWrapper>
        <ProgressBarDiv>
            <ProgressBarFill style={{ width: `${progress}%` }}>{progress}%</ProgressBarFill>
        </ProgressBarDiv>
    </ProgressBarWrapper>
);

const ProgressBarWrapper = styled.div<ThemeProps>`
    width: 190px;
    margin: 5px;
`;

const ProgressBarDiv = styled.div<ThemeProps>`
    width: 100%;
    background-color: #e0e0e0;
    padding: 3px;
    border-radius: 3px;
    box-shadow: inset 0 1px 3px rgba(0, 0, 0, 0.2);
`;

const ProgressBarFill = styled.span<ThemeProps>`
    display: block;
    height: 12px;
    background-color: #f29400;
    color: white;
    border-radius: 3px;
    text-align: center;
    font-size: 8px;

    transition: width 500ms ease-in-out;
`;

export default ProgressBar;
