import { ThumbnailProps, VersionProps } from '../interfaces/DocumentEntry';
import { EErrorTypes } from '../interfaces/globals/errors';
import { ITenant } from '../store/config/types';
import { addError, finishOperation, startOperation } from '../store/global/actions';
import { ResponseError } from '../typings/error';
import { BoolSwapState } from '../typings/global';
import { TranslatedImage, UploadImage } from '../typings/image';
import AxiosClient, { requestURLs } from './axios';
import { t } from './language';

/**
 * Converts the given {@link Date} object to a human readable form based on the current locale.
 *
 * @param {Date} date the date to be converted
 * @param {boolean} includeTime a boolean flag to indicate whether the time should be added during the conversion
 * @returns {string} the human readable representation of the given date or '-/-' if the given date is null
 */
export function makeTimeStampReadable(date: Date | null, includeTime = true) {
    const locale = 'de-DE';
    let innerDate = date;

    if (date !== null) {
        if (typeof date === 'string') {
            innerDate = new Date(date);
        }
        if (innerDate !== null) {
            if (includeTime)
                return innerDate.toLocaleDateString(locale, {
                    year: 'numeric',
                    month: '2-digit',
                    day: '2-digit',
                    hour: '2-digit',
                    minute: '2-digit',
                });
            return innerDate.toLocaleDateString(locale, {
                year: 'numeric',
                month: '2-digit',
                day: '2-digit',
            });
        }
    }
    return '-/-';
}

/**
 * Validates the given permissions against given required permissions for the given tenant.
 * If multiple permissions are given this method will check if there is a single match.
 *
 * Multiple required permissions are combined using logical 'OR'.
 *
 * @param {string} tenant the name of the tenant
 * @param {{}} currentPermissions the permissions to be validated
 * @param {string[]} requiredPermissions the required permissions
 * @returns true if the given permissions match the required permissions and false otherwise
 */
export function validatePermissions(
    tenant: ITenant | null,
    currentPermissions: { [tenant: string]: string[] },
    requiredPermissions: string[]
): boolean {
    // default to true if no special permissions are needed
    let accessGranted = requiredPermissions.length === 0;
    // check for tenant rights
    if (!accessGranted) {
        if (tenant && currentPermissions[tenant.name.toUpperCase()]) {
            currentPermissions[tenant.name.toUpperCase()].forEach(p => {
                if (requiredPermissions.indexOf(p.toUpperCase()) >= 0) accessGranted = true;
            });
        }
    }
    // check for admin rights
    if (!accessGranted && currentPermissions.Global)
        accessGranted = currentPermissions.Global.indexOf('ADMIN') > 0;
    return accessGranted;
}

/**
 * This method reduces a deep object to a single level of key: value pairs.
 *
 * @param obj the object whose attributes should be flattened
 * @returns the flattend object
 */
// eslint-disable-next-line @typescript-eslint/no-explicit-any
export function flattenObjectKeys(obj: any): { [key: string]: any } {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    const flattenedObject: { [key: string]: any } = {};

    if (typeof obj === 'object') {
        // eslint-disable-next-line no-restricted-syntax
        for (const i in obj) {
            // eslint-disable-next-line no-prototype-builtins, no-continue, @typescript-eslint/no-explicit-any
            if (!(obj as Record<string, any>).hasOwnProperty(i)) continue;

            if (typeof obj === 'object' && obj[i] !== null) {
                const fobj = flattenObjectKeys(obj[i]);
                if (Object.keys(fobj).length > 0) {
                    // eslint-disable-next-line no-restricted-syntax
                    for (const x in fobj) {
                        // eslint-disable-next-line no-prototype-builtins
                        if ((fobj as Record<string, unknown>).hasOwnProperty(x))
                            flattenedObject[`${i}.${x}`] = fobj[x];
                    }
                } else {
                    flattenedObject[i] = obj[i];
                }
            } else {
                flattenedObject[i] = obj[i];
            }
        }
    }
    return flattenedObject;
}

/**
 * This returns a single element from the given array, if the given keys matches the
 * given needle.
 *
 * @param array the array to search in
 * @param key the name of the key that has to be matched
 * @param needle the value that the key should have
 * @returns the matching element from the array or null
 */
export function getObjectWithMatchingKeyFromArray<T, K extends keyof T>(
    array: Array<T>,
    key: K,
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    needle: any
): T | null {
    for (let x = 0; x < array.length; x += 1) {
        const obj = array[x];
        if (obj[key] === needle) {
            return obj;
        }
    }
    return null;
}

/**
 * This returns the greater of the two given version strings. For comparison the
 * string is splitted at the version separator (.). Each part of the version has
 * to be number or will just be string compared.
 *
 * @param v1 the first version
 * @param v2 the second version
 * @return the bigger of the two versions (or the second one, if they are identical)
 */
export function getBiggerVersion(v1: string, v2: string) {
    const a1 = v1.split('.');
    const a2 = v2.split('.');

    for (let i = 0; i < a1.length; i += 1) {
        if (a2[i] !== undefined) {
            if (a1[i] > a2[i]) return v1;
            if (a1[i] < a2[i]) return v2;
        } else return v1;
    }
    return v2;
}

/**
 * This function takes an array buffer and tunrs it in a base64 encoded
 * string.
 *
 * @param buffer the array buffer that needs to be a base64 string
 * @return base64 string
 */
function arrayBufferToBase64(buffer: ArrayBuffer) {
    let binary = '';
    const bytes = new Uint8Array(buffer);
    const len = bytes.byteLength;
    for (let i = 0; i < len; i += 1) {
        binary += String.fromCharCode(bytes[i]);
    }
    return window.btoa(binary);
}

/**
 * Checks the mafic number from the file header to get the right
 * file extension
 *
 * @param signature magic number from file header
 * @return base64 string
 */
function getTypeFromMagicNumber(signature: string) {
    switch (signature) {
        case '89504e47':
            return 'image/png';
        case 'ffd8ffe0':
        case 'ffd8ffe1':
        case 'ffd8ffe2':
        case 'ffd8ffe3':
        case 'ffd8ffe8':
            return 'image/jpeg';
        case '3c3f786d':
        case '3c737667':
            return 'image/svg+xml';
        default:
            return 'unknown';
    }
}

/**
 * Reads the given data (imageData || transferData) as data url
 * and uploads this as an UploadImage type
 *
 * @param title name of the image
 * @param reloadData function to start a rerender
 * @param dispatch function dispatch state changes
 * @param imageData file data
 * @param transferData file data
 * @return void
 */
export function handleImageUpload(
    title: string,
    reloadData: BoolSwapState,
    transferData: FileList,
    dispatch: Function,
    addToArticle?: Function,
    updateImageID?: number
) {
    if (transferData)
        Array.from(transferData).forEach(file => {
            if (file) {
                const uploadImage: UploadImage = {
                    title: 'no title',
                    data: undefined,
                };
                uploadImage.title = title;
                if (uploadImage.title !== 'no title') {
                    const reader = new FileReader();
                    reader.onabort = () =>
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                t('StatusCode.SpeacialCase.ImageReadFailed')
                            )
                        );
                    reader.onerror = () =>
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                t('StatusCode.SpeacialCase.ImageReadFailed')
                            )
                        );
                    reader.onload = () => {
                        if (reader.result !== null && reader.result instanceof ArrayBuffer) {
                            const arr = new Uint8Array(reader.result).subarray(0, 4);
                            let signature = '';

                            for (let i = 0; i < arr.length; i += 1) {
                                signature += arr[i].toString(16);
                            }

                            const fileType = getTypeFromMagicNumber(signature);

                            if (fileType !== 'unknown') {
                                const dataURL = `data:${fileType};base64,${arrayBufferToBase64(
                                    reader.result
                                )}`;

                                uploadImage.data = dataURL;
                                if (uploadImage.data && !updateImageID) {
                                    dispatch(startOperation());
                                    AxiosClient.post(`${requestURLs.images}`, uploadImage)
                                        .then(response => {
                                            const data = response.data as ThumbnailProps;
                                            if (addToArticle) {
                                                addToArticle(data);
                                            } else reloadData.set(true);
                                        })
                                        .catch(error => {
                                            dispatch(
                                                addError(
                                                    EErrorTypes.error,
                                                    (error.response.data as ResponseError).status
                                                )
                                            );
                                        })
                                        .finally(() => dispatch(finishOperation()));
                                }
                                if (updateImageID) {
                                    dispatch(startOperation());
                                    AxiosClient.put(
                                        `${requestURLs.images}/${updateImageID}`,
                                        uploadImage
                                    )
                                        .then(() => reloadData.set(true))
                                        .catch(error => {
                                            dispatch(
                                                addError(
                                                    EErrorTypes.error,
                                                    (error.response.data as ResponseError).status
                                                )
                                            );
                                        })
                                        .finally(() => dispatch(finishOperation()));
                                }
                            } else {
                                dispatch(
                                    addError(
                                        EErrorTypes.error,
                                        t('StatusCode.SpecialCase.DataFormat')
                                    )
                                );
                            }
                        }
                    };
                    reader.readAsArrayBuffer(file);
                }
            }
        });
}

/**
 * Uploads a new image Translation
 *
 * @param fileList new image data
 * @param title image title
 * @param reloadData triggers a reload of images
 * @param dispatch function dispatch state changes
 */
export function handleImageTranslationUpload(
    fileList: FileList,
    imageID: number,
    title: string,
    languageCode: string | undefined,
    isUpdate: boolean,
    reloadData: Function,
    dispatch: Function
) {
    if (fileList && languageCode) {
        Array.from(fileList).forEach(file => {
            const newTitle = `${title}_${languageCode}`;
            const translatedImage: TranslatedImage = {
                imageID,
                title: newTitle,
                languageCode,
                imageData: undefined,
            };

            const reader = new FileReader();
            reader.onabort = () =>
                dispatch(addError(EErrorTypes.error, t('StatusCode.SpeacialCase.ImageReadFailed')));
            reader.onerror = () =>
                dispatch(addError(EErrorTypes.error, t('StatusCode.SpeacialCase.ImageReadFailed')));
            reader.onload = () => {
                if (reader.result !== null && reader.result instanceof ArrayBuffer) {
                    const arr = new Uint8Array(reader.result).subarray(0, 4);
                    let signature = '';

                    for (let i = 0; i < arr.length; i += 1) {
                        signature += arr[i].toString(16);
                    }

                    const fileType = getTypeFromMagicNumber(signature);

                    if (fileType !== 'unknown') {
                        const dataURL = `data:${fileType};base64,${arrayBufferToBase64(
                            reader.result
                        )}`;
                        translatedImage.imageData = dataURL;
                        if (!isUpdate) {
                            dispatch(startOperation());
                            AxiosClient.post(`${requestURLs.imageTranslations}`, translatedImage)
                                .then(() => reloadData(true))
                                .catch(error => {
                                    dispatch(
                                        addError(
                                            EErrorTypes.error,
                                            (error.response.data as ResponseError).status
                                        )
                                    );
                                })
                                .finally(() => dispatch(finishOperation()));
                        }
                    } else {
                        dispatch(
                            addError(EErrorTypes.error, t('StatusCode.SpecialCase.DataFormat'))
                        );
                    }
                }
            };
            reader.readAsArrayBuffer(file);
        });
    }
}

export function downloadFile(filePath: string) {
    const link = document.createElement('a');
    link.href = filePath;
    link.download = filePath.substring(filePath.lastIndexOf('/') + 1);
    link.click();
}

/**
 * Compares two version strings.
 *
 * @param {string} a - The first version string to compare.
 * @param {string} b - The second version string to compare.
 *
 * @returns {number} - A negative number. If a < b, a positive number. if a > b, or 0 if a and b are equal.
 */
export function compareVersions(a: string, b: string): number {
    const aParts = a.split('.');
    const bParts = b.split('.');

    for (let i = 0; i < Math.max(aParts.length, bParts.length); i += 1) {
        const aNum = parseInt(aParts[i] || '0', 10);
        const bNum = parseInt(bParts[i] || '0', 10);

        if (aNum !== bNum) return aNum - bNum;
    }

    return 0;
}

/**
 * Returns the object with the second largest productVersion value or null.
 *
 * @param versions - An array of version objects with a productVersion property
 * @returns An object with the second largest productVersion value
 */
export function getSecondHighestVersion(versions: VersionProps[]) {
    let largest: string | null = null;
    let secondLargest: string | null = null;

    versions.forEach(versionObj => {
        const version = versionObj.productVersion;

        if (!largest || compareVersions(version, largest) > 0) {
            secondLargest = largest;
            largest = version;
        } else if (!secondLargest || compareVersions(version, secondLargest) > 0) {
            secondLargest = version;
        }
    });

    return secondLargest ? versions.find(v => v.productVersion === secondLargest) || null : null;
}

export function getHighestVersion(products: VersionProps[]): VersionProps | null {
    if (products.length === 0) return null;

    let maxProduct = products[0];

    for (let i = 1; i < products.length; i += 1) {
        const product = products[i];

        if (compareVersions(product.productVersion, maxProduct.productVersion) > 0) {
            maxProduct = product;
        }
    }

    return maxProduct;
}
