// eslint-disable-next-line no-shadow
enum EDocumentState {
    Planning = 0,
    Preparing = 10,
    Draft = 15,
    Review = 20,
    Finalizing = 30,
    Published = 40,
    Revoked = 90,
    Incorrect = 100,
}

export default EDocumentState;
