import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { FormFieldProps } from '../interfaces/FormField';

export interface TextAreaFieldProps {
    defaultChecked?: boolean;
    placeholder?: string;
}

const TextAreaField: FunctionComponent<FormFieldProps & TextAreaFieldProps> = ({
    id,
    value,
    defaultChecked,
    autoFocus,
    placeholder,
    readOnly,
    disabled,
    label,
    onBlur,
    onFocus,
    onChange,
    onClick,
    tab,
}): ReactElement => (
    <TextArea
        defaultValue={value}
        id={id}
        name={id}
        defaultChecked={defaultChecked}
        autoFocus={autoFocus}
        hasPlaceholder={false}
        placeholder={placeholder}
        readOnly={readOnly}
        disabled={disabled}
        title={label}
        data-testid={id}
        onBlur={onBlur}
        onFocus={onFocus}
        onChange={onChange}
        onClick={onClick}
        data-tabid={tab}
    />
);

interface TextAreaStyleProps {
    hasPlaceholder: boolean;
}

const TextArea = styled.textarea<ThemeProps & TextAreaStyleProps>`
    background-color: ${props => props.theme.$colorGrey10};
    margin: 0.4rem 1rem;
    min-height: 3rem;
    line-height: 1.1rem;
    padding: 0.3rem 0.1rem;

    &::placeholder {
        color: ${props => props.theme.$colorGrey30};
    }

    &::read-only {
        color: transparent;
    }
`;

export default TextAreaField;
