import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';
import { useGlobalStore } from '../../../../../hooks/hooks';
import { TranslationEntry } from '../../../../../interfaces/DocumentEntry';
import { TranslationStatus } from '../../../../../interfaces/globals/job';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import { BoolSwapState, StringState } from '../../../../../typings/global';
import EditorBar from '../../editor/editorBar/EditorBar';
import EditorBarGroup from '../../editor/editorBar/editorBarGroup/EditorBarGroup';
import Spacer from '../../editor/editorBar/editorBarGroup/Spacer';

interface TranslationToolbarProps {
    translation: TranslationEntry | undefined;
    callback: Function;
    lineBreakState: StringState;
    showDiffModal: BoolSwapState;
}

const TranslationToolbar: FunctionComponent<TranslationToolbarProps> = ({
    translation,
    callback,
    lineBreakState,
    showDiffModal,
}) => {
    const { state } = useGlobalStore();

    const [previousStatus, setPreviousStatus] = useState<TranslationStatus>(
        translation ? translation.status : TranslationStatus.PUBLISHED
    );

    useEffect(() => {
        if (translation && previousStatus !== translation.status)
            setPreviousStatus(translation.status);
    }, [translation, previousStatus]);

    function buildActualTranslationStatus(status: TranslationStatus): string {
        const text = 'Aktueller Status der Übersetzung: ';
        let statusAsString;

        switch (status) {
            case TranslationStatus.DRAFT:
                statusAsString = 'Entwurf';
                break;

            case TranslationStatus.VALIDATED:
                statusAsString = 'Validiert';
                break;

            case TranslationStatus.PUBLISHED:
                statusAsString = 'Publiziert';
                break;

            case TranslationStatus.OUTDATED:
                statusAsString = 'Veraltet';
                break;

            default:
                statusAsString = 'Fehler';
                break;
        }

        return text + statusAsString;
    }

    function buildTranslationToolBarButtons(t: TranslationEntry) {
        const elements: ReactElement[] = [];

        elements.push(
            <Spacer>
                <EditorBarGroup border>
                    <ActionControlButton
                        size={20}
                        prefix="ce"
                        name="history"
                        callback={() => showDiffModal.set(!showDiffModal.value)}
                    />
                </EditorBarGroup>
                <EditorBarGroup border>
                    <ActionControlButton
                        className={lineBreakState.value ? 'activePref' : 'inactivePref'}
                        size={20}
                        prefix="ce"
                        name="linebreak"
                        callback={() =>
                            lineBreakState.set(lineBreakState.value === 'on' ? 'off' : 'on')
                        }
                    />
                </EditorBarGroup>
            </Spacer>
        );
        elements.push(
            <Spacer>
                <p className="actual-translation-status">
                    {buildActualTranslationStatus(t.status)}
                </p>
                <ToolbarButton
                    id="btn_save_translation"
                    type="button"
                    onClick={() => callback(15)}
                    disabled={false}
                >
                    {t.status === TranslationStatus.PUBLISHED ||
                    t.status === TranslationStatus.VALIDATED ||
                    t.status === TranslationStatus.OUTDATED ? (
                        <p>Entwurf</p>
                    ) : (
                        <p>Speichern</p>
                    )}
                </ToolbarButton>
                <ToolbarButton
                    type="button"
                    onClick={() => callback(35)}
                    disabled={
                        t.status === TranslationStatus.PUBLISHED ||
                        t.status === TranslationStatus.VALIDATED ||
                        state.documentManager.currentArticle.status !== 40
                    }
                >
                    <p>Validiert</p>
                </ToolbarButton>
            </Spacer>
        );

        return elements;
    }

    return (
        <ToolbarButtonWrapper>
            {translation && <EditorBar>{buildTranslationToolBarButtons(translation)}</EditorBar>}
        </ToolbarButtonWrapper>
    );
};

export default TranslationToolbar;

export const ToolbarButtonWrapper = styled.div<ThemeProps>`
    h3 {
        margin: 0;
        padding: 0;
    }

    .info-text-translation {
        margin: 0;
        padding: 0;

        font-size: 0.9rem;
    }

    .actual-translation-status {
        margin: auto 0;
        font-size: 0.9rem;
    }
`;

export const ToolbarButton = styled.button<ThemeProps & { disabled: boolean }>`
    margin: 0 5px 0 5px;
    cursor: pointer;
    outline: 0;
    display: inline-block;
    font-weight: 400;
    line-height: 1.5;
    text-align: center;
    background-color: transparent;
    border: 1px solid transparent;
    padding: 5px 10px;
    font-size: 0.7rem;
    border-radius: 0.25rem;
    transition: color 0.15s ease-in-out, background-color 0.15s ease-in-out,
        border-color 0.15s ease-in-out, box-shadow 0.15s ease-in-out;
    color: ${props => (props.disabled ? props.theme.$colorSecondary : props.theme.$colorPrimary)};
    border-color: ${props =>
        props.disabled ? props.theme.$colorSecondary : props.theme.$colorPrimary}};

    :hover {
        color: ${props => (props.disabled ? '' : '#fff')};
        background-color: ${props => (props.disabled ? '' : props.theme.$colorPrimary)};
        border-color: ${props => (props.disabled ? '' : props.theme.$colorPrimary)};
    }

    p {
        margin: 0;
        padding: 0;
    }
`;
