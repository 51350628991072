import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';
import { ThumbnailPageProps } from '../../../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import { PageNumberState } from '../../../../../typings/global';

interface PageNavigationProps {
    thumbnailPages: ThumbnailPageProps;
    currentPageNumberState: PageNumberState;
}

const PageNavigation: FunctionComponent<PageNavigationProps> = ({
    thumbnailPages,
    currentPageNumberState,
}) => (
    <ImageSliderButtonWrapper>
        <ActionControlButton
            prefix="image"
            name="arrow-left"
            className="swp-btn"
            size={50}
            callback={() => currentPageNumberState.set(currentPageNumberState.value - 1)}
            fill
            disabled={thumbnailPages.first}
        />
        <p>
            {thumbnailPages.totalPages <= 1
                ? `${thumbnailPages.number + 1}`
                : `${thumbnailPages.number + 1} von ${thumbnailPages.totalPages}`}
        </p>
        <ActionControlButton
            prefix="image"
            name="arrow-right"
            className="swp-btn"
            size={50}
            callback={() => currentPageNumberState.set(currentPageNumberState.value + 1)}
            fill
            disabled={thumbnailPages.last}
        />
    </ImageSliderButtonWrapper>
);

export default PageNavigation;

const ImageSliderButtonWrapper = styled.div<ThemeProps>`
    display: flex;
    position: relative;
    bottom: 0;

    p {
        width: 100%;
        text-align: center;
    }

    .swp-btn {
        margin: 0;
        border: none;
        background-color: inherit;
        padding: 0;
        height: 0;
    }
`;
