/* eslint-disable import/no-named-as-default */
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import { useGlobalStore } from '../../../../hooks/hooks';
import { TranslationEntry } from '../../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { TranslationStatus } from '../../../../interfaces/globals/job';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import loadTranslations from '../../../../lib/app/translation';
import Modal from '../../../../components/dialogs/Modal/Modal';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { t } from '../../../../lib/language';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { ResponseError } from '../../../../typings/error';
import ArticlesAudit from '../articleAudit/ArticleAudit';
import TranslationDiffEditor from './TranslationEditors/TranslationDiffEditor';
import TranslationEditor from './TranslationEditors/TranslationEditor';
import TranslationToolbar from './TranslationToolbar/TranslationToolbar';
import { ArticleDiffState } from '../../../../typings/global';

interface TranslationValidatiobProps {
    reloadPreview: Function;
    articleDiffData: ArticleDiffState;
}

interface Translation {
    content: string;
    status: number;
    validator: string;
}

const TranslationValidation: FunctionComponent<TranslationValidatiobProps> = ({
    reloadPreview,
    articleDiffData,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const { currentDescription, currentVersion, currentArticle } = state.documentManager;
    const [currentTranslation, setCurrentTranslation] = useState<TranslationEntry | undefined>();
    const [editedTranslation, setEditedTranslation] = useState<Translation | undefined>();
    const [changedTranslationText, setChangedTranslationText] = useState<string>('');
    const [reloadArticleDiff, setReloadArticleDiff] = useState<boolean>(false);
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [showArticleAudit, setShowArticleAudit] = useState<boolean>(false);
    const [currentSelectedAudience, setCurrentSelectedAudience] = useState<string>('');
    const [isLineBreak, setLineBreak] = useState<
        'off' | 'on' | 'wordWrapColumn' | 'bounded' | undefined
    >('on');

    const countWords = (s: string): number => {
        let text = s;
        text = text.replace(/\n/, ' ').replace(/\s+/gi, ' ');
        const count = text.split(' ').filter(str => str !== '').length;

        return currentVersion.articles.length > 1 ? count : Math.ceil(count / 100) * 100;
    };

    const countWordsOfVersion = (): number => {
        let versionWordCount = 0;
        if (currentVersion) {
            currentVersion.articles.forEach(a => {
                versionWordCount += countWords(a.content);
            });
        }
        return Math.ceil(versionWordCount / 100) * 100;
    };

    const translationProgress = () => {
        let progress = 0;
        if (currentVersion) {
            const lang = state.documentManager.currentDescription.languageCode;
            const step = 100 / currentVersion.articles.length;

            currentVersion.articles.forEach(a => {
                if (state.documentManager.currentTranslations[a.articleID]) {
                    const translation = state.documentManager.currentTranslations[a.articleID].find(
                        tr => tr.languageCode === lang
                    );
                    if (
                        translation &&
                        (translation.status === TranslationStatus.VALIDATED ||
                            translation.status === TranslationStatus.PUBLISHED)
                    )
                        progress += step;
                }
            });
        }
        return Math.round(progress);
    };

    function keyDownEventListener(event: KeyboardEvent) {
        if (event.ctrlKey) {
            if (event.key === 's') {
                event.preventDefault();
                event.stopPropagation();
                // eslint-disable-next-line no-unused-expressions
                document.getElementById('btn_save_translation')?.click();
            }
        }
    }

    useEffect(() => {
        document.addEventListener('keydown', event => keyDownEventListener(event));

        return () => {
            document.removeEventListener('keydown', event => keyDownEventListener(event));
        };
    }, []);

    useEffect(() => {
        if (!currentTranslation || currentTranslation.articleID !== currentArticle.articleID) {
            dispatch(startOperation());
            const params = `${currentArticle.articleID}?langID=${currentDescription.languageID}`;
            AxiosClient.get(`${requestURLs.translations}article/${params}`)
                .then(response => {
                    const entry: TranslationEntry = response.data;
                    setCurrentTranslation(entry);
                    setEditedTranslation({
                        content: entry.content,
                        status: entry.status,
                        validator: entry.validator,
                    });
                    setChangedTranslationText(entry.content);
                })
                .finally(dispatch(finishOperation()));
        }
    }, [currentArticle.articleID, currentDescription.languageID, currentTranslation, dispatch]);

    function persistTranslation(updatedTranslation: Translation | undefined) {
        if (updatedTranslation && currentTranslation) {
            dispatch(startOperation());
            AxiosClient.put(
                `${requestURLs.translations}${currentTranslation.translationID}?version_id=${currentVersion.versionID}`,
                updatedTranslation
            )
                .then(response => {
                    setCurrentTranslation(response.data);
                    loadTranslations(dispatch, currentVersion.versionID);
                    dispatch(
                        addError(EErrorTypes.info, t('StatusCode.SpecialCase.PersistTranslation'))
                    );
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => {
                    dispatch(finishOperation());
                });
        }
    }

    function setTranslationStatus(status: number) {
        if (
            editedTranslation &&
            currentTranslation &&
            Object.values(TranslationStatus).includes(status) &&
            (currentTranslation.status !== status ||
                (currentTranslation.status === TranslationStatus.DRAFT &&
                    changedTranslationText !== ''))
        ) {
            let skipConfirmation = false;

            if (
                editedTranslation.status === TranslationStatus.DRAFT &&
                status === TranslationStatus.DRAFT
            )
                skipConfirmation = true;

            const updatedTranslation: Translation = {
                content:
                    changedTranslationText !== ''
                        ? changedTranslationText
                        : editedTranslation.content,
                status,
                validator:
                    currentTranslation.status !== TranslationStatus.VALIDATED &&
                    status === TranslationStatus.VALIDATED
                        ? state.user.fullName
                        : currentTranslation.validator,
            };

            setEditedTranslation(updatedTranslation);

            if (!skipConfirmation) setShowConfirmationDialog(true);
            else persistTranslation(updatedTranslation);

            reloadPreview();
        }
    }

    return (
        <Wrapper>
            <HeaderWrapper>
                <h3>
                    Validierungsoberfläche -{' '}
                    <span>{` Übersetzungsfortschritt: ${translationProgress()}%.`}</span>
                </h3>
                <span>{` Anzahl der Wörter in dieser Version: ~${countWordsOfVersion()}.`}</span>
                <span>{` Anzahl der Wörter in diesem Artikel: ~${countWords(
                    currentArticle.content
                )}.`}</span>
            </HeaderWrapper>
            <ValidationWrapper>
                <TranslationToolbar
                    translation={currentTranslation}
                    callback={setTranslationStatus}
                    lineBreakState={{ value: isLineBreak, set: setLineBreak }}
                    showDiffModal={{ value: showArticleAudit, set: setShowArticleAudit }}
                />
                <EditorWrapper>
                    <TranslationDiffEditor
                        originalContent={articleDiffData.value?.content || currentArticle.content}
                        modifiedContent={currentArticle.content}
                        lineBreak={isLineBreak}
                    />
                    <TranslationEditor
                        translation={currentTranslation}
                        editorValueState={{
                            value: changedTranslationText,
                            set: setChangedTranslationText,
                        }}
                        readOnly={
                            !(
                                currentTranslation &&
                                currentTranslation.status === TranslationStatus.DRAFT
                            )
                        }
                        lineBreak={isLineBreak}
                    />
                </EditorWrapper>
                <Modal
                    id="article-modal"
                    isOpen={showArticleAudit}
                    shouldCloseOnOverlayClick
                >
                    <ArticlesAudit
                        articleAuditVisible={{ value: showArticleAudit, set: setShowArticleAudit }}
                        articleDiffData={articleDiffData}
                        reloadArticleAuditData={{
                            value: reloadArticleDiff,
                            set: setReloadArticleDiff,
                        }}
                        currentAudienceState={{
                            value: currentSelectedAudience,
                            set: setCurrentSelectedAudience,
                        }}
                        actionOnClick={() => setShowArticleAudit(false)}
                    />
                </Modal>
                <ConfirmationDialog
                    id="translation-action-confirmation"
                    title="Status der Übersetzung ändern"
                    message={`Wollen Sie den Status der Überstzung für den Artikel: "${currentArticle.title}" ändern?"`}
                    onCancel={() => {
                        setShowConfirmationDialog(false);
                    }}
                    onOK={() => persistTranslation(editedTranslation)}
                    isOpenState={{
                        value: showConfirmationDialog,
                        set: setShowConfirmationDialog,
                    }}
                />
            </ValidationWrapper>
        </Wrapper>
    );
};

export default TranslationValidation;

const Wrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    width: 100%;
    height: 100%;
`;

const EditorWrapper = styled.div<ThemeProps>`
    height: 92%;
    display: flex;
    flex-direction: row;
    flex: 1;
    overflow: hidden;
`;

const ValidationWrapper = styled.div<ThemeProps>`
    height: 95%;
    border: ${props => props.theme.$border.secondary.thin};
`;

const HeaderWrapper = styled.div`
    height: 5%;
    min-height: 50px;

    h3 {
        margin: 0;
    }

    p {
        margin: 0;
    }

    span {
        font-weight: 400;
        font-size: initial;
    }
`;
