import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';

interface BottonToolBarProps {
    className?: string;
    size?: string;
    children?: ReactNode;
}

const ButtonToolBar: FunctionComponent<BottonToolBarProps> = ({
    children,
    className,
    size = '20',
}): ReactElement => (
    <ToolBarWrapper
        className={`btn-tool-bar ${className || ''}`}
        size={size}
    >
        {children}
    </ToolBarWrapper>
);

const ToolBarWrapper = styled.div<ThemeProps & { size: string }>`
    width: 100%;
    height: ${props => props.size}px;
    display: flex;
    flex-direction: row-reverse;
    position: relative;
    background-color: white;
    opacity: 0;

    button {
        margin: 2px;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;

export default ButtonToolBar;
