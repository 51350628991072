import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import key from 'weak-key';
import { BoolSwapState } from '../../typings/global';
import { StyledNavButton } from '../navigation/NavButton/NavButton';
import Modal from './Modal/Modal';
import { ButtonPanel } from './SharedStyles';

export type ConfirmationDialogParams = {
    title: string;
    message: string;
    okAction?: Function;
};

export type OptionalButtonProps = {
    message: string;
    callback: Function;
};

export interface ConfirmationDialogProps {
    id: string;
    title: string;
    message: string;
    isOpenState: BoolSwapState;
    onCancelMessage?: string;
    onOK:
        | ((
              event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
          ) => void | undefined)
        | undefined;
    onCancel: (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
    ) => void | undefined;
    optionalButtons?: OptionalButtonProps[];
    children?: ReactNode;
}

const ConfirmationDialog: FunctionComponent<ConfirmationDialogProps> = ({
    id,
    isOpenState,
    title,
    message,
    onCancelMessage = 'Abbrechen',
    onCancel,
    onOK,
    optionalButtons,
    children,
}): ReactElement => {
    function renderOKButton(): ReactElement | null {
        if (onOK !== undefined) {
            return (
                <StyledNavButton
                    id={`${id}_ok_button`}
                    renderas="button"
                    onClick={event => {
                        isOpenState.set(false);
                        onOK(event);
                    }}
                >
                    OK
                </StyledNavButton>
            );
        }
        return null;
    }

    function renderOptionalButtons(): ReactElement[] | null {
        if (optionalButtons) {
            const buttons: ReactElement[] = [];
            optionalButtons.forEach(button => {
                buttons.push(
                    <StyledNavButton
                        id={`${id}_ok_button`}
                        key={key(button)}
                        renderas="button"
                        onClick={() => {
                            isOpenState.set(false);
                            button.callback();
                        }}
                    >
                        {button.message}
                    </StyledNavButton>
                );
            });
            return buttons;
        }
        return null;
    }

    return (
        <Modal
            key="testModal"
            shouldCloseOnOverlayClick={false}
            onRequestClose={onCancel}
            id={id}
            isOpen={isOpenState.value}
        >
            <h3>{title}</h3>
            {message.split('\n').map((item, i) => (
                <p key={`msg_part_${i + 1}`}>{item}</p>
            ))}
            {children}
            <ButtonPanel>
                {renderOKButton()}
                {renderOptionalButtons()}
                <StyledNavButton
                    id={`${id}_cancel_button`}
                    renderas="button"
                    autoFocus
                    onClick={event => {
                        isOpenState.set(false);
                        onCancel(event);
                    }}
                >
                    {onCancelMessage}
                </StyledNavButton>
            </ButtonPanel>
        </Modal>
    );
};

export default ConfirmationDialog;
