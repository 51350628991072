import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import Icon from '../Icon/Icon';
import { useTheme } from '../../hooks/hooks';
import { ThemeProps } from '../../interfaces/globals/theme';

interface ActionControlButtonProps {
    prefix: string;
    name: string;
    callback: (event: React.MouseEvent<HTMLButtonElement, MouseEvent>) => void;
    className?: string;
    size?: number;
    stroke?: boolean;
    fill?: boolean;
    disabled?: boolean;
    hide?: boolean;
}

const ActionControlButton: FunctionComponent<ActionControlButtonProps> = ({
    prefix,
    name,
    callback,
    className,
    size = 24,
    stroke = false,
    fill = false,
    disabled = false,
    hide = false,
}): ReactElement => {
    const theme = useTheme();

    return (
        <StyledButton
            id={`${prefix}btn_${name}`}
            title={name}
            type="button"
            onClick={callback}
            className={className}
            disabled={disabled}
            hide={hide}
        >
            <Icon
                name={name}
                size={`${size}`}
                stroke={stroke ? theme.$colorPrimary : ''}
                fill={fill ? theme.$colorPrimary : ''}
            />
        </StyledButton>
    );
};

const StyledButton = styled.button<ThemeProps & { hide: boolean }>`
    visibility: ${props => (props.hide ? 'hidden' : 'visible')};
`;

export default ActionControlButton;
