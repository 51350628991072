/* eslint-disable @typescript-eslint/no-explicit-any */
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { IList, useGlobalStore } from '../../../hooks/hooks';
import { DocumentDescriptionProps, VersionProps } from '../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { loadDocument } from '../../../lib/app/document';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';
import Modal from '../../../components/dialogs/Modal/Modal';
import DocumentList from './DocumentList';
import { ResponseError } from '../../../typings/error';
import LoadingScreen from '../../../views/LoadingScreen';
import { SearchRequestBody } from '../../../typings/search';
import { BoolSwapState, CurrentDocumentPropsType } from '../../../typings/global';

export interface PopupProps {
    openState: BoolSwapState;
    actualVersion: DocumentDescriptionProps | VersionProps | undefined;
    currentDocumentProps: CurrentDocumentPropsType;
}

const VersionModal: FunctionComponent<PopupProps> = ({
    openState,
    actualVersion,
    currentDocumentProps,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [currentDocumentId, setCurrentDocumentId] = useState<string>();
    const [selectedLanguages, setSelectedLanguages] = useState<{ [documentID: string]: string }>();
    const [documents, setDocuments] = useState<IList | undefined>(undefined);
    const searchID = 'search_documents';

    useEffect(() => {
        function updateDescriptionsFromLoadedDocuments(elements: IList) {
            const selLanguages: { [documentID: string]: string } = {};

            Object.keys(elements).forEach(key => {
                selLanguages[elements[key].internalDocumentID] =
                    state.config.language.current &&
                    elements[key].descriptions[state.config.language.current.code]
                        ? state.config.language.current.code
                        : 'de_DE';
            });
            setSelectedLanguages(selLanguages);
        }

        if (!selectedLanguages && openState.value) {
            dispatch(startOperation());
            const requestBody: SearchRequestBody = {
                keywordSearch: null,
                deepSearch: null,
                languageCode: null,
            };
            AxiosClient.post(
                `${requestURLs.editorDocuments}${state.config.tenant.current?.tenantID}/search`,
                requestBody
            )
                .then(response => {
                    const elements: IList = response.data;
                    setDocuments(elements);
                    updateDescriptionsFromLoadedDocuments(elements);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }, [dispatch, openState.value, selectedLanguages, state.config.language, state.config.tenant]);

    function handleSelection(event: React.MouseEvent<HTMLButtonElement>) {
        const element = event.currentTarget;
        const documentId = event.currentTarget.getAttribute('data-id');
        const selectedElements = document.getElementsByClassName('selected-doc');

        if (selectedElements.length > 0) {
            element.classList.remove('selected-doc');
            Array.from(selectedElements).forEach(node => {
                node.classList.remove('selected-doc');
            });
        }

        if (documentId && currentDocumentProps.value.documentID !== parseInt(documentId, 10)) {
            event.currentTarget.classList.add('selected-doc');
            setCurrentDocumentId(documentId);
        }
    }

    function moveVersion(event: React.MouseEvent<HTMLButtonElement>) {
        const copyInDocumentId = event.currentTarget.getAttribute('data-id');
        if (
            currentDocumentId &&
            copyInDocumentId &&
            parseInt(copyInDocumentId, 10) !== currentDocumentProps.value.documentID &&
            actualVersion
        ) {
            const keys = Object.keys(actualVersion);
            const values = Object.values(actualVersion);

            if (keys.indexOf('versionID') > -1) {
                dispatch(startOperation());
                AxiosClient.put(
                    `${requestURLs.versions}${
                        values[keys.indexOf('versionID')]
                    }/to/${copyInDocumentId}`
                )
                    .then(() => {
                        openState.set(false);
                        loadDocument(
                            dispatch,
                            parseInt(currentDocumentId, 10),
                            state.documentManager,
                            currentDocumentProps.set
                        );
                    })
                    .catch(error =>
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        )
                    )
                    .finally(() => dispatch(finishOperation()));
            }
        }
    }

    return (
        <Modal
            id="version-modal"
            isOpen={openState.value}
            shouldCloseOnOverlayClick
        >
            <InnerModal>
                <ButtonWrapper>
                    <ActionControlButton
                        prefix="close"
                        name="close"
                        className="close-btn"
                        callback={() => openState.set(false)}
                        size={26}
                    />
                </ButtonWrapper>
                <ModalHeader>
                    <h4>Dokumentenübersicht</h4>
                    <p>
                        Klicken Sie doppelt auf ein Dokument, um die aktuelle Version in ein anderes
                        Dokument zu verschieben.
                    </p>
                </ModalHeader>
                {selectedLanguages && (
                    <ModalBody className="styledScrollBar">
                        {documents ? (
                            <DocumentList
                                searchID={searchID}
                                selectedLanguages={selectedLanguages}
                                documents={documents}
                                handleSelection={handleSelection}
                                handleDoubleClick={moveVersion}
                                currentDocumentProps={currentDocumentProps}
                            />
                        ) : (
                            <LoadingScreen />
                        )}
                    </ModalBody>
                )}
            </InnerModal>
        </Modal>
    );
};

const InnerModal = styled.div<ThemeProps>`
    margin: 5px;
    display: flex;
    flex-direction: column;
    height: 770px;
    .selected-doc {
        background: antiquewhite;

        :hover {
            background: antiquewhite;
        }
    }
`;

const ButtonWrapper = styled.div<ThemeProps>`
    .close-btn {
        margin: 10px;
        padding: 0;
        right: 0%;
        top: 0%;
        justify-content: flex-end;
        position: absolute;
        border: none;
        background-color: inherit;
    }
`;

const ModalHeader = styled.div<ThemeProps>`
    p {
        font-size: 0.7rem;
        font-style: italic;
        margin-top: 5px;
    }

    h4 {
        margin-bottom: 0;
    }

    .bold {
        margin: 0;
        font-weight: bold;
    }
`;

const ModalBody = styled.div<ThemeProps>`
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
    padding: 0.2rem 0 0.2rem 0;
    flex: 1;
    overflow: auto;
`;

export default VersionModal;
