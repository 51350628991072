import { DiffEditor } from '@monaco-editor/react';
import React, { FunctionComponent } from 'react';

interface TranslationDiffEditorProps {
    originalContent: string;
    modifiedContent: string;
    lineBreak: 'off' | 'on' | 'wordWrapColumn' | 'bounded' | undefined;
}

const TranslationDiffEditor: FunctionComponent<TranslationDiffEditorProps> = ({
    originalContent,
    modifiedContent,
    lineBreak,
}) => (
    <DiffEditor
        language="markdown"
        width="50%"
        original={originalContent}
        modified={modifiedContent}
        options={{
            readOnly: true,
            contextmenu: false,
            wordWrap: lineBreak,
            renderSideBySide: false,
        }}
    />
);

export default TranslationDiffEditor;
