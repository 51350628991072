import React, { FunctionComponent, ReactElement } from 'react';
import key from 'weak-key';
import PanelWrapper from './ManagedElements/PanelWrapper';
import Panel from './ManagedElements/Panel';
import { useGlobalStore } from '../../hooks/hooks';

const ErrorList: FunctionComponent = (): ReactElement => {
    const {
        state: {
            global: { errors },
        },
    } = useGlobalStore();
    return (
        <div>
            {errors && (
                <PanelWrapper>
                    {errors.map(error => (
                        <Panel
                            colorType={error.type}
                            key={key(error)}
                        >
                            {error.message}
                        </Panel>
                    ))}
                </PanelWrapper>
            )}
        </div>
    );
};

export default ErrorList;
