import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import Logo from '../Logo/Logo';
import { ThemeProps } from '../../../interfaces/globals/theme';
import Menu from '../Menu/Menu';
import { useGlobalStore } from '../../../hooks/hooks';

interface HeaderProps {
    onSelectLocale?(value: string): void;
}

const Header: FunctionComponent<HeaderProps> = ({ onSelectLocale }): ReactElement => {
    const { state } = useGlobalStore();
    const { config } = state;
    const compTitle = 'JiveX Compendium';
    const compLogo = '/logo/compendium.png';

    return (
        <Wrapper data-testid="header">
            {config.tenant.current && (
                <>
                    <Logo
                        file={compLogo}
                        height={config.header.height}
                        target="/"
                    />
                    <Title>{compTitle}</Title>
                    <Menu onSelectLocale={onSelectLocale} />
                </>
            )}
        </Wrapper>
    );
};

const Wrapper = styled.header<ThemeProps>`
    display: flex;
    flex-direction: row;
    padding: 0.5rem 0.5rem;
    text-align: left;
    border-bottom: ${props => props.theme.$border.secondary.thin};
    background-color: ${props => props.theme.$colorBackgroundPrimary};
`;

const Title = styled.div<ThemeProps>`
    margin: auto 0.8rem;
    width: 50%;
    color: ${props => props.theme.$colorSecondary};
    font-weight: 600;
    font-size: ${props => props.theme.$headerStyles.medium.h2.fontSize}rem;
    text-decoration: none;
    vertical-align: middle;
`;

export default Header;
