import React, { FunctionComponent, ReactElement, ReactNode } from 'react';
import Modal from 'react-modal';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';

export interface ModalProps {
    id: string;
    isOpen: boolean;
    shouldCloseOnOverlayClick: boolean;
    ariaHideApp?: boolean;
    onRequestClose?: (
        event: React.MouseEvent<Element, MouseEvent> | React.KeyboardEvent<Element>
    ) => void | undefined;
    children?: ReactNode;
}

const CModal: FunctionComponent<ModalProps> = ({
    children,
    id,
    isOpen,
    shouldCloseOnOverlayClick,
    ariaHideApp,
    onRequestClose,
}): ReactElement => (
    <ModalStyle
        shouldCloseOnOverlayClick={shouldCloseOnOverlayClick}
        ariaHideApp={ariaHideApp}
        onRequestClose={onRequestClose}
        isOpen={isOpen}
        contentLabel={id}
        appElement={document.getElementById('root') as HTMLElement}
    >
        <div data-testid={`test-${id}`}>{children}</div>
    </ModalStyle>
);

// prettier-ignore
const ModalStyle = styled(Modal)<ThemeProps>`
  background-color: ${props => props.theme.$colorWhite};
  bottom: auto;
  left: 50%;
  margin-right: -50%;
  max-width: 85%;
  max-height: 85%;
  padding: 0.5rem;
  position: absolute;
  right: auto;
  text-align: left;
  top: 5%;
  transform: translate(-50%, 0%);
  min-width: 20%;
  overflow-y: auto;
  z-index: 999;
  transition: all 0.2s ease-in-out;
`;
export default CModal;
