import React, { FunctionComponent } from 'react';
import Editor from '@monaco-editor/react';
import LoadingScreen from '../../../../../views/LoadingScreen';
import { TranslationEntry } from '../../../../../interfaces/DocumentEntry';
import { useGlobalStore } from '../../../../../hooks/hooks';
import { StringState } from '../../../../../typings/global';

interface TranslationEditorProps {
    translation: TranslationEntry | undefined;
    editorValueState: StringState;
    readOnly: boolean | undefined;
    lineBreak: 'off' | 'on' | 'wordWrapColumn' | 'bounded' | undefined;
}

const TranslationEditor: FunctionComponent<TranslationEditorProps> = ({
    translation,
    editorValueState,
    readOnly,
    lineBreak,
}) => {
    const { state } = useGlobalStore();

    if (!translation || translation.articleID !== state.documentManager.currentArticle.articleID)
        return <LoadingScreen />;

    return (
        <Editor
            width="50%"
            defaultLanguage="markdown"
            value={translation.content}
            onChange={value => editorValueState.set(value)}
            options={{ readOnly, contextmenu: false, wordWrap: lineBreak }}
        />
    );
};

export default TranslationEditor;
