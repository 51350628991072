import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import { SortConfig, useSortableData } from '../../hooks/hooks';

import { ThemeProps } from '../../interfaces/globals/theme';
import { Publication } from '../../typings/publication';

export interface TableModelEntryProps {
    id: string;
    width: string;
    columnTitle: string;
    span?: number;
}

interface TableProps {
    model: {
        type: string;
        entries: TableModelEntryProps[];
    };
    entries: { [documentID: string]: Publication };
    renderEntry: Function;
    initialSortOrder?: SortConfig;
}

const Table: FunctionComponent<TableProps> = ({
    model,
    entries,
    renderEntry,
    initialSortOrder = null,
}): ReactElement => {
    const { items, requestSort, sortConfig } = useSortableData(entries, initialSortOrder);

    return (
        <Wrapper className="styledScrollBar">
            <StyledTable>
                <colgroup>
                    {model.entries.map(column => (
                        <col
                            key={weakKey(column)}
                            width={column.width}
                        />
                    ))}
                </colgroup>
                <thead>
                    <tr>
                        {model.entries.map(column =>
                            column.columnTitle ? (
                                <th
                                    key={weakKey(column)}
                                    colSpan={column.span !== undefined ? column.span : 1}
                                >
                                    <button
                                        type="button"
                                        className={
                                            sortConfig !== null &&
                                            column.id !== '' &&
                                            sortConfig.key === column.id
                                                ? sortConfig.direction
                                                : undefined
                                        }
                                        disabled={column.id === ''}
                                        onClick={() => requestSort(column.id)}
                                    >
                                        {column.columnTitle}
                                    </button>
                                </th>
                            ) : null
                        )}
                    </tr>
                </thead>
                <tbody>
                    {Object.keys(items).length > 0
                        ? Object.keys(items).map((id, index) => renderEntry(items[id], index))
                        : null}
                </tbody>
            </StyledTable>
        </Wrapper>
    );
};

// prettier-ignore
const Wrapper = styled.div<ThemeProps>`
    overflow: auto;
    height: 86%;
    width: 100%;
    flex: 1;
    display: block;
    margin: 1rem auto 1rem auto;
`;

// prettier-ignore
const StyledTable = styled.table<ThemeProps>`
    font-size: ${props => props.theme.$headerStyles.medium.h6.fontSize}rem;
    overflow-wrap: break-word;
    width: 90%;
    margin: 0 auto;
    border-spacing: 0;
    table-layout: fixed;
    border-collapse: collapse;

    thead {

        button {
            background-color: inherit;
            font-weight: inherit;
            border: none;
            color: inherit;
        }

        button.descending::after {
            color: ${props => props.theme.$colorSecondary};
            content: '▼'
        }

        button.ascending::after {
            color: ${props => props.theme.$colorSecondary};
            content: '▲'
        }

        tr {
            position: relative;

            th {
                background-color:white;
                text-align: center;
                align-items: center;
                justifiy-content: center;
                vertical-align: middle;
                font-weight: 400;
                line-height: ${props => props.theme.$headerStyles.medium.h3.fontSize}rem;
                top: 0px;
                margin-top: 0;
                position: sticky;
                z-index: 5;

                button {
                    font-size: ${props => props.theme.$headerStyles.medium.h5.fontSize}rem;
                    background-color: ${props => props.theme.$colorWhite};
                    color: ${props => props.theme.$colorBlack};
                }
            }

        }
    }

    tbody {
        overflow: auto;
        position: relative;

        tr {
            height: 2.5rem;
        }
        tr:hover {
            background-color: ${props => props.theme.$colorGrey10}
        }
    }

    td,
    th {
        padding: 0.25rem 0.3rem;
        vertical-align: middle;
        text-align: center;
        align-items: center;
        border-bottom: ${props => props.theme.$border.grey.thin[20]};
    }

    td {
        font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
    }

    .id {
        color: ${props => props.theme.$colorSecondary};
    }

    .title {
        text-align: left;
    }
`;

export default Table;
