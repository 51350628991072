import Editor from '@monaco-editor/react';
import * as monaco from 'monaco-editor/esm/vs/editor/editor.api';
import React, {
    FunctionComponent,
    ReactElement,
    useCallback,
    useEffect,
    useRef,
    useState,
} from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import { useGlobalStore } from '../../../../hooks/hooks';
import { VersionProps } from '../../../../interfaces/DocumentEntry';
import EDocumentState from '../../../../interfaces/DocumentState';
import Modal from '../../../../components/dialogs/Modal/Modal';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { t } from '../../../../lib/language';
import { versionSelected } from '../../../../store/document/actions';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import LoadingScreen from '../../../../views/LoadingScreen';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import { ResponseError } from '../../../../typings/error';
import { ContentArticleAudit } from '../../../../typings/articleAudit';
import {
    ToolbarButton,
    ToolbarButtonWrapper,
} from '../translationValidation/TranslationToolbar/TranslationToolbar';
import EditorBar from './editorBar/EditorBar';
import { loadTranslations } from '../../../../lib/app/translation';
import EditorBarGroup from './editorBar/editorBarGroup/EditorBarGroup';
import DropDown from '../../../../components/DropDown/DropDown';
import ActionControlButton from '../../../../components/ActionControlButton/ActionControlButton';
import MDXTableGenerator from './editorBar/MDXTableGenerator';
import { GermanAudiences } from '../../../../interfaces/Audiences';
import Spacer from './editorBar/editorBarGroup/Spacer';
import { ArticleDTO, ArticlesService } from '../../../../lib/backend';

const ContentEditor: FunctionComponent<{
    changeListener: Function;
    selectedHeading: { articleID: number; heading: string };
    saveListener: Function;
    articleDiffData: ContentArticleAudit | null;
    reloadArticleAuditData: Function;
}> = ({
    changeListener,
    saveListener,
    selectedHeading,
    articleDiffData,
    reloadArticleAuditData,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [article, setArticle] = useState<ArticleDTO>();
    const [articleChanged, setArticleChanged] = useState<boolean>(false);
    const editorRef = useRef<monaco.editor.IStandaloneCodeEditor | null>(null);
    const [editorPrefs, setEditorPrefs] = useState<{ [key: string]: boolean }>({
        linebreak: true,
        locked: true,
    });
    const [requestedHeading, setRequestedHeading] = useState<{
        articleID: number;
        heading: string;
    }>({ articleID: -1, heading: '' });
    const [previousHeading, setPreviousHeading] = useState<{
        articleID: number;
        heading: string;
    }>({ articleID: -1, heading: '' });
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showConfirmationForRepublish, setShowConfirmationForRepublish] =
        useState<boolean>(false);
    const [localDiffData, setLocalDiffData] = useState<ContentArticleAudit | null>(articleDiffData);
    const [currentEditorData, setCurrentEditorData] = useState<string>('');
    const [diffEditor, setDiffEditor] = useState<monaco.editor.IStandaloneDiffEditor | null>(null);
    const [diffEditorLoaded, setDiffEditorLoaded] = useState<boolean>(false);
    const [diffLineBreak, setDiffLineBreak] = useState<boolean>(false);
    const [changeLineBreakTo, setChangeLineBreakTo] = useState<
        'off' | 'on' | 'wordWrapColumn' | 'bounded' | undefined
    >('on');
    const [isUIBlocked, setUIBlocked] = useState<boolean>(false);
    const prefix = 'ce_';
    const dropClassName = 'drop';
    let domNode: HTMLDivElement;
    let mouseDropWidget: monaco.editor.IContentWidget | null = null;
    let dragTarget: monaco.editor.IMouseTarget | null = null;

    const loadArticleToEditor = useCallback(
        (
            editor: React.MutableRefObject<monaco.editor.IStandaloneCodeEditor | null>,
            articleID: number
        ) => {
            ArticlesService.getArticle(articleID)
                .then(response => {
                    editor.current?.setValue(response.content || '');
                    editor.current?.updateOptions({
                        ...editor.current.getRawOptions(),
                        readOnly:
                            response.status !== undefined &&
                            response.status >= EDocumentState.Published,
                    });
                    setArticle(response);
                    setArticleChanged(false);
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        },
        [dispatch]
    );

    useEffect(() => {
        if (
            ((article && state.documentManager.currentArticle.articleID !== article.articleID) ||
                (article && state.documentManager.currentArticle.status !== article.status)) &&
            state.documentManager.currentArticle.articleID !== undefined
        )
            loadArticleToEditor(editorRef, state.documentManager.currentArticle.articleID);

        if (selectedHeading !== requestedHeading) setRequestedHeading(selectedHeading);
    }, [
        article,
        dispatch,
        loadArticleToEditor,
        requestedHeading,
        selectedHeading,
        state.documentManager.currentArticle.articleID,
        state.documentManager.currentArticle.status,
    ]);

    const saveArticleContent = React.useCallback(() => {
        if (article && editorRef.current && article.articleID !== -1) {
            dispatch(startOperation());
            article.content = editorRef.current.getValue();
            article.status = EDocumentState.Draft;
            AxiosClient.put(`${requestURLs.editorArticles}${article.articleID}`, article)
                .then(() => {
                    changeListener(false);
                    setArticleChanged(false);
                    saveListener();
                    dispatch(addError(EErrorTypes.info, t('StatusCode.SpecialCase.ArticleSaved')));
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(dispatch(finishOperation()));
        }
    }, [article, changeListener, saveListener, dispatch]);

    function insertTextAtPos(
        instance: monaco.editor.IStandaloneCodeEditor,
        text: string,
        coords: [number, number] = [0, 0],
        placeCursor = false
    ) {
        const range = new monaco.Range(coords[0], coords[1], coords[0], coords[1]);
        if (placeCursor) {
            const selection = new monaco.Selection(coords[0], coords[1], coords[0], coords[1]);
            instance.executeEdits('insert', [{ range, text, forceMoveMarkers: true }], [selection]);
            instance.focus();
        } else {
            instance.executeEdits('insert', [{ range, text, forceMoveMarkers: true }]);
        }
        instance.pushUndoStop();
    }

    function handleDrop(
        e: React.DragEvent,
        target: monaco.editor.IMouseTarget,
        instance: monaco.editor.IStandaloneCodeEditor | null
    ) {
        const text = e.dataTransfer.getData('text/plain');
        if (text && instance && target.position) {
            insertTextAtPos(
                instance,
                text,
                [target.position.lineNumber, target.position.column],
                true
            );
        }
    }

    function editorLoaded(editor: monaco.editor.IStandaloneCodeEditor) {
        editorRef.current = editor;
        const options = editorRef.current.getRawOptions();
        options.wordWrap = 'on';
        options.scrollBeyondLastLine = false;
        options.folding = false;
        options.readOnly = true;
        options.suggestOnTriggerCharacters = false;
        const textModel = editorRef.current.getModel();
        textModel?.setEOL(0);
        if (options.suggest) options.suggest.showWords = false;
        loadArticleToEditor(editorRef, state.documentManager.currentArticle.articleID);
    }

    useEffect(() => {
        if (editorRef && editorRef.current) {
            editorRef.current.addAction({
                id: 'saveAction',
                label: 'Save Content',
                // eslint-disable-next-line no-bitwise
                keybindings: [monaco.KeyMod.CtrlCmd | monaco.KeyCode.KeyS],
                precondition: undefined,
                keybindingContext: undefined,
                contextMenuGroupId: undefined,
                contextMenuOrder: 1.5,
                run: () => {
                    saveArticleContent();
                    if (articleDiffData) reloadArticleAuditData(true);
                },
            });
        }
    }, [articleDiffData, editorRef, reloadArticleAuditData, saveArticleContent]);

    useEffect(() => {
        if (article && article.status && editorRef && editorRef.current) {
            editorRef.current.updateOptions({
                ...editorRef.current.getRawOptions(),
                readOnly: article.status >= EDocumentState.Published,
            });
        }
    }, [editorRef, article]);

    function toggleLinebreak(event: React.MouseEvent<Element, MouseEvent>) {
        const pref = event.currentTarget.id.replace(`${prefix}btn_`, '');

        const ep = { ...editorPrefs };
        if (ep[pref]) ep[pref] = false;
        else ep[pref] = true;

        if (editorRef.current !== null) {
            editorRef.current.updateOptions({
                ...editorRef.current.getRawOptions(),
                wordWrap: ep[pref] ? 'on' : 'off',
            });
            setDiffLineBreak(true);
            if (ep[pref]) {
                setChangeLineBreakTo('on');
            } else setChangeLineBreakTo('off');
        }
        setEditorPrefs(ep);
    }

    function editArticleIfLocked(a: ArticleDTO | undefined, copyArticle: boolean) {
        const pref = document
            .getElementById(
                `${prefix}btn_${
                    a && a.status && a.status < EDocumentState.Published ? 'un' : ''
                }lock`
            )
            ?.id.replace(`${prefix}btn_`, '');

        if (a) {
            if (copyArticle) {
                startOperation();
                AxiosClient.put(
                    // eslint-disable-next-line max-len
                    `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/articles/${a.articleID}/copy`
                )
                    .then(response => {
                        const version = response.data as VersionProps;
                        dispatch(versionSelected(version));
                    })
                    .catch(error =>
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        )
                    )
                    .finally(() => finishOperation());
            } else {
                startOperation();
                AxiosClient.put(`${requestURLs.editorArticles}${a.articleID}`, {
                    ...a,
                    status: EDocumentState.Draft,
                })
                    .then(() => {
                        saveListener();
                        loadTranslations(dispatch, state.documentManager.currentVersion.versionID);
                    })
                    .catch(error =>
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        )
                    )
                    .finally(() => finishOperation());
            }
        }

        if (pref) {
            const ep = { ...editorPrefs };
            if (ep[pref]) ep[pref] = false;
            else ep[pref] = true;

            if (editorRef.current !== null) {
                editorRef.current.updateOptions({
                    ...editorRef.current.getRawOptions(),
                    readOnly: false,
                });
                setEditorPrefs(ep);
            }
        }
    }

    function modifyTextAt(lineNumber: number, column: number, text: string, remove = false) {
        const range = new monaco.Range(
            lineNumber,
            column,
            lineNumber,
            column + (remove ? text.length : 0)
        );

        if (editorRef.current) {
            const op: monaco.editor.IIdentifiedSingleEditOperation = {
                range,
                text: remove ? '' : text,
                forceMoveMarkers: remove,
            };
            editorRef.current.executeEdits('bla', [op]);
        }
    }

    function insertTable(table: string) {
        if (editorRef.current) {
            const selection = editorRef.current.getSelection();
            if (selection)
                modifyTextAt(selection.positionLineNumber, selection.positionLineNumber, table);
        }
    }

    function applyFormatting(
        event:
            | React.MouseEvent<HTMLButtonElement, MouseEvent>
            | React.MouseEvent<HTMLParagraphElement, MouseEvent>
    ) {
        const formatting = event.currentTarget.id.replace(`${prefix}btn_`, '');

        let opener = '';
        let closer: string | null = null;
        let endingRow = 0;
        switch (formatting) {
            case 'Heading 1':
                opener = '# ';
                break;
            case 'Heading 2':
                opener = '## ';
                break;
            case 'Heading 3':
                opener = '### ';
                break;
            case 'bold':
                opener = '**';
                break;
            case 'italic':
                opener = '*';
                break;
            case 'underline':
                opener = '<u>';
                closer = '</u>';
                break;
            case 'strikethrough':
                opener = '~~';
                break;
            case 'audience-visus':
                opener = '{{VISUS ';
                closer = '}}';
                break;
            case 'audience-partner':
                opener = '{{Partner ';
                closer = '}}';
                break;
            case 'audience-kunden':
                opener = '{{Kunden ';
                closer = '}}';
                break;
            case 'audience-anwender':
                opener = '{{Anwender ';
                closer = '}}';
                break;
            case 'margin-simple':
                opener = '*';
                closer = '{.mn}*';
                break;
            case 'margin-container':
                opener = '{{.margin-notes \r\n\r\n*';
                closer = '{.mn}*\r\n\r\n}}\r\n';
                break;
            default:
                break;
        }

        if (opener !== null)
            endingRow +=
                opener.split(/\r\n|\r|\n/).length > 1 ? opener.split(/\r\n|\r|\n/).length : 0;

        if (closer !== null)
            endingRow +=
                opener.split(/\r\n|\r|\n/).length > 1 ? opener.split(/\r\n|\r|\n/).length : 0;

        if (closer === null) closer = opener;

        if (editorRef.current) {
            const textCoords = {
                startRow: 1,
                startCol: 1,
                endRow: endingRow,
                endCol: 1,
            };

            let remove = false;
            let removeOpener = '';
            let adjustPosition = false;
            const selection = editorRef.current.getSelection();

            if (selection !== null) {
                textCoords.startRow = selection.startLineNumber;
                textCoords.startCol = selection.startColumn;
                textCoords.endRow += selection.endLineNumber;
                textCoords.endCol = selection.endColumn;

                if (
                    textCoords.startRow === textCoords.endRow &&
                    textCoords.startCol === textCoords.endCol
                )
                    adjustPosition = true;

                const selectedText = editorRef.current
                    .getModel()
                    ?.getValueInRange(
                        new monaco.Range(
                            textCoords.startRow,
                            textCoords.startCol,
                            textCoords.endRow,
                            textCoords.endCol
                        )
                    );
                if (selectedText && selectedText.startsWith(opener)) remove = true;
                if (selectedText && selectedText.startsWith('#')) {
                    const selectedCount = selectedText.split('#').length - 1;
                    removeOpener = `${new Array(selectedCount + 1).join('#')} `;
                    remove = true;
                }
            }

            modifyTextAt(
                textCoords.startRow,
                textCoords.startCol,
                removeOpener.length > 0 ? removeOpener : opener,
                remove
            );

            if (removeOpener.length > 0)
                modifyTextAt(textCoords.startRow, textCoords.startCol, opener);

            if (!formatting.startsWith('Heading'))
                modifyTextAt(
                    textCoords.endRow,
                    textCoords.endCol +
                        (textCoords.endRow === textCoords.startRow
                            ? (remove ? -1 : 1) * opener.length - (remove ? closer.length : 0)
                            : 0 - (remove ? closer.length : 0)),
                    closer,
                    remove
                );

            if (adjustPosition && selection) {
                const position = new monaco.Position(
                    selection.getPosition().lineNumber,
                    selection.getPosition().column + opener.length
                );
                editorRef.current.setPosition(position);
            }
            editorRef.current.focus();
        }
    }

    function articleContentChanged(
        value: string | undefined,
        event: monaco.editor.IModelContentChangedEvent
    ) {
        if (!articleChanged) {
            changeListener(true);
            setArticleChanged(true);
        }
        if (value && event) setCurrentEditorData(value);
    }

    // check if heading has to be scrolled into view
    if (
        article &&
        article.articleID === requestedHeading.articleID &&
        requestedHeading.heading !== previousHeading.heading
    ) {
        const e = editorRef.current;
        if (e) {
            const m = e.getModel();
            if (m) {
                const matches = m.findMatches(
                    selectedHeading.heading,
                    false,
                    false,
                    false,
                    ' ',
                    false,
                    1
                );

                if (matches.length > 0) {
                    e.revealRangeNearTopIfOutsideViewport(matches[0].range);
                    setPreviousHeading(selectedHeading);
                }
            }
        }
    }

    function removeMouseDownWidget() {
        const instance = editorRef.current;
        if (instance && mouseDropWidget && domNode) {
            instance.removeContentWidget(mouseDropWidget);
            mouseDropWidget = null;
        }
    }

    function buildMouseDropWidget() {
        if (!domNode) {
            domNode = document.createElement('div');
            domNode.className = dropClassName;
            domNode.style.pointerEvents = 'none';
            domNode.style.borderLeft = '2px solid #ccc';
            domNode.innerHTML = '&nbsp;';
        }
        return {
            getId: () => 'drag',
            getDomNode: () => domNode,
            getPosition: () => ({
                position: dragTarget ? dragTarget.position : null,
                preference: [
                    monaco.editor.ContentWidgetPositionPreference.EXACT,
                    monaco.editor.ContentWidgetPositionPreference.EXACT,
                ],
            }),
        };
    }

    function displayMouseDropPosition(
        instance: monaco.editor.IStandaloneCodeEditor,
        target: monaco.editor.IMouseTarget | null
    ) {
        dragTarget = target;
        if (mouseDropWidget) {
            instance.layoutContentWidget(mouseDropWidget);
        } else {
            mouseDropWidget = buildMouseDropWidget();
            instance.addContentWidget(mouseDropWidget);
        }
    }

    function onDrop(e: React.DragEvent<HTMLDivElement>) {
        if (dragTarget) handleDrop(e, dragTarget, editorRef.current);
        removeMouseDownWidget();
    }

    function onDragOver(e: React.DragEvent<HTMLDivElement>) {
        const instance = editorRef.current;
        if (instance)
            displayMouseDropPosition(
                instance,
                instance.getTargetAtClientPoint(e.clientX, e.clientY)
            );
        e.preventDefault();
    }

    function handleRevert(diffdata: string) {
        const editor = editorRef.current;
        if (editor && editor.getModel() && article && article.status !== EDocumentState.Published) {
            const model = editor.getModel();
            if (model !== null) {
                editor.executeEdits('beautifier', [
                    {
                        range: new monaco.Range(1, 1, model.getLineCount() + 1, 1),
                        text: diffdata,
                        forceMoveMarkers: true,
                    },
                ]);
            }
        } else dispatch(addError(EErrorTypes.warning, t('StatusCode.SpecialCase.EditorLocked')));
    }

    function buildEditorToolBar() {
        const elements: ReactElement[] = [];
        const iconSize = 18;
        const maxTableSize = 5;

        elements.push(
            <Spacer key="main-buttons">
                <EditorBarGroup border>
                    <DropDown
                        id="heading-dropdown"
                        lable="Stil"
                        prefix={prefix}
                        options={{ 'Heading 1': 16, 'Heading 2': 14, 'Heading 3': 12 }}
                        onSelect={applyFormatting}
                    />
                </EditorBarGroup>
                <EditorBarGroup border>
                    <ActionControlButton
                        size={iconSize}
                        prefix={prefix}
                        name="bold"
                        callback={applyFormatting}
                    />
                    <ActionControlButton
                        size={iconSize}
                        prefix={prefix}
                        name="italic"
                        callback={applyFormatting}
                    />
                    <ActionControlButton
                        size={iconSize}
                        prefix={prefix}
                        name="underline"
                        callback={applyFormatting}
                    />
                </EditorBarGroup>
                <EditorBarGroup border>
                    <ActionControlButton
                        size={iconSize}
                        prefix={prefix}
                        name="margin-simple"
                        callback={applyFormatting}
                    />
                    <ActionControlButton
                        size={iconSize}
                        prefix={prefix}
                        name="margin-container"
                        callback={applyFormatting}
                    />
                </EditorBarGroup>
                <EditorBarGroup border>
                    <MDXTableGenerator
                        tableSize={maxTableSize}
                        prefix={prefix}
                        iconSize={iconSize}
                        insertTable={insertTable}
                    />
                </EditorBarGroup>
            </Spacer>
        );
        elements.push(
            <Spacer key="audit-buttons">
                <AudienceGroupWrapper>
                    {GermanAudiences.map(audience => (
                        <ToolbarButton
                            key={`${weakKey(GermanAudiences)}_${audience}`}
                            id={`${prefix}btn_audience-${audience.toLowerCase()}`}
                            onClick={applyFormatting}
                            disabled={false}
                        >
                            {audience}
                        </ToolbarButton>
                    ))}
                </AudienceGroupWrapper>
            </Spacer>
        );
        elements.push(
            <Spacer key="aktivate-buttons">
                <EditorBarGroup border={false}>
                    <ActionControlButton
                        className={editorPrefs.linebreak ? 'activePref' : 'inactivePref'}
                        size={iconSize}
                        prefix={prefix}
                        name="linebreak"
                        callback={toggleLinebreak}
                    />
                    <ActionControlButton
                        className={editorPrefs.readOnly ? 'activePref' : 'inactivePref'}
                        size={iconSize}
                        prefix={prefix}
                        name={`${
                            article && article.status && article.status < EDocumentState.Published
                                ? 'un'
                                : ''
                        }lock`}
                        callback={
                            article && article.status && article.status < EDocumentState.Published
                                ? () => setShowConfirmationForRepublish(true)
                                : () => setShowConfirmation(true)
                        }
                    />
                </EditorBarGroup>
            </Spacer>
        );
        return elements;
    }

    const handleRepublishClick = (): void => {
        const { currentArticle, currentVersion, currentDescription } = state.documentManager;
        const baseURL = `${requestURLs.versions}${currentVersion.versionID}/`;
        const articleURL = `articles/${currentArticle.articleID}/`;

        if (currentArticle.articleID !== -1) {
            setUIBlocked(true);
            AxiosClient.put(`${baseURL}${articleURL}publish/${currentDescription.languageCode}`)
                .then(response => {
                    if (response.status === 201)
                        dispatch(
                            addError(
                                EErrorTypes.info,
                                t('StatusCode.SpecialCase.Republish:Versions.Statuscode')
                            )
                        );
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => {
                    setUIBlocked(false);
                    dispatch(finishOperation());
                });
        }
    };

    useEffect(() => {
        if (articleDiffData) {
            setLocalDiffData(articleDiffData);
            if (
                article &&
                article.content &&
                localDiffData !== null &&
                localDiffData === articleDiffData
            ) {
                const element = document.getElementById('diff-editor');
                const originalModel = monaco.editor.createModel(localDiffData.content, 'markdown');
                const modifiedModel = articleChanged
                    ? monaco.editor.createModel(currentEditorData, 'markdown')
                    : monaco.editor.createModel(article.content, 'markdown');
                if (element && !diffEditorLoaded && diffEditor === null) {
                    setDiffEditor(
                        monaco.editor.createDiffEditor(element, {
                            enableSplitViewResizing: false,
                            readOnly: true,
                            renderSideBySide: false,
                            wordWrap: 'on',
                        })
                    );
                }
                if (diffEditor !== null) {
                    setDiffEditorLoaded(true);
                    if (element && diffEditorLoaded) {
                        diffEditor.setModel({
                            original: originalModel,
                            modified: modifiedModel,
                        });
                        if (editorRef.current && editorRef.current.getPosition()) {
                            diffEditor.revealLineInCenter(
                                editorRef.current.getPosition()?.lineNumber ?? 0
                            );
                        }
                    }
                }
                if (diffLineBreak && diffEditor) {
                    diffEditor.updateOptions({ wordWrap: changeLineBreakTo });
                }
            }
        } else if (diffEditor !== null) {
            diffEditor.dispose();
            setDiffEditorLoaded(false);
            setDiffEditor(null);
        }
    }, [
        article,
        articleChanged,
        articleDiffData,
        changeLineBreakTo,
        currentEditorData,
        diffEditor,
        diffEditorLoaded,
        diffLineBreak,
        localDiffData,
        state.documentManager.currentArticle.articleID,
    ]);

    return (
        <MainWrapper>
            <EditorWrapper
                onDrop={onDrop}
                onDragOver={onDragOver}
                onDragLeaveCapture={removeMouseDownWidget}
            >
                <EditorBar>{buildEditorToolBar()}</EditorBar>
                <Editor
                    onMount={ref => editorLoaded(ref)}
                    onChange={(value, event) => {
                        if (
                            article &&
                            value !== article.content &&
                            article.articleID === state.documentManager.currentArticle.articleID
                        ) {
                            articleContentChanged(value, event);
                        }
                    }}
                    defaultLanguage="markdown"
                />

                <ConfirmationDialog
                    id="copy-edit-article-confirmation"
                    title="Artikel bearbeiten"
                    message={t('ContentEditor.ConfirmationDialog.EditArticle')}
                    onCancel={() => setShowConfirmation(false)}
                    onOK={undefined}
                    optionalButtons={[
                        {
                            message: 'Diesen Artikel bearbeiten',
                            callback: () => editArticleIfLocked(article, false),
                        },
                        {
                            message: 'Kopie vom Artikel erstellen',
                            callback: () => editArticleIfLocked(article, true),
                        },
                    ]}
                    isOpenState={{
                        value: showConfirmation,
                        set: setShowConfirmation,
                    }}
                />
                <ConfirmationDialog
                    id="republish-all-versions-with-this-article-confirmation"
                    title="Alle Versionen freigeben mit diesem Artikel"
                    message={t('ContentEditor.ConfirmationDialog.Republish')}
                    onCancel={() => setShowConfirmationForRepublish(false)}
                    onOK={handleRepublishClick}
                    isOpenState={{
                        value: showConfirmationForRepublish,
                        set: setShowConfirmationForRepublish,
                    }}
                />
                <Modal
                    id="republish-modal"
                    key="republish-modal"
                    shouldCloseOnOverlayClick={false}
                    isOpen={isUIBlocked}
                >
                    <h3>Freigabe Jobs werden erstellt.</h3>
                    <p>Dieser Vorgang kann etwas dauern.</p>
                    <LoadingScreen />
                </Modal>
            </EditorWrapper>
            {articleDiffData && (
                <EditorWrapper>
                    <ToolbarWrapper>
                        <ToolbarButtonWrapper>
                            <ToolbarButton
                                id="btn_editor_revert"
                                onClick={() => handleRevert(articleDiffData.content)}
                                disabled={false}
                            >
                                Zurücksetzen
                            </ToolbarButton>
                        </ToolbarButtonWrapper>
                    </ToolbarWrapper>
                    <div id="diff-editor" />
                </EditorWrapper>
            )}
        </MainWrapper>
    );
};

const MainWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    flex: 1;
    border: ${props => props.theme.$border.secondary.thin};
    overflow: hidden;

    #diff-editor {
        display: flex;
        flex-direction: column;
        flex: 1;
        overflow: hidden;
    }
`;

const EditorWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    border: ${props => props.theme.$border.secondary.thin};
    overflow: hidden;
`;

const ToolbarWrapper = styled.div<ThemeProps>`
    width: 100%;
    height: 30px;
    display: flex;
    flex-direction: row;
    justify-content: space-between;
    background-color: ${props => props.theme.$colorGray20};
    margin-bottom: 4px;
    border-bottom: ${props => props.theme.$border.secondary.thin};
    padding: 4px 0;
`;

const AudienceGroupWrapper = styled.div<ThemeProps>`
    margin: 0;
    padding: 0 5px;
    display: flex;
    flex-direction: row;
`;

export default ContentEditor;
