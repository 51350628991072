import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalStore } from '../../hooks/hooks';
import { ThemeProps } from '../../interfaces/globals/theme';
import { t } from '../../lib/language';
import { updateSearchString } from '../../store/global/actions';

interface SearchFallbackSiteProps {
    search: (needle: string | null) => void;
}

const SearchFallbackSite: FunctionComponent<SearchFallbackSiteProps> = ({ search }) => {
    const { state, dispatch } = useGlobalStore();
    const [searchString, setSearchString] = useState<string>('');

    const useSearchOnce = () =>
        useEffect(() => {
            setSearchString(state.global.searchString);
            return () => setSearchString('');
        }, []);

    useSearchOnce();
    return (
        <SearchFallbackWrapper>
            <SearchFallbackHeader>
                <h2>{t('SearchFallback.Heading.Error')}</h2>
                {!searchString.startsWith('$') && (
                    <p>
                        {t('SearchFallback.Text.NotFound')}{' '}
                        <span className="to-td">
                            <button
                                type="button"
                                onClick={() => {
                                    dispatch(updateSearchString(`$${searchString}`));
                                    setSearchString(`$${searchString}`);
                                    search(`$${searchString}`);
                                }}
                            >
                                ${searchString}
                            </button>
                        </span>
                    </p>
                )}
            </SearchFallbackHeader>
            <SearchFallbackBody>
                <h3>{t('SearchFallback.Heading.HowTo')}</h3>
                <p>{t('SearchFallback.Text.NormalSearch')}</p>
                <SearchFallbackTable>
                    <thead>
                        <tr>
                            <td>{t('SearchFallback.Table.FulltextSearch.Thead.Col1')}</td>
                            <td>{t('SearchFallback.Table.NormalSearch.Thead.Col2')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>$</td>
                            <td>{t('SearchFallback.Table.FulltextSearch.Tbody.Row1.Col2')}</td>
                        </tr>
                    </tbody>
                </SearchFallbackTable>
                <SearchFallbackTable>
                    <thead>
                        <tr>
                            <td>{t('SearchFallback.Table.NormalSearch.Thead.Col1')}</td>
                            <td>{t('SearchFallback.Table.NormalSearch.Thead.Col2')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>+</td>
                            <td>{t('SearchFallback.Table.NormalSearch.Tbody.Row1.Col2')}</td>
                        </tr>
                        <tr>
                            <td>-</td>
                            <td>{t('SearchFallback.Table.NormalSearch.Tbody.Row2.Col2')}</td>
                        </tr>
                        <tr>
                            <td>&quot;</td>
                            <td>{t('SearchFallback.Table.NormalSearch.Tbody.Row3.Col2')}</td>
                        </tr>
                    </tbody>
                </SearchFallbackTable>
                <SearchFallbackTable>
                    <thead>
                        <tr>
                            <td>{t('SearchFallback.Table.KeywordSearch.Thead.Col1')}</td>
                            <td>{t('SearchFallback.Table.NormalSearch.Thead.Col2')}</td>
                        </tr>
                    </thead>
                    <tbody>
                        <tr>
                            <td>author:</td>
                            <td>{t('SearchFallback.Table.KeywordSearch.Tbody.Row1.Col2')}</td>
                        </tr>
                        <tr>
                            <td>product:</td>
                            <td>{t('SearchFallback.Table.KeywordSearch.Tbody.Row2.Col2')}</td>
                        </tr>
                        <tr>
                            <td>version:</td>
                            <td>{t('SearchFallback.Table.KeywordSearch.Tbody.Row3.Col2')}</td>
                        </tr>
                    </tbody>
                </SearchFallbackTable>
            </SearchFallbackBody>
        </SearchFallbackWrapper>
    );
};

export default SearchFallbackSite;

const SearchFallbackWrapper = styled.div<ThemeProps>`
    height: 100%;
    margin: auto;
    text-align: center;

    .to-td {
        border: 1px solid #dddfe1;
        padding: 5px;
        margin 5px;

        button {
            background: none;
            color: inherit;
            border: none;
            padding: 0;
            font: inherit;
            cursor: pointer;
            outline: inherit;
        }
    }

    .note {
        color: red;
    }
`;

const SearchFallbackHeader = styled.div<ThemeProps>`
    margin-bottom: 5px;
    p {
        margin: 0;
        padding: 0;
    }

    h2 {
        margin: 0 0 5px 0;
    }
`;

const SearchFallbackBody = styled.div<ThemeProps>`
    h3 {
        margin: 15px 0 15px 0;
    }
    p {
        margin: 0;
        margin-bottom: 10px;
    }
    .examples {
        margin: 15px;
    }
`;

const SearchFallbackTable = styled.table<ThemeProps>`
    border-collapse: collapse;
    min-width: 1120px;
    max-width: 1120px;
    margin: 5px auto;

    td {
        padding: 10px;
    }

    thead td {
        background-color: #54585d;
        color: #ffffff;
        font-weight: bold;
        border: 1px solid #54585d;
    }

    tbody td {
        border: 1px solid #dddfe1;
    }

    tbody tr {
        background-color: #f9fafb;
    }

    tbody tr:nth-child(odd) {
        background-color: #ffffff;
    }
`;
