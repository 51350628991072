import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';
import { useGlobalStore } from '../../hooks/hooks';
import { t } from '../../lib/language';
import Icon from '../../components/Icon/Icon';
import { BoolSwapState } from '../../typings/global';
import ActionControlButton from '../../components/ActionControlButton/ActionControlButton';
import { updateSearchString } from '../../store/global/actions';

interface SearchProps {
    searchID: string;
    autofocus?: boolean;
    deepSearch?: Function;
    callbackSearch?: Function | null;
    reloadDocuments?: BoolSwapState;
}

const Search: FunctionComponent<SearchProps> = ({
    searchID,
    autofocus = false,
    deepSearch,
    callbackSearch,
    reloadDocuments,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const { searchString } = state.global;
    const [initialized, setInitialized] = useState<boolean>(false);
    const [searchNeedle, setSearchNeedle] = useState<string>('');

    useEffect(() => {
        if (deepSearch) deepSearch(state.global.searchString);
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, []);

    useEffect(() => {
        if (deepSearch && reloadDocuments && reloadDocuments.value) {
            deepSearch(searchNeedle);
            reloadDocuments.set(false);
        }
    }, [deepSearch, reloadDocuments, searchNeedle]);

    useEffect(() => {
        function keyDownEventListener(event: KeyboardEvent) {
            if (event.key === 'Escape') {
                (document.getElementById(searchID) as HTMLInputElement).value = '';
            } else if (event.key === 'Enter') {
                if (callbackSearch)
                    callbackSearch((document.getElementById(searchID) as HTMLInputElement).value);
            }
        }

        if (!initialized) {
            setInitialized(true);
            document
                .getElementById(searchID)
                ?.addEventListener('keydown', event => keyDownEventListener(event));
        }

        return () => {
            document
                .getElementById(searchID)
                ?.removeEventListener('keydown', event => keyDownEventListener(event));
        };
    }, [searchID, dispatch, callbackSearch, initialized, deepSearch]);

    function updateNeedle(value: string) {
        setSearchNeedle(value);
        dispatch(updateSearchString(value));
    }

    function onKeyDownSearch(event: React.KeyboardEvent<HTMLInputElement> | KeyboardEvent) {
        const k = event.key;
        const { value } = document.getElementById(searchID) as HTMLInputElement;
        if (k === 'Enter' && deepSearch) {
            deepSearch(value.length === 0 || undefined || null ? null : value);
        }
    }

    return (
        <Wrapper data-testid="search">
            <Icon name="search" />
            <SearchBox
                id={searchID}
                autoFocus={autofocus}
                onChange={e => updateNeedle(e.currentTarget.value)}
                autoComplete="off"
                onKeyDown={event => onKeyDownSearch(event)}
                placeholder={`${t('Suche')}`}
                value={searchString !== '' ? searchString : ''}
            />
            {reloadDocuments && (
                <ActionControlButton
                    prefix="search-refresh"
                    name="search-refresh"
                    className="refresh-button"
                    callback={() => reloadDocuments.set(true)}
                />
            )}
        </Wrapper>
    );
};

const Wrapper = styled.div<ThemeProps>`
    padding: 0.3rem 0.4rem;
    width: 20rem;
    border: 1px solid ${props => props.theme.$colorSecondary};
    display: inline-flex;
    box-sizing: border-box;

    svg {
        height: 1.5rem;
        width: 1.5rem;
        margin: auto 0.5rem auto 0.2rem;
    }

    .refresh-button {
        margin: 0;
        border: none;
        background-color: inherit;
        padding: 0;
        height: 0;
    }
`;

const SearchBox = styled.input<ThemeProps>`
    width: 100%;
    border: none;
    line-height: 1.5rem;
`;

export default Search;
