import React, { FunctionComponent, ReactElement, useRef } from 'react';
import styled from 'styled-components';
import { useGlobalStore } from '../../../hooks/hooks';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { ThemeProps } from '../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import { UserInformation } from '../../../typings/mgmt';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';
import Icon from '../../../components/Icon/Icon';
import { DocumentCategoryProps } from '../../../interfaces/DocumentEntry';
import { ResponseError } from '../../../typings/error';
import { t } from '../../../lib/language';
import { TenantInformation } from '../../../store/config/types';

interface CurrentUserMetaInformationState {
    currentUserState?: UserInformation;
    currentTenantState?: [TenantInformation, Function];
    currentCategoryState?: [DocumentCategoryProps | undefined, Function];
    reloadTenants?: Function;
}

const MetaInformation: FunctionComponent<CurrentUserMetaInformationState> = ({
    currentUserState,
    currentTenantState,
    currentCategoryState,
    reloadTenants,
}): ReactElement => {
    const { dispatch } = useGlobalStore();
    const inputFile = useRef<HTMLInputElement>(null);

    const onBtnClick = () => {
        if (inputFile && inputFile.current) {
            inputFile.current.click();
        }
    };

    function handleFileUpload(event: React.ChangeEvent<HTMLInputElement>) {
        const acceptedFiles = event.target.files;
        if (acceptedFiles && currentTenantState && reloadTenants) {
            dispatch(startOperation());
            const reader = new FileReader();

            reader.onabort = () => {
                dispatch(addError(EErrorTypes.error, t('StatusCode.SpeacialCase.ImageReadFailed')));
                dispatch(finishOperation());
            };
            reader.onerror = () => {
                dispatch(addError(EErrorTypes.error, t('StatusCode.SpeacialCase.ImageReadFailed')));
                dispatch(finishOperation());
            };
            reader.onload = () => {
                const newTenantData: TenantInformation = {
                    ...currentTenantState[0],
                    logoData: reader.result,
                };
                AxiosClient.put(
                    `${requestURLs.tenants}/${currentTenantState[0].tenantID}`,
                    newTenantData
                )
                    .then(response => {
                        currentTenantState[1](response.data);
                        reloadTenants(true);
                    })
                    .catch(error => {
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        );
                    })
                    .finally(() => dispatch(finishOperation()));
            };
            reader.readAsDataURL(acceptedFiles[0]);
        }
    }

    return (
        <>
            {currentUserState && (
                <MetaInformationWrapper>
                    <ImageWrapper>
                        {currentUserState?.imageData ? (
                            <img
                                alt={currentUserState?.userName}
                                src={`data:image/png;base64, ${currentUserState?.imageData}`}
                            />
                        ) : (
                            <Icon
                                name="profile"
                                size="100"
                                stroke="#000"
                                fill=""
                            />
                        )}
                    </ImageWrapper>
                    <DetailsWrapper>
                        <p>
                            <span className="bold-text">Benutzername: </span>
                            {currentUserState?.userName}
                        </p>
                        <p>
                            <span className="bold-text">Name: </span>
                            {currentUserState?.fullName}
                        </p>
                        <p>
                            <span className="bold-text">E-Mail-Adresse: </span>
                            {currentUserState?.email}
                        </p>
                    </DetailsWrapper>
                </MetaInformationWrapper>
            )}
            {currentTenantState && (
                <MetaInformationWrapper>
                    <ImageWrapper>
                        <ChangeImage className="change-img-btn">
                            <input
                                type="file"
                                ref={inputFile}
                                accept="image/*"
                                onChange={e => handleFileUpload(e)}
                                style={{ display: 'none' }}
                            />
                            <ActionControlButton
                                className="edit-btn"
                                prefix="image"
                                name="edit"
                                callback={onBtnClick}
                                size={25}
                                fill
                            />
                        </ChangeImage>
                        {currentTenantState[0].logoData ? (
                            <img
                                alt={currentTenantState[0].name}
                                src={`${currentTenantState[0].logoData}`}
                            />
                        ) : (
                            <Icon
                                name="visus"
                                size="100"
                            />
                        )}
                    </ImageWrapper>
                    <DetailsWrapper>
                        <p>
                            <span className="bold-text">ID: </span>
                            {currentTenantState[0].tenantID}
                        </p>
                        <p>
                            <span className="bold-text">Name: </span>
                            {currentTenantState[0].name}
                        </p>
                        <p>
                            <span className="bold-text">Secured: </span>
                            {currentTenantState[0].secured.toString()}
                        </p>
                    </DetailsWrapper>
                </MetaInformationWrapper>
            )}
            {currentCategoryState && (
                <MetaInformationWrapper>
                    <DetailsWrapper>
                        {currentCategoryState[0] ? (
                            <>
                                <p>
                                    <span className="bold-text">ID: </span>
                                    {currentCategoryState[0].categoryID}
                                </p>
                                <p>
                                    <span className="bold-text">Title: </span>
                                    {currentCategoryState[0].title}
                                </p>
                                <p>
                                    <span className="bold-text">Parent: </span>
                                    {currentCategoryState[0].parentID}
                                </p>
                            </>
                        ) : (
                            <div />
                        )}
                    </DetailsWrapper>
                </MetaInformationWrapper>
            )}
        </>
    );
};

const ImageWrapper = styled.div<ThemeProps>`
    margin: auto 10px;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    border-radius: 50%;
    svg,
    img {
        height: 4rem;
        width: auto;
    }
    :hover {
        .change-img-btn {
            opacity: 0.7;
        }
    }
    .edit-btn {
        margin: auto;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;

        svg {
            height: 25px;
        }
    }
`;
const MetaInformationWrapper = styled.div<ThemeProps>`
    display: flex;
    border: solid;
    border-width: 0.5px;
    height: max-content;
    width: 65%;

    p {
        font-size: small;
    }

    .bold-text {
        font-weight: bold;
    }
`;

const ChangeImage = styled.div`
    width: 4rem;
    height: 4rem;
    margin: auto;
    overflow: hidden;
    border-radius: 50%;
    display: flex;
    flex-direction: row-reverse;
    opacity: 0;
    position: absolute;
    background-color: white;
    transition: opacity 0.5s;
`;

const DetailsWrapper = styled.div<ThemeProps>``;

export default MetaInformation;
