import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import ActionControlButton from '../../../../components/ActionControlButton/ActionControlButton';
import Icon from '../../../../components/Icon/Icon';
import { useGlobalStore } from '../../../../hooks/hooks';
import { ArticleEntry } from '../../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { makeTimeStampReadable } from '../../../../lib/util';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { ContentArticleAudit, ContentlessArticleAudit } from '../../../../typings/articleAudit';
import { ResponseError } from '../../../../typings/error';
import { ArticleDiffState, BoolSwapState, StringState } from '../../../../typings/global';
import LoadingScreen from '../../../../views/LoadingScreen';

interface ArticlesAuditProps {
    articleDiffData: ArticleDiffState;
    articleAuditVisible: BoolSwapState;
    reloadArticleAuditData: BoolSwapState;
    currentAudienceState?: StringState;
    actionOnClick?: () => void;
}

const ArticlesAudit: FunctionComponent<ArticlesAuditProps> = ({
    articleDiffData,
    articleAuditVisible,
    reloadArticleAuditData,
    currentAudienceState,
    actionOnClick,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const { currentArticle } = state.documentManager;
    const [localCurrentArticle, setLocalCurrentArticle] = useState<ArticleEntry>(currentArticle);
    const [articlesAuditList, setArticlesAuditList] = useState<ContentlessArticleAudit[] | null>(
        null
    );
    const [stateDataID, setStateDataID] = useState<string | null>(null);

    const getIconName = (articleAudit: ContentlessArticleAudit): ReactElement | null => {
        const className = 'audit-status';
        switch (articleAudit.status) {
            case 0:
                return null;
            case 10:
                return null;
            case 15:
                return (
                    <Icon
                        className={className}
                        name="edit"
                        fill="grey"
                        size="18"
                    />
                );
            case 20:
                return null;
            case 30:
                return null;
            case 35:
                return null;
            case 40:
                return (
                    <Icon
                        className={className}
                        name="ok"
                        fill="green"
                        size="18"
                    />
                );
            case 50:
                return null;
            case 90:
                return (
                    <Icon
                        className={className}
                        name="close"
                        stroke="red"
                        size="14"
                    />
                );
            case 100:
                return null;
            default:
                return null;
        }
    };

    useEffect(() => {
        if (
            articleAuditVisible.value &&
            localCurrentArticle.articleID !== currentArticle.articleID
        ) {
            articleAuditVisible.set(false);
            articleDiffData.set(null);
            setArticlesAuditList(null);
            setLocalCurrentArticle(currentArticle);
        }
        if (
            (articleAuditVisible.value && articlesAuditList === null) ||
            reloadArticleAuditData.value
        ) {
            AxiosClient.get(`${requestURLs.editorArticles}${currentArticle.articleID}/audit`)
                .then(response => {
                    const responseData: ContentlessArticleAudit[] = response.data;
                    setArticlesAuditList(responseData);
                    reloadArticleAuditData.set(false);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                });
        }
    }, [
        articlesAuditList,
        currentArticle.articleID,
        dispatch,
        articleAuditVisible,
        localCurrentArticle,
        currentArticle,
        articleDiffData,
        reloadArticleAuditData,
        stateDataID,
    ]);

    function buildAuditHeader(): ReactElement {
        return (
            <ArticlesAuditHeaderWrapper>
                <div className="styled-header">
                    <h3>Artikelhistorie</h3>
                    <ActionControlButton
                        prefix="close"
                        name="close"
                        className="close-btn"
                        callback={() => {
                            articleAuditVisible.set(false);
                            if (!actionOnClick) articleDiffData.set(null);
                        }}
                        size={26}
                    />
                </div>

                <div className="current-article">
                    <span>{`(${currentArticle.articleID})`}</span>
                    <p>{`${currentArticle.title}`}</p>
                </div>
            </ArticlesAuditHeaderWrapper>
        );
    }

    function handleSelection(event: React.MouseEvent<HTMLButtonElement>) {
        const element = event.currentTarget;
        const dataID = element.getAttribute('data-id');
        const selectedElements = document.getElementsByClassName('selected-rev');
        setStateDataID(dataID);
        if (currentAudienceState) currentAudienceState.set(dataID);

        if (selectedElements.length > 0) {
            element.classList.remove('selected-rev');
            Array.from(selectedElements).forEach(node => {
                node.classList.remove('selected-rev');
            });
        }
        if (dataID) {
            event.currentTarget.classList.add('selected-rev');
            const artID = dataID.split('_')[0];
            const revID = dataID.split('_')[1];
            dispatch(startOperation());
            AxiosClient.get(`${requestURLs.editorArticles}${artID}/audit/${revID}/content`)
                .then(response => {
                    const revData: ContentArticleAudit = response.data;
                    articleDiffData.set(revData);
                    if (actionOnClick) actionOnClick();
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        }
    }

    function buildAuditList(): ReactElement | ReactElement[] {
        if (articlesAuditList) {
            const auditList: ReactElement[] = [];
            articlesAuditList.forEach(articleAudit => {
                const dataID = `${articleAudit.articleID}_${articleAudit.rev}`;
                return auditList.push(
                    <span key={weakKey(articleAudit)}>
                        <button
                            type="button"
                            data-id={dataID}
                            className={`${articleAudit.rev} ${
                                stateDataID === `${articleAudit.articleID}_${articleAudit.rev}`
                                    ? 'selected-rev'
                                    : ''
                            } ${
                                currentAudienceState && dataID === currentAudienceState.value
                                    ? 'selected-rev'
                                    : ''
                            }`}
                            onClick={e => handleSelection(e)}
                        >
                            {`${makeTimeStampReadable(articleAudit.lastModifiedDate)}, von: ${
                                articleAudit.lastModifiedBy
                            }`}
                        </button>
                        {getIconName(articleAudit)}
                    </span>
                );
            });
            return auditList;
        }
        return <LoadingScreen />;
    }

    return articleAuditVisible.value ? (
        <ArticlesAuditWrapper>
            {buildAuditHeader()}
            {buildAuditList()}
        </ArticlesAuditWrapper>
    ) : (
        <div />
    );
};

const ArticlesAuditHeaderWrapper = styled.div<ThemeProps>`
    padding-bottom: 5px;
    border-bottom: 0.05rem dashed #72797f;

    span {
        display: flex;
        flex-direction: row;
        margin: auto 0;
        background-color: white;
        text-align: center;
        color: #72797f;
        font-style: italic;
        font-size: 13px !important;
    }

    p {
        margin: auto;
        padding: 6px 8px;
        padding-bottom:
        display: flex;
        flex-direction: row;
        text-align: center;
        position: relative;
        font-size: 13px !important;
        font-style: italic;
        word-break: break-word;
        color: #72797f;
    }

    h3 {
        text-align: left;
        width: 100%;
        margin: 0;
    }

    .current-article {
        display: flex;
        flex-direction: row;
    }

    .styled-header {
        display: flex;
        button {
            right: 0;
            padding: 0;
            border: none;
            background-color: inherit
        }
    }
`;

const ArticlesAuditWrapper = styled.div<ThemeProps>`
    max-width: 375px;
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
    padding: 0.2rem 0 0.2rem 0.5rem;
    flex: 1;
    overflow: auto;

    span {
        display: flex;
        flex-direction: row;
        justify-content: space-between;
        border-bottom: ${props => props.theme.$border.grey.dotted[20]};

        button {
            width: 100%;
            background-color: ${props => props.theme.$colorWhite};
            text-align: left;
            border: none;
            outline: none;
            box-shadow: none;
            padding: 0.3rem 0;
            font-size: 12px;
        }

        button:hover {
            background-color: ${props => props.theme.$colorGrey10};
        }
    }

    .selected-rev {
        background: antiquewhite;

        :hover {
            background: antiquewhite;
        }
    }

    .audit-status {
        align-self: center;
    }
`;

export default ArticlesAudit;
