import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';

interface ToolbarProps {
    btnPrefix: string;
    handler: (event: React.MouseEvent<HTMLButtonElement>) => void;
}

const Toolbar: FunctionComponent<ToolbarProps> = ({ btnPrefix, handler }): ReactElement => (
    <Wrapper>
        <ActionControlButton
            prefix={btnPrefix}
            name="add-document"
            callback={handler}
            fill
        />
        <ActionControlButton
            prefix={btnPrefix}
            name="duplicate"
            callback={handler}
            fill
        />
        <ActionControlButton
            prefix={btnPrefix}
            name="mdx"
            callback={handler}
            fill
        />
        <ActionControlButton
            prefix={btnPrefix}
            name="pdf"
            callback={handler}
            fill
        />
        <ActionControlButton
            prefix={btnPrefix}
            name="publish"
            callback={handler}
            fill
        />
        <ActionControlButton
            prefix={btnPrefix}
            name="save"
            callback={handler}
            fill
        />
        <ActionControlButton
            prefix={btnPrefix}
            name="delete"
            callback={handler}
            fill
        />
    </Wrapper>
);

export default Toolbar;

const Wrapper = styled.span<ThemeProps>`
    padding: 0.2rem;
    display: inline-flex;

    button {
        border: none;
        background-color: ${props => props.theme.$colorBackgroundPrimary};
        padding: 0.1rem;
        margin: 0.1rem;
    }
`;
