import React from 'react';
import { ThemeContext } from 'styled-components';
import { GlobalStore, IGlobalStore } from '..';

export const useGlobalStore = () => React.useContext<IGlobalStore>(GlobalStore);

export const useTheme = () => React.useContext(ThemeContext);

export interface IListElement {
    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    [key: string]: any;
}

export interface IList {
    [id: string]: IListElement;
}

export interface SortConfig {
    key: string;
    direction: string;
}

export const useSortableData = (items: IList, config: SortConfig | null = null) => {
    const [sortConfig, setSortConfig] = React.useState<SortConfig | null>(config);

    const sortedItems = React.useMemo(() => {
        // input is a dictionary
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        let sortedElements: IList = {};
        const unsortedValues: IListElement[] = JSON.parse(JSON.stringify(Object.values(items)));
        // sortConfig defines the attribute name and the direction used for sorting
        if (sortConfig !== null) {
            unsortedValues.sort((a: IListElement, b: IListElement) => {
                // make nested elements sortable
                const sk = sortConfig.key.split('.');
                let valA = JSON.parse(JSON.stringify(a));
                let valB = JSON.parse(JSON.stringify(b));
                let key = '';
                sk.forEach(k => {
                    const ok = k;
                    // eslint-disable-next-line no-param-reassign
                    if (k.endsWith('!')) k = k.substring(0, k.length - 1);
                    // check if a contains k
                    if (a[k] !== undefined) {
                        key += key !== '' ? `.[${a[k]}]` : `[${k}]`;
                        // only handle values of keys not ending with '!'
                        if (!ok.endsWith('!')) {
                            // if this is not the first key element evaluate a[k] on valA
                            valA = key === `[${k}]` ? a[k] : valA[a[k]];
                            valB = key === `[${k}]` ? b[k] : valB[b[k]];
                        }
                        // keys ending with '!' should be resolve to the value of the key in the search objects
                        else {
                            valA = a[k];
                            valB = b[k];
                        }
                    } else {
                        key = `${key}.[${k}]`;
                        valA = valA[k];
                        valB = valB[k];
                    }
                });

                if (valA < valB) {
                    return sortConfig.direction === 'ascending' ? -1 : 1;
                }
                if (valA > valB) {
                    return sortConfig.direction === 'ascending' ? 1 : -1;
                }
                return 0;
            });
            sortedElements = {};
            if (sortedElements)
                unsortedValues.forEach(e => {
                    sortedElements[e.internalDocumentID] = e;
                });
        }
        return Object.keys(sortedElements).length > 0 ? sortedElements : items;
    }, [items, sortConfig]);

    const requestSort = (key: string) => {
        let direction = 'ascending';
        if (sortConfig && sortConfig.key === key && sortConfig.direction === 'ascending') {
            direction = 'descending';
        }
        setSortConfig({ key, direction });
    };

    return { items: sortedItems, requestSort, sortConfig };
};
