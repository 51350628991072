/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { ArticleDTO } from '../models/ArticleDTO';
import type { JobDTO } from '../models/JobDTO';
import type { PublicationProps } from '../models/PublicationProps';
import type { VersionDTO } from '../models/VersionDTO';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class VersionsService {

    /**
     * Moves a version into another document
     * @param versionId
     * @param documentId
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static moveVersion(
        versionId: number,
        documentId: number,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{versionID}/to/{documentID}',
            path: {
                'versionID': versionId,
                'documentID': documentId,
            },
        });
    }

    /**
     * Adds a report to a version
     * @param versionId
     * @param articleId
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static addReport(
        versionId: number,
        articleId: number,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{versionID}/report/{articleID}',
            path: {
                'versionID': versionId,
                'articleID': articleId,
            },
        });
    }

    /**
     * Publish a version
     * @param versionId
     * @param lang
     * @param requestBody
     * @returns JobDTO OK
     * @throws ApiError
     */
    public static publishVersion(
        versionId: number,
        lang: string,
        requestBody?: PublicationProps,
    ): CancelablePromise<JobDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{versionID}/publish/{lang}',
            path: {
                'versionID': versionId,
                'lang': lang,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Adds an article to a version
     * @param versionId
     * @param articleId
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static addArticle(
        versionId: number,
        articleId: number,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{versionID}/articles/{articleID}',
            path: {
                'versionID': versionId,
                'articleID': articleId,
            },
        });
    }

    /**
     * Removes article from version
     * @param versionId
     * @param articleId
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static removeArticleFromVersion(
        versionId: number,
        articleId: number,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/versions/{versionID}/articles/{articleID}',
            path: {
                'versionID': versionId,
                'articleID': articleId,
            },
        });
    }

    /**
     * Publish multiple versions by an article
     * @param versionId
     * @param articleId
     * @param lang
     * @returns string OK
     * @throws ApiError
     */
    public static publishMultipleVersionsByArticle(
        versionId: number,
        articleId: number,
        lang: string,
    ): CancelablePromise<'100 CONTINUE' | '101 SWITCHING_PROTOCOLS' | '102 PROCESSING' | '103 EARLY_HINTS' | '103 CHECKPOINT' | '200 OK' | '201 CREATED' | '202 ACCEPTED' | '203 NON_AUTHORITATIVE_INFORMATION' | '204 NO_CONTENT' | '205 RESET_CONTENT' | '206 PARTIAL_CONTENT' | '207 MULTI_STATUS' | '208 ALREADY_REPORTED' | '226 IM_USED' | '300 MULTIPLE_CHOICES' | '301 MOVED_PERMANENTLY' | '302 FOUND' | '302 MOVED_TEMPORARILY' | '303 SEE_OTHER' | '304 NOT_MODIFIED' | '305 USE_PROXY' | '307 TEMPORARY_REDIRECT' | '308 PERMANENT_REDIRECT' | '400 BAD_REQUEST' | '401 UNAUTHORIZED' | '402 PAYMENT_REQUIRED' | '403 FORBIDDEN' | '404 NOT_FOUND' | '405 METHOD_NOT_ALLOWED' | '406 NOT_ACCEPTABLE' | '407 PROXY_AUTHENTICATION_REQUIRED' | '408 REQUEST_TIMEOUT' | '409 CONFLICT' | '410 GONE' | '411 LENGTH_REQUIRED' | '412 PRECONDITION_FAILED' | '413 PAYLOAD_TOO_LARGE' | '413 REQUEST_ENTITY_TOO_LARGE' | '414 URI_TOO_LONG' | '414 REQUEST_URI_TOO_LONG' | '415 UNSUPPORTED_MEDIA_TYPE' | '416 REQUESTED_RANGE_NOT_SATISFIABLE' | '417 EXPECTATION_FAILED' | '418 I_AM_A_TEAPOT' | '419 INSUFFICIENT_SPACE_ON_RESOURCE' | '420 METHOD_FAILURE' | '421 DESTINATION_LOCKED' | '422 UNPROCESSABLE_ENTITY' | '423 LOCKED' | '424 FAILED_DEPENDENCY' | '425 TOO_EARLY' | '426 UPGRADE_REQUIRED' | '428 PRECONDITION_REQUIRED' | '429 TOO_MANY_REQUESTS' | '431 REQUEST_HEADER_FIELDS_TOO_LARGE' | '451 UNAVAILABLE_FOR_LEGAL_REASONS' | '500 INTERNAL_SERVER_ERROR' | '501 NOT_IMPLEMENTED' | '502 BAD_GATEWAY' | '503 SERVICE_UNAVAILABLE' | '504 GATEWAY_TIMEOUT' | '505 HTTP_VERSION_NOT_SUPPORTED' | '506 VARIANT_ALSO_NEGOTIATES' | '507 INSUFFICIENT_STORAGE' | '508 LOOP_DETECTED' | '509 BANDWIDTH_LIMIT_EXCEEDED' | '510 NOT_EXTENDED' | '511 NETWORK_AUTHENTICATION_REQUIRED'> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{versionID}/articles/{articleID}/publish/{lang}',
            path: {
                'versionID': versionId,
                'articleID': articleId,
                'lang': lang,
            },
        });
    }

    /**
     * Change order of articles in a version
     * @param versionId
     * @param articleId
     * @param direction
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static moveArticle(
        versionId: number,
        articleId: number,
        direction: string,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{versionID}/articles/{articleID}/move/{direction}',
            path: {
                'versionID': versionId,
                'articleID': articleId,
                'direction': direction,
            },
        });
    }

    /**
     * Makes a copy of an article
     * @param versionId
     * @param articleId
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static copyArticle(
        versionId: number,
        articleId: number,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{versionID}/articles/{articleID}/copy',
            path: {
                'versionID': versionId,
                'articleID': articleId,
            },
        });
    }

    /**
     * Retrieves a Version by id
     * @param id
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static getVersion(
        id: number,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/versions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Updates a version by identifier
     * @param id
     * @param requestBody
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static update(
        id: number,
        requestBody: VersionDTO,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'PUT',
            url: '/api/versions/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Deletes a version by identifier
     * @param id
     * @returns string OK
     * @throws ApiError
     */
    public static delete(
        id: number,
    ): CancelablePromise<string> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/versions/{id}',
            path: {
                'id': id,
            },
        });
    }

    /**
     * Creates an article an add it ti the given version
     * @param versionId
     * @param requestBody
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static createReportForVersion(
        versionId: number,
        requestBody: ArticleDTO,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/versions/{versionID}/report',
            path: {
                'versionID': versionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Removes report from version
     * @param versionId
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static removeReportFromVersion(
        versionId: number,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'DELETE',
            url: '/api/versions/{versionID}/report',
            path: {
                'versionID': versionId,
            },
        });
    }

    /**
     * Adds an article to a version
     * @param versionId
     * @param requestBody
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static createArticleForVersion(
        versionId: number,
        requestBody: ArticleDTO,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/versions/{versionID}/articles',
            path: {
                'versionID': versionId,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Creates a new version for a document
     * @param id
     * @param requestBody
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static createNew(
        id: number,
        requestBody: VersionDTO,
    ): CancelablePromise<VersionDTO> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/versions/documents/{id}',
            path: {
                'id': id,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves meta data of all versions
     * @returns VersionDTO OK
     * @throws ApiError
     */
    public static getAll(): CancelablePromise<Array<VersionDTO>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/versions',
        });
    }

}
