import { useState, useCallback, useRef } from 'react';
import { useAuth, hasAuthParams } from 'react-oidc-context';

function parseJwt(token: string) {
    if (!token) {
        return {};
    }
    const base64Url = token.split('.')[1];
    const base64 = base64Url.replace(/-/g, '+').replace(/_/g, '/');
    const jsonPayload = decodeURIComponent(
        window
            .atob(base64)
            .split('')
            .map(c => `00${c.charCodeAt(0).toString(16)}`.slice(-2))
            .map(c => `%${c}`)
            .join('')
    );

    return JSON.parse(jsonPayload);
}

const removeParams = () => {
    const url = new URL(window.location.href);
    let changed = false;
    ['state', 'session_state', 'code', 'iss'].forEach(param => {
        if (url.searchParams.has(param)) {
            url.searchParams.delete(param);
            changed = true;
        }
    });
    if (changed) {
        window.history.replaceState({}, '', url.toString());
    }
};

export default (): {
    initSession: typeof initSession;
    extend: typeof extend;
    signIn: typeof signIn;
    signOut: typeof signOut;
    user: typeof auth.user;
} => {
    const [sessionInitialized, setSessionInitialized] = useState<boolean>(false);
    const timeoutId = useRef<number | undefined>(undefined);

    const auth = useAuth();

    const extend = useCallback(() => {
        const sessionTimeout = parseJwt(auth.user?.refresh_token ?? '').exp - Date.now() / 1000;
        if (timeoutId.current !== undefined) {
            window.clearTimeout(timeoutId.current);
        }
        timeoutId.current = window.setTimeout(() => {
            if (auth.isAuthenticated) {
                auth.signoutRedirect({
                    post_logout_redirect_uri: window.location.origin,
                });
            }
        }, sessionTimeout * 1000);
    }, [auth]);

    const initSession = useCallback(() => {
        if (!sessionInitialized && auth.user) {
            setSessionInitialized(true);
            removeParams();
            extend();
        }
    }, [auth.user, extend, sessionInitialized]);

    const signIn = useCallback(() => {
        if (!hasAuthParams() && !auth.isAuthenticated && !auth.activeNavigator && !auth.isLoading) {
            auth.signinRedirect();
        }
    }, [auth]);

    const signOut = useCallback(() => {
        if (auth.isAuthenticated) {
            auth.signoutRedirect({
                post_logout_redirect_uri: window.location.origin,
            });
        }
    }, [auth]);

    return { initSession, extend, signIn, signOut, user: auth.user };
};

export { AuthProvider } from 'react-oidc-context';
export type { AuthProviderProps } from 'react-oidc-context';
