/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */
import type { PublicationDTO } from '../models/PublicationDTO';
import type { PublishedDocumentDTO } from '../models/PublishedDocumentDTO';
import type { SearchInput } from '../models/SearchInput';

import type { CancelablePromise } from '../core/CancelablePromise';
import { OpenAPI } from '../core/OpenAPI';
import { request as __request } from '../core/request';

export class PublicationService {

    /**
     * @param audience
     * @param requestBody
     * @returns PublishedDocumentDTO OK
     * @throws ApiError
     */
    public static getPublicationListForAudience(
        audience: 'VISUS' | 'PARTNER' | 'CUSTOMER' | 'USER',
        requestBody: SearchInput,
    ): CancelablePromise<Record<string, PublishedDocumentDTO>> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/publications/audience/{audience}/search',
            path: {
                'audience': audience,
            },
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Persists a Publication from internal Compendium
     * @param requestBody
     * @returns any OK
     * @throws ApiError
     */
    public static persistPublication(
        requestBody: PublicationDTO,
    ): CancelablePromise<any> {
        return __request(OpenAPI, {
            method: 'POST',
            url: '/api/external/publications',
            body: requestBody,
            mediaType: 'application/json',
        });
    }

    /**
     * Retrieves the PDF content of a publication
     * @param id
     * @param a
     * @param v
     * @param l
     * @param report
     * @returns string OK
     * @throws ApiError
     */
    public static getPdfExternal(
        id: string,
        a: number,
        v?: string,
        l?: string,
        report?: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/publications/show/external/pdf',
            query: {
                'id': id,
                'v': v,
                'a': a,
                'l': l,
                'report': report,
            },
        });
    }

    /**
     * Retrieves the HTML content of a publication
     * @param id
     * @param v
     * @param a
     * @param l
     * @returns string OK
     * @throws ApiError
     */
    public static getHtmlExternal(
        id: string,
        v?: string,
        a?: number,
        l?: string,
    ): CancelablePromise<Array<string>> {
        return __request(OpenAPI, {
            method: 'GET',
            url: '/api/publications/show/external/html',
            query: {
                'id': id,
                'v': v,
                'a': a,
                'l': l,
            },
        });
    }

}
