import axios, { AxiosInstance, AxiosRequestConfig, AxiosResponse } from 'axios';

// eslint-disable-next-line no-shadow
export enum requestURLs {
    login = 'api/users/login',
    users = 'api/users',
    tenants = 'api/tenants',
    roles = 'api/roles',
    permissions = 'api/permissions',
    categories = 'api/categories',
    languages = 'api/languages',
    images = 'api/images',
    editorDocuments = 'api/documents/tenants/',
    compendiumDocuments = 'api/publications/tenant/',
    compendiumDocumentsAudience = 'api/publications/audience/',
    publications = 'api/publications/',
    editorSingleDocument = 'api/documents',
    editorArticles = 'api/articles/',
    versions = 'api/versions/',
    translations = 'api/translations/',
    imageTranslations = 'api/translations/images',
    mdx = 'api/mdx/',
    jobs = 'api/jobs/',
}

const defaultRequestConfig: AxiosRequestConfig = {
    baseURL: process.env.REACT_APP_BACKEND_SERVER,
    withCredentials: true,
    timeout: 60000,
    headers: {
        'content-type': 'application/json;charset=UTF-8',
    },
};

const axiosHandler = axios.create(defaultRequestConfig);

// eslint-disable-next-line no-use-before-define
let wrapperInstance: AxiosWrapper | null = null;

class AxiosWrapper {
    handler: AxiosInstance;

    requestConfig: AxiosRequestConfig = JSON.parse(JSON.stringify(defaultRequestConfig));

    constructor() {
        this.handler = axiosHandler;
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public put(url: string, data?: any) {
        return this.handler.put(url, data, this.requestConfig);
    }

    public get(url: string) {
        return this.handler.get(url, this.requestConfig);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public post(url: string, data?: any) {
        return this.handler.post(url, data, this.requestConfig);
    }

    public delete(url: string) {
        return this.handler.delete(url, this.requestConfig);
    }

    // eslint-disable-next-line @typescript-eslint/no-explicit-any
    public login(url: string, username: string, password: string): Promise<AxiosResponse<any>> {
        const authConfig: AxiosRequestConfig = {
            ...this.requestConfig,
            params: {},
        };
        return this.handler.post(url, { username, password }, authConfig);
    }

    public setAuthorizationHeader(token: string) {
        this.requestConfig = {
            ...this.requestConfig,
            headers: {
                ...this.requestConfig.headers,
                Authorization: `Bearer ${token}`,
            },
        };
    }

    public removeAuthorizationHeader() {
        this.requestConfig = JSON.parse(JSON.stringify(defaultRequestConfig));
    }
}

function getInstance(): AxiosWrapper {
    if (wrapperInstance === null) wrapperInstance = new AxiosWrapper();
    return wrapperInstance;
}

const AxiosClient: AxiosWrapper = getInstance();

export default AxiosClient;
