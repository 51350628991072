import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';

interface InPlaceEditorPreviewProps {
    disabled: boolean;
    setInputActive: Function;
    inputText: string;
    minStyles: boolean;
}

const InPlaceEditorPreview: FunctionComponent<InPlaceEditorPreviewProps> = ({
    disabled,
    setInputActive,
    inputText,
    minStyles,
}) => (
    <InPlaceInputPreview
        onClick={() => (disabled ? null : setInputActive(true))}
        disabled={disabled}
        minStyles={minStyles}
    >
        <InPlaceInputPreviewText
            className="in-place-editor-preview-text"
            disabled={disabled}
            minStyles={minStyles}
        >
            {inputText !== '' ? inputText : 'Empty'}
        </InPlaceInputPreviewText>
    </InPlaceInputPreview>
);

const InPlaceInputPreview = styled.div<ThemeProps & { disabled: boolean; minStyles: boolean }>`
    display: flex;
    max-width: ${props => (props.minStyles ? '100%' : 'fit-content')};
    padding: ${props => (props.minStyles ? '0' : '6px 8px')};
    justify-content: ${props => (props.minStyles ? 'center' : '')};

    :hover {
        background-color: #f5f5f5;

        .in-place-editor-preview-text {
            border: none;
            margin-bottom: ${props => (props.disabled || props.minStyles ? '0' : '1px')};
        }
    }
`;

const InPlaceInputPreviewText = styled.p<ThemeProps & { disabled: boolean; minStyles: boolean }>`
    margin: 0;
    padding: 0;
    border-bottom: ${props => (props.disabled || props.minStyles ? 'none' : '1px dashed')};
    font-size: ${props => props.theme.$headerStyles.small.p.fontSize}rem;
`;

export default InPlaceEditorPreview;
