import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { useGlobalStore } from '../../../hooks/hooks';
import { t } from '../../../lib/language';

function renderSpinner(working: boolean) {
    if (working)
        return (
            <Spinner
                src={`${process.env.REACT_APP_DIR_BASENAME}/icn/loading.gif`}
                alt="Lade..."
            />
        );
    return null;
}

const Footer: FunctionComponent = (): ReactElement => {
    const { state } = useGlobalStore();

    const { global, config } = state;
    return (
        <Wrapper data-testid="footer">
            <LeftFooter>
                {config.footer.leftFooter}
                <FooterLink href={t('Terms_URL')}>{t('Terms')}</FooterLink>
                <FooterLink href={t('Privacy_URL')}>{t('Privacy')}</FooterLink>
            </LeftFooter>
            {renderSpinner(global.working > 0)}
            <RightFooter>{config.footer.rightFooter}</RightFooter>
        </Wrapper>
    );
};

// prettier-ignore
const Wrapper = styled.footer<ThemeProps>`
    display: flex;
    flex-direction: row;
    background-color: ${props => props.theme.$colorWhite};
    font-size: ${props => props.theme.$headerStyles.small.h5.fontSize}rem;
    padding: 1rem 0;
    text-align: center;
    box-sizing: border-box;
    border-top: ${props => props.theme.$border.secondary.thin};
`;

const LeftFooter = styled.div<ThemeProps>`
    flex: 1;
    padding: 0 1rem;
    text-align: left;
`;

const FooterLink = styled.a`
    margin-left: 15px;
`;

const RightFooter = styled.div<ThemeProps>`
    flex: 1;
    padding: 0 1rem;
    text-align: right;
`;

const Spinner = styled.img<ThemeProps>`
    height: 15px;
    width: 15px;
`;

export default Footer;
