import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { BoolSwapState, StringState } from '../../../typings/global';

interface InPlaceEditorButtonProps {
    inPlaceEditorID: string;
    value: string;
    actionOnBlur: 'Cancel' | 'Save';
    showButtons: boolean;
    minStyles: boolean;
    submit: Function;
    inPutActiveState: BoolSwapState;
    inPlaceInputTextState: StringState;
}

const InPlaceEditorForm: FunctionComponent<InPlaceEditorButtonProps> = ({
    inPlaceEditorID,
    value,
    actionOnBlur,
    showButtons,
    minStyles,
    inPutActiveState,
    submit,
    inPlaceInputTextState,
}) => {
    function handleOnBlur(event: React.FocusEvent<HTMLFormElement, Element>) {
        if (!event.currentTarget.contains(event.relatedTarget)) {
            if (actionOnBlur === 'Cancel') {
                inPlaceInputTextState.set(value);
            } else inPlaceInputTextState.set(inPutActiveState.value);
            inPutActiveState.set(false);
        }
    }

    return (
        <form
            autoComplete="off"
            onBlur={e => handleOnBlur(e)}
            onSubmit={e => {
                e.preventDefault();
                e.stopPropagation();
                submit();
                inPutActiveState.set(false);
            }}
        >
            <InPlaceInput
                id={`in-place-editor#${inPlaceEditorID}`}
                defaultValue={inPlaceInputTextState.value}
                autoFocus
                onChange={e => inPlaceInputTextState.set(e.currentTarget.value)}
                minStyles={minStyles}
            />
            {showButtons && (
                <InPlaceEditorButtons>
                    <button
                        id="inplace-editor-buttons"
                        type="button"
                        onClick={() => inPutActiveState.set(false)}
                    >
                        ✗
                    </button>
                    <input
                        id="inplace-editor-buttons"
                        type="submit"
                        value="✓"
                    />
                </InPlaceEditorButtons>
            )}
        </form>
    );
};

const InPlaceInput = styled.input<ThemeProps & { minStyles: boolean }>`
    width: 100%;
    height: 18px;
    outline: 0 solid transparent;
    border: none;

    margin: ${props => (props.minStyles ? '0' : '6px 8px')};

    :focus {
        border-bottom: ${props => (props.minStyles ? 'none' : '1px solid #f29400')};
        text-align: ${props => (props.minStyles ? 'center' : '')};
    }
`;

const InPlaceEditorButtons = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row-reverse;

    #inplace-editor-buttons {
        margin: 2px;
        padding: 5px 10px;
        border: none;
        background-color: inherit;
        box-shadow: 0px 3px 4px rgba(0, 0, 0, 0.3);
        cursor: pointer;
    }

    #inplace-editor-buttons:hover {
        background-color: #f29400;
        transition: 1s;
    }
`;

export default InPlaceEditorForm;
