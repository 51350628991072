/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalStore } from '../../../../hooks/hooks';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { TenantInformation } from '../../../../store/config/types';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { ResponseError } from '../../../../typings/error';

interface PermissionsState {
    tenant: [TenantInformation, Function];
    newLengthOfTenantsList: [number, Function];
}

const EditTenantInformation: FunctionComponent<PermissionsState> = ({
    tenant,
    newLengthOfTenantsList,
}): ReactElement => {
    const { dispatch } = useGlobalStore();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [localTenantName, setLocalTenantName] = useState<string>(tenant[0].name);
    const [localTenant, setLocalTenant] = useState<TenantInformation>(tenant[0]);

    useEffect(() => {
        if (localTenantName !== tenant[0].name) {
            setLocalTenantName(tenant[0].name);
            dispatch(startOperation());
            AxiosClient.get(`${requestURLs.tenants}/${tenant[0].tenantID}`)
                .then(response => {
                    setLocalTenant(response.data as TenantInformation);
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
            setLocalTenant(tenant[0]);
            setIsDisabled(true);
        }
    }, [localTenantName, tenant, isDisabled, dispatch]);

    function saveChangedTenantInformation(data: TenantInformation) {
        const updateTenantWith = {
            name: data.name,
            logoData: data.logoData,
            secured: data.secured,
            mdxPackage: data.mdxPackage,
            autoNameImages: data.autoNameImages,
        };

        dispatch(startOperation());
        AxiosClient.put(`${requestURLs.tenants}/${data.tenantID}`, updateTenantWith)
            .then(response => {
                setIsDisabled(!isDisabled);
                tenant[1](response.data);
                newLengthOfTenantsList[1](0);
            })
            .catch(error =>
                dispatch(addError(EErrorTypes.error, (error.response.data as ResponseError).status))
            )
            .finally(() => dispatch(finishOperation()));
    }

    return (
        <EditTenantInformationWrapper>
            <EditTenantInformationInner>
                <p>Tenant Informationen</p>
                <form>
                    <label>
                        Name:
                        <input
                            type="text"
                            name="name"
                            value={localTenant.name}
                            disabled={isDisabled}
                            onChange={e =>
                                setLocalTenant({ ...localTenant, name: e.currentTarget.value })
                            }
                        />
                    </label>
                    <label>
                        mdX Package:
                        <input
                            type="text"
                            name="mdxPackage"
                            value={localTenant.mdxPackage}
                            disabled={isDisabled}
                            onChange={e =>
                                setLocalTenant({
                                    ...localTenant,
                                    mdxPackage: e.currentTarget.value,
                                })
                            }
                        />
                    </label>
                    <label>
                        Auto Name Images:
                        <label>
                            <input
                                type="radio"
                                disabled={isDisabled}
                                checked={localTenant.autoNameImages}
                                onChange={() =>
                                    setLocalTenant({
                                        ...localTenant,
                                        autoNameImages: !localTenant.autoNameImages,
                                    })
                                }
                            />
                            True
                        </label>
                        <label>
                            <input
                                type="radio"
                                disabled={isDisabled}
                                checked={!localTenant.autoNameImages}
                                onChange={() =>
                                    setLocalTenant({
                                        ...localTenant,
                                        autoNameImages: !localTenant.autoNameImages,
                                    })
                                }
                            />
                            False
                        </label>
                    </label>
                    <label>
                        Secured:
                        <label>
                            <input
                                type="radio"
                                disabled={isDisabled}
                                checked={localTenant.secured}
                                onChange={() =>
                                    setLocalTenant({
                                        ...localTenant,
                                        secured: !localTenant.secured,
                                    })
                                }
                            />
                            True
                        </label>
                        <label>
                            <input
                                type="radio"
                                disabled={isDisabled}
                                checked={!localTenant.secured}
                                onChange={() =>
                                    setLocalTenant({
                                        ...localTenant,
                                        secured: !localTenant.secured,
                                    })
                                }
                            />
                            False
                        </label>
                    </label>
                </form>
                {isDisabled ? (
                    <button
                        type="button"
                        onClick={() => setIsDisabled(!isDisabled)}
                    >
                        Bearbeiten
                    </button>
                ) : (
                    <button
                        type="button"
                        onClick={() => saveChangedTenantInformation(localTenant)}
                    >
                        Speichern
                    </button>
                )}
            </EditTenantInformationInner>
        </EditTenantInformationWrapper>
    );
};

const EditTenantInformationWrapper = styled.div<ThemeProps>`
    height: 75%;
    margin: 5px 10px;
    border: solid;
    border-width: 0.5px;
`;

const EditTenantInformationInner = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    border: solid;
    border-width: 0.5px;
    margin: 5px;

    p {
        margin: 5px;
        font-weight: bold;
    }

    form {
        display: flex;
    }

    label {
        margin: 5px 50px 0 0;
        font-size: small;
    }

    input {
        margin-left: 10px;
    }

    select {
        margin-left: 10px;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;

export default EditTenantInformation;
