/* eslint-disable jsx-a11y/label-has-associated-control */
import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalStore } from '../../../../hooks/hooks';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { t } from '../../../../lib/language';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { DocumentCategoryProps } from '../../../../interfaces/DocumentEntry';
import { ResponseError } from '../../../../typings/error';

interface PermissionsState {
    category: [DocumentCategoryProps | undefined, Function];
    newLengthOfCategoryList: [number, Function];
}

const EditCateogryInformation: FunctionComponent<PermissionsState> = ({
    category,
    newLengthOfCategoryList,
}): ReactElement => {
    const { dispatch } = useGlobalStore();
    const [isDisabled, setIsDisabled] = useState<boolean>(true);
    const [localCategoryName, setLocalCategoryName] = useState<string>(
        category[0] ? category[0].title : ''
    );
    const [localCategory, setLocalCategory] = useState<DocumentCategoryProps | undefined>(
        category[0]
    );

    useEffect(() => {
        if (category[0] && localCategoryName !== category[0].title) {
            setLocalCategoryName(category[0].title);
            setLocalCategory(category[0]);
            setIsDisabled(true);
        }
    }, [localCategoryName, category, isDisabled]);

    function saveChangedCategoryInformation(data: DocumentCategoryProps) {
        const updateCategoryWith = {
            title: data.title,
            parentID: data.parentID,
        };

        dispatch(startOperation());
        AxiosClient.put(`${requestURLs.categories}/${data.categoryID}`, updateCategoryWith)
            .then(response => {
                setIsDisabled(!isDisabled);
                category[1](response.data);
                newLengthOfCategoryList[1](0);
            })
            .catch(error =>
                dispatch(addError(EErrorTypes.error, (error.response.data as ResponseError).status))
            )
            .finally(() => dispatch(finishOperation()));
    }

    return (
        <EditCategoryInformationWrapper>
            <EditCategoryInformationInner>
                {localCategory && category[0] ? (
                    <>
                        <p>Category Informationen</p>
                        <form>
                            <label>
                                Title:
                                <input
                                    type="text"
                                    name="title"
                                    value={localCategory.title}
                                    disabled={isDisabled}
                                    onChange={e =>
                                        setLocalCategory({
                                            ...localCategory,
                                            title: e.currentTarget.value,
                                        })
                                    }
                                />
                            </label>
                            <label>
                                Parent:
                                <input
                                    type="text"
                                    disabled
                                    name="parent"
                                    value={localCategory.parentID}
                                    onChange={e =>
                                        setLocalCategory({
                                            ...localCategory,
                                            parentID: Number.parseInt(e.currentTarget.value, 10),
                                        })
                                    }
                                />
                            </label>
                        </form>
                        {isDisabled ? (
                            <button
                                type="button"
                                onClick={() => setIsDisabled(!isDisabled)}
                            >
                                Bearbeiten
                            </button>
                        ) : (
                            <button
                                type="button"
                                onClick={() => saveChangedCategoryInformation(localCategory)}
                            >
                                Speichern
                            </button>
                        )}
                    </>
                ) : (
                    <p className="center-p">{t('EditCategoryInformation')}</p>
                )}
            </EditCategoryInformationInner>
        </EditCategoryInformationWrapper>
    );
};

const EditCategoryInformationWrapper = styled.div<ThemeProps>`
    height: 75%;
    margin: 5px 10px;
    border: solid;
    border-width: 0.5px;
`;

const EditCategoryInformationInner = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    border: solid;
    border-width: 0.5px;
    margin: 5px;

    .center-p {
        text-align: center;
    }

    p {
        margin: 5px;
        font-weight: bold;
    }

    form {
        display: flex;
    }

    label {
        margin: 5px 50px 0 0;
        font-size: small;
    }

    input {
        margin-left: 10px;
    }

    select {
        margin-left: 10px;
    }

    button {
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;

export default EditCateogryInformation;
