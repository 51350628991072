import React, { FunctionComponent, ReactElement } from 'react';
import ErrorPage from './ErrorPage';
import Icon from '../components/Icon/Icon';

const ErrorPage404: FunctionComponent = (): ReactElement => (
    <div className="grid-container">
        <ErrorPage>
            <div className="errorPageWrapper">
                <div className="errorPage__icon">
                    <Icon
                        name="sad"
                        size="76"
                        fill="#d3665e"
                    />
                </div>
                <div className="ml-1 errorPage__message">
                    <h1>Seite nicht gefunden</h1>
                    <p>
                        {/* eslint-disable-next-line react/jsx-one-expression-per-line */}
                        Die Seite <b>{window.location.href}</b> konnte nicht gefunden werden.
                        <br />
                        Bitte überprüfen Sie ihren Link.
                    </p>
                </div>
            </div>
        </ErrorPage>
        <footer />
    </div>
);

export default ErrorPage404;
