import React, { useRef, useEffect, FunctionComponent } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';
import LoadingScreen from '../../views/LoadingScreen';
import ReviewFrameHeader from '../review/ReviewModule/ReviewFrame/ReviewFrameHeader';
import { ReviewDTO } from '../../lib/backend';
import { useGlobalStore } from '../../hooks/hooks';

interface IFrameProps {
    html: Document;
    review: ReviewDTO;
    loading: boolean;
}

const IFrame: FunctionComponent<IFrameProps> = ({ html, loading, review }) => {
    const { user } = useGlobalStore().state;
    const iframeRef = useRef<HTMLIFrameElement>(null);

    useEffect(() => {
        const iframe = iframeRef.current;
        if (iframe) {
            iframe.contentDocument?.open();
            iframe.contentDocument?.write(html.documentElement.outerHTML);
            iframe.contentDocument?.close();
        }
    }, [html]);

    if (loading)
        return (
            <IFrameWrapper>
                {review.createdBy !== user.fullName && <ReviewFrameHeader />}
                <LoadingScreen />
            </IFrameWrapper>
        );

    return (
        <IFrameWrapper>
            {review.createdBy !== user.fullName && <ReviewFrameHeader />}
            <IFrameCom
                title="preview"
                ref={iframeRef}
            />
        </IFrameWrapper>
    );
};

export default IFrame;

const IFrameWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    width: 75%;
    height: 100%;
    margin: auto;
    padding: 0.5rem;

    h3 {
        margin: 0;
    }
`;

const IFrameCom = styled.iframe<ThemeProps>`
    width: 100%;
    height: 92%;
    margin: 0 auto;
    border: none;
    overflow: auto;
    overflow-x: hidden;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;

    ins {
        background-color: #cfc;
        text-decoration: none;
    }

    del {
        color: #999;
        background-color: #fec8c8;
    }
`;
