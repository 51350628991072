import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import key from 'weak-key';
import { useGlobalStore } from '../../hooks/hooks';
import { ThemeProps } from '../../interfaces/globals/theme';
import { BoolSwapState } from '../../typings/global';
import ActionControlButton from '../ActionControlButton/ActionControlButton';
import JobEntry from './JobEntry';

interface JobListProps {
    showJobListState: BoolSwapState;
}

const JobList: FunctionComponent<JobListProps> = ({ showJobListState }): ReactElement => {
    const { state } = useGlobalStore();
    const { jobList } = state.jobs;

    function generateJobList(): ReactElement[] {
        const list: ReactElement[] = [];

        jobList.forEach(job => {
            list.push(
                <JobEntry
                    key={key(job)}
                    jobEntry={job}
                    isCurrentJob={false}
                />
            );
        });
        return list;
    }

    return (
        <JobEntryWrapper>
            <JobListHeader>
                <h3>Laufende Prozesse</h3>
                <ActionControlButton
                    prefix="close"
                    name="close"
                    className="close-btn"
                    callback={() => showJobListState.set(false)}
                    size={15}
                />
            </JobListHeader>
            <JobListWrapper className="styledScrollBar">{generateJobList()}</JobListWrapper>
        </JobEntryWrapper>
    );
};

const JobEntryWrapper = styled.div<ThemeProps>`
    position: fixed;
    height: 200px;
    width: 360px;
    right: 0;
    bottom: 52px;
    border: solid 1px;
    border-bottom: none;
    background-color: white;
`;

const JobListHeader = styled.div<ThemeProps>`
    display: flex;
    justify-content: center;
    border-bottom: 0.1rem solid;

    h3 {
        margin: 7px;
        font-size: 0.9rem;
    }

    .close-btn {
        position: absolute;
        margin: 10px;
        right: 0;
        padding: 0;
        border: none;
        background-color: inherit;
    }
`;

const JobListWrapper = styled.div<ThemeProps>`
    overflow: auto;
    height: 80%;
`;

export default JobList;
