import { TDocumentManagerReducerActions, DocumentManagerState } from './types';
import {
    DocumentEntry,
    ArticleEntry,
    VersionProps,
    DocumentDescriptionProps,
    ThumbnailProps,
    DocumentCategoryProps,
    ThumbnailPageProps,
    TranslationsEntries,
    TranslationEntryPreview,
} from '../../interfaces/DocumentEntry';
import ActionTypes from '../actionTypes';
import { PublicationMeta } from '../../typings/publication';
import { GermanAudiences } from '../../interfaces/Audiences';
import TranslationStatus from '../../interfaces/globals/job';

export const emptyDescription: DocumentDescriptionProps = {
    descriptionID: -1,
    languageID: 'c664e629-aa73-4459-87be-7ccf0fae6980',
    languageCode: 'de_DE',
    title: '',
    description: '',
    keywords: '',
    status: 0,
    changed: false,
    createdDate: null,
    createdBy: '',
    lastModifiedDate: null,
    lastModifiedBy: '',
};

export const emptyCategory: DocumentCategoryProps = {
    categoryID: -1,
    title: '',
    parentID: 0,
};

export const emptyDocument: DocumentEntry = {
    documentID: undefined,
    tenantID: undefined,
    internalDocumentID: '',
    title: '',
    formular: false,
    product: '',
    descriptions: {
        de_DE: JSON.parse(JSON.stringify(emptyDescription)),
    },
    categories: [],
    versions: [],
    createdDate: null,
    lastModifiedDate: null,
    lastTouched: null,
    createdBy: '',
    lastModifiedBy: '',
};

export const emptyArticle: ArticleEntry = {
    articleID: -1,
    title: '',
    status: 0,
    content: '',
    referencedImages: [],
    createdDate: null,
    lastModifiedDate: null,
    lastModifiedBy: '-/-',
    createdBy: '-/-',
};

export const emptyTranslationPreview: TranslationEntryPreview = {
    translationID: -1,
    status: TranslationStatus.DRAFT,
    languageCode: '',
};

export const emptyArticlePreview: TranslationsEntries = {
    [-1]: [emptyTranslationPreview],
};

export const emptyVersion: VersionProps = {
    versionID: -1,
    audience: 0,
    status: 0,
    author: '',
    coauthors: '',
    productVersion: '',
    articles: [],
    report: JSON.parse(JSON.stringify(emptyArticle)),
    publishedCheckMap: {
        de_DE: {
            published: false,
            upToDatePublication: false,
        },
    },
    createdDate: null,
    lastModifiedDate: null,
    createdBy: '',
    lastModifiedBy: '',
    changed: false,
};

export const emptyThumbnail: ThumbnailProps = {
    imageID: -1,
    title: '',
    data: undefined,
    createdDate: null,
    createdBy: '',
    lastModifiedDate: null,
    lastModifiedBy: '',
};

export const emptyThumbnailPage: ThumbnailPageProps = {
    content: [emptyThumbnail],
    totalElements: 0,
    totalPages: 0,
    first: false,
    last: false,
    number: 0,
    size: 0,
};

export const emptyPublication: PublicationMeta = {
    publicationID: -1,
    audience: -1,
    languageCode: 'de_DE',
    tenantID: '',
    internalDocumentID: '',
    author: '',
    product: '',
    productVersion: '',
    versionID: -1,
    title: '',
    description: '',
    whatsNew: '',
    keywords: '',
    activated: false,
    createdBy: '',
    createdDate: null,
    lastModifiedBy: '',
    lastModifiedDate: null,
    publicationDate: null,
};

export const documentManagerInitialState: DocumentManagerState = {
    currentPublications: JSON.parse(JSON.stringify([emptyPublication])),
    currentArticle: JSON.parse(JSON.stringify(emptyArticle)),
    currentDescription: JSON.parse(JSON.stringify(emptyDescription)),
    currentVersion: JSON.parse(JSON.stringify(emptyVersion)),
    currentTranslations: JSON.parse(JSON.stringify(emptyArticlePreview)),
    currentAudience: 0,
    documentChanged: false,
    articleChanged: false,
    audiences: GermanAudiences,
};

export const documentManagerReducer = (
    action: TDocumentManagerReducerActions,
    state: DocumentManagerState = documentManagerInitialState
): DocumentManagerState => {
    switch (action.type) {
        case ActionTypes.MARK_DOCUMENT_AS_CHANGED:
            return {
                ...state,
                documentChanged: action.payload.changeState,
            };

        case ActionTypes.MARK_ARTICLE_AS_CHANGED:
            return {
                ...state,
                articleChanged: action.payload.changeState,
            };

        case ActionTypes.LOAD_ARTICLE: {
            return {
                ...state,
                currentArticle: action.payload.article,
                articleChanged: false,
            };
        }

        case ActionTypes.SELECT_ARTICLE: {
            return {
                ...state,
                currentArticle: action.payload.article,
                articleChanged: false,
            };
        }

        case ActionTypes.SAVE_ARTICLE: {
            return {
                ...state,
                currentArticle: action.payload.article,
            };
        }

        case ActionTypes.LOAD_TRANSLATIONS: {
            return {
                ...state,
                currentTranslations: action.payload.translations,
            };
        }

        case ActionTypes.LOAD_PUBLICATIONS: {
            return {
                ...state,
                currentPublications: action.payload.publications,
            };
        }

        case ActionTypes.SELECT_AUDIENCE: {
            return {
                ...state,
                currentAudience: action.payload.audience,
            };
        }

        case ActionTypes.SELECT_VERSION: {
            let article: ArticleEntry;

            // don't change article if new version contains article with same ID
            if (
                action.payload.version.articles.filter(
                    a => a.articleID === state.currentArticle.articleID
                ).length === 1
            )
                [article] = action.payload.version.articles.filter(
                    a => a.articleID === state.currentArticle.articleID
                );
            // otherwise check if article with same title stripped of product version exists
            else if (
                action.payload.version.articles.filter(a =>
                    a.title.startsWith(
                        state.currentArticle.title.replace(
                            ` (${state.currentVersion.productVersion})`,
                            ''
                        )
                    )
                ).length === 1
            )
                [article] = action.payload.version.articles.filter(a =>
                    a.title.startsWith(
                        state.currentArticle.title.replace(
                            ` (${state.currentVersion.productVersion})`,
                            ''
                        )
                    )
                );
            // use the first article if the version has any article
            else if (action.payload.version.articles.length > 0)
                [article] = action.payload.version.articles;
            // or use an empty article as the last resort
            else article = emptyArticle;
            return {
                ...state,
                currentVersion: action.payload.version,
                currentArticle: article,
            };
        }

        case ActionTypes.SELECT_DESCRIPTION: {
            return {
                ...state,
                currentDescription: action.payload.description,
            };
        }

        default:
            return state;
    }
};
