import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import { useGlobalStore } from '../../../../../hooks/hooks';
import { ImageTranslationProps, ThumbnailProps } from '../../../../../interfaces/DocumentEntry';
import EDocumentState from '../../../../../interfaces/DocumentState';
import { EErrorTypes } from '../../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../../lib/axios';
import { addError, finishOperation, startOperation } from '../../../../../store/global/actions';
import { ResponseError } from '../../../../../typings/error';
import { BoolSwapState } from '../../../../../typings/global';
import ThumbnailHeader from './thumnbnailHeader/ThumbnailHeader';

interface IThumbnailProps {
    openState: boolean;
    reloadData: BoolSwapState;
    thumbnail: ThumbnailProps;
    showAdditionalControls: boolean;
    onDoubleClick: Function;
}

const Thumbnail: FunctionComponent<IThumbnailProps> = ({
    openState,
    reloadData,
    thumbnail,
    showAdditionalControls,
    onDoubleClick,
}) => {
    const { state, dispatch } = useGlobalStore();
    const { imageID } = thumbnail;
    const [imageTranslations, setImageTranslations] = useState<ImageTranslationProps[]>();

    useEffect(() => {
        if (!imageTranslations) {
            dispatch(startOperation());
            AxiosClient.get(`${requestURLs.imageTranslations}/thumbnails/image/${imageID}`)
                .then(response => {
                    setImageTranslations(response.data);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }, [dispatch, imageID, imageTranslations, reloadData.value]);

    function handleDrag(event: React.DragEvent<HTMLImageElement>) {
        if (thumbnail.imageID && thumbnail.title) {
            const dragContent = `![title][${thumbnail.title.replace(/\s+/g, '_')}]`;

            event.dataTransfer.setData('text/plain', dragContent);
        }
    }

    return (
        <ThumbnailWrapper
            id={`${thumbnail.imageID}`}
            className="thumbnail"
        >
            <ThumbnailHeader
                openState={openState}
                reloadData={reloadData}
                thumbnail={thumbnail}
                showAdditionalControls={showAdditionalControls}
            />
            <ThumbnailTitle>
                <p>
                    {imageTranslations &&
                        imageTranslations.map(imgTrans => {
                            const lang = Object.values(state.config.language.list).find(
                                lng => lng.languageID === imgTrans.languageID
                            );
                            if (lang) {
                                return (
                                    <img
                                        key={weakKey(imgTrans)}
                                        src={`/icn/flags/${lang.code}.svg`}
                                        alt="Selected flag"
                                        width="20px"
                                        height="10px"
                                    />
                                );
                            }
                            return null;
                        })}
                    {thumbnail.title}
                </p>
            </ThumbnailTitle>
            <img
                data-id={thumbnail.imageID}
                style={{ maxWidth: 150, maxHeight: 150 }}
                alt={thumbnail.title}
                src={thumbnail.data}
                draggable={state.documentManager.currentArticle.status < EDocumentState.Finalizing}
                onDragStart={e => handleDrag(e)}
                onDoubleClick={() => onDoubleClick(thumbnail)}
            />
        </ThumbnailWrapper>
    );
};

const ThumbnailWrapper = styled.div<ThemeProps>`
    display: flex;
    background-color: white;
    height: 150px;
    width: 150px;
    margin: 5px;
    position: relative;
    border: 0.5px solid black;

    ::hover {
        .btn-tool-bar {
            opacity: 0.9;
        }
    }

    .upload-btn {
        position: absolute;
        top: 50%;
        left: 50%;
        transform: translate(-50%, -50%);
        border: none;
        background-color: white;
    }
`;

const ThumbnailTitle = styled.div<ThemeProps>`
    width: 150px;
    position: absolute;
    background-color: white;
    word-break: break-all;
    opacity: 0.9;
    bottom: 0;

    p {
        margin: 10px 5px;
        font-size: 0.8rem;
    }
`;

export default Thumbnail;
