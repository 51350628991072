import React, { FunctionComponent, ReactElement } from 'react';
import { useNavigate } from 'react-router-dom';

interface LogoProps {
    file: string;
    height: number;
    target: string;
}

/* eslint-disable jsx-a11y/click-events-have-key-events, jsx-a11y/no-noninteractive-element-interactions */
const Logo: FunctionComponent<LogoProps> = ({ file, height, target }): ReactElement => {
    const history = useNavigate();
    const path = '/icn/sprite.svg';

    if (target !== '') {
        if (file) {
            return (
                <img
                    style={{ height, cursor: 'pointer' }}
                    src={file}
                    alt="Logo"
                    onClick={() => history(target)}
                />
            );
        }
        return (
            <svg
                xmlns="http://www.w3.org/2000/svg"
                xmlnsXlink="http://www.w3.org/1999/xlink"
                style={{ minHeight: 40, maxHeight: 40, minWidth: 145, maxWidth: 145 }}
            >
                <use
                    xmlns="http://www.w3.org/2000/svg"
                    xmlnsXlink="http://www.w3.org/1999/xlink"
                    xlinkHref={`${path}#icon-visus`}
                />
            </svg>
        );
    }
    return (
        <img
            style={{ height }}
            src={file}
            alt="Logo"
        />
    );
};

export default Logo;
