import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../../components/ActionControlButton/ActionControlButton';
import ConfirmationDialog from '../../../../../../components/dialogs/ConfirmationDialog';
import DropZone from '../../../../../../components/DropZone/DropZone';
import { useGlobalStore } from '../../../../../../hooks/hooks';
import { ThumbnailProps } from '../../../../../../interfaces/DocumentEntry';
import EDocumentState from '../../../../../../interfaces/DocumentState';
import { EErrorTypes } from '../../../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../../../lib/axios';
import { t } from '../../../../../../lib/language';
import { handleImageUpload } from '../../../../../../lib/util';
import { addError, finishOperation, startOperation } from '../../../../../../store/global/actions';
import { ResponseError } from '../../../../../../typings/error';
import { BoolSwapState } from '../../../../../../typings/global';
import ImageUploadEntry from './ImageUploadEntry';

interface ThumbnailHeaderProps {
    openState: boolean;
    reloadData: BoolSwapState;
    thumbnail: ThumbnailProps;
    showAdditionalControls: boolean;
}

const ThumbnailHeader: FunctionComponent<ThumbnailHeaderProps> = ({
    openState,
    reloadData,
    thumbnail,
    showAdditionalControls,
}) => {
    const { state, dispatch } = useGlobalStore();
    const { currentArticle } = state.documentManager;
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [showUpdate, setShowUpdate] = useState<boolean>(false);
    const [transferData, setTransferData] = useState<FileList>();
    const [showPreviewImg, setShowPreviewImg] = useState<boolean>(false);
    const [preview, setPreview] = useState<{ name: string; size: number } | undefined>();

    function addImageToArticle(thumb: ThumbnailProps) {
        const newImageReference = {
            articleID: currentArticle.articleID,
            imageID: thumb.imageID,
        };
        dispatch(startOperation());
        AxiosClient.post(
            `${requestURLs.editorArticles}${currentArticle.articleID}/images`,
            newImageReference
        )
            .then(() => {
                if (openState) reloadData.set(true);
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => dispatch(finishOperation()));
    }

    function deleteImageFromArticle() {
        const searchPattern = `[${thumbnail.title.replace(/\s+/g, '_')}]`;
        if (currentArticle.content.includes(searchPattern)) {
            dispatch(
                addError(EErrorTypes.warning, t('StatusCode.SpecialCase.DeleteImageFromArticle'))
            );
        } else {
            dispatch(startOperation());
            AxiosClient.delete(
                // eslint-disable-next-line max-len
                `${requestURLs.editorArticles}${state.documentManager.currentArticle.articleID}/images/${thumbnail.imageID}`
            )
                .then(() => {
                    reloadData.set(true);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }

    function updateImage(title: string) {
        const updateTitle = title !== '' && title.length !== 0 ? title : thumbnail.title;
        if (transferData) {
            handleImageUpload(
                updateTitle,
                reloadData,
                transferData,
                dispatch,
                undefined,
                thumbnail.imageID
            );
        } else if (!transferData && updateTitle) {
            AxiosClient.put(`${requestURLs.images}/${thumbnail.imageID}`, { title: updateTitle })
                .then(() => reloadData.set(true))
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }

    function handleOnDrop(e: React.DragEvent<HTMLDivElement>) {
        if (e.dataTransfer.files[0]) {
            setTransferData(e.dataTransfer.files);
            setPreview({ name: e.dataTransfer.files[0].name, size: e.dataTransfer.files[0].size });
            setShowPreviewImg(true);
        }
    }

    function cancelImageSelection() {
        setPreview(undefined);
        setTransferData(undefined);
        setShowPreviewImg(false);
    }

    return (
        <div>
            {state.documentManager.currentArticle.status < EDocumentState.Finalizing && (
                <ToolBarWrapper className="btn-tool-bar">
                    {showAdditionalControls && (
                        <>
                            <ActionControlButton
                                className="tool-bar-btns"
                                prefix="image"
                                name="delete"
                                callback={() => setShowConfirmation(true)}
                                size={25}
                                fill
                            />
                            <ActionControlButton
                                className="tool-bar-btns"
                                prefix="image"
                                name="edit"
                                callback={() => setShowUpdate(true)}
                                size={25}
                                fill
                            />
                        </>
                    )}

                    {!showAdditionalControls && (
                        <ActionControlButton
                            className="tool-bar-btns"
                            prefix="image"
                            name="add-document"
                            callback={() => addImageToArticle(thumbnail)}
                            size={25}
                            fill
                        />
                    )}
                </ToolBarWrapper>
            )}
            <ConfirmationDialog
                id="image-delete-confirmation"
                title="Bild aus dem Artikel entfernen"
                message="Wollen Sie das Bild wirklich aus dem Artikel entfernen?"
                onCancel={() => {
                    setShowConfirmation(false);
                }}
                onOK={() => {
                    deleteImageFromArticle();
                }}
                isOpenState={{
                    value: showConfirmation,
                    set: setShowConfirmation,
                }}
            />
            <ConfirmationDialog
                id="image-update-confirmation"
                title="Bild aktualisieren"
                message="Zum aktualisieren des Bildes legen Sie die neue Datei hier ab."
                onCancel={() => {
                    setShowUpdate(false);
                    setPreview(undefined);
                    setTransferData(undefined);
                    setShowPreviewImg(false);
                }}
                onOK={() => {
                    const inputValueFromDialog = (
                        document.getElementById('update-image-title') as HTMLInputElement
                    ).value;
                    updateImage(inputValueFromDialog);
                }}
                isOpenState={{
                    value: showUpdate,
                    set: setShowUpdate,
                }}
            >
                <>
                    Titel:{' '}
                    <input
                        id="update-image-title"
                        type="text"
                    />
                    <DropZone
                        id="image-update-dropzone"
                        handleOnDrop={handleOnDrop}
                    >
                        <InnerDropZone id="update-image-inner-dropzone">
                            <p>Ziehen Sie die Bilder in diesen Bereich</p>
                            {showPreviewImg && preview && (
                                <ImageUploadEntry
                                    name={preview.name}
                                    size={preview.size}
                                    callback={cancelImageSelection}
                                />
                            )}
                        </InnerDropZone>
                    </DropZone>
                </>
            </ConfirmationDialog>
        </div>
    );
};

export const ToolBarWrapper = styled.div<ThemeProps>`
    width: 150px;
    height: 30px;
    display: flex;
    flex-direction: row-reverse;
    opacity: 0;
    position: absolute;
    background-color: white;
    transition: opacity 0.5s;

    .tool-bar-btns {
        margin: 2px;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;

const InnerDropZone = styled.div<ThemeProps>`
    flex: 1;
    height: 150px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    border-color: #eeeeee;

    p {
        margin: auto;
    }

    .testclass {
        width: 100%;
        height: 50px;
        background-color: red;
    }
`;

export default ThumbnailHeader;
