/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

import type { LanguageDTO } from './LanguageDTO';
import type { ReviewUserDTO } from './ReviewUserDTO';

export type ReviewDTO = {
    /**
     * The creator of the entity.
     *
     * The name of the user who created the entity. The name must not be longer than 255 characters.
     */
    createdBy: string;
    /**
     * The date of creation for the entity.
     *
     * The date/time of the entity was created.
     */
    createdDate: string;
    /**
     * The modifier of the entity.
     *
     * The name of the user who last modified the entity. The name must not be longer than 255 characters.
     */
    lastModifiedBy: string;
    /**
     * The date of the last modification of an entity.
     *
     * The date/time of the entity was modified last.
     */
    lastModifiedDate: string;
    /**
     * The ID of the review.
     *
     * The ID has to be a long.
     */
    reviewID: number;
    /**
     * The ID of a version.
     *
     * The ID has to be a long.
     */
    versionID: number;
    /**
     * The start date of this review.
     *
     * The start date/time of this review.
     */
    reviewStartDate: string;
    /**
     * The end date of this review.
     *
     * The end date/time of this review.
     */
    reviewEndDate: string;
    /**
     * The status of this review.
     *
     * The status of this review.
     */
    status: ReviewDTO.status;
    /**
     * The user of this review.
     *
     * The user that need to check this review and set there status.
     */
    reviewUserList?: Array<ReviewUserDTO>;
    language: LanguageDTO;
};

export namespace ReviewDTO {

    /**
     * The status of this review.
     *
     * The status of this review.
     */
    export enum status {
        OPEN = 'OPEN',
        FINISHED = 'FINISHED',
        ABORTED = 'ABORTED',
        APPROVED = 'APPROVED',
        REWORK = 'REWORK',
    }


}

