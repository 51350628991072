import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import { useNavigate } from 'react-router';
import styled from 'styled-components';
import { Buffer } from 'buffer';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { IList, useGlobalStore } from '../../../hooks/hooks';
import ConfirmationDialog from '../../../components/dialogs/ConfirmationDialog';
import LoadingScreen from '../../../views/LoadingScreen';
import { addError, finishOperation } from '../../../store/global/actions';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { loadDocument } from '../../../lib/app/document';
import DocumentList from './DocumentList';
import { ResponseError } from '../../../typings/error';
import Search from '../../search/Search';
import { BoolSwapState, CurrentDocumentPropsType } from '../../../typings/global';
import { SearchRequestBody } from '../../../typings/search';
import { audienceSelected, documentMarkedAsModified } from '../../../store/document/actions';
import { ITenant } from '../../../store/config/types';

interface ListBrowserProps {
    expansion: number;
    reloadDocuments: BoolSwapState;
    currentDocumentProps: CurrentDocumentPropsType;
}

const DocumentBrowser: FunctionComponent<ListBrowserProps> = ({
    expansion = 0.3,
    reloadDocuments,
    currentDocumentProps,
}): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [showConfirmation, setShowConfirmation] = useState<boolean>(false);
    const [indexOfElementToLoad, setIndexOfElementToLoad] = useState<number | null>(null);
    const [startLoading, setStartLoading] = useState<boolean>(false);
    const [documents, setDocuments] = useState<IList | undefined>(undefined);
    const [tenant, setTenant] = useState<ITenant | null>(state.config.tenant.current);
    const [selectedLanguages, updateSelectedLanguages] = useState<{ [documentID: string]: string }>(
        {}
    );
    const history = useNavigate();
    const searchID = 'search_document';
    const defaultAudience = 0;

    function updateDescriptionsFromLoadedDocuments(elements: IList) {
        const selLanguages: { [internalDocumentID: string]: string } = {};

        Object.keys(elements).forEach(key => {
            selLanguages[elements[key].internalDocumentID] =
                state.config.language.current &&
                elements[key].descriptions[state.config.language.current.code]
                    ? state.config.language.current.code
                    : 'de_DE';
        });

        updateSelectedLanguages(selLanguages);

        const element = document.getElementsByClassName('highlightedDocument')[0];
        if (element) {
            const id = element.getAttribute('data-id');
            if (id !== null && state.config.tenant.current)
                loadDocument(
                    dispatch,
                    Number.parseInt(id, 10),
                    state.documentManager,
                    currentDocumentProps.set
                );
        }
    }

    function deepSearch(needle: string | null = null) {
        setStartLoading(true);
        if (state.config.tenant.current) {
            const url = `${requestURLs.editorDocuments}${state.config.tenant.current.tenantID}/search`;
            const requestBody: SearchRequestBody = {
                keywordSearch: null,
                deepSearch: null,
                languageCode: null,
            };

            if (needle) {
                if (needle.startsWith('$'))
                    requestBody.deepSearch = Buffer.from(needle).toString('base64');
                else requestBody.keywordSearch = Buffer.from(needle).toString('base64');
            }

            AxiosClient.post(url, requestBody)
                .then(response => {
                    const elements: IList = response.data;
                    setDocuments(elements);
                    updateDescriptionsFromLoadedDocuments(elements);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => {
                    setStartLoading(false);
                    dispatch(finishOperation());
                });
        }
    }

    function handleElementSelection(event: React.MouseEvent<HTMLButtonElement>) {
        const id = event.currentTarget.getAttribute('data-id');
        if (id !== null && Number.parseInt(id, 10) !== currentDocumentProps.value.documentID) {
            if (state.documentManager.documentChanged) {
                setIndexOfElementToLoad(Number.parseInt(id, 10));
                setShowConfirmation(true);
            } else {
                dispatch(audienceSelected(defaultAudience));
                loadDocument(
                    dispatch,
                    Number.parseInt(id, 10),
                    state.documentManager,
                    currentDocumentProps.set
                );
            }
        }
    }

    function handleOpenContentEditor() {
        if (state.documentManager.currentVersion.versionID !== -1)
            history('/editor', {
                state: { currentDocument: JSON.stringify(currentDocumentProps.value) },
            });
    }

    useEffect(() => {
        if (state.config.tenant.current !== tenant) {
            reloadDocuments.set(true);
            setTenant(state.config.tenant.current);
            deepSearch(state.global.searchString);
        }
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [reloadDocuments, state.config.tenant, state.global.searchString, tenant]);

    return (
        <Wrapper
            data-testid="list_browser"
            flex={expansion}
        >
            <div>
                <h4>Dokumentenübersicht</h4>
            </div>
            <SearchContainer>
                <Search
                    searchID={searchID}
                    deepSearch={deepSearch}
                    reloadDocuments={reloadDocuments}
                />
            </SearchContainer>
            <ConfirmationDialog
                id="document-select-confirmation"
                title="Änderungen vorhanden!"
                message="Bei einer Neuauswahl werden die Änderungen verworfen!"
                onCancel={() => {
                    setIndexOfElementToLoad(null);
                }}
                onOK={() => {
                    if (indexOfElementToLoad !== null) {
                        dispatch(audienceSelected(defaultAudience));
                        dispatch(documentMarkedAsModified(false));
                        loadDocument(
                            dispatch,
                            indexOfElementToLoad,
                            state.documentManager,
                            currentDocumentProps.set
                        );
                        setIndexOfElementToLoad(null);
                    }
                }}
                isOpenState={{
                    value: showConfirmation,
                    set: setShowConfirmation,
                }}
            />
            {startLoading ? (
                <LoadingScreen />
            ) : (
                <DocumentList
                    currentDocumentProps={currentDocumentProps}
                    searchID={searchID}
                    documents={documents}
                    selectedLanguages={selectedLanguages}
                    handleSelection={handleElementSelection}
                    handleDoubleClick={handleOpenContentEditor}
                />
            )}
        </Wrapper>
    );
};

interface WrapperStyleProps {
    flex: number;
}

const Wrapper = styled.div<ThemeProps & WrapperStyleProps>`
    display: flex;
    flex-direction: column;
    flex: ${props => props.flex};
    padding: 0.2rem;
    overflow: hidden;

    h4 {
        margin: 0;
        padding: 0;
    }
`;

const SearchContainer = styled.div<ThemeProps>`
    width: 95%;
    margin-top: 0.5rem;
    margin-bottom: 1rem;
`;

export default DocumentBrowser;
