/**
 * Normalizes the given locale to lower case characters.
 *
 * @param {string} locale the locale to normalize
 * @returns {string} the normalized locale
 */
export function normalize(locale: string): string {
    return locale;
}

/**
 * Port of array.find for Internet Explorer.
 *
 * Searches the given array for an item using the given predicate.
 *
 * @param {ReadonlyArray | Array} array the given
 * @param predicate boolean function to search for an item
 * @returns {T} the item, if it was found in the array and undefined otherwise
 */
export function find<T>(
    array: ReadonlyArray<T> | Array<T>,
    predicate: (item: T, index?: number, obj?: ReadonlyArray<T> | Array<T>) => boolean
): T | undefined {
    if (typeof Array.prototype.find === 'function') {
        return (array as Array<T>).find(predicate);
    }

    // Cover Internet Explorer
    for (let i = 0; i < array.length; i += 1) {
        const item = array[i];

        if (predicate(item, i, array)) {
            return item;
        }
    }

    return undefined;
}

/**
 * Checks if the given array contains the normalized item.
 *
 * @param {ReadonlyArray<string>} arr the array to search
 * @param {string} item the needle to search for
 * @returns {boolean} true if the item was found in the array and false otherwise
 */
export function containsNormalized(arr: ReadonlyArray<string>, item: string): boolean {
    const normalizedItem = normalize(item);

    return !!find(arr, curr => normalize(curr) === normalizedItem);
}

/**
 * Retrieves the language code from the given locale.
 *
 * @param {string} locale the locale to retrieve the language code from
 * @returns {string} the language code for the given locale
 */
export function languageCode(locale: string): string {
    return locale.split('_')[0];
}
