import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import weakKey from 'weak-key';
import { BoolSwapState } from '../../../typings/global';
import Modal from '../../../components/dialogs/Modal/Modal';
import { useGlobalStore } from '../../../hooks/hooks';
import { ThemeProps } from '../../../interfaces/globals/theme';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';
import { ElementListWrapper } from './DocumentList';
import { ButtonPanel } from '../../../components/dialogs/SharedStyles';
import { StyledNavButton } from '../../../components/navigation/NavButton/NavButton';
import { publishVersion } from '../../../lib/app/document';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { t } from '../../../lib/language';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import { PublicationMeta } from '../../../typings/publication';
import { DocumentEntry } from '../../../interfaces/DocumentEntry';

interface PublishDialogProps {
    openState: BoolSwapState;
    currentDocument: DocumentEntry;
}

const PublishModal: FunctionComponent<PublishDialogProps> = ({ openState, currentDocument }) => {
    const { state, dispatch } = useGlobalStore();
    const { currentDescription } = state.documentManager;
    const [whatsNewText, setWhatsNewText] = useState<string | null>(null);
    const [showWhatsNewField, setShowWhatsNewField] = useState<boolean>(false);
    const [selectedVersions, setSelectedVersions] = useState<number[]>([]);
    const [previousVersions, setPreviousVersions] = useState<number[]>(selectedVersions);
    const [checkForOutdatedDoc, setCheckForOutdatedDoc] = useState<boolean>(false);
    const [needWhatsNew, setNeedWhatsNew] = useState<boolean>(true);
    const [isPublicationVisible, setPublicationVisibility] = useState<boolean>(false);
    const languages = state.config.language.list;

    useEffect(() => {
        if (checkForOutdatedDoc) {
            setCheckForOutdatedDoc(false);
            const outdatedDoc = document.getElementsByClassName('selected-doc red');

            if (outdatedDoc.length > 0) setShowWhatsNewField(true);
            else setShowWhatsNewField(false);
        }
    }, [checkForOutdatedDoc]);

    useEffect(() => {
        if (openState.value) {
            if (selectedVersions.length !== previousVersions.length) {
                const version = currentDocument.versions.filter(v =>
                    selectedVersions.includes(v.versionID)
                )[0];
                if (version) {
                    dispatch(startOperation());
                    const urlID = `id=${currentDocument.internalDocumentID}`;
                    const urlV = `v=${version.productVersion}`;
                    const urlA = 'a=0';
                    const urlL = `l=${currentDescription.languageCode}`;

                    AxiosClient.get(
                        `${requestURLs.publications}find?${urlID}&${urlV}&${urlA}&${urlL}`
                    )
                        .then(response => {
                            const { activated } = response.data as PublicationMeta;
                            (
                                document.getElementById(
                                    'pub-visibility-checkbox'
                                ) as HTMLInputElement
                            ).checked = activated;

                            setPublicationVisibility(activated);
                            setPreviousVersions(selectedVersions);
                        })
                        .catch(() => {
                            setPublicationVisibility(false);
                            setPreviousVersions(selectedVersions);
                        })
                        .finally(() => dispatch(finishOperation()));
                }
            }

            if (selectedVersions.length === 0) {
                const test = document.getElementById('pub-visibility-checkbox') as HTMLInputElement;
                if (test) {
                    setPreviousVersions([]);
                    test.checked = false;
                }
            }
        }
    }, [
        currentDescription.languageCode,
        currentDocument.internalDocumentID,
        currentDocument.versions,
        dispatch,
        openState.value,
        previousVersions.length,
        selectedVersions,
    ]);

    function handleSelection(event: React.MouseEvent<HTMLButtonElement>) {
        const element = event.currentTarget;
        const versionIDAsString = event.currentTarget.getAttribute('data-id');

        if (versionIDAsString) {
            const versionID = parseInt(versionIDAsString, 10);
            if (element.classList.contains('selected-doc')) {
                const index = selectedVersions.indexOf(versionID);

                element.classList.remove('selected-doc');
                selectedVersions.splice(index, 1);
            } else {
                element.classList.add('selected-doc');
                setSelectedVersions(selectedVersions.concat(versionID));
            }
        }
        setCheckForOutdatedDoc(true);
    }

    function handleSelectAll() {
        const unselectedMatches = document.getElementsByClassName(
            'version-selection-publish-buttons'
        );
        const selectedMatches = document.getElementsByClassName('selected-doc');

        if (selectedMatches.length > 0)
            while (selectedMatches.length > 0) {
                const item = selectedMatches.item(0);

                if (item) {
                    const versionIDAsString = item.getAttribute('data-id');
                    if (versionIDAsString) {
                        const index = selectedVersions.indexOf(parseInt(versionIDAsString, 10));
                        selectedVersions.splice(index, 1);
                    }
                    item.classList.remove('selected-doc');
                }
            }
        else
            for (let i = 0; i < unselectedMatches.length; i += 1) {
                const item = unselectedMatches.item(i);
                if (item) {
                    const versionIDAsString = item.getAttribute('data-id');
                    if (versionIDAsString) {
                        selectedVersions.push(parseInt(versionIDAsString, 10));
                    }
                    item.classList.add('selected-doc');
                }
            }
        setCheckForOutdatedDoc(true);
    }

    const versions = () =>
        currentDocument.versions.map(v => {
            const lang = currentDescription.languageCode;

            if (!v.publishedCheckMap[lang]) return null;

            const { upToDatePublication } = v.publishedCheckMap[lang];
            const { published } = v.publishedCheckMap[lang];
            let className = 'version-selection-publish-buttons';

            if ((published && upToDatePublication) || (!published && lang !== 'de_DE')) return null;
            if (published && !upToDatePublication) className += ' red';
            return (
                <span key={weakKey(v)}>
                    <button
                        type="button"
                        onClick={event => handleSelection(event)}
                        data-id={v.versionID}
                        className={className}
                    >
                        {v.productVersion}
                    </button>
                </span>
            );
        });

    function publishVersions() {
        let doPublish = true;
        if (selectedVersions.length < 1)
            dispatch(addError(EErrorTypes.info, t('PublishModal.VersionIsEmpty')));
        else
            selectedVersions.forEach(vID => {
                const langCode = (
                    document.getElementById('language-selection-publish') as HTMLInputElement
                ).value;
                const filteredVersions = currentDocument.versions.filter(
                    version =>
                        needWhatsNew &&
                        version.versionID === vID &&
                        version.publishedCheckMap[langCode].published &&
                        !version.publishedCheckMap[langCode].upToDatePublication &&
                        (!whatsNewText || whatsNewText.length < 10)
                );
                if (filteredVersions.length > 0) doPublish = false;
                if (doPublish)
                    currentDocument.versions.forEach(version => {
                        if (version.versionID === vID) {
                            publishVersion(
                                dispatch,
                                version.versionID,
                                langCode,
                                () => null,
                                state.jobs.currentRunningJob,
                                whatsNewText,
                                isPublicationVisible
                            );
                            openState.set(false);
                            setSelectedVersions([]);
                        }
                    });
            });
        if (!doPublish && needWhatsNew) {
            dispatch(addError(EErrorTypes.info, t('PublishModal.WhatsNewToShort')));
        }
    }

    const handleCheckBoxChange = (event: React.MouseEvent<HTMLInputElement, MouseEvent>) => {
        const value = !event.currentTarget.checked;
        const textArea = document.getElementById(`${currentDocument.documentID}-input`);

        if (textArea) {
            if (!value) textArea.setAttribute('disabled', '');
            else textArea.removeAttribute('disabled');
        }
        setNeedWhatsNew(value);
    };

    return (
        <Modal
            id="publish-model"
            isOpen={openState.value}
            shouldCloseOnOverlayClick
        >
            <InnerModal>
                <ModalHeader>
                    <ButtonWrapper>
                        <ActionControlButton
                            prefix="close"
                            name="close"
                            className="close-btn"
                            callback={() => {
                                openState.set(false);
                                setSelectedVersions([]);
                                setPreviousVersions([]);
                            }}
                            size={26}
                        />
                    </ButtonWrapper>
                    <h3>{`Freigabe des Dokuments "${currentDocument.descriptions.de_DE.title}"`}</h3>
                </ModalHeader>
                <ModalBody>
                    <fieldset>
                        <legend>Wählen Sie eine Sprache aus</legend>
                        <select
                            name="language"
                            id="language-selection-publish"
                            disabled
                        >
                            {Object.keys(currentDocument.descriptions).map(key => (
                                <option
                                    key={weakKey(languages[key])}
                                    value={languages[key].code}
                                    selected={
                                        languages[key].code === currentDescription.languageCode
                                    }
                                >
                                    {languages[key].name}
                                </option>
                            ))}
                        </select>
                    </fieldset>
                    <fieldset>
                        <legend>Wählen Sie Versionen aus, die Publiziert werden sollen</legend>
                        <StyledNavButton
                            id="select-all-versions-to-publish"
                            renderas="button"
                            onClick={() => handleSelectAll()}
                        >
                            Alle auswählen
                        </StyledNavButton>
                        <ElementListWrapper>{versions()}</ElementListWrapper>
                    </fieldset>
                    <fieldset>
                        <legend>Sichtbarkeit der Publikation</legend>
                        <label htmlFor="pub-visibility">
                            <input
                                type="checkbox"
                                id="pub-visibility-checkbox"
                                onClick={e => setPublicationVisibility(e.currentTarget.checked)}
                            />
                            Publikation soll angezeigt werden
                        </label>
                    </fieldset>
                    {showWhatsNewField && (
                        <fieldset>
                            <legend>Geben Sie bitte eine Beschreibung der Neuerungen an</legend>
                            <label htmlFor="whats-new-label">
                                <input
                                    type="checkbox"
                                    id="whats-new-checkbox"
                                    onClick={handleCheckBoxChange}
                                />
                                Keine Inhaltlichen Änderungen
                            </label>
                            <TextField
                                id={`${currentDocument.documentID}-input`}
                                typeof="input"
                                onChange={e => setWhatsNewText(e.currentTarget.value)}
                            />
                        </fieldset>
                    )}
                </ModalBody>
                <ButtonPanel>
                    <StyledNavButton
                        id=""
                        renderas="button"
                        onClick={() => publishVersions()}
                    >
                        OK
                    </StyledNavButton>
                    <StyledNavButton
                        id=""
                        renderas="button"
                        onClick={() => {
                            openState.set(false);
                            setSelectedVersions([]);
                            setPreviousVersions([]);
                        }}
                    >
                        Abbrechen
                    </StyledNavButton>
                </ButtonPanel>
            </InnerModal>
        </Modal>
    );
};

const InnerModal = styled.div<ThemeProps>`
    margin-top: 2rem;
    max-width: 600px;
`;
const ModalHeader = styled.div<ThemeProps>``;
const ModalBody = styled.div<ThemeProps>`
    input {
        :focus {
            outline: none;
        }
    }
    .selected-doc {
        background: antiquewhite;

        :hover {
            background: antiquewhite;
        }
    }

    .red {
        color: red;
    }
`;

export const ButtonWrapper = styled.div<ThemeProps>`
    .close-btn {
        margin: 10px;
        padding: 0;
        right: 0%;
        top: 0%;
        justify-content: flex-end;
        position: absolute;
        border: none;
        background-color: inherit;
    }
`;

const TextField = styled.textarea<ThemeProps>`
    width: 97%;
    height: 100px;
    -moz-border-bottom-colors: none;
    -moz-border-left-colors: none;
    -moz-border-right-colors: none;
    -moz-border-top-colors: none;
    background: none repeat scroll 0 0 rgba(0, 0, 0, 0.07);
    border-color: -moz-use-text-color #ffffff #ffffff -moz-use-text-color;
    border-image: none;
    border-radius: 6px 6px 6px 6px;
    border-style: none solid solid none;
    border-width: medium 1px 1px medium;
    box-shadow: 0 1px 2px rgba(0, 0, 0, 0.12) inset;
    color: #555555;
    font-family: 'Helvetica Neue', Helvetica, Arial, sans-serif;
    font-size: 1em;
    line-height: 1.4em;
    padding: 5px 8px;
    transition: background-color 0.2s ease 0s;

    :focus {
        background: none repeat scroll 0 0 #ffffff;
        outline-width: 0;
    }
`;

export default PublishModal;
