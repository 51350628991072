import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useGlobalStore } from '../../hooks/hooks';
import { ThemeProps } from '../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../lib/axios';
import { LanguageInformation } from '../../typings/mgmt';
import SideView from './sideview/SideView';
import DetailsView from './detailsview/DetailView';
import LoadingScreen from '../../views/LoadingScreen';
import loadAllTenants from '../../lib/app/tenant';
import { addError, finishOperation, startOperation } from '../../store/global/actions';
import { EErrorTypes } from '../../interfaces/globals/errors';
import { DocumentCategoryProps } from '../../interfaces/DocumentEntry';
import { ResponseError } from '../../typings/error';
import { TenantInformation } from '../../store/config/types';
import ErrorPage401 from '../../views/ErrorPage401';
import { validatePermissions } from '../../lib/util';

const Management: FunctionComponent = (): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [navigationState, setNavigationState] = useState<string>('tenants');
    const [tenants, setTenants] = useState<{ [tenantID: number]: TenantInformation }>({});
    const [newLengthOfTenantsList, setNewLengthOfTenantsList] = useState<number>(
        Object.entries(tenants).length
    );
    const [categories, setCategories] = useState<{ [categoryID: number]: DocumentCategoryProps }>(
        {}
    );
    const [newLengthOfCategoryList, setNewLengthOfCategoryList] = useState<number>(
        Object.entries(categories).length
    );
    const [languages, setLanguages] = useState<{ [languageID: number]: LanguageInformation }>({});
    const [newLengthOfLanguageList, setNewLengthOfLanguageList] = useState<number>(
        Object.entries(languages).length
    );
    const [currentTenant, setCurrentTenant] = useState<TenantInformation>();
    const [currentCategory, setCurrentCategory] = useState<DocumentCategoryProps>();
    const [currentLanguage, setCurrentLanguage] = useState<LanguageInformation>();
    const [reloadTenants, setReloadTenants] = useState(false);
    const [initialized, setInitialized] = useState(false);

    useEffect(() => {
        if (
            Object.entries(tenants).length === 0 ||
            (Object.entries(tenants).length !== newLengthOfTenantsList &&
                navigationState === 'tenants')
        ) {
            dispatch(startOperation());
            AxiosClient.get(`${requestURLs.tenants}`)
                .then(response => {
                    const tenantsResponse = response.data as TenantInformation[];
                    const tList: { [tenantID: string]: TenantInformation } = {};
                    tenantsResponse.forEach(tr => {
                        tList[tr.tenantID] = tr;
                    });
                    setTenants(tList);
                    setNewLengthOfTenantsList(tenantsResponse.length);
                    if (Object.entries(tenants).length === 0) {
                        setCurrentTenant(tenantsResponse[0]);
                    }
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
        if (reloadTenants) {
            AxiosClient.get(`${requestURLs.tenants}`)
                .then(response => {
                    loadAllTenants(dispatch);
                    setTenants(response.data);
                    setReloadTenants(false);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }, [dispatch, navigationState, newLengthOfTenantsList, reloadTenants, tenants]);

    useEffect(() => {
        if (
            (Object.entries(categories).length === 0 && !initialized) ||
            (Object.entries(categories).length !== newLengthOfCategoryList &&
                navigationState === 'categories')
        ) {
            setInitialized(true);
            dispatch(startOperation());
            AxiosClient.get(`${requestURLs.categories}`)
                .then(response => {
                    const categoryResponse = response.data;
                    setCategories(categoryResponse);
                    setNewLengthOfCategoryList(Object.entries(categoryResponse).length);
                    if (Object.entries(categories).length === 0) {
                        setCurrentCategory(categoryResponse[0]);
                    }
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }, [categories, dispatch, initialized, navigationState, newLengthOfCategoryList]);

    useEffect(() => {
        if (
            Object.entries(languages).length === 0 ||
            (Object.entries(languages).length !== newLengthOfLanguageList &&
                navigationState === 'languages')
        ) {
            dispatch(startOperation());
            AxiosClient.get(`${requestURLs.languages}`)
                .then(response => {
                    const languageResponse = response.data;
                    setLanguages(languageResponse);
                    setNewLengthOfLanguageList(Object.entries(languageResponse).length);
                    if (Object.entries(languageResponse).length === 0) {
                        setCurrentLanguage(languageResponse[0]);
                    }
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(dispatch(finishOperation()));
        }
    }, [dispatch, languages, navigationState, newLengthOfLanguageList]);

    if (
        state.config.tenant.current === null ||
        state.user.userName === '' ||
        !validatePermissions(
            state.config.tenant.list.find(
                tenant =>
                    tenant.name.toUpperCase() ===
                    Object.keys(state.user.permissions).find(key => {
                        if (Object.values(state.user.permissions[key]).includes('ADMIN')) {
                            return key;
                        }
                        return '';
                    })
            ) || state.config.tenant.current,
            state.user.permissions,
            ['ADMIN']
        )
    )
        return <ErrorPage401 />;

    return (
        <ManagementWrapper>
            {currentTenant ? (
                <>
                    <SideView
                        tenants={[tenants, setTenants]}
                        categories={categories}
                        languages={languages}
                        currentTenantState={[currentTenant, setCurrentTenant]}
                        currentCategoryState={[currentCategory, setCurrentCategory]}
                        currentLanguageState={[currentLanguage, setCurrentLanguage]}
                        newLengthOfTenantsList={[newLengthOfTenantsList, setNewLengthOfTenantsList]}
                        newLengthOfCategoryList={[
                            newLengthOfCategoryList,
                            setNewLengthOfCategoryList,
                        ]}
                        newLengthOfLanguageList={[
                            newLengthOfLanguageList,
                            setNewLengthOfLanguageList,
                        ]}
                        toggleNavigationState={[navigationState, setNavigationState]}
                    />
                    <DetailsView
                        currentTenantState={[currentTenant, setCurrentTenant]}
                        currentCategoryState={[currentCategory, setCurrentCategory]}
                        currentLanguageState={[currentLanguage, setCurrentLanguage]}
                        newLengthOfTenantsList={[newLengthOfTenantsList, setNewLengthOfTenantsList]}
                        newLengthOfCategoryList={[
                            newLengthOfCategoryList,
                            setNewLengthOfCategoryList,
                        ]}
                        activeTap={navigationState}
                        reloadTenants={setReloadTenants}
                    />
                </>
            ) : (
                <LoadingScreen />
            )}
        </ManagementWrapper>
    );
};

const ManagementWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    flex: 1;
`;

export default Management;
