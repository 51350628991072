import ActionTypes from '../actionTypes';
import { TGlobalReducerActions } from './types';
import { EErrorTypes } from '../../interfaces/globals/errors';

export function startOperation(): TGlobalReducerActions {
    return {
        type: ActionTypes.START_OPERATION,
        payload: {},
    };
}

export function finishOperation(): TGlobalReducerActions {
    return {
        type: ActionTypes.FINISH_OPERATION,
        payload: {},
    };
}

export function addError(type: EErrorTypes, message: string): TGlobalReducerActions {
    return {
        type: ActionTypes.ADD_ERROR,
        payload: {
            error: {
                type,
                message: message || 'Die Anfrage konnte nicht verarbeitet werden',
            },
        },
    };
}

export function removeError(type: EErrorTypes, message: string): TGlobalReducerActions {
    return {
        type: ActionTypes.REMOVE_ERROR,
        payload: {
            error: {
                type,
                message,
            },
        },
    };
}

export function updateSearchString(searchString: string): TGlobalReducerActions {
    return {
        type: ActionTypes.UPDATE_SEARCH_STRING,
        payload: {
            searchString,
        },
    };
}
