import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ArticleEntry } from '../../../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../../../interfaces/globals/theme';

interface ArticleRecordProps {
    article: ArticleEntry;
    headingSelectionListener: Function;
}

const ArticleRecord: FunctionComponent<ArticleRecordProps> = ({
    article,
    headingSelectionListener,
}) => {
    function buildArticleRecord() {
        const headingNumbers = [0, 0, 0, 0, 0, 0];
        const articleRecords: ReactElement[] = [];
        let lineNumber = 0;
        article.content.split('\n').forEach(line => {
            lineNumber += 1;
            const trimedLine = line.trim();
            if (trimedLine.startsWith('#')) {
                let heading = 1;
                while (trimedLine[heading] === trimedLine[heading - 1]) heading += 1;
                // increment counter for this heading
                headingNumbers[heading - 1] += 1;
                // reset subheadings
                for (let i = heading; i < headingNumbers.length; i += 1) headingNumbers[i] = 0;
                articleRecords.push(
                    <li
                        key={`${article.articleID}_${lineNumber}`}
                        className={`heading${heading}`}
                    >
                        <HeadingButton
                            type="button"
                            onClick={() => headingSelectionListener(article.articleID, trimedLine)}
                        >
                            {`${headingNumbers
                                .filter((_, idx) => idx < heading)
                                .join('.')} ${trimedLine.substring(heading).trim()}`}
                        </HeadingButton>
                    </li>
                );
            }
        });
        if (articleRecords.length === 0) articleRecords.push(<br key="br" />);
        return articleRecords;
    }

    return <>{buildArticleRecord()}</>;
};

const HeadingButton = styled.button<ThemeProps>`
    border: none;
    margin: 0;
    padding: 0;
    text-align: left;
    background-color: inherit;
`;

export default ArticleRecord;
