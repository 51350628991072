import React, { FunctionComponent } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';
import ButtonToolBar from '../../../../../components/ButtonToolBar/ButtonToolBar';
import { useGlobalStore } from '../../../../../hooks/hooks';
import { ArticleEntry } from '../../../../../interfaces/DocumentEntry';
import { addError, finishOperation, startOperation } from '../../../../../store/global/actions';
import AxiosClient, { requestURLs } from '../../../../../lib/axios';
import { EErrorTypes } from '../../../../../interfaces/globals/errors';
import { ResponseError } from '../../../../../typings/error';
import { t } from '../../../../../lib/language';
import { BoolSwapState } from '../../../../../typings/global';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import InPlaceEditor from '../../../../../components/InPlaceEditor/InPlaceEditor';

interface ArticleRecordHeaderProps {
    article: ArticleEntry;
    locked: boolean;
    saveListener: Function;
    documentModalOpenState: BoolSwapState;
    showConfirmationDialog: BoolSwapState;
    isReport?: boolean;
}

const ArticleRecordHeader: FunctionComponent<ArticleRecordHeaderProps> = ({
    article,
    saveListener,
    documentModalOpenState,
    showConfirmationDialog,
    locked,
    isReport,
}) => {
    const { state, dispatch } = useGlobalStore();
    const { currentArticle } = state.documentManager;

    function editArticleTitle(changedArticle: ArticleEntry) {
        if (changedArticle && state.documentManager.currentVersion) {
            dispatch(startOperation());
            AxiosClient.put(
                `${requestURLs.editorArticles}${state.documentManager.currentArticle.articleID}`,
                changedArticle
            )
                .then(() => {
                    saveListener();
                    dispatch(
                        addError(EErrorTypes.info, t('StatusCode.SpeacialCase.ChangeArticleTitle'))
                    );
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => {
                    dispatch(finishOperation());
                });
        } else dispatch(addError(EErrorTypes.error, `${t('StatusCode.General.Failed')}`));
    }

    function switchArticle(direction: string) {
        if (state.documentManager.currentArticle.articleID) {
            dispatch(startOperation());
            AxiosClient.put(
                // eslint-disable-next-line max-len
                `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/articles/${state.documentManager.currentArticle.articleID}/move/${direction}`
            )
                .then(() => {
                    saveListener();
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.warning, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => {
                    dispatch(finishOperation());
                });
        }
    }

    return (
        <ArticleRecordHeaderWrapper>
            {state.documentManager.currentDescription.languageCode === 'de_DE' && (
                <ButtonToolBar
                    className={article.articleID === currentArticle.articleID ? 'active' : ''}
                >
                    <ActionControlButton
                        prefix="delete"
                        name="delete"
                        size={15}
                        callback={() => showConfirmationDialog.set(true)}
                    />
                    {!isReport && (
                        <>
                            <ActionControlButton
                                className="arrow-down"
                                prefix="down"
                                name="arrow-right"
                                size={15}
                                callback={() => switchArticle('down')}
                            />
                            <ActionControlButton
                                className="arrow-up"
                                prefix="up"
                                name="arrow-right"
                                size={15}
                                callback={() => switchArticle('up')}
                            />
                        </>
                    )}
                    <ActionControlButton
                        className="doc"
                        prefix="icon"
                        name="doc"
                        size={15}
                        callback={() => documentModalOpenState.set(true)}
                    />
                </ButtonToolBar>
            )}
            <span className="article-title-wrapper">
                ({article.articleID})
                {state.documentManager.currentArticle.articleID === article.articleID ? (
                    <InPlaceEditor
                        id="article-title"
                        value={article.title}
                        showButtons={false}
                        disabled={locked}
                        submit={() =>
                            editArticleTitle({
                                ...article,
                                title:
                                    (
                                        document.getElementById(
                                            'in-place-editor#article-title'
                                        ) as HTMLInputElement
                                    ).value || state.documentManager.currentDescription.title,
                            })
                        }
                    />
                ) : (
                    <div className="non-editable-title">{article.title}</div>
                )}
            </span>
        </ArticleRecordHeaderWrapper>
    );
};

const ArticleRecordHeaderWrapper = styled.div<ThemeProps>`
    .article-title-wrapper {
        margin: 0 auto;
        background-color: white;
        text-align: center;
        color: ${props => props.theme.$colorSecondary};
        font-style: italic;
        display: flex;
        flex-direction: row;
        font-size: 13px !important;

        .non-editable-title {
            flex: 1;
            text-align: center;
            margin: 0 28px 0 0;
            padding: 5px 8px;
        }
    }

    #article-title {
        display: flex;
        width: 100%;
        justify-content: center;
        margin: 0 auto;
        background-color: white;
        text-align: center;

        span {
            color: ${props => props.theme.$colorSecondary};
            font-style: italic;
            border-bottom: 0;
        }
    }

    .e-editable-value-wrapper {
        width: 100%;
    }

    .arrow-down {
        width: 15px;
        height: 15px;
        transform: rotate(90deg);
    }

    .arrow-up {
        width: 15px;
        height: 15px;
        transform: rotate(-90deg);
    }

    .active.btn-tool-bar {
        opacity: 1;
    }

    .title {
        margin: 0 auto;
        max-width: 350px;
        text-align: center;
        color: ${props => props.theme.$colorSecondary};
    }

    .heading2 {
        margin-left: 0.2rem;
    }
    .heading3 {
        margin-left: 0.4rem;
    }
    .heading4 {
        margin-left: 0.6rem;
    }
`;

export default ArticleRecordHeader;
