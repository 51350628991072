import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { LanguageInformation, UserInformation } from '../../../typings/mgmt';
import Icon from '../../../components/Icon/Icon';
import { DocumentCategoryProps } from '../../../interfaces/DocumentEntry';
import { t } from '../../../lib/language';
import { TenantInformation } from '../../../store/config/types';

interface InputProps {
    underline: string;
    user?: UserInformation;
    tenant?: TenantInformation;
    category?: DocumentCategoryProps;
    language?: LanguageInformation;
    currentUserState?: [UserInformation, Function];
    currentTenantState?: [TenantInformation, Function];
    currentCategoryState?: [DocumentCategoryProps | undefined, Function];
    currentLanguageState?: [LanguageInformation | undefined, Function];
}

const Entry: FunctionComponent<InputProps> = ({
    underline,
    user,
    tenant,
    category,
    language,
    currentUserState,
    currentTenantState,
    currentCategoryState,
    currentLanguageState,
}): ReactElement => {
    function buildImages() {
        if (user) {
            return user?.imageData ? (
                <img
                    alt={user.userName}
                    src={`data:image/png;base64, ${user.imageData}`}
                />
            ) : (
                <Icon
                    name="profile"
                    size="100"
                    stroke="#000"
                    fill=""
                />
            );
        }
        if (tenant) {
            return tenant.logoData ? (
                <img
                    alt={tenant.name}
                    src={`${tenant.logoData}`}
                />
            ) : (
                <Icon
                    name="visus"
                    size="100"
                />
            );
        }
        return null;
    }

    const setCurrent = () => {
        if (currentUserState && user) {
            currentUserState[1](user);
        }
        if (currentTenantState && tenant) {
            currentTenantState[1](tenant);
        }
        if (currentCategoryState && category) {
            currentCategoryState[1](category);
        }
        if (currentLanguageState && language) {
            currentLanguageState[1](language);
        }
    };

    const setActive = () => {
        if (currentUserState && user) {
            if (currentUserState[0].userName === user.userName) {
                return true;
            }
        }
        if (currentTenantState && tenant) {
            if (currentTenantState[0]?.name === tenant.name) {
                return true;
            }
        }
        if (currentCategoryState && currentCategoryState[0] && category) {
            if (currentCategoryState[0].categoryID === category.categoryID) return true;
        }
        if (currentLanguageState && currentLanguageState[0] && language) {
            if (currentLanguageState[0].languageID === language.languageID) return true;
        }
        return false;
    };

    return (
        <EntryWrapper
            onClick={setCurrent}
            active={setActive()}
        >
            {(user || tenant) && <ImageWrapper>{buildImages()}</ImageWrapper>}
            <EntryData>
                <p className="userName">
                    {user?.fullName ||
                        user?.userName ||
                        tenant?.name ||
                        category?.title ||
                        language?.name ||
                        t('SideView.Entry.NoEntry')}
                </p>
                <p>{underline || 'Datenbankbenutzer'}</p>
            </EntryData>
        </EntryWrapper>
    );
};

const EntryWrapper = styled.div<ThemeProps & { active: boolean }>`
    display: flex;
    border-top: 1px solid gray;
    cursor: pointer;
    background-color: ${props => (props.active ? '#ffe6cc' : '')};
`;

const EntryData = styled.div<ThemeProps>`
    p {
        font-size: small;
    }

    .userName {
        font-weight: bold;
    }
`;

const ImageWrapper = styled.div<ThemeProps>`
    margin: auto 10px;
    width: 4rem;
    height: 4rem;
    overflow: hidden;
    border-radius: 50%;
    svg,
    img {
        height: 4rem;
        width: auto;
    }
`;

export default Entry;
