/*
    eslint-disable no-unneeded-ternary, @typescript-eslint/no-empty-function
*/

import React, { ChangeEvent, FunctionComponent, ReactElement, ReactNode } from 'react';
import { GermanAudiences } from '../../../interfaces/Audiences';
import { VersionProps } from '../../../interfaces/DocumentEntry';
import EDocumentState from '../../../interfaces/DocumentState';
import { t } from '../../../lib/language';
import { emptyVersion } from '../../../store/document/reducers';
import { SupportedElements } from '../../../components/forms/interfaces/FormField';
import LabeledFormField, { FormFieldTypes } from '../../../components/forms/LabeledFormField';

interface VersionFormProps {
    version?: VersionProps | null;
    report?: React.ReactNode | null;
    publication?: React.ReactNode | null;
    index?: number | null;
    changeHandler?: ((event: ChangeEvent<SupportedElements>) => void) | null;
    callback?: Function;
    children?: ReactNode;
}

const VersionForm: FunctionComponent<VersionFormProps> = ({
    version = null,
    report = null,
    index = null,
    changeHandler = null,
    callback,
    children,
    publication,
}): ReactElement => {
    let capturedVersion = version;
    if (capturedVersion === null) capturedVersion = JSON.parse(JSON.stringify(emptyVersion));

    return (
        <>
            <LabeledFormField
                formFieldType={FormFieldTypes.Input}
                id={`versions#${index}#author`}
                label="Autor:"
                disabled={changeHandler === null}
                value={`${capturedVersion && capturedVersion.author ? capturedVersion.author : ''}`}
                trackChanges
                onChange={changeHandler ? changeHandler : () => {}}
            />
            <LabeledFormField
                formFieldType={FormFieldTypes.Input}
                id={`versions#${index}#coauthors`}
                label="Mitwirkende:"
                disabled={changeHandler === null}
                value={`${
                    capturedVersion && capturedVersion.coauthors ? capturedVersion.coauthors : ''
                }`}
                trackChanges
                onChange={changeHandler ? changeHandler : () => {}}
            />
            <LabeledFormField
                formFieldType={FormFieldTypes.Select}
                id={`versions#${index}#audience`}
                label="Zielgruppe:"
                options={GermanAudiences}
                disabled={changeHandler === null}
                value={capturedVersion ? capturedVersion.audience : 0}
                trackChanges
                onChange={changeHandler ? changeHandler : () => {}}
            />
            <LabeledFormField
                formFieldType={FormFieldTypes.List}
                id={`versions#${index}#articles`}
                label="Artikel:"
                disabled
                size={6}
            >
                {children}
            </LabeledFormField>
            <LabeledFormField
                formFieldType={FormFieldTypes.Button}
                id={`versions#${index}#report`}
                label="Bericht:"
                onClick={callback ? () => callback() : () => null}
                trackChanges
                onChange={changeHandler ? changeHandler : () => {}}
            >
                {report}
            </LabeledFormField>
            <LabeledFormField
                formFieldType={FormFieldTypes.Label}
                id={`versions#${index}#status`}
                label="Status:"
                disabled
                value={`${t(EDocumentState[capturedVersion ? capturedVersion.status : 0])}`}
            />
            <LabeledFormField
                formFieldType={FormFieldTypes.List}
                id={`versions#${index}#publication`}
                label="Publikation:"
            >
                {publication}
            </LabeledFormField>
        </>
    );
};

export default VersionForm;
