import { ThemeConfigurableProps } from '../interfaces/globals/theme';

export const getTheme = (theme: ThemeConfigurableProps) => {
    const colorPrimary = '#f29400';
    const colorSecondary = '#72797f';
    const colorChanged = '#f66';
    const colorBackgroundPrimary = '#fff';
    const colorBackgroundSecondary = '#aaa';
    const colorFont = '#000';
    const colorFontSelected = '#fff';

    const colorGrey10 = '#f6f6f6';
    const colorGrey20 = '#cfcfcf';
    const colorGrey30 = '#b6b6b6';
    const colorGrey50 = '#757575';
    const colorGrey70 = '#4c4c4c';
    const colorGrey90 = '#191919';

    const defaultTheme = {
        $colorPrimary: colorPrimary,
        $colorSecondary: colorSecondary,
        $colorBackgroundPrimary: colorBackgroundPrimary,
        $colorBackgroundSecondary: colorBackgroundSecondary,
        $colorBackgroundSelected: colorPrimary,
        $colorFont: colorFont,
        $colorFontSelected: colorFontSelected,
        $colorButtonFont: theme.colorButtonFont,
        $colorButtonIcon: theme.colorButtonIcon,
        $colorButtonHoverFont: theme.colorButtonHoverFont,
        $colorButtonHoverIcon: theme.colorButtonHoverIcon,
        $colorChanged: colorChanged,
        $colorGreen: '#6db16d',
        $colorRed: '#f66',
        $colorYellow: 'yellow',
        $colorOrange: 'orange',
        $colorBlue: 'blue',
        $colorWhite: '#fff',
        $colorBlack: '#000',
        $colorGrey10: colorGrey10,
        $colorGrey20: colorGrey20,
        $colorGrey30: colorGrey30,
        $colorGrey50: colorGrey50,
        $colorGrey70: colorGrey70,
        $colorGrey90: colorGrey90,
        $colorSuccess: '#6db16d',
        $colorDanger: '#f66',
        $colorWarning: 'yellow',
        $colorInfo: 'blue',
        $border: {
            black: {
                thin: '.05rem solid #000',
                dashed: '.05rem dashed #000',
                bold: '.15rem solid #000',
            },
            primary: {
                thin: `.05rem solid ${theme.colorPrimary}`,
                dashed: `.05rem dashed ${theme.colorPrimary}`,
                bold: `.15rem solid ${theme.colorPrimary}`,
            },
            secondary: {
                thin: `.05rem solid ${theme.colorSecondary}`,
                dashed: `.05rem dashed ${theme.colorSecondary}`,
                bold: `.15rem solid ${theme.colorSecondary}`,
            },
            transparent: {
                thin: '.05rem solid transparent',
                dashed: '.05rem dashed transparent',
                bold: '.15rem solid transparent',
            },
            changed: {
                thin: `.05rem solid ${theme.colorChanged}`,
                dashed: `.05rem dashed ${theme.colorChanged}`,
                bold: `.15rem solid ${theme.colorChanged}`,
            },
            grey: {
                thin: {
                    10: `.05rem solid ${colorGrey10}`,
                    20: `.05rem solid ${colorGrey20}`,
                    30: `.05rem solid ${colorGrey30}`,
                    50: `.05rem solid ${colorGrey50}`,
                    70: `.05rem solid ${colorGrey70}`,
                    90: `.05rem solid ${colorGrey90}`,
                },
                dotted: {
                    10: `.05rem dotted ${colorGrey10}`,
                    20: `.05rem dotted ${colorGrey20}`,
                    30: `.05rem dotted ${colorGrey30}`,
                    50: `.05rem dotted ${colorGrey50}`,
                    70: `.05rem dotted ${colorGrey70}`,
                    90: `.05rem dotted ${colorGrey90}`,
                },
                bold: {
                    10: `.15rem solid ${colorGrey10}`,
                    20: `.15rem solid ${colorGrey20}`,
                    30: `.15rem solid ${colorGrey30}`,
                    50: `.15rem solid ${colorGrey50}`,
                    70: `.15rem solid ${colorGrey70}`,
                    90: `.15rem solid ${colorGrey90}`,
                },
            },
            red: {
                thin: {
                    10: '.05rem solid #f99',
                    20: '.05rem solid #f77',
                    30: '.05rem solid #f55',
                    50: '.05rem solid #f44',
                    70: '.05rem solid #f22',
                    90: '.05rem solid #f11',
                },
                dotted: {
                    10: '.05rem dotted #f99',
                    20: '.05rem dotted #f77',
                    30: '.05rem dotted #f55',
                    50: '.05rem dotted #f44',
                    70: '.05rem dotted #f22',
                    90: '.05rem dotted #f11',
                },
                bold: {
                    10: '.15rem solid #f99',
                    20: '.15rem solid #f77',
                    30: '.15rem solid #f55',
                    50: '.15rem solid #f44',
                    70: '.15rem solid #f22',
                    90: '.15rem solid #f11',
                },
            },
        },
        $outline: {
            black: {
                thin: '.05rem dotted #000',
                bold: '.15rem dotted #000',
            },
            primary: {
                thin: `.05rem dotted ${theme.colorPrimary}`,
                bold: `.15rem dotted ${theme.colorPrimary}`,
            },
            secondary: {
                thin: `.05rem dotted ${theme.colorSecondary}`,
                bold: `.15rem dotted ${theme.colorSecondary}`,
            },
            changed: {
                thin: `.05rem dotted ${theme.colorChanged}`,
                bold: `.15rem dotted ${theme.colorChanged}`,
            },
            grey: {
                thin: {
                    10: `.05rem dotted ${colorGrey10}`,
                    20: `.05rem dotted ${colorGrey20}`,
                    30: `.05rem dotted ${colorGrey30}`,
                    50: `.05rem dotted ${colorGrey50}`,
                    70: `.05rem dotted ${colorGrey70}`,
                    90: `.05rem dotted ${colorGrey90}`,
                },
                bold: {
                    10: `.15rem dotted ${colorGrey10}`,
                    20: `.15rem dotted ${colorGrey20}`,
                    30: `.15rem dotted ${colorGrey30}`,
                    50: `.15rem dotted ${colorGrey50}`,
                    70: `.15rem dotted ${colorGrey70}`,
                    90: `.15rem dotted ${colorGrey90}`,
                },
            },
        },
        $footerHeight: '6rem',
        $globalMargin: '1rem',
        $headerStyles: {
            small: {
                h1: {
                    fontSize: 1.5,
                },
                h2: {
                    fontSize: 1.25,
                },
                h3: {
                    fontSize: 1.125,
                },
                h4: {
                    fontSize: 1,
                },
                h5: {
                    fontSize: 0.875,
                },
                h6: {
                    fontSize: 0.75,
                },
                p: {
                    fontSize: 0.9,
                },
            },
            medium: {
                h1: {
                    fontSize: 2,
                },
                h2: {
                    fontSize: 1.75,
                },
                h3: {
                    fontSize: 1.5,
                },
                h4: {
                    fontSize: 1.225,
                },
                h5: {
                    fontSize: 1,
                },
                h6: {
                    fontSize: 0.875,
                },
                p: {
                    fontSize: 1,
                },
            },
            xlarge: {
                h1: {
                    fontSize: 2,
                },
                h2: {
                    fontSize: 1.75,
                },
                h3: {
                    fontSize: 1.5,
                },
                h4: {
                    fontSize: 1.25,
                },
                h5: {
                    fontSize: 1.125,
                },
                h6: {
                    fontSize: 1,
                },
                p: {
                    fontSize: 1,
                },
            },
        },
        $breakpoints: {
            small: 0,
            medium: 640,
            large: 1024,
            xlarge: 1200,
            xxlarge: 1440,
        },
    };

    return defaultTheme;
};

export const transition = (prop: string, duration: string, delay: string) => {
    if (duration === 'slow') return `transition: ${prop} .65s ${delay}`;
    if (duration === 'normal') return `transition: ${prop} .35s ${delay}`;
    if (duration === 'fast') return `transition: ${prop} .15s ${delay}`;
    return `transition: ${prop} ${duration} ${delay}`;
};

export const hexToRGBA = (hex: string, alpha = 1.0) => {
    let red = 0;
    let green = 0;
    let blue = 0;

    if (hex.length === 4) {
        red = parseInt(hex.substr(1, 1), 16) * 16;
        green = parseInt(hex.substr(2, 1), 16) * 16;
        blue = parseInt(hex.substr(3, 1), 16) * 16;
    } else if (hex.length === 7) {
        red = parseInt(hex.substr(1, 2), 16);
        green = parseInt(hex.substr(3, 2), 16);
        blue = parseInt(hex.substr(5, 2), 16);
    }

    if (Number.isNaN(red) || Number.isNaN(green) || Number.isNaN(blue)) {
        throw new Error(`hexToRGBA(): invalid hex value was provided, hex = ${hex}`);
    }

    return `rgba(${red},${green},${blue},${alpha})`;
};
