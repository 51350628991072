import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import key from 'weak-key';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';
import Modal from '../../../../../components/dialogs/Modal/Modal';
import { ButtonPanel } from '../../../../../components/dialogs/SharedStyles';
import DropZone from '../../../../../components/DropZone/DropZone';
import { StyledNavButton } from '../../../../../components/navigation/NavButton/NavButton';
import { useGlobalStore } from '../../../../../hooks/hooks';
import { ImageTranslationProps, ThumbnailProps } from '../../../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../../lib/axios';
import { t } from '../../../../../lib/language';
import { handleImageTranslationUpload } from '../../../../../lib/util';
import { addError, finishOperation, startOperation } from '../../../../../store/global/actions';
import { ResponseError } from '../../../../../typings/error';
import { BoolSwapState } from '../../../../../typings/global';
import LoadingScreen from '../../../../../views/LoadingScreen';
import ThumbnailTranslation from './ThumbnailTranslation';
import ImageUploadEntry from './thumnbnailHeader/ImageUploadEntry';

interface ImageTranslationModalProps {
    thumbnail: ThumbnailProps;
    openState: BoolSwapState;
}

const ImageTranslationModal: FunctionComponent<ImageTranslationModalProps> = ({
    thumbnail,
    openState,
}) => {
    const { state, dispatch } = useGlobalStore();
    const { config } = state;
    const { imageID, title } = thumbnail;
    const [reloadData, setReloadData] = useState<boolean>(false);
    const [showPreviewImg, setShowPreviewImg] = useState<boolean>(false);
    const [preview, setPreview] = useState<{ name: string; size: number } | undefined>();
    const [imageTranslations, setImageTranslations] = useState<ImageTranslationProps[]>();
    const [imageDataForUpload, setImageDataForUpload] = useState<FileList>();
    const [currentLanguageFromSelection, setCurrentLanguageFromSelection] = useState<string>();

    function cancelImageSelection() {
        setImageDataForUpload(undefined);
        setShowPreviewImg(false);
        setPreview(undefined);
    }

    useEffect(() => {
        if ((openState.value && !imageTranslations) || reloadData) {
            dispatch(startOperation());
            AxiosClient.get(`${requestURLs.imageTranslations}/thumbnails/image/${imageID}`)
                .then(response => setImageTranslations(response.data))
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => {
                    setReloadData(false);
                    dispatch(finishOperation());
                });
        }

        if (!openState.value) {
            cancelImageSelection();
            setImageTranslations(undefined);
        }
    }, [dispatch, imageID, imageTranslations, openState.value, reloadData]);

    function handleOnDrop(e: React.DragEvent<HTMLDivElement>) {
        if (e.dataTransfer.files[0]) {
            setPreview({
                name: e.dataTransfer.files[0].name,
                size: e.dataTransfer.files[0].size,
            });
            setShowPreviewImg(true);
            setImageDataForUpload(e.dataTransfer.files);
        }
    }

    function handleOnPaste(e: React.ClipboardEvent<HTMLDivElement>) {
        if (e.clipboardData.files[0]) {
            setPreview({
                name: e.clipboardData.files[0].name,
                size: e.clipboardData.files[0].size,
            });
            setShowPreviewImg(true);
            setImageDataForUpload(e.clipboardData.files);
        }
    }

    function deleteImageTranslation(imageTranslationID: number) {
        if (imageTranslationID) {
            dispatch(startOperation());
            AxiosClient.delete(`${requestURLs.imageTranslations}/${imageTranslationID}`)
                .then(() => setReloadData(true))
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        }
    }

    return (
        <Modal
            id="image-translation-modal"
            isOpen={openState.value}
            shouldCloseOnOverlayClick
        >
            <ModalHeader>
                <ButtonWrapper>
                    <ActionControlButton
                        prefix="close"
                        name="close"
                        className="close-btn"
                        callback={() => openState.set(false)}
                        size={26}
                    />
                </ButtonWrapper>
                <h3>Übersetzungen</h3>
                <p>
                    Ausgewähltes Bild:
                    <Highlight> {imageID}</Highlight> - <Highlight>{title}</Highlight>
                </p>
                <select
                    id="images-lang-selector"
                    defaultValue="DEFAULT"
                    onChange={() =>
                        setCurrentLanguageFromSelection(
                            (document.getElementById('images-lang-selector') as HTMLInputElement)
                                .value
                        )
                    }
                >
                    {Object.values(config.language.list).map(lang => {
                        const elements: ReactElement[] = [];
                        if (imageTranslations) {
                            imageTranslations.forEach(imageTranslation => {
                                if (
                                    !Object.values(imageTranslation).includes(lang.languageID) &&
                                    lang.code !== 'de_DE'
                                )
                                    elements.push(
                                        <option
                                            key={key(lang)}
                                            value={lang.code}
                                        >
                                            {lang.name}
                                        </option>
                                    );
                            });
                            if (imageTranslations.length === 0 && lang.code !== 'de_DE')
                                elements.push(
                                    <option
                                        key={key(lang)}
                                        value={lang.code}
                                    >
                                        {lang.name}
                                    </option>
                                );
                        }
                        return elements;
                    })}
                    <option
                        value="DEFAULT"
                        style={{ display: 'none' }}
                        disabled
                    >
                        {t('StatusCode.SpecialCase.SelectLanguage')}
                    </option>
                </select>
            </ModalHeader>
            <ModalBody>
                <DropZone
                    id="image-translation-dropzone"
                    handleOnPaste={handleOnPaste}
                    handleOnDrop={handleOnDrop}
                >
                    <InnerDropZone>
                        <p>Ziehen Sie die Bilder in diesen Bereich</p>
                        {showPreviewImg && preview && (
                            <ImageUploadEntry
                                name={preview.name}
                                size={preview.size}
                                callback={cancelImageSelection}
                            />
                        )}
                    </InnerDropZone>
                </DropZone>
                <ThumbnailWrapper>
                    {!imageTranslations && <LoadingScreen />}
                    {imageTranslations && imageTranslations.length === 0 && (
                        <p style={{ padding: '40px' }}>Noch keine Übersetzungen vorhanden!</p>
                    )}
                    {imageTranslations &&
                        imageTranslations.length > 0 &&
                        imageTranslations.map(trans => (
                            <ThumbnailTranslation
                                key={key(trans)}
                                thumbnail={trans}
                                deleteThumbnail={deleteImageTranslation}
                            />
                        ))}
                </ThumbnailWrapper>
            </ModalBody>
            <ModalFooter>
                <ButtonPanel>
                    <StyledNavButton
                        id={`${imageID}-ok`}
                        renderas="button"
                        disabled={!imageDataForUpload || !currentLanguageFromSelection}
                        onClick={() => {
                            if (imageDataForUpload)
                                handleImageTranslationUpload(
                                    imageDataForUpload,
                                    thumbnail.imageID,
                                    thumbnail.title,
                                    currentLanguageFromSelection,
                                    false,
                                    setReloadData,
                                    dispatch
                                );
                        }}
                    >
                        OK
                    </StyledNavButton>
                    <StyledNavButton
                        id={`${imageID}-cancel`}
                        renderas="button"
                        onClick={() => openState.set(false)}
                    >
                        Abbrechen
                    </StyledNavButton>
                </ButtonPanel>
            </ModalFooter>
        </Modal>
    );
};

const ModalHeader = styled.div<ThemeProps>`
    margin-bottom: 5px;

    #images-lang-selector {
        width: 292px;
        margin-bottom: 5px;
        padding: 5px;
    }
`;

const ModalBody = styled.div<ThemeProps>`
    display: flex;
    height: 40vh;
`;

const ModalFooter = styled.div<ThemeProps>``;

const InnerDropZone = styled.div<ThemeProps>`
    flex: 1;
    height: 93%;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 10px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    border-color: #eeeeee;

    p {
        margin: auto;
    }
`;

const ButtonWrapper = styled.div<ThemeProps>`
    .close-btn {
        margin: 10px;
        padding: 0;
        right: 0%;
        top: 0%;
        justify-content: flex-end;
        position: absolute;
        border: none;
        background-color: inherit;
    }
`;

const ThumbnailWrapper = styled.div<ThemeProps>`
    width: 325px;
    display: inline-flex;
    flex-wrap: wrap;
    align-content: flex-start;
`;

const Highlight = styled.span<ThemeProps>`
    color: ${props => props.theme.$colorPrimary};
`;

export default ImageTranslationModal;
