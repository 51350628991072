import React, { FunctionComponent, ReactElement, useEffect, useState } from 'react';
import styled from 'styled-components';
import { useLocation } from 'react-router-dom';
import { useGlobalStore } from '../../../hooks/hooks';
import {
    ArticleEntry,
    DocumentDescriptionProps,
    DocumentEntry,
    VersionProps,
} from '../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { downloadFile, validatePermissions } from '../../../lib/util';
import ErrorPage401 from '../../../views/ErrorPage401';
import LoadingScreen from '../../../views/LoadingScreen';
import ContentEditor from './editor/ContentEditor';
import DocumentInformation from '../DocumentInformation';
import { articleSelected } from '../../../store/document/actions';
import { addError, finishOperation, startOperation } from '../../../store/global/actions';
import AxiosClient, { requestURLs } from '../../../lib/axios';
import EDocumentState from '../../../interfaces/DocumentState';
import { t } from '../../../lib/language';
import { loadDocument } from '../../../lib/app/document';
import ActionControlButton from '../../../components/ActionControlButton/ActionControlButton';
import ArticleModal from './ArticleModal';
import { EErrorTypes } from '../../../interfaces/globals/errors';
import { ResponseError } from '../../../typings/error';
import DocumentModal from './DocumentModal';
import ArticlesAudit from './articleAudit/ArticleAudit';
import { ContentArticleAudit } from '../../../typings/articleAudit';
import ImageSilder from './imageSlider/ImageSilder';
import ImageLibrary from './imageSlider/ImageLibrary';
import ArticleOutline from './articleOutline/ArticleOutline';
import ReportOutline from './articleOutline/ReportOutline';
import InPlaceEditor from '../../../components/InPlaceEditor/InPlaceEditor';
import TranslationValidation from './translationValidation/TranslationValidation';
import PublishModal from '../document/PublishModal';
import TextInputDialog from '../../../components/dialogs/InputDialog';
import MinimizedEditorHeader from './minimizedEditorContainer/MinimizedEditorHeader';
import MinimizedEditorOutline from './minimizedEditorContainer/MinimizedEditorOutline';

const ContentManager: FunctionComponent = (): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [currentDocument, setCurrentDocument] = useState<DocumentEntry | null>(null);
    const [currentVersion, setCurrentVersion] = useState<VersionProps | null>(null);
    const [previewArticle, setPreviewArticle] = useState<ArticleEntry | null>(null);
    const [previewVersion, setPreviewVersion] = useState<VersionProps | null>(null);
    const [previewAudience, setPreviewAudience] = useState<number | null>(null);
    const [previewDescription, setPreviwDescription] = useState<DocumentDescriptionProps | null>(
        null
    );
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const [articles, setArticles] = useState<ArticleEntry[]>([]);
    const [reports, setReports] = useState<ArticleEntry[]>([]);
    const [isImageLibaryVisible, setImageLibaryVisible] = useState<boolean>(false);
    const [reloadPageData, setReloadPageData] = useState<boolean>(false);
    const [selectedHeading, setSelectedHeading] = useState<{ articleID: number; heading: string }>({
        articleID: -1,
        heading: '',
    });
    const [dataRetrieved, setDataRetrieved] = useState<boolean>(false);
    const [unsavedChanges, setUnsavedChanges] = useState<boolean>(false);
    const [isArticleModalOpen, setIsArticleModalOpen] = useState<boolean>(false);
    const [isReportModalOpen, setIsReportModalOpen] = useState<boolean>(false);
    const [articleAuditVisible, setArticleAuditVisible] = useState<boolean>(false);
    const [imageSliderVisible, setImageSliderVisible] = useState<boolean>(false);
    const [articleDiffData, setArticleDiffData] = useState<ContentArticleAudit | null>(null);
    const [reloadArticleAuditData, setReloadArticleAuditData] = useState<boolean>(false);
    const [showPublishModal, setShowPublishModal] = useState<boolean>(false);
    const [fileExtension, setFileExtension] = useState<string>('txt');
    const [showInputDialogForDownloadFile, setShowInputDialogForDownloadFile] =
        useState<boolean>(false);
    const [previewWindow, setPreviewWindow] = useState<Window | null>(null);
    const [isArticleOutlineClosed, setArticleOutlineClosed] = useState<boolean>(false);
    const [isEditorHeaderClosed, setEditorHeaderClosed] = useState<boolean>(false);
    const [documentProps, setDocumentProps] = useState<DocumentEntry>(
        JSON.parse(
            (useLocation().state as { currentDocument: string }).currentDocument
        ) as DocumentEntry
    );
    const previewWindowName = 'CompendiumPreviewWindow';
    const toggleEditorHeaderStyles = () =>
        isEditorHeaderClosed
            ? document.getElementById('inner-wrapper-header')?.classList.add('hide-header')
            : document.getElementById('inner-wrapper-header')?.classList.remove('hide-header');
    const toggleEditorOutlineStyles = () =>
        isArticleOutlineClosed
            ? document.getElementById('inner-wrapper-outline')?.classList.add('hide-outline')
            : document.getElementById('inner-wrapper-outline')?.classList.remove('hide-outline');

    useEffect(() => {
        toggleEditorHeaderStyles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isEditorHeaderClosed]);

    useEffect(() => {
        toggleEditorOutlineStyles();
        // eslint-disable-next-line react-hooks/exhaustive-deps
    }, [isArticleOutlineClosed]);

    useEffect(() => {
        if (state.user.userName !== '') {
            if (
                documentProps.documentID &&
                currentDocument === null &&
                state.documentManager.currentVersion.versionID !== -1
            ) {
                setCurrentDocument(documentProps.value);
            }

            if (currentVersion !== state.documentManager.currentVersion) {
                setCurrentVersion(state.documentManager.currentVersion);
                dispatch(startOperation());
                AxiosClient.get(
                    `${requestURLs.editorArticles}versions/${state.documentManager.currentVersion.versionID}`
                )
                    .then(response => {
                        setArticles(response.data);
                        setDataRetrieved(true);
                    })
                    .catch(error => {
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        );
                    })
                    .finally(() => dispatch(finishOperation()));
            }
        }
    }, [
        dispatch,
        state.documentManager.currentVersion,
        currentDocument,
        currentVersion,
        state.user.userName,
        state.documentManager.currentAudience,
        documentProps,
    ]);

    useEffect(() => {
        if (
            previewVersion === null ||
            previewAudience === null ||
            previewDescription === null ||
            previewAudience !== state.documentManager.currentAudience ||
            previewVersion.versionID !== state.documentManager.currentVersion.versionID ||
            previewDescription.languageCode !==
                state.documentManager.currentDescription.languageCode ||
            state.documentManager.currentArticle.articleID ===
                state.documentManager.currentVersion.report?.articleID ||
            (previewArticle?.articleID === state.documentManager.currentVersion.report?.articleID &&
                state.documentManager.currentArticle.articleID !==
                    state.documentManager.currentVersion.report?.articleID)
        ) {
            setPreviewVersion(state.documentManager.currentVersion);
            setPreviewAudience(state.documentManager.currentAudience);
            setPreviewArticle(state.documentManager.currentArticle);
            setPreviwDescription(state.documentManager.currentDescription);
            let url: string;
            if (
                state.documentManager.currentArticle.articleID ===
                state.documentManager.currentVersion.report?.articleID
            ) {
                // eslint-disable-next-line max-len
                url = `${process.env.REACT_APP_BACKEND_SERVER}/${requestURLs.mdx}${state.documentManager.currentVersion.versionID}/${state.documentManager.currentAudience}/${state.documentManager.currentDescription.languageCode}/html?isr=true`;
            } else {
                // eslint-disable-next-line max-len
                url = `${process.env.REACT_APP_BACKEND_SERVER}/${requestURLs.mdx}${state.documentManager.currentVersion.versionID}/${state.documentManager.currentAudience}/${state.documentManager.currentDescription.languageCode}/html`;
            }

            // create preview Window
            if (window.name === previewWindowName) window.name = 'CompendiumEditor';

            if (previewWindow === null || previewWindow.closed) {
                setTimeout(() => {
                    setPreviewWindow(window.open(url, previewWindowName, ''));
                }, 0);
            } else if (previewWindow.location.href !== url) {
                const pw = previewWindow;
                pw.location.href = url;
                setPreviewWindow(pw);
            }
        }
    }, [
        dispatch,
        previewArticle,
        previewAudience,
        previewDescription,
        previewVersion,
        previewWindow,
        state.documentManager.currentArticle,
        state.documentManager.currentArticle.articleID,
        state.documentManager.currentAudience,
        state.documentManager.currentDescription,
        state.documentManager.currentDescription.languageCode,
        state.documentManager.currentVersion,
    ]);

    useEffect(() => {
        if (reports.length === 0 && isReportModalOpen && state.config.tenant.current?.tenantID) {
            dispatch(startOperation());
            AxiosClient.get(
                `${requestURLs.editorArticles}tenants/${state.config.tenant.current.tenantID}/reports`
            )
                .then(response => {
                    setReports(response.data as ArticleEntry[]);
                })
                .catch(error => {
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    );
                })
                .finally(() => dispatch(finishOperation()));
        }
    }, [dispatch, isReportModalOpen, reports.length, state.config.tenant]);

    if (
        state.config.tenant.current === null ||
        state.user.userName === '' ||
        !validatePermissions(state.config.tenant.current, state.user.permissions, [
            'EDITOR',
            'ADMIN',
        ])
    )
        return <ErrorPage401 />;

    function articleInListSelected(article: ArticleEntry) {
        if (article.articleID !== state.documentManager.currentArticle.articleID) {
            dispatch(articleSelected(article));
            if (state.documentManager.currentDescription.languageCode !== 'de_DE')
                setArticleDiffData({
                    articleID: article.articleID,
                    content: article.content,
                    rev: -1,
                });

            setUnsavedChanges(false);
        }
    }

    function articleContentChanged(changed: boolean) {
        setUnsavedChanges(changed);
    }

    function checkPreviewReload() {
        const isr =
            currentVersion?.report?.articleID === state.documentManager.currentArticle.articleID
                ? '?isr=true'
                : '';
        // eslint-disable-next-line max-len
        const url = `${process.env.REACT_APP_BACKEND_SERVER}/${requestURLs.mdx}${state.documentManager.currentVersion.versionID}/${state.documentManager.currentAudience}/${state.documentManager.currentDescription.languageCode}/html${isr}`;

        if (previewWindow === null || previewWindow.closed) {
            setTimeout(() => {
                setPreviewWindow(window.open(url, previewWindowName));
            }, 0);
        } else
            setTimeout(() => {
                previewWindow.location.reload();
            }, 0);
    }

    function reloadDocument() {
        dispatch(startOperation());
        AxiosClient.get(`${requestURLs.versions}${state.documentManager.currentVersion.versionID}`)
            .then(response => {
                const version: VersionProps = response.data;
                setArticles(version.articles);
                if (documentProps.documentID)
                    loadDocument(
                        dispatch,
                        documentProps.documentID,
                        state.documentManager,
                        setDocumentProps
                    );
                checkPreviewReload();
            })
            .catch(error => {
                dispatch(() =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                );
            })
            .finally(() => dispatch(finishOperation()));
    }

    function articleContentSaved() {
        reloadDocument();
    }

    function handleContentChange(desciption: DocumentDescriptionProps) {
        dispatch(startOperation());
        AxiosClient.put(
            `${requestURLs.editorSingleDocument}/descriptions/${desciption.descriptionID}`,
            desciption
        )
            .then(() => {
                if (documentProps.documentID)
                    loadDocument(
                        dispatch,
                        documentProps.documentID,
                        state.documentManager,
                        setDocumentProps
                    );
                if (previewWindow) previewWindow.location.reload();
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => dispatch(finishOperation()));
    }

    function addArticleToDoc(event: React.MouseEvent<HTMLButtonElement>) {
        const articleID = event.currentTarget.getAttribute('data-id');
        if (state.documentManager.currentVersion.versionID && articleID) {
            dispatch(startOperation());
            AxiosClient.put(
                `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/articles/${articleID}`
            )
                .then(() => {
                    if (documentProps.documentID) {
                        setIsArticleModalOpen(false);
                        articleContentSaved();
                    }
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        }
    }

    function addReportToVersion(event: React.MouseEvent<HTMLButtonElement>) {
        const reportID = event.currentTarget.getAttribute('data-id');
        if (state.documentManager.currentVersion.versionID && reportID) {
            dispatch(startOperation());
            AxiosClient.put(
                `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/report/${reportID}`
            )
                .then(() => {
                    if (documentProps.documentID) {
                        setIsReportModalOpen(false);
                        articleContentSaved();
                    }
                })
                .catch(error =>
                    dispatch(
                        addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                    )
                )
                .finally(() => dispatch(finishOperation()));
        }
    }

    function checkForPublish() {
        if (documentProps.documentID && documentProps.documentID > 0) {
            const lang = state.documentManager.currentDescription.languageCode;
            if (
                documentProps.versions.find(
                    version =>
                        !version.publishedCheckMap.de_DE.published ||
                        (version.publishedCheckMap[lang].published &&
                            !version.publishedCheckMap[lang].upToDatePublication)
                )
            )
                setShowPublishModal(true);
            else
                dispatch(addError(EErrorTypes.info, 'Alle Versionen sind aktuell und publiziert.'));
        }
    }

    function buildAudienceEntry() {
        const elements: ReactElement[] = [];
        for (let i = 0; i <= state.documentManager.currentVersion.audience; i += 1) {
            const audience = () => {
                if (i === 0) return 'Intern';
                if (i === 1) return 'Partner';
                if (i === 2) return 'Kunden';
                if (i === 3) return 'Anwender';
                return '';
            };
            elements.push(
                <option
                    key={i}
                    value={i}
                >
                    {audience()}
                </option>
            );
        }
        return elements;
    }

    function renderInputDialogForDownload() {
        if (fileExtension !== 'pdf' && fileExtension !== 'mdx') {
            return null;
        }
        return (
            <TextInputDialog
                id={`documenteditor-download-doc-${fileExtension}`}
                title={`Download des Dokuments als ${fileExtension}`}
                message={`Bitte Audience für Version ${
                    currentVersion !== null && currentVersion.versionID !== -1
                        ? currentVersion.productVersion
                        : '???'
                } auswählen:`}
                onOK={() => {
                    dispatch(startOperation());
                    const input = (document.getElementById('audience-selector') as HTMLInputElement)
                        .value;

                    if (input !== undefined && input !== '' && input !== 'DEFAULT') {
                        if (fileExtension === 'pdf') {
                            downloadFile(
                                // eslint-disable-next-line max-len
                                `${process.env.REACT_APP_BACKEND_SERVER}/${requestURLs.mdx}${state.documentManager.currentVersion.versionID}/${input}/${state.documentManager.currentDescription.languageCode}/pdf`
                            );
                        }
                        if (fileExtension === 'mdx') {
                            downloadFile(
                                // eslint-disable-next-line max-len
                                `${process.env.REACT_APP_BACKEND_SERVER}/${requestURLs.mdx}${state.documentManager.currentVersion.versionID}/${input}/${state.documentManager.currentDescription.languageCode}/md`
                            );
                        }
                    } else {
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                'Die ausgewählte Sprache wurde nicht gefunden!'
                            )
                        );
                    }
                    dispatch(finishOperation());
                }}
                selectField
                isOpenState={[showInputDialogForDownloadFile, setShowInputDialogForDownloadFile]}
            >
                {state.config.language.current !== null ? (
                    <select
                        id="audience-selector"
                        defaultValue="DEFAULT"
                    >
                        {buildAudienceEntry()}
                        <option
                            value="DEFAULT"
                            style={{ display: 'none' }}
                            disabled
                        >
                            {t('Bitte wählen Sie eine Audience aus.')}
                        </option>
                    </select>
                ) : null}
            </TextInputDialog>
        );
    }

    if (!dataRetrieved) return <LoadingScreen />;
    return (
        <OuterWrapper data-testid="document_content_manager">
            <MinimizedEditorHeader
                currentDocument={documentProps}
                isEditorHeaderClosedProps={{
                    value: isEditorHeaderClosed,
                    set: setEditorHeaderClosed,
                }}
            />
            <ToggleHeader>
                <InnerWrapper id="inner-wrapper-header">
                    <DocumentInformation
                        currentDocumentProps={{ value: documentProps, set: setDocumentProps }}
                        setArticleDiffData={setArticleDiffData}
                    />

                    <div id="center">
                        <FormWrapper>
                            <h3>Titel:</h3>
                            <InPlaceEditor
                                id="title"
                                value={state.documentManager.currentDescription.title}
                                actionOnBlur="Cancel"
                                submit={() =>
                                    handleContentChange({
                                        ...state.documentManager.currentDescription,
                                        title:
                                            (
                                                document.getElementById(
                                                    'in-place-editor#title'
                                                ) as HTMLInputElement
                                            ).value ||
                                            state.documentManager.currentDescription.title,
                                    })
                                }
                            />
                        </FormWrapper>
                        <FormWrapper>
                            <h3>Status:</h3>
                            <InPlaceEditor
                                id="status"
                                value={`${t(
                                    EDocumentState[state.documentManager.currentDescription.status]
                                )}`}
                                actionOnBlur="Cancel"
                                submit={() => null}
                                disabled
                            />
                        </FormWrapper>
                        <FormWrapper>
                            <h3>Download:</h3>
                            <ButtonWrapper>
                                <ActionControlButton
                                    className="article-editor-button"
                                    prefix="article-editor"
                                    name="pdf"
                                    disabled={false}
                                    callback={() => {
                                        setFileExtension('pdf');
                                        setShowInputDialogForDownloadFile(true);
                                    }}
                                    fill
                                />
                                <ActionControlButton
                                    className="article-editor-button"
                                    prefix="article-editor"
                                    name="mdx"
                                    disabled={false}
                                    callback={() => {
                                        setFileExtension('mdx');
                                        setShowInputDialogForDownloadFile(true);
                                    }}
                                    fill
                                />
                            </ButtonWrapper>
                        </FormWrapper>
                        <FormWrapper>
                            <h3>Freigeben:</h3>
                            <ButtonWrapper>
                                <ActionControlButton
                                    disabled={false}
                                    className="article-editor-button"
                                    prefix="article-editor"
                                    name="publish"
                                    callback={() => checkForPublish()}
                                    fill
                                />
                            </ButtonWrapper>
                        </FormWrapper>
                    </div>
                    <div id="right">
                        <FormWrapper>
                            <h3>Beschreibung</h3>
                            <InPlaceEditor
                                id="description"
                                value={state.documentManager.currentDescription.description}
                                actionOnBlur="Cancel"
                                submit={() =>
                                    handleContentChange({
                                        ...state.documentManager.currentDescription,
                                        description:
                                            (
                                                document.getElementById(
                                                    'in-place-editor#description'
                                                ) as HTMLInputElement
                                            ).value ||
                                            state.documentManager.currentDescription.description,
                                    })
                                }
                            />
                        </FormWrapper>
                        <FormWrapper>
                            <h3>Keywords</h3>
                            <InPlaceEditor
                                id="keywords"
                                value={state.documentManager.currentDescription.keywords}
                                actionOnBlur="Cancel"
                                submit={() =>
                                    handleContentChange({
                                        ...state.documentManager.currentDescription,
                                        keywords:
                                            (
                                                document.getElementById(
                                                    'in-place-editor#keywords'
                                                ) as HTMLInputElement
                                            ).value ||
                                            state.documentManager.currentDescription.keywords,
                                    })
                                }
                            />
                        </FormWrapper>
                    </div>
                    <div className="header-minimize-btn">
                        <ActionControlButton
                            prefix="minimize-header"
                            name="minimize"
                            size={18}
                            callback={() => setEditorHeaderClosed(true)}
                        />
                    </div>
                </InnerWrapper>
            </ToggleHeader>
            {unsavedChanges ? (
                <div className="unsavedChanges">*** Ungespeicherte Änderungen ***</div>
            ) : (
                <div className="unsavedChanges" />
            )}
            <EditorWrapper>
                <MinimizedEditorOutline
                    currentArticle={state.documentManager.currentArticle}
                    articleOutlineState={{
                        value: isArticleOutlineClosed,
                        set: setArticleOutlineClosed,
                    }}
                />
                <ToggleOutline>
                    <OutlineToCWrapper id="inner-wrapper-outline">
                        <OutlineHeader>
                            <h3>Artikelverzeichnis</h3>
                            <div>
                                {state.documentManager.currentDescription.languageCode ===
                                    'de_DE' && (
                                    <ActionControlButton
                                        prefix="add"
                                        className="add-btn"
                                        name="add-thumbnail"
                                        callback={() => setIsArticleModalOpen(true)}
                                        size={20}
                                    />
                                )}
                                <ActionControlButton
                                    prefix="minimize-header"
                                    className="add-btn"
                                    size={20}
                                    name="minimize"
                                    callback={() => {
                                        setArticleOutlineClosed(true);
                                    }}
                                />
                            </div>
                        </OutlineHeader>
                        <ArticleOutline
                            articles={articles}
                            articleSelectionListener={articleInListSelected}
                            currentDocumentProps={{
                                value: documentProps,
                                set: setDocumentProps,
                            }}
                            saveListener={articleContentSaved}
                            headingSelectionListener={(articleID: number, heading: string) => {
                                setSelectedHeading({ articleID, heading });
                            }}
                            documentModalOpenState={{
                                value: showConfirmationDialog,
                                set: setShowConfirmationDialog,
                            }}
                        />
                        <OutlineHeader className="report-header">
                            <h3>Abschlussbericht</h3>
                            {state.documentManager.currentDescription.languageCode === 'de_DE' && (
                                <ActionControlButton
                                    prefix="add"
                                    className="add-btn"
                                    name="add-thumbnail"
                                    callback={() => setIsReportModalOpen(true)}
                                    size={20}
                                />
                            )}
                        </OutlineHeader>
                        <ReportOutline
                            report={state.documentManager.currentVersion.report}
                            saveListener={articleContentSaved}
                            articleSelectionListener={articleInListSelected}
                            headingSelectionListener={(articleID: number, heading: string) => {
                                setSelectedHeading({ articleID, heading });
                            }}
                            documentModalOpenState={{
                                value: showConfirmationDialog,
                                set: setShowConfirmationDialog,
                            }}
                        />
                    </OutlineToCWrapper>
                </ToggleOutline>
                {state.documentManager.currentDescription.languageCode !== 'de_DE' ? (
                    <TranslationValidation
                        reloadPreview={checkPreviewReload}
                        articleDiffData={{
                            value: articleDiffData,
                            set: setArticleDiffData,
                        }}
                    />
                ) : (
                    <ContentEditor
                        selectedHeading={selectedHeading}
                        changeListener={articleContentChanged}
                        saveListener={articleContentSaved}
                        articleDiffData={articleDiffData}
                        reloadArticleAuditData={setReloadArticleAuditData}
                    />
                )}

                {state.documentManager.currentDescription.languageCode === 'de_DE' &&
                    !articleAuditVisible &&
                    !imageSliderVisible && (
                        <EditorSideBarWrapper>
                            <SideBarButton
                                type="button"
                                className="btn-8"
                                onClick={() => setImageSliderVisible(true)}
                            >
                                <span>Bildverzeichnis</span>
                            </SideBarButton>
                            <SideBarButton
                                type="button"
                                className="btn-8"
                                onClick={() => setArticleAuditVisible(true)}
                            >
                                <span>Artikelhistorie</span>
                            </SideBarButton>
                        </EditorSideBarWrapper>
                    )}
                <ImageSilder
                    imageSliderVisible={{
                        value: imageSliderVisible,
                        set: setImageSliderVisible,
                    }}
                    openState={{
                        value: isImageLibaryVisible,
                        set: setImageLibaryVisible,
                    }}
                    reloadData={{
                        value: reloadPageData,
                        set: setReloadPageData,
                    }}
                    showAdditionalControls
                />
                <ArticlesAudit
                    articleAuditVisible={{
                        value: articleAuditVisible,
                        set: setArticleAuditVisible,
                    }}
                    articleDiffData={{
                        value: articleDiffData,
                        set: setArticleDiffData,
                    }}
                    reloadArticleAuditData={{
                        value: reloadArticleAuditData,
                        set: setReloadArticleAuditData,
                    }}
                />
            </EditorWrapper>
            <ImageLibrary
                openState={{
                    value: isImageLibaryVisible,
                    set: setImageLibaryVisible,
                }}
                reloadData={{
                    value: reloadPageData,
                    set: setReloadPageData,
                }}
            />
            <ArticleModal
                saveListener={articleContentSaved}
                handleDoubleClick={(e: React.MouseEvent<HTMLButtonElement>) => addArticleToDoc(e)}
                openState={{
                    value: isArticleModalOpen,
                    set: setIsArticleModalOpen,
                }}
            />
            <ArticleModal
                articles={reports}
                saveListener={articleContentSaved}
                openState={{
                    value: isReportModalOpen,
                    set: setIsReportModalOpen,
                }}
                handleDoubleClick={(e: React.MouseEvent<HTMLButtonElement>) =>
                    addReportToVersion(e)
                }
                isReport
            />
            <DocumentModal
                currentArticle={state.documentManager.currentArticle}
                currentDocumentProps={{ value: documentProps, set: setDocumentProps }}
                openState={{
                    value: showConfirmationDialog,
                    set: () => setShowConfirmationDialog,
                }}
            />
            <PublishModal
                currentDocument={documentProps}
                openState={{ value: showPublishModal, set: setShowPublishModal }}
            />
            {renderInputDialogForDownload()}
        </OuterWrapper>
    );
};

const OuterWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 80%;

    .unsavedChanges {
        min-height: 21px;
        color: ${props => props.theme.$colorDanger};
        text-align: center;
        width: 100%;
    }
`;

const InnerWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    height: 10rem;
    visibility: visible;
    opacity: 1;
    border-bottom: ${props => props.theme.$border.secondary.thin};
    transition: 0.3s;

    fieldset {
        border: none;
    }

    #center {
        flex: 1;
        display: flex;
        flex-direction: column;
    }

    #right {
        display: flex;
        flex-direction: column;
        flex: 1;
        font-size: ${props => props.theme.$headerStyles.small.h6.fontSize}rem;
    }

    #status {
        width: 100%;
    }

    #title {
        width: 100%;
    }

    #keywords {
        width: 100%;
    }

    #description {
        width: 100%;
        min-height: max-content;
        max-height: 120px;
        overflow: auto;
    }

    .header-minimize-btn {
        button {
            all: unset;
            cursor: pointer;
        }
    }
`;

const EditorWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: row;
    flex: 1;
    margin: 0.5rem 0;
    border: 1px solid white;
    height: 75%;
`;

const FormWrapper = styled.div<ThemeProps>`
    display: inline-flex;
    margin: 0.2rem;

    h3 {
        margin: 5px;
        width: 200px;
        font-size: 0.875rem;
        font-weight: bold;
    }
`;

const ButtonWrapper = styled.div`
    width: 100%;
    button {
        all: unset;
        cursor: pointer;
    }
`;

const OutlineToCWrapper = styled.div<ThemeProps>`
    height: 100%;
    display: flex;
    flex-direction: column;
    visibility: visible;
    opacity: 1;
    width: 450px;
    transition: 0.3s;

    .report-header {
        padding-top: 1rem;
        border-top: ${props => props.theme.$border.secondary.thin};
    }
`;

const OutlineHeader = styled.div<ThemeProps>`
    margin: 5px;
    display: flex;
    justify-content: space-between;

    h3 {
        margin: 0;
    }

    .add-btn {
        margin-left: 5px;
        background: none;
        color: inherit;
        border: none;
        padding: 0;
        font: inherit;
        cursor: pointer;
        outline: inherit;
    }
`;

const EditorSideBarWrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    margin: auto 5px;

    span {
        writing-mode: vertical-rl;
        text-orientation: mixed;
    }
`;

const SideBarButton = styled.button<ThemeProps>`
    text-align: center;
    display: inline-block;
    padding: 15px 15px;
    border: 1px solid #fff;
    cursor: pointer;
    letter-spacing: 2px;
    position: relative;
    overflow: hidden;

    :before {
        content: '';
        position: absolute;
        height: 100%;
        width: 0;
        background: ${props => props.theme.$colorPrimary};
        left: 100%;
        bottom: 0;
        transition: all 0.3s;
        opacity: 0.3;
    }

    :hover:before {
        width: 100%;
        left: 0;
    }
`;

const ToggleHeader = styled.div`
    .hide-header {
        height: 0;
        visibility: hidden;
        opacity: 0;
    }
`;

const ToggleOutline = styled.div`
    .hide-outline {
        visibility: hidden;
        opacity: 0;
        width: 0;
    }
`;

export default ContentManager;
