import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import ConfirmationDialog from '../../../../components/dialogs/ConfirmationDialog';
import { useGlobalStore } from '../../../../hooks/hooks';
import { ArticleEntry } from '../../../../interfaces/DocumentEntry';
import EDocumentState from '../../../../interfaces/DocumentState';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { t } from '../../../../lib/language';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { ResponseError } from '../../../../typings/error';
import { BoolSwapState } from '../../../../typings/global';
import ArticleRecord from './articleRecord/ArticleRecord';
import ArticleRecordHeader from './articleRecord/ArticleRecordHeader';

interface ReportOutlineProps {
    report: ArticleEntry | null;
    saveListener: Function;
    headingSelectionListener: Function;
    articleSelectionListener: Function;
    documentModalOpenState: BoolSwapState;
}

const ReportOutline: FunctionComponent<ReportOutlineProps> = ({
    report,
    saveListener,
    headingSelectionListener,
    articleSelectionListener,
    documentModalOpenState,
}) => {
    const { state, dispatch } = useGlobalStore();
    const { currentArticle } = state.documentManager;
    const [showConfirmationDialog, setShowConfirmationDialog] = useState<boolean>(false);
    const locked = report !== null && report.status >= EDocumentState.Published;

    function selectReport(
        event: React.MouseEvent<HTMLUListElement, MouseEvent>,
        article: ArticleEntry
    ) {
        event.preventDefault();
        articleSelectionListener(article);
    }

    function removeReport() {
        dispatch(startOperation());
        AxiosClient.delete(
            `${requestURLs.versions}${state.documentManager.currentVersion.versionID}/report`
        )
            .then(() => {
                saveListener();
                dispatch(
                    addError(EErrorTypes.info, t('StatusCode.SpecialCase.RemoveReportFromVersion'))
                );
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => {
                dispatch(finishOperation());
            });
    }

    return (
        <OutlineWrapper className="styledScrollBar">
            {report && (
                <ReportOutlineWrapper
                    selected={report.articleID === currentArticle.articleID}
                    locked={locked}
                    onClick={event => selectReport(event, report)}
                >
                    <ArticleRecordHeader
                        article={report}
                        locked={locked}
                        saveListener={saveListener}
                        documentModalOpenState={documentModalOpenState}
                        showConfirmationDialog={{
                            value: showConfirmationDialog,
                            set: setShowConfirmationDialog,
                        }}
                        isReport
                    />
                    <ArticleRecord
                        article={report}
                        headingSelectionListener={headingSelectionListener}
                    />
                    <ConfirmationDialog
                        id="report-action-confirmation"
                        title="Artikel entfernen"
                        // eslint-disable-next-line max-len
                        message={`Wollen sie den Report "${report.title}" wirklich entfernen?`}
                        onCancel={() => {
                            setShowConfirmationDialog(false);
                        }}
                        onOK={() => removeReport()}
                        isOpenState={{
                            value: showConfirmationDialog,
                            set: setShowConfirmationDialog,
                        }}
                    />
                </ReportOutlineWrapper>
            )}
        </OutlineWrapper>
    );
};

const OutlineWrapper = styled.div<ThemeProps>`
    max-height: 20%;
    display: flex;
    flex-direction: column;
    min-width: 400px;
    font-size: ${props => props.theme.$headerStyles.small.h5.fontSize}rem;
    overflow-y: auto;
`;

const ReportOutlineWrapper = styled.ul<ThemeProps & { selected: boolean; locked: boolean }>`
    border-bottom: ${props => props.theme.$border.secondary.dashed};
    margin: 0.2rem;
    align-items: left;
    text-align: left;
    background-color: ${props => (props.selected ? props.theme.$colorPrimary : 'transparent')};
    background-image: ${props => (props.locked ? 'url(/icn/lock.svg)' : '')};
    background-position: right bottom;
    background-repeat: no-repeat;
    background-size: 25px;
    list-style: none;
    padding-left: 0;
    padding-bottom: 10px;
    max-width: 450px;
`;

export default ReportOutline;
