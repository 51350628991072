/* istanbul ignore file */
/* tslint:disable */
/* eslint-disable */

export type JobDTO = {
    /**
     * The UUID uniquely identifying this job.
     */
    id: string;
    /**
     * The name of the type of the job.
     *
     * The List of valid jobTypes is given by the simple-names of all Java classes implementing DistributedJob.
     *
     */
    jobType: string;
    /**
     * The current status of the job.
     *
     * See below for allowed values.
     */
    status: JobDTO.status;
    /**
     * The progress of a running job
     *
     * When a job has started (status is RUNNING) this number indicates the progress in a range from 0 to 100.
     */
    progress: number;
    /**
     * The time when the job execution commenced.
     *
     */
    jobStartTime: string;
    /**
     * The time when the job execution finished.
     *
     */
    jobEndTime: string;
    /**
     * The time when the job details were last changed.
     *
     */
    jobChangeTime: string;
    /**
     * The name of the user who created this job.
     *
     */
    createdBy: string;
    /**
     * The time when the job was created.
     *
     */
    createdDate: string;
    /**
     * The name of the [%product] instance to which this job is assigned
     *
     * This can also be set to "any". In this case, the job will be assigned to the next available instance.
     *
     */
    server: string;
    /**
     * Additional information or data that is needed or processed by the job.
     *
     * The type of the payload is determined by the jobType.
     *
     */
    payload: string;
    /**
     * The reason for failure if the job is failed.
     *
     */
    errorMessage?: string;
};

export namespace JobDTO {

    /**
     * The current status of the job.
     *
     * See below for allowed values.
     */
    export enum status {
        PENDING = 'PENDING',
        ASSIGNED = 'ASSIGNED',
        RUNNING = 'RUNNING',
        FINISHED = 'FINISHED',
        CANCELED = 'CANCELED',
        FAILED = 'FAILED',
    }


}

