import ActionTypes from '../actionTypes';
import { IGlobalState, TGlobalReducerActions } from './types';

export const globalReducerInitialState: IGlobalState = {
    working: 0,
    errors: [],
    searchString: '',
};

export const globalReducer = (
    action: TGlobalReducerActions,
    state: IGlobalState = globalReducerInitialState
): IGlobalState => {
    switch (action.type) {
        case ActionTypes.START_OPERATION:
            return {
                ...state,
                working: state.working + 1,
            };
        case ActionTypes.FINISH_OPERATION:
            return {
                ...state,
                working: state.working - 1,
            };
        case ActionTypes.ADD_ERROR:
            return {
                ...state,
                working: state.working - 1,
                errors: [...state.errors, action.payload.error],
            };
        case ActionTypes.UPDATE_SEARCH_STRING:
            return {
                ...state,
                searchString: action.payload.searchString,
            };
        default:
            return state;
    }
};
