import React, { FunctionComponent, useEffect, useState } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../components/ActionControlButton/ActionControlButton';
import TextInputDialog from '../../../../components/dialogs/InputDialog';
import Modal from '../../../../components/dialogs/Modal/Modal';
import DropZone from '../../../../components/DropZone/DropZone';
import { useGlobalStore } from '../../../../hooks/hooks';
import { ThumbnailPageProps } from '../../../../interfaces/DocumentEntry';
import { EErrorTypes } from '../../../../interfaces/globals/errors';
import { ThemeProps } from '../../../../interfaces/globals/theme';
import AxiosClient, { requestURLs } from '../../../../lib/axios';
import { handleImageUpload } from '../../../../lib/util';
import { emptyThumbnailPage } from '../../../../store/document/reducers';
import { addError, finishOperation, startOperation } from '../../../../store/global/actions';
import { ResponseError } from '../../../../typings/error';
import { BoolSwapState } from '../../../../typings/global';
import ImageHeader from './imageHeader/ImageHeader';
import PageNavigation from './pageNavigation/PageNavigation';
import ThumbnailPage from './thumbnail/ThumbnailPage';

interface ImageLibraryNew {
    openState: BoolSwapState;
    reloadData: BoolSwapState;
}

const ImageLibraryNew: FunctionComponent<ImageLibraryNew> = ({ openState, reloadData }) => {
    const { state, dispatch } = useGlobalStore();
    const { currentArticle } = state.documentManager;
    const [previousArticleID, setPreviousArticleID] = useState<number>(-1);
    const [thumbnailPage, setThumbnailPage] = useState<ThumbnailPageProps>(emptyThumbnailPage);
    const [currentPageNumber, setCurrentPageNumber] = useState<number>(0);
    const [previousPageNumber, setPreviousPageNumber] = useState<number>(-1);
    const [searchNeedle, setSearchNeedle] = useState<string>('');
    const [showInputDialog, setShowInputDialog] = useState<boolean>(false);
    const [transferData, setTransferData] = useState<FileList>();
    const currentTenant = state.config.tenant.current;
    const imagesPerPage = 6;

    useEffect(() => {
        if (
            currentPageNumber !== previousPageNumber ||
            currentArticle.articleID !== previousArticleID ||
            reloadData.value
        ) {
            if (openState) {
                dispatch(startOperation());
                AxiosClient.get(
                    // eslint-disable-next-line max-len
                    `${requestURLs.images}/thumbs/page/${currentPageNumber}?itemsPerPage=${imagesPerPage}&needle=${searchNeedle}`
                )
                    .then(response => setThumbnailPage(response.data))
                    .catch(error =>
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        )
                    )
                    .finally(() => dispatch(finishOperation()));
            }
            reloadData.set(false);
            setPreviousArticleID(currentArticle.articleID);
            setPreviousPageNumber(currentPageNumber);
        }
    }, [
        currentArticle.articleID,
        currentPageNumber,
        dispatch,
        openState,
        previousArticleID,
        previousPageNumber,
        reloadData,
        searchNeedle,
    ]);

    function handleSearch(needle: string) {
        setSearchNeedle(needle);
        dispatch(startOperation());
        AxiosClient.get(
            `${requestURLs.images}/thumbs/page/0?itemsPerPage=${imagesPerPage}&needle=${needle}`
        )
            .then(response => {
                setThumbnailPage(response.data);
                setCurrentPageNumber(0);
            })
            .catch(error => {
                dispatch(
                    addError(EErrorTypes.error, (error.response.data as ResponseError).status)
                );
            })
            .finally(() => dispatch(finishOperation()));
    }

    function handleOnDrop(e: React.DragEvent<HTMLDivElement>) {
        if (currentTenant?.autoNameImages) {
            const autoImageName = `Bild_${currentTenant.tenantID}_autoImageNameTmp`;
            handleImageUpload(autoImageName, reloadData, e.dataTransfer.files, dispatch);
        } else {
            setTransferData(e.dataTransfer.files);
            setShowInputDialog(true);
        }
    }

    function handleOnPaste(e: React.ClipboardEvent<HTMLDivElement>) {
        if (currentTenant?.autoNameImages) {
            const autoImageName = `Bild_${currentTenant.tenantID}_autoImageNameTmp`;
            handleImageUpload(autoImageName, reloadData, e.clipboardData.files, dispatch);
        } else {
            setTransferData(e.clipboardData.files);
            setShowInputDialog(true);
        }
    }

    function buildImageLibraryDropZone() {
        return (
            <DropZone
                id="image-library-dropzone"
                handleOnPaste={handleOnPaste}
                handleOnDrop={handleOnDrop}
            >
                <h3>Bild Upload</h3>
                <InnerDropZone>
                    <p>Ziehen Sie die Bilder in diesen Bereich</p>
                </InnerDropZone>
            </DropZone>
        );
    }

    return (
        <Modal
            id="image-libary"
            isOpen={openState.value}
            shouldCloseOnOverlayClick
        >
            <InnerModal>
                {buildImageLibraryDropZone()}
                <ImageSliderWrapper>
                    <ImageHeader
                        handleSearch={handleSearch}
                        showAdditionalControls={false}
                    />
                    <ThumbnailPage
                        openImageLibrary={openState.set}
                        thumbnailPage={thumbnailPage}
                        openState={openState.value}
                        reloadData={reloadData}
                        showAdditionalControls={false}
                    />
                    <PageNavigation
                        thumbnailPages={thumbnailPage}
                        currentPageNumberState={{
                            value: currentPageNumber,
                            set: setCurrentPageNumber,
                        }}
                    />
                </ImageSliderWrapper>
                <ActionControlButton
                    prefix="close"
                    name="close"
                    className="close-btn"
                    callback={() => openState.set(false)}
                    size={26}
                />
            </InnerModal>
            <TextInputDialog
                id="add-title-to-image"
                title="Neues Image hochladen"
                message="Geben Sie einen Titel ein und bestätigen Sie mit OK"
                onCancel={() => {
                    setShowInputDialog(false);
                }}
                onOK={() => {
                    const inputValueFromDialog = (
                        document.getElementById('add-title-to-image-input') as HTMLInputElement
                    ).value;
                    if (transferData)
                        handleImageUpload(inputValueFromDialog, reloadData, transferData, dispatch);
                }}
                isOpenState={[showInputDialog, setShowInputDialog]}
            />
        </Modal>
    );
};

const InnerModal = styled.div<ThemeProps>`
    display: flex;
    height: 675px;
    margin: auto;

    .close-btn {
        margin: 10px;
        padding: 0;
        right: 0%;
        top: 0%;
        justify-content: flex-end;
        position: absolute;
        border: none;
        background-color: inherit;
    }
`;

const ImageSliderWrapper = styled.div<ThemeProps>`
    height: 100%;
    display: flex;
    flex-direction: column;
`;

const InnerDropZone = styled.div<ThemeProps>`
    flex: 1;
    height: 565px;
    display: flex;
    flex-direction: column;
    align-items: center;
    padding: 20px;
    border-width: 2px;
    border-radius: 2px;
    border-style: dashed;
    background-color: #fafafa;
    color: #bdbdbd;
    outline: none;
    transition: border 0.24s ease-in-out;
    border-color: #eeeeee;

    p {
        margin: auto;
    }
`;

export default ImageLibraryNew;
