import React, { FunctionComponent, ReactNode, useCallback, useEffect } from 'react';
import styled from 'styled-components';
import { BoolSwapState } from '../../../typings/global';

interface ProfileModalProps {
    id: string;
    showModalState: BoolSwapState;
    className?: string;
    styles?: CSSStyleDeclaration;
    children?: ReactNode;
}

const ProfileModal: FunctionComponent<ProfileModalProps> = ({
    children,
    id,
    className,
    styles,
    showModalState,
}) => {
    const toggleModalStyles = useCallback(() => {
        const element = document.getElementById(id);

        if (!element) return;
        if (showModalState.value) element.classList.add('show');
        else element.classList.remove('show');
    }, [id, showModalState.value]);

    const handleShow = useCallback(() => {
        const modal = document.getElementById(id);
        const wrapper = document.getElementById(`wrapper-${id}`);

        if (
            document.activeElement !== modal &&
            document.activeElement !== wrapper &&
            !modal?.contains(document.activeElement) &&
            modal?.classList.contains('show')
        ) {
            showModalState.set(false);
            document.getElementById(id)?.classList.remove('show');
        }
    }, [id, showModalState]);

    useEffect(() => {
        toggleModalStyles();
    }, [showModalState.value, toggleModalStyles]);

    useEffect(() => {
        window.addEventListener('click', handleShow, false);
        return () => window.removeEventListener('click', handleShow, false);
    }, [handleShow]);

    return (
        <ToggleWrapper
            id={`wrapper-${id}`}
            styles={styles}
        >
            <ProfileModalWrapper
                id={id}
                className={className}
                styles={styles}
                tabIndex={-1}
            >
                {children}
            </ProfileModalWrapper>
        </ToggleWrapper>
    );
};

const ProfileModalWrapper = styled.div<{ styles: CSSStyleDeclaration | undefined }>`
    height: 200px;
    width: 250px;
    visibility: hidden;
    opacity: 0;
    box-shadow: rgba(0, 0, 0, 0.24) 0px 3px 8px;
    position: ${props => props.style?.position || 'absolute'};
    top: ${props => props.style?.top || '3rem'};
    right: ${props => props.style?.right || '.5rem'};
    background-color: ${props => props.style?.backgroundColor || '#ffffff'};
    transition: ${props => props.style?.transition || '0.2s'};
    z-index: ${props => props.style?.zIndex || '999'};

    :focus {
        outline: none;
    }
`;

const ToggleWrapper = styled.div<{ styles: CSSStyleDeclaration | undefined }>`
    .show {
        visibility: ${props => props.styles?.visibility || 'visible'};
        height: ${props => props.styles?.height || '250px'};
        width: ${props => props.styles?.width || '350px'};
        opacity: ${props => props.styles?.opacity || '1'};
    }
`;

export default ProfileModal;
