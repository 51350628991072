import React, { FunctionComponent, ReactElement } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../../interfaces/globals/theme';
import { LanguageInformation } from '../../../typings/mgmt';
import ChangeInformation from '../informationheader/ChangeInformation';
import EditTenantInformation from './managedelements/EditTenantInformation';
import EditCategoryInformation from './managedelements/EditCategoryInformation';
import MetaInformation from '../informationheader/MetaInformation';
import { DocumentCategoryProps } from '../../../interfaces/DocumentEntry';
import EditLanguageInformation from './managedelements/EditLanguageInformation';
import { TenantInformation } from '../../../store/config/types';

interface CurrentUserPermissionState {
    newLengthOfTenantsList: [number, Function];
    newLengthOfCategoryList: [number, Function];
    currentTenantState: [TenantInformation, Function];
    currentCategoryState: [DocumentCategoryProps | undefined, Function];
    currentLanguageState: [LanguageInformation | undefined, Function];
    activeTap: string;
    reloadTenants: Function;
}

const DetailsView: FunctionComponent<CurrentUserPermissionState> = ({
    newLengthOfTenantsList,
    newLengthOfCategoryList,
    currentTenantState,
    currentCategoryState,
    currentLanguageState,
    activeTap,
    reloadTenants,
}): ReactElement => (
    <ProfilDetailsWrapper>
        <DetailsWrapper>
            {activeTap === 'tenants' && (
                <>
                    <MetaInformation
                        currentTenantState={currentTenantState}
                        reloadTenants={reloadTenants}
                    />
                    <ChangeInformation
                        createdBy={currentTenantState[0].createdBy}
                        createdDate={currentTenantState[0].createdDate}
                        lastModifiedBy={currentTenantState[0].lastModifiedBy}
                        lastModifiedDate={currentTenantState[0].lastModifiedDate}
                    />
                </>
            )}
            {activeTap === 'category' && (
                <MetaInformation currentCategoryState={currentCategoryState} />
            )}
        </DetailsWrapper>
        {activeTap === 'tenants' && (
            <EditTenantInformation
                tenant={currentTenantState}
                newLengthOfTenantsList={newLengthOfTenantsList}
            />
        )}
        {activeTap === 'categories' && (
            <EditCategoryInformation
                category={currentCategoryState}
                newLengthOfCategoryList={newLengthOfCategoryList}
            />
        )}
        {activeTap === 'languages' && <EditLanguageInformation language={currentLanguageState} />}
    </ProfilDetailsWrapper>
);

const ProfilDetailsWrapper = styled.div<ThemeProps>`
    width: 80%;
`;

const DetailsWrapper = styled.div<ThemeProps>`
    display: flex;
    margin: 28px 10px 0px 10px;
`;

export default DetailsView;
