/* eslint-disable react/require-default-props */
import React, { forwardRef, ReactElement, ReactNode } from 'react';
import styled from 'styled-components';
import { ThemeProps } from '../../interfaces/globals/theme';
import { GridParent } from '../../styles/sharedStyles';

interface FormProps {
    id?: string;
    children?: ReactNode;
}

// eslint-disable-next-line @typescript-eslint/no-explicit-any
type Ref = any;

const Form = forwardRef<Ref, FormProps>(
    ({ id, children }, ref): ReactElement => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <Wrapper
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={ref as any}
            id={id}
            className="styledScrollBar"
        >
            {children}
        </Wrapper>
    )
);

export const FormFields = forwardRef<Ref, FormProps>(
    ({ id, children }, ref): ReactElement => (
        // eslint-disable-next-line @typescript-eslint/no-explicit-any
        <fieldset
            id={id}
            // eslint-disable-next-line @typescript-eslint/no-explicit-any
            ref={ref as any}
        >
            <GridParent>{children}</GridParent>
        </fieldset>
    )
);

const Wrapper = styled.div<ThemeProps>`
    display: block;
    width: 100%;
    overflow: auto;

    fieldset {
        overflow-y: auto;
        border: none;
        box-sizing: border-box;
    }

    footer {
        display: flex;
        flex-direction: row;
        justify-content: flex-end;
        box-sizing: border-box;
        vertical-align: middle;
    }
`;

export default Form;
