import React, {
    FunctionComponent,
    ReactElement,
    useState,
    useLayoutEffect,
    useEffect,
    useCallback,
} from 'react';
import key from 'weak-key';
import styled from 'styled-components';
import { Buffer } from 'buffer';
import { ThemeProps } from '../../interfaces/globals/theme';
import Table, { TableModelEntryProps } from '../../components/Table/Table';
import { useGlobalStore } from '../../hooks/hooks';
import { startOperation, finishOperation, addError } from '../../store/global/actions';
import AxiosClient, { requestURLs } from '../../lib/axios';
import { t } from '../../lib/language';
import { EErrorTypes } from '../../interfaces/globals/errors';
import LoadingScreen from '../../views/LoadingScreen';
import { ResponseError } from '../../typings/error';
import DocumentRow from './DocumentRow';
import { Publication } from '../../typings/publication';
import Search from '../search/Search';
import SearchFallbackSite from '../search/SearchFallbackSite';
import { ITenant } from '../../store/config/types';
import { SearchRequestBody } from '../../typings/search';
import { getHighestAudience } from '../../interfaces/Audiences';

const selectAvailableLanguage = (publication: Publication, preferredLanguage: string) => {
    if (publication.descriptions[preferredLanguage] !== undefined) {
        return preferredLanguage;
    }
    return Object.keys(publication.descriptions)[0];
};

const Compendium: FunctionComponent = (): ReactElement => {
    const { state, dispatch } = useGlobalStore();
    const [publications, setPublications] = useState<{ [documentID: string]: Publication }>();
    const [startLoading, setStartLoading] = useState<boolean>(false);
    const [reloadDocuments, setReloadDocuments] = useState<boolean>(false);
    const [showHowToSearch, setShowHowToSearch] = useState<boolean>(false);
    const [tenant, setTenant] = useState<ITenant | null>(state.config.tenant.current);
    const system = process.env.REACT_APP_SERVER;
    const currentTenant = state.config.tenant.current;
    const searchID = 'search_compendium';

    const compendiumTableModel: { type: string; entries: TableModelEntryProps[] } = {
        type: 'Compendium',
        entries: [
            { id: 'internalDocumentID', width: '3rem', columnTitle: t('ID') },
            { id: 'descriptions.selectedLanguage.title', width: '20rem', columnTitle: t('Titel') },
            {
                id: 'versions.selectedVersion.whatsNew',
                width: '4rem',
                columnTitle: t('News'),
            },
            { id: 'product', width: '4rem', columnTitle: t('Produkt') },
            {
                id: 'versions.selectedVersion!',
                width: '3rem',
                columnTitle: t('Version'),
            },
            { id: '', width: '5rem', columnTitle: t('Zielgruppe') },
            { id: '', width: '3rem', columnTitle: t('Sprache') },
        ],
    };

    if (currentTenant && currentTenant.name !== 'Training&Education') {
        compendiumTableModel.entries.push(
            { id: '', width: '2rem', columnTitle: t('PDF') },
            {
                id: 'versions.selectedVersion.selectedAudience.hasReport',
                width: '2rem',
                columnTitle: t('Bericht'),
            }
        );
    }

    const updateLanguages = useCallback(
        (elements: { [documentID: string]: Publication }) => {
            Object.keys(elements).forEach(documentID => {
                const entry = elements[documentID];
                const selAudience = 0;
                const preferredLanguage =
                    state.config.language.current &&
                    elements[documentID].descriptions[state.config.language.current.code]
                        ? state.config.language.current.code
                        : 'de_DE';
                const selLanguage = selectAvailableLanguage(entry, preferredLanguage);
                const selVersion = Object.keys(entry.versions).sort((a, b) => {
                    if (a < b) return 1;
                    if (a > b) return -1;
                    return 0;
                })[0];
                entry.selectedAudience = selAudience;
                entry.selectedLanguage = selLanguage;
                entry.selectedVersion = selVersion;
            });
            return elements;
        },
        [state.config.language]
    );

    const deepSearch = useCallback(
        (needle: string | null = null) => {
            if (system !== 'intern' && state.user.permissions[''] === undefined) {
                setPublications({});
                return;
            }
            setStartLoading(true);
            dispatch(startOperation());
            if (state.config.tenant.current) {
                const url =
                    system === 'intern'
                        ? `${requestURLs.compendiumDocuments}${state.config.tenant.current.tenantID}/search`
                        : `${
                              requestURLs.compendiumDocumentsAudience
                              // eslint-disable-next-line prettier/prettier
                          }${getHighestAudience(state.user.permissions[''])}/search`;
                const requestBody: SearchRequestBody = {
                    keywordSearch: null,
                    deepSearch: null,
                    languageCode: null,
                };

                if (needle) {
                    if (needle.startsWith('$'))
                        requestBody.deepSearch = Buffer.from(needle).toString('base64');
                    else requestBody.keywordSearch = Buffer.from(needle).toString('base64');
                }

                AxiosClient.post(url, requestBody)
                    .then(response => {
                        const elements: { [documentID: string]: Publication } = response.data;

                        if (Object.keys(elements).length === 0) {
                            setShowHowToSearch(true);
                            setPublications({});
                        } else {
                            setShowHowToSearch(false);
                            setPublications(updateLanguages(elements));
                        }
                    })
                    .catch(error => {
                        dispatch(
                            addError(
                                EErrorTypes.error,
                                (error.response.data as ResponseError).status
                            )
                        );
                    })
                    .finally(() => {
                        setStartLoading(false);
                        dispatch(finishOperation());
                    });
            }
        },
        [dispatch, state.config.tenant, state.user.permissions, system, updateLanguages]
    );

    useEffect(() => {
        if (state.config.tenant.current !== tenant) {
            setReloadDocuments(true);
            setTenant(state.config.tenant.current);
            deepSearch(state.global.searchString);
        }
    }, [tenant, state.config.tenant, state.global.searchString, deepSearch]);

    useLayoutEffect(() => {
        const element = document.getElementsByClassName('highlightedDocument')[0] as HTMLElement;
        if (element) element.scrollIntoView({ behavior: 'smooth', block: 'nearest' });
    });

    function documentRow(entry: Publication, index: number) {
        return (
            <DocumentRow
                key={key(entry)}
                entry={entry}
                index={index}
            />
        );
    }

    return (
        <Wrapper data-testid="compendium">
            <SearchContainer>
                <Search
                    searchID={searchID}
                    deepSearch={deepSearch}
                    reloadDocuments={{ value: reloadDocuments, set: setReloadDocuments }}
                    autofocus
                />
            </SearchContainer>
            {(startLoading || !publications) && <LoadingScreen />}
            {publications && Object.keys(publications).length > 0 && !startLoading && (
                <Table
                    model={compendiumTableModel}
                    entries={publications}
                    renderEntry={documentRow}
                    initialSortOrder={{
                        key: 'descriptions.selectedLanguage.title',
                        direction: 'ascending',
                    }}
                />
            )}
            {showHowToSearch && !startLoading && <SearchFallbackSite search={deepSearch} />}
            {system === 'intern' && (
                <Feedback>
                    {`${t('Themenvorschläge')} `}
                    <a href="mailto:compendium@visus.com?subject=[Feedback]%20">
                        compendium@visus.com
                    </a>
                </Feedback>
            )}
        </Wrapper>
    );
};

const SearchContainer = styled.div<ThemeProps>`
    display: flex;
    width: 95%;
    margin: 1rem 0 0 0;
    flex-direction: row;
    justify-content: flex-end;
`;

const Wrapper = styled.div<ThemeProps>`
    display: flex;
    flex-direction: column;
    flex: 1;
    height: 100%;
    overflow: auto;

    .single_flag {
        height: 16px;
    }

    .single_value {
        width: 100%;
        text-align: center;
        margin: 0.64rem 0;

        span {
            padding-right: 37px;
        }
    }

    .highlightedDocument {
        background-color: ${props => props.theme.$colorBackgroundSecondary} !important;
        color: ${props => props.theme.$colorFontSelected};
    }

    .compendium {
        font-size: ${props => props.theme.$headerStyles.medium.h6.fontSize}rem;
    }

    .compendium__control {
        border: none;
    }

    .compendium__control--is-focused .compendium__control--is-open {
        border: none;

        :hover {
            border: none;
        }
    }

    .compendium__option {
        :hover {
            background: ${props => props.theme.$colorPrimary};
        }
    }

    .compendium__option--is-selected {
        background: ${props => props.theme.$colorSecondary};
    }

    .compendium__control--is-disabled {
        background-color: inherit;
    }

    .compendium__value-container {
        justify-content: center;
    }

    .compendium__single-value--is-disabled {
        color: inherit;
    }

    .compendium__indicator {
        :hover {
            color: ${props => props.theme.$colorPrimary};
        }
    }
`;

const Feedback = styled.div<ThemeProps>`
    white-spae: nowrap;
    text-align: center;
    margin-bottom: 0.5rem;
`;

export default Compendium;
