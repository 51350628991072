import React, { FunctionComponent, useState } from 'react';
import styled from 'styled-components';
import ActionControlButton from '../../../../../components/ActionControlButton/ActionControlButton';
import ConfirmationDialog from '../../../../../components/dialogs/ConfirmationDialog';
import { useGlobalStore } from '../../../../../hooks/hooks';
import { ImageTranslationProps } from '../../../../../interfaces/DocumentEntry';
import { ThemeProps } from '../../../../../interfaces/globals/theme';
import { ToolBarWrapper } from './thumnbnailHeader/ThumbnailHeader';

interface ThumbnailTranslationProps {
    thumbnail: ImageTranslationProps;
    deleteThumbnail: Function;
}

const ThumbnailTranslation: FunctionComponent<ThumbnailTranslationProps> = ({
    thumbnail,
    deleteThumbnail,
}) => {
    const { state } = useGlobalStore();
    const [isImageTranslationDelete, setImageTranslationDelete] = useState<boolean>(false);
    const languageOfImage = Object.values(state.config.language.list).find(
        lang => lang.languageID === thumbnail.languageID
    );

    return (
        <MainThumbnailWrapper>
            <ThumbnailTranslationWrapper className="thumbnail-translation-wrapper">
                <ToolBarWrapper className="btn-tool-bar-translations">
                    <ActionControlButton
                        className="tool-bar-btns"
                        prefix="image"
                        name="delete"
                        callback={() => setImageTranslationDelete(true)}
                        size={25}
                        fill
                    />
                </ToolBarWrapper>
                <img
                    data-id={thumbnail.imageTranslationID}
                    style={{ maxWidth: 150, maxHeight: 150 }}
                    alt={thumbnail.title}
                    src={window.atob(thumbnail.data)}
                    draggable={false}
                />
            </ThumbnailTranslationWrapper>
            <p>Sprache: {languageOfImage ? languageOfImage.name : 'Keine Sprache gefunden!'}</p>
            <ConfirmationDialog
                id="image-delete-confirmation"
                title="Bildüberstzung löschen"
                message="Wollen Sie das Bild wirklich entfernen?"
                onCancel={() => {
                    setImageTranslationDelete(false);
                }}
                onOK={() => deleteThumbnail(thumbnail.imageTranslationID)}
                isOpenState={{
                    value: isImageTranslationDelete,
                    set: setImageTranslationDelete,
                }}
            />
        </MainThumbnailWrapper>
    );
};

const ThumbnailTranslationWrapper = styled.div<ThemeProps>`
    background-color: white;
    height: 150px;
    width: 150px;
    margin: 5px;
    position: relative;
    border: 0.5px solid black;
`;

const MainThumbnailWrapper = styled.div<ThemeProps>`
    height: 185px;

    p {
        margin: 0;
        padding: 0;
        margin-left: 5px;
    }

    .thumbnail-translation-wrapper:hover {
        .btn-tool-bar-translations {
            opacity: 0.9;
        }
    }
`;

export default ThumbnailTranslation;
